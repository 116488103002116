import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { InterstitialPage } from './interstitial';
import { SharedModule } from '../../../shared';
import { translateConfig } from '../../config/translate.config';
import { CommonModule } from '@angular/common';
import { InterstitialWeightCapturePage } from './interstitial-weight-capture';
import { InterstitialDppCongratsPage } from './interstitial-dpp-congrats';
import { InterstitialDppLockPage } from './interstitial-dpp-lock';
import { InterstitialLockedPage } from './interstitial-locked';
import { InterstitialDefaultPage } from './interstitial-default';
import { InterstitialLessonNotSupportedPage } from './interstitial-lesson-not-supported';

@NgModule({
  declarations: [
    InterstitialPage,
    InterstitialDefaultPage,
    InterstitialLockedPage,
    InterstitialWeightCapturePage,
    InterstitialDppCongratsPage,
    InterstitialDppLockPage,
    InterstitialLessonNotSupportedPage
  ],
  imports: [
    IonicModule,
    CommonModule,
    RouterModule.forChild([{ path: '', component: InterstitialPage }]),
    TranslateModule.forChild(translateConfig),
    SharedModule
  ]
})
export class InterstitialPageModule {}
