import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, filter, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import * as myCoachActions from '../actions/my-coach.actions';
import { ConversationsFilterParams, MyCoachProvider } from '../../../providers/my-coach.provider';
import { AddChildData, AddData, RemoveChildData, SetData } from 'ngrx-normalizr';
import {
  Conversation,
  ConversationFeed,
  conversationFeedSchema,
  conversationSchema,
  Message,
  MessageFeed,
  messageFeedSchema,
  messageSchema,
  myCoachNotificationsFeedSchema
} from '../../normalized/schemas/my-coach.schema';
import { Store } from '@ngrx/store';
import { SessionState } from '../session.reducers';
import { conversationFeedId, messageFeedId, myCoachNotificationFeedId } from '../reducers/my-coach.reducer';
import {
  getConversationFeedById,
  getConversationsFilter,
  getConversationsFromFeed,
  getConversationsPage,
  getMessageFeedById,
  getMyCoachCurrentNotificationFeed,
  getOpenConversationId
} from '../selectors/my-coach.selectors';
import { ToastService } from '../../../services/toast.service';
import { getCurrentUserProfile } from '../../normalized/selectors/user-profile.selectors';
import { UserProfile } from '../../normalized/schemas/user.schema';
import { IridiumProvider } from '../../../services/analytics/iridium/iridium.provider';
import { Observable, of } from 'rxjs';
import { UserProvider } from 'src/app/providers/user.provider';
import { OpenModal } from '../actions/navigation.actions';
import { UserProfilePage } from 'src/app/pages/user-profile/user-profile';
import { NotificationFeed } from '../../normalized/schemas/inbox.schema';
import { isIridiumActivated } from '../selectors/iridium.selectors';
import { LoggerService } from '../../../services/logger.service';
import { HttpErrorResponse } from '@angular/common/http';

const currentConversationFeedId: string = conversationFeedId();
const currentMessageFeedId: string = messageFeedId();

@Injectable({providedIn: 'root'})
export class MyCoachEffects {


  notifyNewMessage$ = createEffect(() => this.actions$.pipe(ofType(myCoachActions.NOTIFY_NEW_MESSAGE),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getOpenConversationId)))
    ),
    map(([action, openConversationId]: [myCoachActions.NotifyNewMessage, any]) => {
      const conversationId = parseInt(openConversationId, 10);

      if (!conversationId || action.payload.conversation_id !== conversationId) {
        return new myCoachActions.LoadUnreadMessagesCount();
      }

      return new myCoachActions.LoadMessagesSilent(action.payload.conversation_id);
    })
  ));


  markConversationAsRead$ = createEffect(() => this.actions$.pipe(ofType<myCoachActions.MarkConversationAsRead>(myCoachActions.MARK_CONVERSATION_AS_READ),
    map((action) => action.payload),
    switchMap((conversationId) => this.iridiumProvider.markConversationAsRead(conversationId)
      .pipe(
        map(() => new myCoachActions.MarkConversationAsReadSuccess(conversationId))
      ))));


  markConversationAsReadSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<myCoachActions.MarkConversationAsReadSuccess>(myCoachActions.MARK_CONVERSATION_AS_READ_SUCCESS),
    map((action) => action.payload),
    concatMap(conversationId => of(conversationId)
      .pipe(withLatestFrom(this.store.select(getConversationsFromFeed)))
    ),
    switchMap(([conversationId, conversations]) => {
      const actions = [];
      const conversation = conversations.find(c => c.id === conversationId);

      if (conversation) {
        conversation.unread_messages_count = 0;

        actions.push(
          new AddData({
            data: [conversation],
            schema: conversationSchema
          })
        );
      }

      return [
        ...actions,
        new myCoachActions.LoadUnreadMessagesCount(),
        new myCoachActions.LoadMyCoachNotifications()
      ];
    })
  ));


  addConversation$ = createEffect(() => this.actions$.pipe(ofType(myCoachActions.CREATE_CONVERSATION),
    switchMap((action: myCoachActions.CreateConversation) => this.iridiumProvider.addConversation(action.payload)
      .pipe(
        mergeMap((conversation: any) => [
          new myCoachActions.LoadConversations(),
          new AddChildData({
            data: [conversation],
            childSchema: conversationSchema,
            parentSchema: conversationFeedSchema,
            parentId: currentConversationFeedId
          }),
          new myCoachActions.CreateConversationSuccess(conversation),
          new myCoachActions.NewConversationId(conversation.id)
        ]),
        // there is a difference between catching errors inside the switchMap observable
        // and catching them outside due to how effects work
        // https://stackblitz.com/edit/ngrx-effects-catcherror-inner-stream-qp4bua?file=src%2Fapp%2Feffects%2Ffoo.effects.ts
        catchError((error) => of(new myCoachActions.CreateConversationFail(error)))
      ))
  ));


  newConversation$ = createEffect(() => this.actions$.pipe(ofType(myCoachActions.NEW_CONVERSATION),
    mergeMap(() => [
      new SetData<MessageFeed>({
        data: [{
          list: [],
          total_count: 0,
          conversation_id: null,
          id: currentMessageFeedId
        }],
        schema: messageFeedSchema
      })
    ])
  ));


  deleteConversation$ = createEffect(() => this.actions$.pipe(ofType(myCoachActions.DELETE_CONVERSATION),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getConversationFeedById)))
    ),
    switchMap(([action, conversationFeed]: [myCoachActions.DeleteConversation, ConversationFeed]) => {
      const conversationId = action.payload;

      return this.iridiumProvider.deleteConversation(conversationId)
        .pipe(
          mergeMap(() => [
            new SetData<ConversationFeed>({
              data: [{
                ...conversationFeed,
                total_count: conversationFeed.total_count - 1,
                id: currentConversationFeedId
              }],
              schema: conversationFeedSchema
            }),
            new RemoveChildData({
              id: action.payload,
              childSchema: conversationSchema,
              parentSchema: conversationFeedSchema,
              parentId: currentConversationFeedId
            }),
            new myCoachActions.DeleteConversationSuccess(action.payload)
          ])
        );
    }),
    catchError((error, caught) => {
      this.store.dispatch(new myCoachActions.DeleteConversationFail(error));

      return caught;
    })
  ));

  deleteCoachMessage$ = createEffect(() => this.actions$.pipe(ofType<myCoachActions.DeleteCoachMessage>(myCoachActions.DELETE_COACH_MESSAGE),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getMessageFeedById)))
    ),
    mergeMap(([action, messageFeed]) => [
      new SetData<MessageFeed>({
        data: [{
          ...messageFeed,
          total_count: messageFeed.total_count - 1,
          id: currentMessageFeedId
        }],
        schema: messageFeedSchema
      }),
      new RemoveChildData({
        id: action.payload,
        childSchema: messageSchema,
        parentSchema: messageFeedSchema,
        parentId: currentMessageFeedId
      }),
      new myCoachActions.DeleteCoachMessageSuccess(action.payload),
      new myCoachActions.RefreshConversations(),
      new myCoachActions.LoadUnreadMessagesCount()
    ])
  ));

  deleteMessage$ = createEffect(() => this.actions$.pipe(ofType(myCoachActions.DELETE_MESSAGE),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getMessageFeedById)))
    ),
    switchMap(([action, messageFeed]: [myCoachActions.DeleteMessage, MessageFeed]) => {
      const messageId = action.payload;

      return this.iridiumProvider.deleteMessage(messageId)
        .pipe(
          mergeMap(() => [
            new SetData<MessageFeed>({
              data: [{
                ...messageFeed,
                total_count: messageFeed.total_count - 1,
                id: currentMessageFeedId
              }],
              schema: messageFeedSchema
            }),
            new RemoveChildData({
              id: messageId,
              childSchema: messageSchema,
              parentSchema: messageFeedSchema,
              parentId: currentMessageFeedId
            }),
            new myCoachActions.DeleteMessageSuccess(messageId),
            new myCoachActions.RefreshConversations()
          ])
        );
    }),
    catchError((error, caught) => {
      this.store.dispatch(new myCoachActions.DeleteMessageFail(error));

      return caught;
    })
  ));


  deleteMessageFail$ = createEffect(() => this.actions$.pipe(ofType(myCoachActions.DELETE_MESSAGE_FAIL),
    tap(() => this.toastService.translateError('errors.common.generic_error_please_retry')),
    tap((error: {type: string; payload: HttpErrorResponse}) => this.logger.error(error.type, error.payload, 'my-coach.effects -> deleteMessageFail$'))
  ), {dispatch: false});


  deleteMessageSuccess$ = createEffect(() => this.actions$.pipe(ofType(myCoachActions.DELETE_MESSAGE_SUCCESS),
    tap(() => this.toastService.translateConfirm('my-coach.conversation.message_deleted'))
  ), {dispatch: false});


  addConversationFail$ = createEffect(() => this.actions$.pipe(ofType(myCoachActions.CREATE_CONVERSATION_FAIL),
    tap(() => this.toastService.translateError('errors.common.generic_error_please_retry')),
    tap((error: {type: string; payload: HttpErrorResponse}) => this.logger.error(error.type, error.payload, 'my-coach.effects -> addConversationFail$'))
  ), {dispatch: false});


  deleteConversationFail$ = createEffect(() => this.actions$.pipe(ofType(myCoachActions.DELETE_CONVERSATION_FAIL),
    tap(() => this.toastService.translateError('errors.common.generic_error_please_retry')),
    tap((error: {type: string; payload: HttpErrorResponse}) => this.logger.error(error.type, error.payload, 'my-coach.effects -> deleteConversationFail$'))
  ), {dispatch: false});


  deleteConversationSuccess$ = createEffect(() => this.actions$.pipe(ofType(myCoachActions.DELETE_CONVERSATION_SUCCESS),
    tap(() => this.toastService.translateConfirm('my-coach.conversations.conversation_deleted'))
  ), {dispatch: false});


  loadAllMessagesFail$ = createEffect(() => this.actions$.pipe(ofType(myCoachActions.LOAD_ALL_MESSAGES_FAIL),
    tap(() => this.toastService.translateError('errors.common.generic_error_please_retry')),
    tap((error: {type: string; payload: HttpErrorResponse}) => this.logger.error(error.type, error.payload, 'my-coach.effects -> loadAllMessagesFail$'))
  ), {dispatch: false});


  loadMessagesFail$ = createEffect(() => this.actions$.pipe(ofType(myCoachActions.LOAD_MESSAGES_FAIL),
    tap(() => this.toastService.translateError('errors.common.generic_error_please_retry')),
    tap((error: {type: string; payload: HttpErrorResponse}) => this.logger.error(error.type, error.payload, 'my-coach.effects -> loadMessagesFail$'))
  ), {dispatch: false});


  refreshConversations$ = createEffect(() => this.actions$.pipe(
    ofType(myCoachActions.REFRESH_CONVERSATIONS, myCoachActions.CHANGE_CONVERSATIONS_FILTER),
    concatMap(action => of(action)
      .pipe(withLatestFrom(
        this.store.select(getConversationsFilter),
        this.store.select(getCurrentUserProfile)
      ))
    ),
    switchMap(([action, conversationsFilter, profile]: [myCoachActions.ChangeConversationsFilter, string[], UserProfile]) => {
      const params: ConversationsFilterParams = {};

      if (!conversationsFilter) {
        return this.iridiumProvider.loadConversations(params, 1);
      }

      if (conversationsFilter.includes('mine')) {
        params.profile_id = parseInt(profile.id, 10);
      }

      if (conversationsFilter.includes('closed')) {
        params.status = 'closed';
      } else if (conversationsFilter.includes('open')) {
        params.status = 'open';
      } else if (conversationsFilter.includes('new')) {
        params.new = true;
      }

      return this.iridiumProvider.loadConversations(params, 1);
    }),
    mergeMap((conversations) => [
      new SetData<ConversationFeed>({
        data: [{
          total_count: conversations.total_count,
          list: conversations.list,
          id: currentConversationFeedId
        }],
        schema: conversationFeedSchema
      }),
      new myCoachActions.LoadConversationsSuccess(conversations)
    ]),
    catchError((error, caught) => {
      this.store.dispatch(new myCoachActions.LoadConversationsFail(error));

      return caught;
    })
  ));


  loadConversations$ = createEffect(() => this.actions$.pipe(
    ofType(myCoachActions.LOAD_CONVERSATIONS, myCoachActions.LOAD_CONVERSATIONS_SILENT),
    concatMap(action => of(action)
      .pipe(withLatestFrom(
        this.store.select(getConversationsFilter),
        this.store.select(getCurrentUserProfile),
        this.store.select(getConversationsPage)
      ))
    ),
    switchMap(([action, conversationsFilter, currentUserProfile, page]:
                 [myCoachActions.LoadConversationsSilent, string[], UserProfile, number]) => {
      const params: ConversationsFilterParams = {};

      if (!conversationsFilter) {
        return this.iridiumProvider.loadConversations(params, page);
      }

      if (conversationsFilter.includes('mine')) {
        params.profile_id = parseInt(currentUserProfile.id, 10);
      }

      if (conversationsFilter.includes('closed')) {
        params.status = 'closed';
      } else if (conversationsFilter.includes('open')) {
        params.status = 'open';
      } else if (conversationsFilter.includes('new')) {
        params.new = true;
      }

      return this.iridiumProvider.loadConversations(params, page);
    }),
    mergeMap((conversations) => [
      new AddChildData<Conversation>({
        data: conversations.list,
        childSchema: conversationSchema,
        parentSchema: conversationFeedSchema,
        parentId: currentConversationFeedId
      }),
      new myCoachActions.LoadConversationsSuccess(conversations)
    ]),
    catchError((error, caught) => {
      this.store.dispatch(new myCoachActions.LoadConversationsFail(error));

      return caught;
    })
  ));


  loadMessages$ = createEffect(() => this.actions$.pipe(
    ofType<myCoachActions.LoadMessages | myCoachActions.LoadAllMessages | myCoachActions.LoadMessagesSilent>(
      myCoachActions.LOAD_ALL_MESSAGES, myCoachActions.LOAD_MESSAGES, myCoachActions.LOAD_MESSAGES_SILENT
    ),
    switchMap((action) => {
      const handler = action instanceof myCoachActions.LoadAllMessages ? 'loadAllMessages' : 'loadMessages';
      const response$: Observable<Message[] | MessageFeed> = this.iridiumProvider[handler](action.payload);

      return response$
        .pipe(
          map((response) => {
            if (action instanceof myCoachActions.LoadAllMessages) {
              response = response as Message[];

              return this.buildMessagesFeed({list: response, total_count: response.length});
            }

            response = response as MessageFeed;

            return this.buildMessagesFeed(response);
          })
        );
    }),
    mergeMap((messagesFeed: MessageFeed) => {
      const actions = [];
      const conversationId = this.extractConversationId(messagesFeed.list);

      actions.push(new SetData<MessageFeed>({
        data: [{
          ...messagesFeed,
          id: currentMessageFeedId
        }],
        schema: messageFeedSchema
      }));

      actions.push(new myCoachActions.LoadUnreadMessagesCount());

      actions.push(new myCoachActions.LoadMessagesSuccess(conversationId));

      actions.push(new myCoachActions.RefreshConversations());

      return actions;
    }),
    catchError((error, caught) => {
      this.store.dispatch(new myCoachActions.LoadAllMessagesFail(error));

      return caught;
    })
  ));

  loadUnreadMessagesCount$ = createEffect(() => this.actions$.pipe(ofType(myCoachActions.LOAD_UNREAD_MESSAGES_COUNT),
    switchMap(() => this.store.select(isIridiumActivated)
      .pipe(
        filter((activated) => activated),
        take(1),
        switchMap(() => this.iridiumProvider.loadUnreadMessagesCount()
          .pipe(
            map((count: number) => new myCoachActions.LoadUnreadMessagesCountSuccess(count)),
            catchError((error) => of(new myCoachActions.LoadUnreadMessagesCountFail(error)))
          ))
      ))
  ));


  openUserProfileDetails$ = createEffect(() => this.actions$.pipe(ofType(myCoachActions.OPEN_USER_PROFILE_DETAILS),
    map((action: myCoachActions.OpenUserProfileDetails) => action.payload),
    switchMap((userId: any) => this.userProvider.getUserProfile(userId)
      .pipe(
        map((data: any) => new OpenModal('ModalComponent', {
          header: 'user-profile.title',
          embedComponent: UserProfilePage,
          componentProps: {user: data}
        })),
        catchError((error) => of(new myCoachActions.OpenUserProfileFail(error)))
      ))
  ));


  openUserProfileDetailsFail$ = createEffect(() => this.actions$.pipe(ofType(myCoachActions.OPEN_USER_PROFILE_DETAILS_FAIL),
    tap(() => this.toastService.translateError('errors.common.could_not_open_profile'))
  ), {dispatch: false});


  postMessage$ = createEffect(() => this.actions$.pipe(ofType(myCoachActions.POST_MESSAGE),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getMessageFeedById)))
    ),
    switchMap(([action, messageFeed]: [myCoachActions.PostMessage, MessageFeed]) =>
      this.iridiumProvider.postMessage(action.payload.content, action.payload.conversation_id)
        .pipe(
          mergeMap((message) => [
            new AddData<MessageFeed>({
              data: [{
                ...messageFeed,
                total_count: messageFeed.total_count + 1
              }],
              schema: messageFeedSchema
            }),
            new AddChildData<Message>({
              data: [message],
              childSchema: messageSchema,
              parentSchema: messageFeedSchema,
              parentId: currentMessageFeedId
            }),
            new myCoachActions.PostMessageSuccess(),
            new myCoachActions.RefreshConversations()
          ])
        )),
    catchError((error, caught) => {
      this.store.dispatch(new myCoachActions.PostMessageFail(error));

      return caught;
    })
  ));


  closeConversation$ = createEffect(() => this.actions$.pipe(ofType(myCoachActions.CLOSE_CONVERSATION),
    switchMap((action: myCoachActions.CloseConversation) => {
      const conversationId = action.payload;

      return this.myCoachProvider.closeConversation(conversationId)
        .pipe(
          mergeMap(() => [
            new myCoachActions.CloseConversationSuccess(),
            new myCoachActions.LoadConversations()
          ])
        );
    }),
    catchError((error, caught) => {
      this.store.dispatch(new myCoachActions.CloseConversationFail(error));

      return caught;
    })
  ));


  closeConversationSuccess$ = createEffect(() => this.actions$.pipe(ofType(myCoachActions.CLOSE_CONVERSATION_SUCCESS),
    tap(() => this.toastService.translateConfirm('my-coach.conversations.conversation_closed'))
  ), {dispatch: false});


  closeConversationFail$ = createEffect(() => this.actions$.pipe(ofType(myCoachActions.CLOSE_CONVERSATION_FAIL),
    tap(() => this.toastService.translateError('errors.common.generic_error_please_retry'))
  ), {dispatch: false});


  loadAllNotifications$ = createEffect(() => this.actions$.pipe(ofType(myCoachActions.LOAD_ALL_NOTIFICATIONS),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getMyCoachCurrentNotificationFeed)))
    ),
    switchMap(([action, notificationFeed]) => this.iridiumProvider.loadAllNotifications()
      .pipe(
        mergeMap(notifications => [
          new SetData<NotificationFeed>({
            data: [{
              ...notificationFeed,
              list: notifications,
              id: myCoachNotificationFeedId()
            }],
            schema: myCoachNotificationsFeedSchema
          }),
          new myCoachActions.LoadAllMyCoachNotificationsSuccess()
        ])
      )),
    catchError((error, caught) => {
      this.store.dispatch(new myCoachActions.LoadAllMyCoachNotificationsFailure());

      return caught;
    })
  ));


  loadNotifications$ = createEffect(() => this.actions$.pipe(ofType(myCoachActions.LOAD_NOTIFICATIONS),
    switchMap(() => this.iridiumProvider.loadNotifications()
      .pipe(
        mergeMap(notificationFeed => [
          new SetData<NotificationFeed>({
            data: [{
              ...notificationFeed,
              id: myCoachNotificationFeedId()
            }],
            schema: myCoachNotificationsFeedSchema
          }),
          new myCoachActions.LoadMyCoachNotificationsSuccess()
        ])
      )),
    catchError((error, caught) => {
      this.store.dispatch(new myCoachActions.LoadMyCoachNotificationsFailure());

      return caught;
    })
  ));

  constructor(
    private actions$: Actions,
    private myCoachProvider: MyCoachProvider,
    private store: Store<SessionState>,
    private toastService: ToastService,
    private userProvider: UserProvider,
    private iridiumProvider: IridiumProvider,
    private logger: LoggerService
  ) {
  }

  private buildMessagesFeed(messages): MessageFeed {
    return {
      ...messages,
      id: null,
      total_count: messages.total_count,
      conversation_id: this.extractConversationId(messages.list)
    };
  }

  // every message in a list will include the conversation id
  private extractConversationId(messages) {
    if (!messages || !messages.length) {
      return null;
    }

    return messages[0].conversation_id;
  }
}
