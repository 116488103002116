import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { ClarityConfig } from '../../config/clarity.config';
import { LoggerService } from '../logger.service';
import { Store } from '@ngrx/store';
import { State } from '../../store';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import * as myCoachActions from '../../store/session/actions/my-coach.actions';
import { getInappCoaching } from '../../store/session/selectors/my-coach.selectors';

@Injectable({providedIn: 'root'})
export class MyCoachService implements OnDestroy {

  public SERVICE_NAME = 'my-coach';

  public inappCoaching$ = this.store.select(getInappCoaching);
  private inappCoachingSubscription: Subscription;

  public typingCoach: Subject<any> = new Subject<any>();
  public typingCoach$: Observable<any> = this.typingCoach.asObservable();

  public coachOnline: ReplaySubject<any> = new ReplaySubject<any>(1);
  public coachOnline$: Observable<any> = this.coachOnline.asObservable();

  private readonly coachOnlineHideDelay: number = 60000;
  private hideCoachOnlineMessageTimeout;

  constructor(
    private config: ClarityConfig,
    private logger: LoggerService,
    private store: Store<State>
  ) {

  }

  public initialize(): Promise<any> {
    this.inappCoachingSubscription = this.inappCoaching$.subscribe(inappCoaching => {
      if (this.config.isERN() && inappCoaching || this.config.programDPPorWL) {
        this.store.dispatch(new myCoachActions.LoadUnreadMessagesCount());
      }
    });

    return Promise.resolve();
  }

  ngOnDestroy() {
    this.inappCoachingSubscription && this.inappCoachingSubscription.unsubscribe();
  }

  onCoachOnlineWsMessage(status) {
    this.coachOnline.next(status);
    if (!status) {
      return;
    }
    if (this.hideCoachOnlineMessageTimeout) {
      clearTimeout(this.hideCoachOnlineMessageTimeout);
    }
    this.hideCoachOnlineMessageTimeout = setTimeout(() => {
      this.coachOnline.next(null);
      this.hideCoachOnlineMessageTimeout = null;
    }, this.coachOnlineHideDelay);
  }
}
