import { Component, HostBinding } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';

import { OnboardingCloseEvent, onboardingTypes } from '../../constants/onboarding-types.const';
import { SavePlayedFile } from '../../store/persistent/media/media.actions';
import { MediaFile } from '../../store/normalized/schemas/mediaFile.schema';
import { SetOnboardingCompleted } from '../../store/persistent/onboarding/onboarding.actions';
import { State } from '../../store/state.reducer';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import { ClarityConfig } from '../../config/clarity.config';

@Component({
  selector: 'page-onboarding',
  styleUrls: ['onboarding.scss'],
  templateUrl: 'onboarding.html'
})
export class OnboardingPage {
  @HostBinding('class.desktop') isDesktop = this.config.isWebApp;

  modal;
  onboardingSettings = onboardingTypes[this.params.get('type')];
  video: MediaFile = this.params.get('video');

  constructor(
    private store: Store<State>,
    private params: NavParams,
    private config: ClarityConfig,
    public modalCtrl: ModalController
  ) {
    if (this.params.get('type') === 'custom') {
      this.onboardingSettings = this.params.get('onboardingSettings');
    }

    if (!this.onboardingSettings) {
      throw new Error(`[ERROR] OnboardingPage: couldn\'t find onboardingSettings for NavParams.type = ${this.params.get('type')}. `
        + 'Please implement it in onboarding-types constant file');
    }
  }

  onPlayedVideo() {
    this.store.dispatch(new SavePlayedFile(this.video.data_fingerprint));
  }

  dismissScreen(event?: OnboardingCloseEvent) {
    if (!event || event.isComplete) {
      this.store.dispatch(new SetOnboardingCompleted(this.params.get('type')));
    }
    this.store.dispatch(new navigationActions.CloseModal({modalId: this.modal.id}));
  }

}
