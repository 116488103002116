import { createSelector } from '@ngrx/store';
import { createSchemaSelectors } from 'ngrx-normalizr';
import equal from 'fast-deep-equal';
import { addDays, addMonths, addWeeks, addYears, differenceInDays, differenceInMonths, differenceInWeeks, differenceInYears, parseJSON } from 'date-fns';

import * as programReducer from '../reducers/program.reducer';

import {
  getRealNormalizedProgress,
  getUserBootstrap
} from '../../../../app/store/normalized/selectors/user-bootstrap.selectors';
import {
  getNormalizedLessons,
  getNormalizedModules, getProgramBootstrap
} from '../../../../app/store/normalized/selectors/program-bootstrap.selectors';
import { getCurrentUserProgram } from '../../../../app/store/normalized/selectors/user.selectors';
import { createLessonId, createModuleId, Lesson, Module, Week } from '../../../../app/store/normalized/schemas/user-bootstrap.schema';
import { ProgramDay, ProgramDayExercise } from '../../../../app/store/normalized/schemas/program-bootstrap.schema';
import { getProgramState } from '../../../../app/store';
import { getCurrentDay, isFullSynced, isProgramSyncing, isSyncing } from './sync.selectors';
import { ThemeWeek } from '../../normalized/schemas/theme-week.schema';
import { UserBootstrap } from '../../normalized/schemas/user-bootstrap.schema';
import { BonusExercise } from '../../normalized/schemas/exercise.schema';
import { getNormalizedThemeWeeks } from '../../normalized/selectors/theme-week.selectors';
import { ProgramType, UserFavorite, userFavoriteSchema, UserProgram } from '../../normalized/schemas/user.schema';
import { getCurrentUser } from '../../normalized/selectors/user.selectors';
import { userDppwlTypeToEnum } from 'src/app/utils/user-dppwl-type-to-enum';

export class LiveBonusExercise extends BonusExercise {
  moduleNumber: number;
  isLocked: boolean;
  isNew: boolean;
  title: string;
  length: number;
}

export class LiveWeek extends Week {
  modules: LiveModule[];
  record: ThemeWeek;
  isCompleted: boolean;
  isAvailable: boolean;
  isCurrent: boolean;
  customWeekIndex?: number;
}

export class LiveModule extends Module {
  lessons: LiveLesson[];
  record?: ProgramDay;
  isFirst: boolean;
  isLast: boolean;
  isAllPrevDone: boolean;
  isCompleted: boolean;
  isInProgress: boolean;
  isAvailable: boolean;
  isAccelerated: boolean;
  isCurrent: boolean;
  isLive: true;
  isFav: boolean;
  positionInWeek: number;
  isFirstInWeek: boolean;
  willUnlockAt?: 'tomorrow' | Date;
}

export class LiveLesson extends Lesson {
  record: ProgramDayExercise;
  isFirst: boolean;
  isLast: boolean;
  isLive: true;
  isAllPrevDone: boolean;
  completedAt?: string;
  isCompleted: boolean;
  isAvailable: boolean;
  isAccelerated: boolean;
  isCurrent: boolean;
}

export class LiveProgram {
  weeks: LiveWeek[];
  acceleratedTo?: number;
  restartedAt?: string;
}

export const isLessonCompleting = createSelector(
  getProgramState,
  programReducer.lessonCompleting
);

export const getLastCompletedLesson = createSelector(
  getProgramState,
  programReducer.lastCompletedLesson
);

export const isModuleCompleting = createSelector(
  getProgramState,
  programReducer.moduleCompleting
);

export const getActiveUserWeekNumber = createSelector(
  getProgramState,
  programReducer.activeUserWeekNumber
);

let favoritedModulesCopy = {};

export const getFavoritedModules = createSelector(
  createSchemaSelectors<UserFavorite>(userFavoriteSchema).getEntities,
  (userFavs): any => {
    if (!userFavs) {
      return [];
    }

    const favoritedModules = userFavs.filter(f => f.favourable_type === 'ProgramDay');

    if (!equal(favoritedModules, favoritedModulesCopy)) {
      favoritedModulesCopy = favoritedModules;
    }

    return favoritedModulesCopy;
  }
);

export const getLastCompletedModule = createSelector(
  getProgramState,
  programReducer.lastCompletedModule,
  (ProgramState, lastCompletedModule) => lastCompletedModule
);

export const getPlayState = createSelector(
  getProgramState,
  programReducer.play
);

export const hasIntroModule = createSelector(
  getProgramState,
  programReducer.introModule
);

export const getPlayWeekNumber = createSelector(
  getPlayState,
  (state: programReducer.PlayState) => state.week
);

export const getPlayModuleNumber = createSelector(
  getPlayState,
  (state: programReducer.PlayState) => state.module
);

export const getPlayExerciseId = createSelector(
  getPlayState,
  (state: programReducer.PlayState) => state.exercise
);

export const getPlayLessonPosition = createSelector(
  getPlayState,
  (state: programReducer.PlayState) => state.lesson
);

export const getProgramLanguages = createSelector(
  getProgramBootstrap,
  (bootstrap) => {
    if (bootstrap['available_locales'] && bootstrap['available_locales'].length > 0) {
      return bootstrap['available_locales'];
    }

    // return default locale file nothing else
    return ['en'];
  }
);

let liveProgramCopy: LiveProgram = {
  weeks: []
};

const liveProgramHelpers = createSelector(
  isFullSynced,
  getActiveUserWeekNumber,
  getCurrentDay,
  (fullSynced, activeUserWeekNumber, currentDay) => [fullSynced, activeUserWeekNumber, currentDay]
);

const getNormalizedProgramRecords = createSelector(
  getNormalizedModules,
  getNormalizedLessons,
  getNormalizedThemeWeeks,
  (normalizedModules, normalizedLessons, normalizedThemeWeeks) => [normalizedModules, normalizedLessons, normalizedThemeWeeks]
);

// LIVE PROGRAM
export const getLiveProgram = createSelector(
  getUserBootstrap,
  getNormalizedProgramRecords,
  getRealNormalizedProgress,
  getFavoritedModules,
  liveProgramHelpers,
  getCurrentUserProgram,
  (
    userBootstrap: UserBootstrap,
    [
      normalizedModules, normalizedLessons, normalizedThemeWeeks
    ]: [
      Record<string, ProgramDay>, Record<string, ProgramDayExercise>, Record<string, ThemeWeek>
    ],
    normalizedProgress,
    favoritedModules,
    [fullSynced, activeUserWeekNumber],
    currentUserProgram
  ): LiveProgram => {
    const allLiveWeeks: LiveWeek[] = [];

    if (!fullSynced || !userBootstrap || !userBootstrap.weeks) {
      return liveProgramCopy;
    }

    if (!normalizedProgress) {
      normalizedProgress = {};
    }

    // let lastModule = null;
    // let lastWeek = null;

    let prevModule: LiveModule = null;
    let liveModule: LiveModule = null;

    userBootstrap.weeks.forEach((week, weekIdx) => {

      const liveWeek: LiveWeek = Object.assign({}, week, {
        isCompleted: false,
        isAvailable: false,
        isCurrent: false,
        modules: [],
        record: week.type === 'theme' && normalizedThemeWeeks && normalizedThemeWeeks[week.theme_week_id]
          ? normalizedThemeWeeks[week.theme_week_id]
          : null
      });

      week?.modules?.forEach((storedModule, moduleIdx) => {
        liveModule = Object.assign({}, storedModule, {lessons: []}) as LiveModule;

        liveModule.isLive = true;
        liveModule.positionInWeek = moduleIdx + 1;
        liveModule.isFav = favoritedModules?.find(f => f.favourable_id === liveModule.recordId) || false;

        if (normalizedModules && normalizedModules[liveModule.recordId]) {
          liveModule.record = {...normalizedModules[liveModule.recordId]};
        }

        // add isFirst
        liveModule.isFirst =
          liveModule.number === 0
          || (!prevModule && liveModule.number === 1)
            ? true
            : false;

        liveModule.isLast =
          weekIdx === userBootstrap.weeks.length - 1 && moduleIdx === week.modules.length - 1
            ? true
            : false;

        liveModule.isFirstInWeek = moduleIdx === 0
          ? true
          : false;

        // add isCompleted
        const completed = normalizedProgress[createModuleId(liveModule)] || {} as any;
        liveModule.completedAt = completed.completedAt || null;
        liveModule.isCompleted = !!liveModule.completedAt;

        liveModule.isAccelerated =
          (userBootstrap?.acceleratedTo === -1)
          || (userBootstrap?.acceleratedTo > 0 && userBootstrap?.acceleratedTo >= liveModule.number);

        // add isAvailable
        liveModule.isAvailable =
          liveModule.isCompleted
          || liveModule.isAccelerated
          || liveModule.isFirst
          || (
            prevModule?.isCompleted &&
            isCompletedBeforeToday(prevModule.completedAt) &&
            shouldUnlockBasedOnCustomTag(liveModule, currentUserProgram)
          )
          || (liveModule.number === 1 && prevModule?.isCompleted)
          // first modules in custom and theme weeks must always be available, timeline approach no longer works with kindle format
          || (liveModule.isFirstInWeek && liveModule.type !== 'program')
            ? true
            : false;

        liveModule.willUnlockAt = liveModule.isAvailable || !prevModule?.isCompleted ? null : calculateUnlockAt(liveModule, currentUserProgram);

        // add isAllPrevDone
        liveModule.isAllPrevDone =
          liveModule.isFirst
          || (prevModule && prevModule.isCompleted)
            ? true
            : false;

        let prevLesson: LiveLesson = null;
        let liveLesson: LiveLesson = null;

        storedModule?.lessons?.forEach((storedLesson, lessonIdx) => {
          liveLesson = {...storedLesson} as LiveLesson;

          liveLesson.isLive = true;
          if (normalizedLessons && normalizedLessons[liveLesson.recordId]) {
            liveLesson.record = {...normalizedLessons[liveLesson.recordId]};
          }

          // add isFirst
          liveLesson.isFirst =
            liveLesson.position === 1
              ? true
              : false;

          // add isLast
          liveLesson.isLast =
            lessonIdx === storedModule.lessons.length - 1
              ? true
              : false;

          // add isCompleted
          const complete = normalizedProgress[createLessonId(liveLesson)] || {} as any;
          liveLesson.completedAt = complete.completedAt || null;
          liveLesson.isCompleted = !!liveLesson.completedAt;

          // add isAccelerated
          liveLesson.isAccelerated = liveModule.isAccelerated;

          // add isAvailable
          liveLesson.isAvailable =
            liveLesson.isCompleted
            || liveLesson.isAccelerated
            || (liveModule.isAvailable && liveLesson.position === 1)
            || (liveModule.isAvailable && prevLesson && prevLesson.isCompleted)
              ? true
              : false;

          // add isAllPrevDone
          liveLesson.isAllPrevDone =
            liveLesson.position === 0
            || (liveLesson.position === 1 && liveLesson.isFirst)
            || (prevLesson && prevLesson.isCompleted && prevLesson.isAllPrevDone)
              ? true
              : false;

          // add isCurrent
          liveLesson.isCurrent =
            // restarted programs should not advance automatically since all days are available
            (liveLesson.isFirst && !liveLesson.isCompleted)
            || (liveLesson.isAllPrevDone && !liveLesson.isCompleted)
              ? true
              : false;

          // add isInProgress to lesson's module
          // (module is considered 'in progress' if it's not completed and has at least one completed lesson)
          liveModule.isInProgress =
            liveModule.isInProgress
            || (liveLesson.isCompleted && !liveModule.isCompleted)
              ? true
              : false;

          prevLesson = liveLesson;
          liveModule.lessons.push(liveLesson);
        });

        prevModule = liveModule;
        liveWeek.modules.push(liveModule);
      });

      // update week
      liveWeek.isCompleted =
        liveModule?.isCompleted && liveModule?.isAllPrevDone
          ? true
          : false;

      liveWeek.isAvailable =
        liveWeek?.modules[0]?.isAvailable
          ? true
          : false;

      allLiveWeeks.push(liveWeek);
    });

    const programRestartedAndNotCompleted = userBootstrap.restartedAt
      && allLiveWeeks.find((week) => week.type === 'program' && !week.isCompleted);

    // it's possible to complete multiple modules a day when the program is restarted.
    // for example: if the user restarts the program then completed module 100, every module up to 100 will be completed as well immediately.
    let lastAccerelatedModuleCompletedToday;
    if (currentUserProgram?.accelerate > 0) {
      const allLiveModules = allLiveWeeks
        .map(week => week.modules)
        .reduce((prev, curr) => prev.concat(curr), []);
      lastAccerelatedModuleCompletedToday = allLiveModules
        .filter(module => module.isAccelerated && isCompletedToday(module.completedAt))
        .sort((a, b) => a.number - b.number)
        .pop();
    }

    // add isCurrent
    allLiveWeeks.forEach((week, weekIdx) => {
      week.modules.forEach((module, moduleIdx) => {

        // add prev module
        let previousModule = week.modules[moduleIdx - 1];

        if (!previousModule && allLiveWeeks[weekIdx - 1] && allLiveWeeks[weekIdx - 1].modules[moduleIdx]) {
          previousModule = allLiveWeeks[weekIdx - 1].modules[moduleIdx];
        }

        // add next module
        let nextModule = week.modules[moduleIdx + 1];

        if (!nextModule && allLiveWeeks[weekIdx + 1] && allLiveWeeks[weekIdx + 1].modules[0]) {
          nextModule = allLiveWeeks[weekIdx + 1].modules[0];
        }

        // add isCurrent
        module.isCurrent =
          // restarted programs should not advance automatically since all days are available
          module.isAccelerated && lastAccerelatedModuleCompletedToday?.number === module.number
          || (
            !module.isAccelerated &&
            isCompletedToday(module.completedAt) &&
            !nextModule?.isCompleted &&
            nextModule?.number !== 1
          )
          || (module.isFirst && !module.isCompleted)
          || (
            module.isAllPrevDone &&
            !module.isCompleted &&
            module.isAvailable
          )
          || (module.isFirstInWeek && !module.isCompleted && module.type !== 'program')
          || (
            module.number === 1 &&
            previousModule?.isCompleted &&
            !nextModule?.isCompleted &&
            (!module.isCompleted || isCompletedToday(module.completedAt))
          )
          || (
            module.number > 1 &&
            isCompletedToday(module.completedAt) &&
            !nextModule?.isCompleted
          )
          || ( // if next module is not available, and previous is not the current, this one should be current
            module.number > 1 &&
            module.isAvailable &&
            !previousModule?.isCurrent &&
            !nextModule?.isAvailable
          );
      });

      const isActiveWeekEmpty = typeof activeUserWeekNumber !== 'number';
      const weekHasCurrentModule = week.modules.find((module) => module.isCurrent);

      // set week isCurrent
      week.isCurrent =
        week.isCurrent
          // if program restarted and not completed, prioritize core modules
          || programRestartedAndNotCompleted && week.type === 'program' && weekHasCurrentModule
          // if we have an active week set already, use that
          || (!programRestartedAndNotCompleted && !isActiveWeekEmpty && week.number === activeUserWeekNumber)
          // if there's no active week number (yet -- usually after app upgrade), use whatever is in progress - for custom/theme weeks
          || (!programRestartedAndNotCompleted && isActiveWeekEmpty && weekHasCurrentModule)
          ? true
          : false;

      if (week.isCurrent) {
        // make sure the week has a current module
        if (!week.modules.find((module) => module.isCurrent)) {
          console.log('Current week has no current module, forcing last completed one!');

          const lastCompletedModule = week.modules
            .slice()
            .reverse()
            .find((module) => module.isCompleted);

          if (lastCompletedModule) {
            lastCompletedModule.isCurrent = true;
          }
        }
      }
    });

    // last resort -- if there's no current week, mark the last one of the program as current to avoid other selectors from not working
    // https://sentry.io/organizations/mindsciences/discover/clarity4:c09e2f814b9845cfa1d2e22128fa9fba
    if (!allLiveWeeks.find((week) => week.isCurrent)) {
      console.log('Not current week found, forcing last one!');
      if (allLiveWeeks[allLiveWeeks.length - 1]) {
        allLiveWeeks[allLiveWeeks.length - 1].isCurrent = true;

        // also mark the last completed module as current one
        const lastCompletedModule = allLiveWeeks[allLiveWeeks.length - 1].modules
          .slice()
          .reverse()
          .find((module) => module.isCompleted);

        if (lastCompletedModule) {
          lastCompletedModule.isCurrent = true;
        }
      }

    }

    const lastWeek = allLiveWeeks[allLiveWeeks.length - 1];
    const lastModule = lastWeek?.modules[lastWeek.modules.length - 1];

    // add one extra module to display when program is complete
    if (lastModule && lastModule.isCompleted && lastModule.isAllPrevDone && isCompletedBeforeToday(lastModule.completedAt)) {
      lastWeek.modules.push({
        number: lastModule.number + 1,
        type: 'extend',
        lessons: [],
        recordId: null,
        completedAt: null,
        weekNumber: lastWeek.number,
        record: {
          goal: 'Start a new week'
        } as ProgramDay,
        isFirst: false,
        isLast: true,
        isAllPrevDone: true,
        isCompleted: false,
        isAvailable: true,
        isAccelerated: true,
        isCurrent: true,
        isLive: true,

        isInProgress: false,
        isFav: false,
        positionInWeek: lastWeek.modules.length + 1,
        isFirstInWeek: false
      });

      lastModule.isCurrent = false;
      lastModule.isLast = false;

      lastWeek.isCurrent = true;
    }

    liveProgramCopy = {
      weeks: allLiveWeeks,
      acceleratedTo: userBootstrap.acceleratedTo,
      restartedAt: userBootstrap.restartedAt
    };

    console.log('$$$ SELECTOR getLiveProgram');

    return liveProgramCopy;
  }
);

export const getLastProgramWeek = createSelector(
  getLiveProgram,
  (liveProgram) => {
    console.log('$$$ SELECTOR getLastProgramWeek');

    if (!liveProgram || !liveProgram.weeks || liveProgram.weeks.length === 0) {
      return null;
    }

    return liveProgram.weeks[liveProgram.weeks.length - 1];
  }
);

export const getLastCoreProgramWeek = createSelector(
  getLiveProgram,
  (liveProgram) => {
    console.log('$$$ SELECTOR getLastCoreProgramWeek');

    if (!liveProgram || !liveProgram.weeks || liveProgram.weeks.length === 0) {
      return null;
    }

    const coreProgramWeeks = liveProgram.weeks.filter((week) => week.type === 'program');

    return coreProgramWeeks[coreProgramWeeks.length - 1];
  }
);

export const isCoreProgramCompleted = createSelector(
  getLastCoreProgramWeek,
  (lastWeek) => {
    if (!lastWeek) {
      return false;
    }

    if (lastWeek.isCompleted) {
      return true;
    }

    return false;
  }
);

export const isWeekInProgress = createSelector(
  getLastProgramWeek,
  (lastWeek) => {
    if (!lastWeek || lastWeek.isCompleted) {
      return false;
    }

    return true;
  }
);

export const getProgramWeeks = createSelector(
  getLiveProgram,
  (program) => {
    console.log('$$$ SELECTOR getProgramWeeks');

    if (!program || !program.weeks) {
      return [];
    }

    return program.weeks;
  }
);

export const getModuleById = (id) => createSelector(
  getLiveProgram,
  (program) => {
    let result: LiveModule;
    program.weeks.some(week => {
      result = week.modules.find(module => module.number === id);

      return result !== undefined;
    });

    return result;
  }
);

export const getCoreTrainingWeeks = createSelector(
  getProgramWeeks,
  (weeks) => weeks.filter((week) => week.type === 'program')
);

export const getCustomTrainingWeeks = createSelector(
  getProgramWeeks,
  (weeks) => weeks.filter((week) => week.type === 'custom')
    .map((week, index) => {
      week.customWeekIndex = index + 1;

      return week;
    })
);

export const getThemeTrainingWeeks = createSelector(
  getProgramWeeks,
  (weeks) => weeks.filter((week) => week.type === 'theme')
);

// MODULES BY PROGRAM DAY
export const getProgramModulesByProgramDayId = createSelector(
  getLiveProgram,
  (liveProgram): {} => {
    console.log('$$$ SELECTOR getProgramModulesByProgramDayId');

    const modules = {};

    if (!liveProgram || !liveProgram.weeks) {
      return modules;
    }

    liveProgram.weeks.forEach((week) => {
      if (week.type !== 'program') {
        return;
      }

      week.modules.forEach((module) => {
        modules[module.recordId] = module;
      });
    });

    return modules;
  }
);

// MODULE
export const getPlayWeek = createSelector(
  getLiveProgram,
  getPlayWeekNumber,
  (program, playWeekNumber): LiveWeek => {
    console.log('$$$ SELECTOR getPlayWeek');

    if (!program || !program.weeks) {
      return;
    }

    return program.weeks.find((week) => week.number === playWeekNumber);
  }
);

export const getPlayModule = createSelector(
  getPlayWeek,
  getPlayModuleNumber,
  (playWeek, playModuleNumber): LiveModule => {
    if (!playWeek || (playModuleNumber !== 0 && !playModuleNumber)) {
      return null;
    }
    const activeModule = playWeek.modules.find((module) => module.number === playModuleNumber);

    return activeModule;
  }
);

export const getPlayLesson = createSelector(
  getPlayModule,
  getPlayLessonPosition,
  (playModule, playLessonPosition): LiveLesson => {
    if (!playModule || !playLessonPosition) {
      return null;
    }

    return playModule.lessons.find((lesson) => lesson.position === playLessonPosition);
  }
);

export const getCurrentWeek = createSelector(
  getLiveProgram,
  (program): LiveWeek => {
    console.log('$$$ SELECTOR getCurrentWeek');

    if (!program || !program.weeks) {
      return null;
    }

    return program.weeks.find((week) => week.isCurrent);
  }
);

export const getCurrentModule = createSelector(
  getCurrentWeek,
  (week): LiveModule => {
    console.log('$$$ SELECTOR getCurrentModule');

    if (!week) {
      return null;
    }

    return week.modules.find((module) => module.isCurrent);
  }
);

export const getPreviousModule = createSelector(
  getLiveProgram,
  getCurrentModule,
  (program, currentModule): LiveModule => {
    console.log('$$$ SELECTOR getPreviousModule');

    let module = null;

    if (!program || !program.weeks) {
      return module;
    }

    program.weeks.find((week) => {
      module = week.modules.find((data) => data.number === currentModule.number - 1);

      // return the current module if there is
      if (!module) {
        module = week.modules.find((data) => data.number === currentModule.number);
      }

      return !!module;
    });

    return module;
  }
);

export const getCurrentLesson = createSelector(
  getCurrentModule,
  (module): LiveLesson | void => {
    console.log('$$$ SELECTOR getCurrentLesson');

    if (!module) {
      return null;
    }

    return module.lessons.find((lesson) => lesson.isCurrent);
  }
);

export const getStartedThemeWeekIds = createSelector(
  getLiveProgram,
  (liveProgram) => {
    console.log('$$$ SELECTOR getStartedThemeWeekIds');

    const weekIds = [];

    if (!liveProgram || !liveProgram.weeks) {
      return weekIds;
    }

    // return only started, but not completed weeks
    liveProgram.weeks.forEach((week) => week.type === 'theme' && week.record && weekIds.push(week.record.id));

    return weekIds;
  }
);

export const getCompletedThemeWeekIds = createSelector(
  getLiveProgram,
  getStartedThemeWeekIds,
  (liveProgram, startedThemeWeekIds) => {
    console.log('$$$ SELECTOR getCompletedThem§eWeekIds');

    const weekIds = [];

    if (!liveProgram || !liveProgram.weeks) {
      return weekIds;
    }

    // find the last user week for each theme week of te program and see if that one is completed
    startedThemeWeekIds.forEach((themeWeekId) => {
      const startedThemeWeek = liveProgram.weeks
        .slice()
        .reverse()
        .find((week) => week.record && week.record.id === themeWeekId);

      if (startedThemeWeek && startedThemeWeek.isCompleted) {
        weekIds.push(themeWeekId);
      }
    });

    return weekIds;
  }
);

export const isProgramReady = createSelector(
  isProgramSyncing,
  isSyncing,
  (programSyncing, everythingSyncing) => !programSyncing && !everythingSyncing
);

export const getDashboardSliderIndex = createSelector(
  getCurrentModule,
  hasIntroModule,
  isProgramReady,
  (currentModule, introModule, programReady): number => {
    console.log('$$$ SELECTOR getDashboardSliderIndex');

    if (!currentModule || !programReady) {
      return null;
    }

    return introModule ? currentModule.number : currentModule.number - 1;
  }
);

export const getDppWlProgramType = createSelector(
  getCurrentUser,
  (user) => {
    if (!user || !user.id) {
      return null;
    }

    return userDppwlTypeToEnum(user.dpp_wl_type);
  }
);

export const canStartThemeWeeks = createSelector(
  isCoreProgramCompleted,
  getDppWlProgramType,
  getCurrentModule,
  getCurrentUserProgram,
  (coreProgramCompleted, dppWlType, currentModule, userProgram) => {
    if (dppWlType !== ProgramType.DPP) {
      return coreProgramCompleted;
    } else if (!userProgram?.program_module_number_allow_user_week) {
      // this shouldn't happen, but if do, log and default to previous behavior
      console.error('DPP UserProgram without program_module_number_allow_user_week');

      return coreProgramCompleted;
    }

    const threshold = Number(userProgram.program_module_number_allow_user_week);

    return (
      currentModule.number > threshold ||
      currentModule.number === threshold && currentModule.isCompleted
    );
  }
);

// helper functions

type TimingTypes = 'd' | 'w' | 'm' | 'y';
type DifferenceTimingFunctions = (a: Date | number, b: Date | number) => number;
type AddTimingFunctions = (start: Date | number, amount: number) => Date;

function parseTimingTag(tag: string): [number, TimingTypes] {
  const regex = /(\d+)([dwmy])/;

  const [, amount, type] = tag.match(regex);

  return [parseInt(amount, 10), type as TimingTypes];
}

function getParsedCustomUnlockTag(currentModule: LiveModule): void | [number, TimingTypes] {
  const tagRegex = /unlock-after-start-((\d+)([dwmy]))/;

  const unlockTag = currentModule?.record?.tags?.find((tag) => tag?.name?.match(tagRegex));

  if (!unlockTag) {
    return null;
  }

  // examples: unlock-after-start-365d unlock-after-start-54w unlock-after-start-12m unlock-after-start-1y
  const [, timingPattern] = unlockTag.name.match(tagRegex);

  return parseTimingTag(timingPattern);
}

function shouldUnlockBasedOnCustomTag(currentModule: LiveModule, userProgram: UserProgram) {
  const parsedTag = getParsedCustomUnlockTag(currentModule);

  if (!parsedTag) {
    return true;
  }

  const [amount, timingType] = parsedTag;

  const timingFunctionMap: Record<TimingTypes, DifferenceTimingFunctions> = {
    d: differenceInDays,
    w: differenceInWeeks,
    m: differenceInMonths,
    y: differenceInYears
  };

  const differenceIn = timingFunctionMap[timingType];

  return differenceIn(new Date(), parseJSON(userProgram?.created_at)) >= amount;
}

function calculateUnlockAt(currentModule: LiveModule, userProgram: UserProgram) {
  const parsedTag = getParsedCustomUnlockTag(currentModule);

  if (!parsedTag) {
    return 'tomorrow'; // default mechanism
  }

  const [amount, timingType] = parsedTag;

  const timingFunctionMap: Record<TimingTypes, AddTimingFunctions> = {
    d: addDays,
    w: addWeeks,
    m: addMonths,
    y: addYears
  };

  const addAmount = timingFunctionMap[timingType];

  return addAmount(parseJSON(userProgram?.created_at), amount);
}

function isCompletedToday(completedAt: string) {
  if (!completedAt) {
    return false;
  }

  const today = new Date();
  const completedDate = new Date(completedAt);

  return today.getFullYear() === completedDate.getFullYear()
    && today.getMonth() === completedDate.getMonth()
    && today.getDate() === completedDate.getDate();
}

function isCompletedBeforeToday(completedAt: string) {
  if (!completedAt) {
    return false;
  }

  const today = new Date();
  const completedDate = new Date(completedAt);

  today.setHours(0, 0, 0, 0);

  return completedDate < today;
}
