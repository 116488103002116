import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ClarityConfig } from '../config/clarity.config';
import { BrowserService } from './browser.service';
import { TemporaryTokenProvider } from '../providers/temporary-token.provider';
import { LoadingService } from './loading.service';
import { AlertsService } from './alerts.service';

@Injectable()
export class IntegrationsService {
  constructor(
    private browser: BrowserService,
    private config: ClarityConfig,
    private temporaryTokenProvider: TemporaryTokenProvider,
    private loadingService: LoadingService,
    private translate: TranslateService,
    private alerts: AlertsService
  ) {}

  async goToFitbitConnectionPage({ returnPath = '' }) {
    const translations = await this.translate.get(['loading.loading']).toPromise();
    await this.loadingService.showLoadingOverlay(translations['loading.loading']);

    this.temporaryTokenProvider.generateTemporaryToken()
      .pipe(take(1))
      .subscribe(token => {
        const redirectUri = this.generateRedirectUri(returnPath);

        const url =
          'https://' +
          this.config.apiHost +
          '/integrations/fitbit/connect' +
          `?temporary_token=${token}` +
          `&redirect_uri=${encodeURIComponent(redirectUri)}`;

        this.loadingService.hideLoadingOverlay();

        this.browser.openInCurrentTab(url);
      },
      error => this.alerts.connectionError(error));
  }

  private generateRedirectUri(returnPath: string) {
    if (!this.config.isDevice) {
      const location = (window as any).location;

      return location.origin + location.pathname + location.search + `#/${returnPath}`;
    }

    return `${this.config.program.mobileUrlScheme}://${returnPath}`;
  }
}
