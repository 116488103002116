import { schema } from 'normalizr';

export class Entry {
  id: string;
  created_at: Date;
  updated_at: Date;
  profile_id: number;
  username: string;
  avatar: string;
  quit_date: string;
  content: string;
  current_user_liked: boolean;
  likes: {
    total_count: number;
    list: Like[];
  };
}

class EntryFeed<T> {
  id: string;
  total_count: number;
  list: T[];
}

export class PostFeed extends EntryFeed<Post> {
  list: Post[];
}

export class Post extends Entry {
  comments_count: number;
  comments: CommentFeed;
  private: boolean;
  categories: string[];
  labels: string[];
  isNew: boolean;
}

export class LocalPostUpdate {
  likePost: string;
  likeFail: Post;
  postSuppression: string;
  postCreation: Post;
}

export class CommentFeed extends EntryFeed<Comment> {
}

export class Comment extends Entry {}

export class Like {
  id: string;
  avatar: string;
  profile_id: string;
}

export const commentSchema = new schema.Entity(
  'comments'
);

export const commentFeedSchema = new schema.Entity(
  'commentFeeds',
  {list: [commentSchema]}
);

export const postsSchema = new schema.Entity(
  'socialPosts'
);

export const postFeedSchema = new schema.Entity(
  'socialPostFeed',
  {list: [postsSchema]}
);
