import { schema } from 'normalizr';
import { UserAgent, UserProfile } from './user.schema';

export class Message {
  id: string;
  profile_id: string;
  content: string;
  username: string;
  avatar: string;
  created_at: Date;
  updated_at: Date;
  read: boolean;
  conversation_id: number;
  type: 'coach' | 'user';
}

export class MessageFeed {
  id: string;
  conversation_id: number;
  total_count: number;
  list: Message[];
}

export class InAppMessage {
  authorName: string;
  authorAvatar: string;
  content: string;
  createdAt: string;
  id: number;
}

export class Conversation {
  id: string;
  owner_id: number;
  messages_count: number;
  unread_messages_count: number;
  avatar: string;
  content: string;
  profiles?: UserProfile[];
  created_at: Date;
  updated_at: Date;
  last_message_at: Date;
  last_message_author: string;
  agents?: UserAgent[];
}

export class ConversationFeed {
  id: string;
  total_count: number;
  list: Conversation[];
}

export const conversationSchema = new schema.Entity(
  'conversations'
);

export const conversationFeedSchema = new schema.Entity(
  'conversationFeed',
  {list: [conversationSchema]}
);

export const messageSchema = new schema.Entity(
  'messages'
);

export const messageFeedSchema = new schema.Entity(
  'messageFeed',
  { list: [messageSchema] }
);

export const myCoachNotificationsSchema = new schema.Entity(
  'myCoachNotifications'
);

export const myCoachNotificationsFeedSchema = new schema.Entity(
  'myCoachNotificationFeed',
  {list: [myCoachNotificationsSchema]}
);
