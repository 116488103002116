import { Injectable } from '@angular/core';
import { HttpProvider } from './http/http.provider';
import { AuthToken } from '../store/session/models/auth-token.model';
import { ClarityConfig } from '../config/clarity.config';
import { map } from 'rxjs/operators';
import { User } from '../store/normalized/schemas/user.schema';
import { SignupData } from '../store/session/models/signup-data.model';

@Injectable({providedIn: 'root'})
export class UserProvider {

  private passwordEndpoint = '/password';
  private userAccountEndpoint = '/user_account';
  private registerEndpoint = '/registration';
  private userProfileEndpoint = '{community}/profiles/:id/details';

  constructor(
    protected http: HttpProvider,
    private config: ClarityConfig
  ) {

  }

  updateUserAccount(userInfo: any, canDefer = true) {

    const allowed = new Set([
      'cigs_per_day',
      'cig_pack_currency',
      'cig_pack_cost',
      'end_date',
      'gender',
      'age',
      'weight_unit',
      'weight',
      'start_date',
      'end_date',
      'quit_date',
      'first_name',
      'last_name',
      'sms_opt_in',
      'phone',
      'phone_code_confirmation',
      'email'
    ]);

    const filteredUserInfo = Object.keys(userInfo)
      .filter(key => allowed.has(key))
      .reduce((obj, key) => {
        obj[key] = userInfo[key];

        return obj;
      }, {});

    // if (canDefer === true) {
    //   return this.handlePatch(
    //     this.userAccountEndpoint,
    //     undefined,
    //     filteredUserInfo,
    //     'userSchema'
    //   );
    // }

    return this.http.patch(
      this.userAccountEndpoint,
      filteredUserInfo
    );
  }

  setUserProgram(userInfo: any) {
    const dppwlParams = {};

    if (this.config.programDPPorWL()) {
      // DPPWL supports english only for now
      dppwlParams['language_code'] = 'en';
      // set the feature flag on the backend for correct program
      dppwlParams['dpp_wl_type'] = this.config.forceWl ? 'wl' : 'dpp';
    }

    return this.http.patch<AuthToken>(
      this.registerEndpoint,
      {
        email: userInfo.email,
        password: userInfo.password,
        user_programs_attributes: [{
          program_code: this.config.currentProgramCode.toUpperCase(),
          agree_to_tos: true,
          license_code: null,
          ...dppwlParams
        }]
      });
  }

  changePassword(payload: any) {
    return this.http.patch<AuthToken>(
      this.passwordEndpoint,
      {
        password_confirmation: payload.newPasswordConfirmation,
        password: payload.newPassword,
        current_password: payload.currentPassword
      });
  }

  doSignup(credentials: SignupData) {
    // const {
    //   device_model,
    //   device_platform,
    //   device_uuid,
    //   device_version
    // } = this.config.env.device;

    return this.http.post<AuthToken>(
      this.registerEndpoint,
      {
        email: credentials.email,
        password: credentials.password,
        account_attributes: {
          first_name: credentials.first_name,
          last_name: credentials.last_name
        },
        ...credentials.captcha && ({'g-recaptcha-response': credentials.captcha})
      });
  }

  loadUserAccount() {
    return this.http.get(this.userAccountEndpoint)
      .pipe(
        // remove complete attribute if it's not an object - normalizr doesn't like it
        map((data: User) => {
          if (!data.complete) {
            delete(data.complete);
          }

          return data;
        })
      );
  }

  getUserProfile(id: string) {
    const url = this.userProfileEndpoint.replace(':id', id);

    return this.http.get<any>(url);
  }
}
