import { AlertController } from '@ionic/angular';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { SettingsComponent } from './settings.component';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../../store/normalized/schemas/user.schema';
import { Subscription } from 'rxjs';
import { ConnectivityService } from '../../../services/connectivity.service';
import { take } from 'rxjs/operators';
import { getCurrentUser } from '../../../store/normalized/selectors/user.selectors';
import { State } from '../../../store/state.reducer';
import { getUserWeightInfo } from 'src/app/store/normalized/selectors/program-bootstrap.selectors';
import { WeightActivity } from 'src/app/store/normalized/schemas/weight-activity.schema';
import { compareWeightActivity, getLastWeightActivity, getWeightChange } from 'src/app/store/session/selectors/weight-activities.selectors';
import { getWeightActivity } from 'src/app/store/normalized/selectors/weight-activity.selectors';
import * as WeightActivitiesActions from '../../../store/session/actions/weight-activities.actions';

@Component({
  selector: 'cl-account-weight-ern',
  styleUrls: ['weight-track-ern.component.scss'],
  template: `
    <div class="account-weight-container">
      <ion-row class="weight-stats">
        <ion-col class="weight-tracker">
          <cl-weight-track-block
            [weightTrackingInfo]="weightInfo$ | async"
            [weightTitle]="'weight_tracking.start' | translate">
          </cl-weight-track-block>
        </ion-col>

        <ion-col class="weight-tracker big">
          <div *ngIf="(lastWeight$ | async) as lastWeightData">
            <cl-weight-track-block
              [weightTrackingInfo]="lastWeightData"
              [big]="true"
              [weightTitle]="'weight_tracking.current' | translate">
            </cl-weight-track-block>
          </div>
          <div *ngIf="!(lastWeight$ | async)">
            <cl-weight-track-block
              [weightTrackingInfo]="weightInfo$ | async"
              [big]="true"
              [weightTitle]="'weight_tracking.current' | translate">
            </cl-weight-track-block>
          </div>
        </ion-col>

        <ion-col class="weight-tracker">
          <cl-weight-track-block
            [weightTrackingInfo]="weightChange$ | async"
            [weightTitle]="'weight_tracking.change' | translate">
          </cl-weight-track-block>
        </ion-col>
      </ion-row>

      <div *ngIf="visibleWeightActivities.length > 0" class="weight-track-info">
        <ion-row>
          <ion-col size="10" offset="1">
            <span class="weight-entries-title">
              {{ 'weight_tracking.entries' | translate }}
            </span>
          </ion-col>
        </ion-row>

        <div class="weight-track-list">
          <ion-row *ngFor="let activity of visibleWeightActivities" class="weight-point-container">
            <ion-col size="8" class="track-col weight-col ion-text-left">
              {{ activity.value | number:'1.1-1' }} {{ activity | weightUnit }}, {{ activity.activity_at | date: 'mediumDate' :'UTC' }}
            </ion-col>

            <ion-col offset="3" size="1" class="track-col icon-col">
              <ion-icon
                *ngIf="!isWithingsTracker(activity); else withingsIcon"
                name="remove-circle"
                class="darkgrey remove-circle"
                (click)="removeWeightTrack(activity.id)"
              ></ion-icon>
              <ng-template #withingsIcon>
                <ion-icon
                  class="withings-logo"
                  src="/assets/imgs/withings-logotype.svg"
                  title="{{ 'weight_tracking.withings_entry' | translate }}"
                ></ion-icon>
              </ng-template>
            </ion-col>
          </ion-row>
        </div>
      </div>

      <ion-row *ngIf="showAll === false" class="action-button">
        <ion-col style="padding: 0;" class="ion-text-center" auto>
          <ion-button class="dots" color="action" size="small" (click)="showAllWeights()">
            ...
          </ion-button>
        </ion-col>
      </ion-row>

      <ion-row class="action-button">
        <ion-col class="ion-text-center" auto>
          <cl-action-button
            label="{{ 'weight_tracking.add_weight' | translate }}"
            (action)="addWeightTrack()"
            [canClick]="true">
          </cl-action-button>
        </ion-col>
      </ion-row>
    </div>`
})

export class WeightTrackERNComponent implements SettingsComponent, AfterViewInit, OnDestroy {

  userInfo$ = this.store.select(getCurrentUser);
  weightInfo$ = this.store.select(getUserWeightInfo);
  lastWeight$ = this.store.select(getLastWeightActivity);
  weightChange$ = this.store.select(getWeightChange);
  weightActivities$ = this.store.select(getWeightActivity);

  public weightActivities: WeightActivity[] = [];

  public weightSubscription: Subscription;

  showAll = false;

  weightUnit = 'lb';

  constructor(
    private store: Store<State>,
    private alertCtrl: AlertController,
    private translationService: TranslateService,
    private connectivityService: ConnectivityService,
    private cdr: ChangeDetectorRef
  ) {
  }

  showAllWeights() {
    this.showAll = true;
  }

  get visibleWeightActivities() {
    if (this.showAll) {
      return this.weightActivities;
    }

    return this.weightActivities.slice(0, 3);
  }

  ngAfterViewInit() {
    this.userInfo$
      .pipe(
        take(1)
      )
      .subscribe((userInfo: User) => {
        this.weightUnit = userInfo.weight_unit || this.weightUnit;
      });

    this.weightSubscription = this.weightActivities$.subscribe(
      (weightActivities) => {
        if (weightActivities && weightActivities.length > 0) {
          this.weightActivities = weightActivities.sort(compareWeightActivity);
        }

        try {
          this.cdr.detectChanges();
        }
        catch (error) {
          // console.log('cannot detect changes')
        }
      }
    );
  }

  addWeightTrack() {
    if (this.connectivityService.preventAccessWhenOffline()) {
      return;
    }
    const translations: any = {};

    this.translationService.get([
      'weight_tracking.add_weight',
      'weight_tracking.weight',
      'weight_tracking.date',
      'weight_tracking.cancel',
      'weight_tracking.submit',
      'weight_tracking.weight_date_required',
      'weight_tracking.weight_must_be_number',
      'weight_tracking.weight_must_be_positive'
    ])
      .subscribe(async (translationResults) => {
        translations.addWeight = translationResults['weight_tracking.add_weight'];
        translations.weight = translationResults['weight_tracking.weight'];
        translations.date = translationResults['weight_tracking.date'];
        translations.cancel = translationResults['weight_tracking.cancel'];
        translations.submit = translationResults['weight_tracking.submit'];
        translations.weightDateRequired = translationResults['weight_tracking.weight_date_required'];
        translations.weightMustBeNumber = translationResults['weight_tracking.weight_must_be_number'];
        translations.weightMustBePositive = translationResults['weight_tracking.weight_must_be_positive'];

        const today = moment()
          .format('YYYY-MM-DD');
        const alert = await this.alertCtrl.create({
          header: translations.addWeight,
          inputs: [
            {
              name: 'weight',
              placeholder: translations.weight,
              type: 'number'
            },
            {
              name: 'date',
              placeholder: translations.date,
              type: 'date',
              max: today,
              value: moment()
                .format('YYYY-MM-DD')
            }
          ],
          buttons: [
            {
              text: translations.cancel,
              role: 'cancel'
            },
            {
              text: translations.submit,
              handler: data => {
                if (!data.weight || !data.date) {
                  this.warningAlert(translations.weightDateRequired);

                  return false;
                }
                if (isNaN(data.weight)) {
                  this.warningAlert(translations.weightMustBeNumber);

                  return false;
                }
                if (data.weight < 0) {
                  this.warningAlert(translations.weightMustBePositive);

                  return false;
                }

                this.sendUserWeight(data);
              }
            }
          ]
        });

        await alert.present();
      });
  }

  async warningAlert(message, buttonText = 'Ok') {
    const alert = await this.alertCtrl.create(
      {
        header: 'Error',
        subHeader: message,
        buttons: [buttonText]
      });

    await alert.present();
  }

  async removeWeightTrack(id) {
    const translations: any = {};

    this.translationService.get([
      'weight_tracking.remove_weight',
      'weight_tracking.cancel',
      'weight_tracking.confirm'
    ])
      .subscribe(async (translationResults) => {
        translations.removeWeight = translationResults['weight_tracking.remove_weight'];
        translations.cancel = translationResults['weight_tracking.cancel'];
        translations.confirm = translationResults['weight_tracking.confirm'];

        const alert = await this.alertCtrl.create(
          {
            header: translations.removeWeight,
            buttons: [
              {
                text: translations.cancel,
                role: 'cancel'
              },
              {
                text: translations.confirm,
                handler: () => {
                  this.removeUserWeight(id);
                }
              }
            ]
          }
        );
    
        await alert.present();
      });    
  }

  removeUserWeight(id) {
    this.store.dispatch(new WeightActivitiesActions.RemoveUserWeight({ id }));
  }

  sendUserWeight(data) {
    let { weight } = data;
    try {
      const weightAsNumber = +weight;
      weight = weightAsNumber.toFixed(2);
    } catch {
      console.log('weight failed to round to 2 decimal places');
    }
    const weightActivity: WeightActivity = {
      value: weight,
      activity_at: new Date(data.date).toISOString(),
      unit: this.weightUnit,
      source: 'manual'
    };
    this.store.dispatch(new WeightActivitiesActions.AddUserWeight({ weightActivity }));
  }

  isWithingsTracker(activity: WeightActivity) {
    return activity.source === 'withings';
  }

  ngOnDestroy() {
    if (this.weightSubscription) {
      this.weightSubscription.unsubscribe();
    }
  }
}
