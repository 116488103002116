import { Component, Output, EventEmitter } from '@angular/core';

import { SettingsComponent } from './settings.component';
import { ClarityConfig } from 'src/app/config/clarity.config';
import { slideInOut } from 'src/app/utils/animations';

@Component({
  selector: 'cl-account-weight',
  styleUrls: ['weight-track.component.scss'],
  animations: [slideInOut],
  template: `
    <div class="account-weight-container" [@state]="visibility" (@state.done)="animationDone($event)">
      <ng-container *ngIf="(config.programDPPorWL()) then dppComponent else ernComponent"></ng-container>

      <ng-template #ernComponent>
        <cl-account-weight-ern></cl-account-weight-ern>
      </ng-template>
      <ng-template #dppComponent>
        <cl-account-weight-dpp></cl-account-weight-dpp>
      </ng-template>
    </div>`
})

export class WeightTrackComponent implements SettingsComponent {

  @Output() endAnimation = new EventEmitter();
  visibility = 'visible';

  constructor(
    public config: ClarityConfig
  ) {
  }

  animationDone(event) {
    if (event.toState === 'hidden') {
      this.endAnimation.emit(true);
    }
  }

  closeComponent() {
    this.visibility = 'hidden';
  }
}
