import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { LiveWeek } from '../../store/session/selectors/program.selectors';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-journey-week-card-web',
  styleUrls: ['journey-week-card.web-component.scss'],
  template: `
    <div class="card-wrapper"
         [ngClass]="{
           'current': isCurrent,
           'completed': isCompleted,
           'locked': isLocked,
           'expanded': isExpanded
          }">
      <ion-grid>
        <ion-row class="header-row" (click)="onClick()" tappable>
          <ion-col class="week-name ion-text-left" size="10" [class.no-icon]="!_week.isCompleted && !isPartiallyCompleted && !isCurrent">
            <ion-icon
              *ngIf="_week.isCompleted"
              clTooltip
              [preventPropagation]="isExpanded"
              [tooltipText]="'tooltips.program_week.completed' | translate"
              src="/assets/icon/checkin_icon_orange.svg">
            </ion-icon>
            <ion-icon
              *ngIf="isPartiallyCompleted"
              clTooltip
              [preventPropagation]="isExpanded"
              [tooltipText]="'tooltips.program_week.in_progress' | translate"
              src="/assets/icon/partially_completed_orange.svg">
            </ion-icon>
            <ion-icon
              *ngIf="isCurrent && !_week.isCompleted && !isPartiallyCompleted"
              clTooltip
              [preventPropagation]="isExpanded"
              [tooltipText]="'tooltips.program_week.in_progress' | translate"
              src="/assets/icon/partially_completed_orange.svg">
            </ion-icon>

            <ng-container *ngIf="_week.type !== 'custom'; else customName">
              {{'common.week' | translate}} {{_week.number}} - {{getWeekName() | translate}}
            </ng-container>

            <ng-template #customName>
              {{getWeekName() | translate}} {{_week.customWeekIndex}}
            </ng-template>
          </ion-col>

          <ion-col class="week-caret ion-text-right" size="2" *ngIf="showExpand && !isCurrent">
            <ion-icon name="chevron-forward" [class.down]="isExpanded && showExpand"></ion-icon>
          </ion-col>
        </ion-row>

        <ion-row>
          <div class="flex-wrapper modules-holder">
            <div class="flex-column">
              <cl-module-item-web
                *ngFor="let index of firstColumnModules"
                [isCustomPath]="isCustomPath"
                [module]="_week.modules[index] || null"
                [selectedModules]="selectedModules"
                [expanded]="isExpanded"
                (clicked)="onModuleClicked($event)">
              </cl-module-item-web>
            </div>

            <div class="flex-column">
              <cl-module-item-web
                *ngFor="let index of secondColumnModules"
                [isCustomPath]="isCustomPath"
                [module]="_week.modules[index] || null"
                [selectedModules]="selectedModules"
                [expanded]="isExpanded"
                (clicked)="onModuleClicked($event)">
              </cl-module-item-web>
            </div>
          </div>
        </ion-row>

      </ion-grid>

      <!--<div class="card-overlay" *ngIf="isLocked"></div>-->
    </div>
  `
})
export class JourneyWeekCardWebComponent {

  @Input()
  set week(value: LiveWeek) {
    this._week = {
      ...value,
      modules: value.modules.filter((module) => module.type !== 'extend')
    };
    this.checkIfIsPartiallyCompleted();
  }

  @Input() expanded = null;
  @Input() isCustomPath = false;
  @Input() selectedModules = [];

  @Output() playModule = new EventEmitter();
  @Output() weekExpanded = new EventEmitter();
  @Output() weekCollpased = new EventEmitter();

  _week: LiveWeek;
  isPartiallyCompleted: boolean;

  checkIfIsPartiallyCompleted() {
    if (this.isCustomPath) {
      this.isPartiallyCompleted = false;
    }

    if (this._week.isCompleted) {
      return false;
    }
    const modulesCompleted = this._week.modules.filter(l => l.isCompleted);
    const modulesToComplete = modulesCompleted.length < this._week.modules.length;

    this.isPartiallyCompleted = modulesCompleted.length > 0 && modulesToComplete ? true : false;
  }

  get isExpanded() {
    if (this.isCustomPath) {
      return true;
    }

    return this.expanded === null
      ? this._week.isCurrent && !this._week.isCompleted
      : this.expanded;
  }

  get showExpand() {
    return !this.isCustomPath;
  }

  get isLocked() {
    if (this.isCustomPath) {
      return false;
    }

    return !this._week.isCompleted && !this._week.isCurrent ? true : false;
  }

  get isCurrent() {
    if (this.isCustomPath) {
      return false;
    }

    return this._week.isCurrent && !this._week.isCompleted;
  }

  get isCompleted() {
    if (this.isCustomPath) {
      return true;
    }

    return this._week.isCompleted;
  }

  get moduleNumbers() {
    return this._week.modules.length ?
      this._week.modules.map((module, index) => index) : [];
  }

  get firstColumnModules() {
    if (!this.moduleNumbers) {
      return;
    }

    return this.moduleNumbers.slice(0, Math.ceil(this.moduleNumbers.length / 2));
  }

  get secondColumnModules() {
    if (!this._week || !this._week.modules.length) {
      return;
    }

    return this.moduleNumbers.slice(Math.ceil(this.moduleNumbers.length / 2), this.moduleNumbers.length);
  }

  constructor() {}

  getWeekName() {
    switch (this._week.type) {
      case 'program':
        return 'myjourney.core_training';

      case 'custom':
        return 'myjourney.custom_week';

      case 'theme':
        return this._week.record && this._week.record.title;
    }
  }

  onClick() {
    this.expanded = !this.expanded;

    if (this.expanded) {
      this.weekExpanded.emit(this._week.number);
    }
    else {
      this.weekCollpased.emit(this._week.number);
    }
  }

  onModuleClicked(module) {
    this.playModule.emit(module);
  }
}
