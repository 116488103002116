import * as cigTrakerActions from '../actions/cig-tracker.actions';

export interface CigTrakerState {
  count: number;
  smokeFree: boolean;
}

export const initialCigTrackerState: CigTrakerState = {
  count: 0,
  smokeFree: false
};

export function cigTrackerReducer(state = initialCigTrackerState, action: cigTrakerActions.CigTrackerActions): CigTrakerState {
  return state;
}

export const cigCount = (state: CigTrakerState) => state.count;
export const smokeFree = (state: CigTrakerState) => state.smokeFree;
