import { Component } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { OfflineQueueService } from '../../services/offline-queue.service';
import { getOfflineQueue, getOfflineQueueCount } from '../../store/session/selectors/offline-queue.selectors';
import { Store } from '@ngrx/store';
import { isDownloading, getDownloadedModules, getDownloadQueue } from '../../store/session/selectors/download.selectors';
import { take } from 'rxjs/operators';
import { State } from '../../store/state.reducer';
import * as navigationActions from '../../store/session/actions/navigation.actions';

@Component({
  selector: 'page-debug',
  styleUrls: ['debug.scss'],
  templateUrl: 'debug.html'
})
export class DebugPage {
  modal;

  public offlineQueue$ = this.store.select(getOfflineQueue);
  public offlineQueueCount$ = this.store.select(getOfflineQueueCount);

  public downloadQueue$ = this.store.select(getDownloadQueue);
  public downloadedModules$ = this.store.select(getDownloadedModules);

  private internalInterval = null;

  offlineQueueIsReplaying: boolean;
  downloadInProgress: boolean;

  constructor(
    public navCtrl: NavController,
    private store: Store<State>,
    public offlineQueueService: OfflineQueueService,
    public alertCtrl: AlertController
  ) {
    clearInterval(this.internalInterval);

    this.internalInterval = setInterval(() => {
      this.offlineQueueIsReplaying = this.offlineQueueService.queueIsReplaying();

      this.store.select(isDownloading)
        .pipe(take(1))
        .subscribe((downloading) => this.downloadInProgress = downloading);
    });
  }

  ionViewWillLeave() {
    clearInterval(this.internalInterval);
  }

  async showOfflineRequest(request) {
    const alert = await this.alertCtrl.create({
      header: 'OFFLINE REQUEST',
      subHeader: '<pre class="pre-wrapped">' + JSON.stringify(request)
        + '</pre>',
      buttons: ['Close']
    });

    await alert.present();
  }

  forceOfflineQueueProcess() {
    this.offlineQueueService.forceQueueProcess();
  }

  onClose() {
    this.store.dispatch(new navigationActions.CloseModal({modalId: this.modal.id}));
  }
}
