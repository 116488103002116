import { schema } from 'normalizr';

export class StripePlan {
  id: number;
  name: string;
  currency: string;
  discounted_price_cents: number;
  full_price_cents: number;
  interval: string;
  interval_count: number;
}

export class StripeProduct extends StripePlan{
  type: string;
  length: string;
  priceFormatted: string;
  fullPriceFormatted: string;
  pricePerWeekFormatted: string;
  priceMicros: number;
}

export const stripePlanSchema = new schema.Entity('stripePlans');
