import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { ClarityConfig } from '../../config/clarity.config';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store';
import { getCurrentUserProgram } from '../../store/normalized/selectors/user.selectors';
import { take, withLatestFrom } from 'rxjs/operators';
import { hasEnglishSubtitles } from '../../store/persistent/media/media.selectors';
import { LoggerService } from '../../services/logger.service';
import { BrightcoveWebPlayerLoaderService } from 'src/app/services/brightcove/brightcove-web-player-loader.service';

@Component({
  selector: 'cl-video-player-brightcove-web',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div #videoWrapper></div>
  `
})
export class VideoPlayerBrightcoveWebComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  readonly MINIMUM_PLAYED_SECS = 1;
  @Input() brightcove_key: string;

  @Input() controls: Observable<string>;
  @Input() autoplay: boolean;
  @Input() autoSkip: boolean;
  @Input() autoPauseAfter: number;

  @Output() canPlay = new EventEmitter();
  @Output() canPlayThrough = new EventEmitter();
  @Output() playerError = new EventEmitter();
  @Output() playedMinimum = new EventEmitter();
  @Output() completed = new EventEmitter();
  @Output() autoSkipped = new EventEmitter();
  @Output() autoPaused = new EventEmitter();

  @ViewChild('videoWrapper', { static: true }) videoWrapper: ElementRef;

  player: any;

  @ViewChild('track', { static: true }) trackElement: ElementRef;

  private playerIsPaused = true;

  accountId = this.config.env.brightcove.accountId;
  playerId = this.config.env.brightcove.playerId;

  showTrackElement = false;

  playingStarted = false;
  controlsSubscription: Subscription;

  autoPauseAfterTimeout: any;

  userLanguage: string;
  userProgramSubscription: Subscription;
  showSubtitles = false;

  constructor(
    public config: ClarityConfig,
    protected changeDetector: ChangeDetectorRef,
    private store: Store<State>,
    private brightcoveWebPlayerLoaderService: BrightcoveWebPlayerLoaderService,
    private logger: LoggerService
  ) {
    this.userProgramSubscription = this.store.select(getCurrentUserProgram)
      .pipe(withLatestFrom(this.store.select(hasEnglishSubtitles)), take(1))
      .subscribe(([userProgram, englishSubtitleEnable]) => {
        this.showSubtitles = (userProgram.language_code !== ClarityConfig.DEFAULT_LANGUAGE) ||
          (userProgram.language_code === ClarityConfig.DEFAULT_LANGUAGE && englishSubtitleEnable);

        this.userLanguage = userProgram.language_code;
      });

    if (!this.brightcoveWebPlayerLoaderService.isPlayerLoaded()) {
      this.brightcoveWebPlayerLoaderService.forcePlayerLoad();
    }
  }

  get playIcon() {
    return this.stopped ? 'play' : 'pause';
  }

  get stopped() {
    return this.playerIsPaused;
  }

  ngOnInit() {
    this.brightcoveWebPlayerLoaderService.playerLoadPromise
      .then(() => this.load());
  }

  load() {
    if (this.player) {
      // we can get into this situation when
      // switching alternatives files on an exercise
      this.unload();
    }

    const cssClass = this.config.runningOnSafari ? 'vjs-16-9 safari' : 'vjs-16-9';

    // https://docs.videojs.com/tutorial-layout.html
    const playerHTML = `
      <video-js
        id="brightcove-player"
        data-video-id="${this.brightcove_key}"
        data-account="${this.accountId}"
        data-player="${this.playerId}"
        data-embed="default"
        class="${cssClass}"
        controls>
      </video-js>`;

    this.videoWrapper.nativeElement.innerHTML = playerHTML;

    this.player = window['bc']('brightcove-player');

    this.player.on('loadedmetadata', () => {
      if (this.autoplay) {
        this.player.play();
      }
    });

    this.listenPlayerEvents();

    if (this.controls) {
      this.controlsSubscription = this.controls.subscribe((action) => this.parentPlayControl(action));
    }
  }

  setPlayerSubtitles() {
    // from https://github.com/BrightcoveLearning/18750-automatically-set-caption-language/blob/master/index.html
    const tracks = this.player.textTracks();

    for (let i = 0; i < (tracks.length); i++) {
      const track_language = tracks[i].language.substr(0, 2);

      if (track_language) {
        if (track_language === this.userLanguage) {
          tracks[i].mode = 'showing';
        } else {
          tracks[i].mode = 'disabled';
        }
      }
    }
  }

  ngAfterViewInit() {
    if (!this.brightcove_key) {
      this.logger.error('BrightcoveWebVideoPlayerError', 'Video player requested, but no brightcove_key provided');

      return this.playerError.emit();
    }
  }

  ngOnChanges(changes) {
    if (changes.brightcove_key) {
      const {brightcove_key} = changes;
      const hasBrightcoveKeyChanges = brightcove_key && brightcove_key.currentValue !== brightcove_key.previousValue && !brightcove_key.firstChange;

      if (!hasBrightcoveKeyChanges) {
        return;
      }

      if (brightcove_key && brightcove_key.currentValue) {
        this.setSrc();
      }
    }
  }

  ngOnDestroy() {
    this.unload();
  }

  unload() {
    this.player && this.player.dispose();
    this.controlsSubscription && this.controlsSubscription.unsubscribe();
  }

  parentPlayControl(action) {
    switch (action) {
      case 'play':
        return this.resumePlay();

      case 'pause':
        return this.pausePlay();

      default:
        return false;
    }
  }

  pausePlay() {
    this.player.pause();
  }

  resumePlay() {
    setTimeout(() => {
      const ret = this.player.play();

      // on some older Android devices, this will not return a promise
      if (ret && ret.catch) {
        ret.catch((error) => {
          // sometimes the error will not be available so this will throw an exception
          // console.log('Player error occurred', error, this.player.error.code ? this.player.error.code : 'Unknown error code');

          this.handleError(error);
        });
      }
    });
  }

  handleError(error) {
    this.playerError.emit(error);
  }

  getCurrentTime() {
    return this.player.getPosition();
  }

  setCurrentTime(newTime) {
    this.player.seek(newTime);
  }

  forwardFifteenSec() {
    this.pausePlay();
    this.setCurrentTime(this.getCurrentTime() + 15);
    this.resumePlay();
  }

  backwardFifteenSec() {
    this.pausePlay();
    this.setCurrentTime(Math.max(this.getCurrentTime() - 15, 0));
    this.resumePlay();
  }

  handlePlaying() {
    console.log('video - playing');

    this.playerIsPaused = false;

    this.changeDetector.detectChanges();

    this.cancelAutoPauseTimeout();

    if (!this.playingStarted) {
      if (this.playedMinimum.observers.length > 0) {
        setTimeout(() => {
          this.playedMinimum.emit(this.MINIMUM_PLAYED_SECS);
        }, this.MINIMUM_PLAYED_SECS * 1000);
      }

      if (this.autoPauseAfter && this.autoPaused.observers.length > 0) {
        this.autoPauseAfterTimeout = setTimeout(() => {
          this.pausePlay();
          this.exitFullscreen();

          this.autoPaused.emit(this.autoPauseAfter);
        }, this.autoPauseAfter * 1000);
      }
    }

    this.playingStarted = true;
  }

  handlePause() {
    console.log('video - pause');

    this.playerIsPaused = true;

    // cancel any autopause if the user pauses manually
    if (this.autoPauseAfterTimeout) {
      this.cancelAutoPauseTimeout();
    }

    this.changeDetector.detectChanges();
  }

  handleEnded() {
    console.log('video - ended');
    this.changeDetector.detectChanges();
    this.completed.emit();
  }

  handleCanPlayThrough(buffer) {
    if (this.autoplay && buffer.bufferPercent === 100) {
      console.log('video - canplaythrough');
      this.canPlayThrough.emit(true);
    }

    if (this.showSubtitles) {
      this.setPlayerSubtitles();
    }
  }

  handleCanPlay() {
    console.log('video - canplay', this.autoplay, this.autoSkip);

    if (this.autoplay && !this.autoSkip) {
      this.canPlay.emit(true);
      this.resumePlay();
    }

    if (this.autoSkip) {
      this.autoSkipped.emit();
      this.playedMinimum.emit(0);
    }
  }

  enterFullscreen() {
    this.player.setFullscreen(true);
  }

  setSrc() {
    this.playingStarted = false;

    this.load();
  }

  exitFullscreen() {
    this.player.setFullscreen(false);
  }

  private cancelAutoPauseTimeout() {
    clearTimeout(this.autoPauseAfterTimeout);
    this.autoPauseAfterTimeout = null;
  }

  private listenPlayerEvents() {
    this.player.on('play', this.handlePlaying.bind(this));
    this.player.on('pause', this.handlePause.bind(this));
    this.player.on('ended', this.handleEnded.bind(this));
    this.player.on('canplaythrough', this.handleCanPlayThrough.bind(this));
    this.player.on('error', this.handleError.bind(this));
    // looks like this is not supported by video-js
    // this.player.on('captionsChanged', this.captionsChanged.bind(this));
  }

}
