import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../store/state.reducer';
import { DatetimeChangeEventDetail, IonContent } from '@ionic/angular';
import { CloseModal } from '../../store/session/actions/navigation.actions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UpdateUserProfile } from '../../store/session/actions/user-profile.actions';
import { getProfileUpdated } from '../../store/session/selectors/user-profile.selectors';
import { LoadingService } from '../../services/loading.service';
import { getCurrentUserProfile, hasAcceptedTerms } from '../../store/normalized/selectors/user-profile.selectors';
import { getCurrentUser } from '../../store/normalized/selectors/user.selectors';
import { first, withLatestFrom } from 'rxjs/operators';
import { ClarityConfig } from '../../config/clarity.config';
import { BrowserService } from '../../services/browser.service';
import { Subscription } from 'rxjs';
import moment from 'moment-timezone';
import { ImageCroppedEvent, CropperPosition, Dimensions } from 'ngx-image-cropper';
import { dateFormatter } from 'src/app/utils/date-formatter';


@Component({
  selector: 'page-profile-completion',
  templateUrl: './profile-completion.html',
  styleUrls: ['./profile-completion.scss']
})

export class ProfileCompletionPage implements OnInit, OnDestroy {
  @ViewChild(IonContent, { static: true }) content: IonContent;
  @ViewChild('bufferImg') public bufferImg;
  @ViewChild('bufferCanvas', { static: true }) public bufferCanvas;
  @ViewChild('profileAvatarField') public avatarFileField;

  modal;
  imageUrl: any;
  croppedImage = null;
  profileForm: FormGroup;
  currentUserProfile$ = this.store.select(getCurrentUserProfile);
  currentUser$ = this.store.select(getCurrentUser);
  cropperCoordinates: CropperPosition = {x1: 0, y1: 0, x2: 0, y2: 0};

  profileSubscription: Subscription;
  termsSubscription: Subscription;
  termsAgreed = true;

  imageEditing = false;
  imageLoading = false;

  maxQuitDate: string;

  cropperOptions: any = {
    dragMode: 'crop',
    aspectRatio: 1,
    autoCrop: true,
    movable: true,
    zoomable: true,
    scalable: true,
    autoCropArea: 0.8
  };

  profileData: any = {
    id: '',
    username: '',
    avatar: null,
    quit_date: null
  };

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<State>,
    private loading: LoadingService,
    public config: ClarityConfig,
    private browser: BrowserService
  ) {
    this.termsSubscription = this.store.select(hasAcceptedTerms)
      .subscribe((agreed) => {
        this.termsAgreed = agreed;
      });

    this.profileSubscription = this.currentUserProfile$
      .pipe(withLatestFrom(this.currentUser$))
      .subscribe((res) => {
        this.profileData = res[0];

        // default username if empty
        if (this.profileData && (!this.profileData.username || 0 === this.profileData.username.length)) {
          this.profileData.username = this.getDefaultUsernameFromUser(res[1]);
        }

        this.profileForm = this.formBuilder.group({
          username: [this.profileData ? this.profileData.username : null, Validators.required],
          avatar: [this.profileData ? this.profileData.avatar : null],
          quit_date: [this.profileData ? this.profileData.quit_date : null]
        });
      });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  cropperReady(loadedImage: Dimensions) {
    const initialCropperDimensions = {
      x: Math.min(loadedImage.width, loadedImage.height) >= 480 ? 480 : Math.min(loadedImage.width, loadedImage.height),
      y: Math.min(loadedImage.width, loadedImage.height) >= 480 ? 480 : Math.min(loadedImage.width, loadedImage.height)
    };
    const paddingValueX = (loadedImage.width - initialCropperDimensions.x) / 2;
    const paddingValueY = (loadedImage.height - initialCropperDimensions.y) / 2;

    this.cropperCoordinates = {
      x1: paddingValueX,
      y1: paddingValueY,
      x2: loadedImage.width - paddingValueX,
      y2: loadedImage.height - paddingValueY
    };
  }

  ngOnInit(): void {
    this.maxQuitDate = moment()
      .subtract(1, 'days')
      .format('YYYY-MM-DD');
  }

  getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)['__zone_symbol__originalInstance'];

    return zoneOriginalInstance || fileReader;
  }

  async changeAvatar(event) {
    const f = event.target.files[0]; // FileList object

    this.imageLoading = true;

    const orientation: any = await this.getOrientation(f);

    const reader = this.getFileReader();
    reader.onload = ((theFile) => (ev) => {
      let img = new Image();
      img.src = ev.target.result as string;
      img.onload = () => {
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');

        ctx.drawImage(img, 0, 0);

        const width = 600;
        const scaleFactor = width / img.width;
        canvas.width = width;
        const height = canvas.height = img.height * scaleFactor;

        if ([5, 6, 7, 8].includes(orientation)) {
          canvas.width = height;
          canvas.height = width;
        }

        ctx = canvas.getContext('2d');
        switch (orientation) {
          case 2:
            ctx.transform(-1, 0, 0, 1, width, 0);
            break;
          case 3:
            ctx.transform(-1, 0, 0, -1, width, height);
            break;
          case 4:
            ctx.transform(1, 0, 0, -1, 0, height);
            break;
          case 5:
            ctx.transform(0, 1, 1, 0, 0, 0);
            break;
          case 6:
            ctx.transform(0, 1, -1, 0, height, 0);
            break;
          case 7:
            ctx.transform(0, -1, -1, 0, height, width);
            break;
          case 8:
            ctx.transform(0, -1, 1, 0, 0, width);
            break;
          default:
            ctx.transform(1, 0, 0, 1, 0, 0);
        }
        ctx.drawImage(img, 0, 0, width, height);
        this.imageUrl = canvas.toDataURL(f.type);

        this.imageLoading = false;
        this.imageEditing = true;
        img = null;
        canvas = null;
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsDataURL(f);
  }

  ngOnDestroy(): void {
    this.termsSubscription && this.termsSubscription.unsubscribe();
    this.profileSubscription && this.profileSubscription.unsubscribe();
  }

  saveEdition() {
    this.profileForm.patchValue({avatar: this.croppedImage});
    this.imageEditing = false;
  }

  cancelEdition() {
    this.imageEditing = false;
    this.avatarFileField.nativeElement.value = null;
  }

  reset() {
    this.profileForm.patchValue({avatar: null});
    this.imageEditing = true;
  }

  closeModal() {
    this.store.dispatch(new CloseModal({modalId: this.modal.id}));
  }

  onAgree() {
    this.currentUserProfile$.pipe(first())
      .subscribe(profile => {
        this.store.dispatch(new UpdateUserProfile({...profile, agreed_to_tos: true}));
      });
  }

  onClose() {
    this.closeModal();
  }

  onTermsClick() {
    this.browser.goTo(this.config.privacyTermsUrl);
  }

  profileCompletion() {
    this.store.dispatch(new UpdateUserProfile(this.profileForm.value));
    this.store.select(getProfileUpdated)
      .pipe(first(profileUpdated => profileUpdated))
      .subscribe(() => {
        this.closeModal();
      });

  }

  resetQuitDate() {
    this.profileForm.controls.quit_date.setValue(null);
  }

  get quittingDate() {
    return this.profileForm.get('quit_date');
  }

  setQuittingDate(changeEvent: CustomEvent<DatetimeChangeEventDetail>) {
    this.quittingDate.setValue(dateFormatter(changeEvent.detail.value));
  }

  private getOrientation(file) {
    return new Promise((resolve, reject) => {
      const reader = this.getFileReader();
      reader.onload = (event: any) => {

        const view = new DataView(event.target.result);
        if (view.getUint16(0, false) !== 0xFFD8) {
          return resolve(-2);
        }
        const length = view.byteLength;
        let offset = 2;
        while (offset < length) {
          const marker = view.getUint16(offset, false);
          offset += 2;
          if (marker === 0xFFE1) {
            const little = view.getUint16(offset += 8, false) === 0x4949;
            offset += view.getUint32(offset + 4, little);
            const tags = view.getUint16(offset, little);
            offset += 2;
            for (let i = 0; i < tags; i++) {
              if (view.getUint16(offset + (i * 12), little) === 0x0112) {
                return resolve(view.getUint16(offset + (i * 12) + 8, little));
              }
            }
          } else if ((marker & 0xFF00) !== 0xFF00) {
            break;
          } else {
            offset += view.getUint16(offset, false);
          }
        }

        return resolve(0);
      };
      reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
    });
  }

  private getDefaultUsernameFromUser(user) {
    if (!user) {
      return;
    }
    const parts = [];

    if (user.first_name && user.first_name.length > 0) {
      parts.push(user.first_name);
    }
    if (user.last_name && user.last_name.length > 0) {
      parts.push(user.last_name.substring(0, 1) + '.');
    }

    return parts.join(' ');
  }
}
