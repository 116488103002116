import { SyncState } from '../../store/session/reducers/sync.reducer';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActionCableService } from './action-cable.service';
import { SessionState } from '../../store/session/session.reducers';
import { getCurrentUserProgram } from '../../store/normalized/selectors/user.selectors';
import { getSyncState } from '../../store/state.reducer';
import * as syncActions from '../../store/session/actions/sync.actions';
import { Subscription } from 'rxjs';
import { ClarityConfig } from '../../config/clarity.config';
import { LoggerService } from '../logger.service';

const RECEIVED_MESSAGES: MessageType[] = [
  {
    actionCableProperty: 'programs',
    reducerProperty: 'programs',
    actions: [syncActions.SyncUserBootstrap, syncActions.SyncProgram]
  },
  {
    actionCableProperty: 'user_bootstraps',
    reducerProperty: 'userBootstrap',
    actions: [syncActions.SyncUserBootstrap, syncActions.SyncProgram]
  },
  {
    actionCableProperty: 'user_progresses',
    reducerProperty: 'userProgress',
    actions: [syncActions.SyncUserProgress, syncActions.SyncProgramDaysByDay],
    debounceTime: 2
  },
  {
    actionCableProperty: 'user_programs',
    reducerProperty: 'userProgram',
    actions: [syncActions.SyncUserProgram]
  },
  {
    actionCableProperty: 'program_bonus_exercises',
    reducerProperty: 'programBonusExercises',
    actions: [syncActions.SyncBonusExercises]
  },
  {
    actionCableProperty: 'cig_counts',
    reducerProperty: 'cigCounts',
    actions: [syncActions.SyncCigCount]
  },
  {
    actionCableProperty: 'community_posts',
    reducerProperty: 'communityPosts',
    actions: [syncActions.SyncCommunityPosts]
  },
  {
    actionCableProperty: 'community_tracking_posts',
    reducerProperty: 'communityBookmarkedPosts',
    actions: [syncActions.SyncCommunityBookmarkedPosts]
  },
  {
    actionCableProperty: 'community_journals',
    reducerProperty: 'communityJournal',
    actions: [syncActions.SyncCommunityJournal]
  },
  {
    actionCableProperty: 'user_reminders',
    reducerProperty: 'userReminders',
    actions: [syncActions.SyncUserReminders]
  },
  {
    actionCableProperty: 'user_goals',
    reducerProperty: 'userGoals',
    actions: [syncActions.SyncUserGoals],
    debounceTime: 1
  },
  {
    actionCableProperty: 'user_accounts',
    reducerProperty: 'userAccounts',
    actions: [syncActions.SyncUserAccount]
  },
  {
    actionCableProperty: 'subscriptions',
    reducerProperty: 'subscriptions',
    actions: [syncActions.SyncSubscription]
  },
  {
    actionCableProperty: 'check_ins',
    reducerProperty: 'checkIns',
    actions: [syncActions.SyncCheckins]
  },
  {
    actionCableProperty: 'stress_tests',
    reducerProperty: 'stressTests',
    actions: [syncActions.SyncStressTests]
  },
  {
    actionCableProperty: 'craving_meters',
    reducerProperty: 'cravingMeters',
    actions: [syncActions.SyncCravingMeters]
  },
  {
    actionCableProperty: 'dis_o_meters',
    reducerProperty: 'disOMeters',
    actions: [syncActions.SyncDisOMeters]
  },
  {
    actionCableProperty: 'awareness_quizzes',
    reducerProperty: 'awarenessQuizzes',
    actions: [syncActions.SyncAwarenessQuizzes]
  },
  {
    actionCableProperty: 'anxiety_quizzes',
    reducerProperty: 'anxietyQuizzes',
    actions: [syncActions.SyncAnxietyQuizzes]
  },
  {
    actionCableProperty: 'video_chat_schedules',
    reducerProperty: 'videoChatSchedules',
    actions: [syncActions.SyncVideoChatSchedules]
  },
  {
    actionCableProperty: 'theme_weeks',
    reducerProperty: 'themeWeeks',
    actions: [syncActions.SyncThemeWeeks]
  },
  {
    actionCableProperty: 'worry_tools',
    reducerProperty: 'worryTool',
    actions: [syncActions.SyncWorryTool]
  },
  {
    actionCableProperty: 'favourites',
    reducerProperty: 'userFavs',
    actions: [syncActions.SyncUserFavs]
  },
  {
    actionCableProperty: 'weight_activities',
    reducerProperty: 'weightActivities',
    actions: [syncActions.SyncWeightActivities]
  },
  {
    actionCableProperty: 'minutes_activities',
    reducerProperty: 'minutesActivities',
    actions: [syncActions.SyncMinutesActivities],
    debounceTime: 2
  },
  {
    actionCableProperty: 'program_days',
    reducerProperty: 'programDays',
    actions: [syncActions.SyncProgramDays]
  },
  {
    actionCableProperty: 'exercises',
    reducerProperty: 'exercises',
    actions: [syncActions.SyncExercises]
  }
];

interface MessageType {
  actionCableProperty: string;
  reducerProperty: string;
  actions: any[];
  debounceTime?: number;
  skipSyncOnOwnActions?: boolean;
}

@Injectable({providedIn: 'root'})
export class UserDataSyncChannelService implements OnDestroy {
  readonly DEBOUNCE_TIME_IN_SECONDS = 10;

  messageBuffer = {};

  public applicationDataSyncChannel: any;
  private userDataSyncChannel: any;

  private callback: () => void;
  private userProgramId: number;
  private syncState: SyncState;
  private syncSubscription: Subscription;

  constructor(
    private actionCableService: ActionCableService,
    private sessionStore: Store<SessionState>,
    private config: ClarityConfig,
    private logger: LoggerService,
    private zone: NgZone
  ) {
    this.setUserProgramId();
    this.setLastSyncAt();
  }

  initActionCable(): void {
    if (!this.userProgramId) {
      this.logger.error('action-cable', 'starting actioncable with empty userProgramId');
    }

    this.zone.runOutsideAngular(() => {

      this.applicationDataSyncChannel = this.actionCableService.createChannel(
        {
          channel: 'ApplicationDataSyncChannel',
          // DPPWL uses a different channel
          program: this.config.currentProgramCode
        },
        {
          connected: () => this.sessionStore.dispatch(new syncActions.ApplicationDataSyncChannelConnected()),
          received: message => {
            this.handleReceivedMessage(message);

            if (this.callback) this.callback.call(null, message);
          },
          disconnected: () => this.logger.warning('action-cable', 'action cable disconnected')
        }
      );

      this.userDataSyncChannel = this.actionCableService.createChannel(
        {
          channel: 'UserDataSyncChannel',
          user_program_id: this.userProgramId
        },
        {
          received: message => {
            this.handleReceivedMessage(message);
            if (this.callback) this.callback.call(null, message);
          },
          disconnected: () => this.logger.warning('action-cable', 'action cable disconnected')
        }
      );
    });

  }

  sendMessage(action: string, message: object): void {
    this.userDataSyncChannel.perform(action, message);
  }

  handleReceivedMessage(message: any): void {

    RECEIVED_MESSAGES.forEach(receivedMessage => {
      let dispatchSync = false;

      const receivedMessageTimestamp = message[receivedMessage.actionCableProperty];
      const lastSyncStateTimestamp = this.syncState.lastSyncTimestamps[receivedMessage.reducerProperty];

      // handle initial sync, we compare with the sync everything timestamp
      if (this.syncState && !lastSyncStateTimestamp) {
        if (new Date(this.syncState.lastSyncEverythingAt) < new Date(receivedMessageTimestamp)) {
          dispatchSync = true;
        }
      } else if (receivedMessageTimestamp && (receivedMessageTimestamp !== lastSyncStateTimestamp)) {
        console.log(`
          Dispatching sync for ${receivedMessage.reducerProperty} -
          received TS ${receivedMessageTimestamp} vs. last sync TS ${lastSyncStateTimestamp}
        `);
        dispatchSync = true;
      }

      // skip resync if skipSyncOnOwnActions is set
      if (receivedMessage.skipSyncOnOwnActions && message.device_id
        && message.device_id === this.config.env.device.device_uuid) {
        dispatchSync = false;
      }

      if (dispatchSync) {
        this.dispatchAction(receivedMessage, receivedMessageTimestamp, lastSyncStateTimestamp);
      }
    });
  }

  setCallback(fn: () => void): void {
    this.callback = fn;
  }

  dispatchAction(receivedMessage: MessageType, receivedTimestamp: string, lastTimestamp: string) {
    if (!receivedMessage.debounceTime) {
      receivedMessage.actions.forEach((action) => {
        this.sessionStore.dispatch(new action(receivedTimestamp, lastTimestamp));
      });

      return;
    }

    const oldPayload = this.messageBuffer[receivedMessage.actionCableProperty] || {};

    if (oldPayload) {
      clearTimeout(oldPayload.timer);
    }

    const timerClosure = (receivedMsg, timestr) => () => {
      receivedMsg.actions.forEach((action) => {
        this.sessionStore.dispatch(new action(timestr));
      });

      delete this.messageBuffer[receivedMsg.actionCableProperty];
    };

    this.zone.runOutsideAngular(() => {
      const newTimer = setTimeout(timerClosure(receivedMessage, receivedTimestamp), receivedMessage.debounceTime * 1000);

      this.messageBuffer[receivedMessage.actionCableProperty] = {
        receivedMessage,
        timestamp: receivedTimestamp,
        timer: newTimer
      };
    });
  }

  setUserProgramId(): void {
    this.sessionStore.select(getCurrentUserProgram)
      .subscribe(currentUserProgram => this.userProgramId = currentUserProgram.id);
  }

  setLastSyncAt(): void {
    this.syncSubscription = this.sessionStore.select(getSyncState)
      .subscribe(syncState => {
        this.syncState = syncState;
      });
  }

  ngOnDestroy() {
    this.syncSubscription && this.syncSubscription.unsubscribe();
  }
}
