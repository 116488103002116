import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { FileService } from '../../services/files/file.service';
import { ClarityConfig } from '../../config/clarity.config';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { JWPlayerService } from '../../services/jwplayer.service';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store';
import * as mediaActions from '../../store/persistent/media/media.actions';
import { getCurrentUserProgram } from '../../store/normalized/selectors/user.selectors';
import { take, withLatestFrom } from 'rxjs/operators';
import { hasEnglishSubtitles } from '../../store/persistent/media/media.selectors';
import { AlertsService } from '../../services/alerts.service';
import { LoadingService } from '../../services/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '../../services/logger.service';

const LANGS_CODE_NAME = {
  es: 'Spanish',
  en: 'English'
};

@Component({
  selector: 'cl-video-player-jwplayer',
  styleUrls: ['video-player-jwplayer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="video-wrapper">
      <div #video class="video"></div>
    </div>
    <ion-row>
      <ion-col>
        <ion-button *ngIf="player && showControls" type="button" (click)="backwardFifteenSec()"
                fill="outline" size="small" color="white">
          <ion-icon name="play-back"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-button *ngIf="player && showControls" type="button" (click)="stopped ? resumePlay() : pausePlay()"
                fill="outline" size="small"
                color="white">
          <ion-icon name="{{playIcon}}"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-button *ngIf="player && showControls" type="button" (click)="enterFullscreen()"
                fill="outline" size="small" color="white">
          <ion-icon name="desktop"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-button *ngIf="player && showControls" type="button" (click)="forwardFifteenSec()"
                fill="outline" size="small" color="white">
          <ion-icon name="play-forward"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  `
})
export class VideoPlayerJWPlayerComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  readonly MINIMUM_PLAYED_SECS = 1;
  @Input() jw_key: string;

  @Input() title: string;
  @Input() controls: Observable<string>;
  @Input() showControls: boolean;
  @Input() autoplay: boolean;
  @Input() autoSkip: boolean;
  @Input() autoPauseAfter: number;

  @Output() canPlay = new EventEmitter();
  @Output() canPlayThrough = new EventEmitter();
  @Output() playerError = new EventEmitter();
  @Output() playedMinimum = new EventEmitter();
  @Output() completed = new EventEmitter();
  @Output() autoSkipped = new EventEmitter();
  @Output() autoPaused = new EventEmitter();

  @ViewChild('video', { static: true }) playerElement: ElementRef;
  player: any;

  @ViewChild('track', { static: true }) trackElement: ElementRef;

  private playerIsPaused = true;
  private previousCaptionEvent;

  showTrackElement = false;

  playingStarted = false;
  controlsSubscription: Subscription;

  autoPauseAfterTimeout: any;

  userLanguage: string;
  userProgramSubscription: Subscription;
  captionsConfigInit = false;
  showSubtitles = false;

  constructor(
    public config: ClarityConfig,
    private file: FileService,
    private insomnia: Insomnia,
    protected changeDetector: ChangeDetectorRef,
    private backgroundMode: BackgroundMode,
    private store: Store<State>,
    private jwPlayerService: JWPlayerService,
    private alerts: AlertsService,
    private loading: LoadingService,
    private translate: TranslateService,
    private logger: LoggerService
  ) {
    this.userProgramSubscription = this.store.select(getCurrentUserProgram)
      .pipe(withLatestFrom(this.store.select(hasEnglishSubtitles)), take(1))
      .subscribe(([userProgram, englishSubtitleEnable]) => {
        this.showSubtitles = (userProgram.language_code !== ClarityConfig.DEFAULT_LANGUAGE) ||
          (userProgram.language_code === ClarityConfig.DEFAULT_LANGUAGE && englishSubtitleEnable);

        this.userLanguage = userProgram.language_code;
      });

    if (!this.jwPlayerService.isPlayerLoaded()) {
      this.jwPlayerService.forcePlayerLoad();
    }
  }

  get playIcon() {
    return this.stopped ? 'play' : 'pause';
  }

  get stopped() {
    return this.playerIsPaused;
  }

  ngOnInit() {
    this.jwPlayerService.playerLoadPromise
      .then(() => {
        this.player = this.jwPlayerService.getPlayerElement(this.playerElement.nativeElement);

        // // TODO: Reenable orientation handling when landscape disabled
        // this.player.addEventListener('webkitfullscreenchange', this.handleScreenOrientation.bind(this));

        if (this.controls) {
          this.controlsSubscription = this.controls.subscribe((action) => this.parentPlayControl(action));
        }
      });
  }

  ngAfterViewInit() {
    if (!this.jw_key) {
      this.logger.error('JWPlayerError', 'Video player requested, but no JW key provided');

      return this.playerError.emit();
    }

    this.jwPlayerService.playerLoadPromise
      .then(() => this.setSrc());
  }

  ngOnChanges(changes) {
    if (changes.jw_key) {
      const {jw_key} = changes;
      const hasJwKeyChanges = jw_key && jw_key.currentValue !== jw_key.previousValue && !jw_key.firstChange;

      if (!hasJwKeyChanges) {
        return;
      }

      if (jw_key && jw_key.currentValue) {
        this.setSrc();
      }
    }
  }

  ngOnDestroy() {
    if (this.config.isDevice) {
      this.insomnia.allowSleepAgain();
      this.backgroundMode.disable();
    }

    this.player && this.player.remove();
    this.controlsSubscription && this.controlsSubscription.unsubscribe();
  }

  parentPlayControl(action) {
    switch (action) {
      case 'play':
        return this.resumePlay();

      case 'pause':
        return this.pausePlay();

      default:
        return false;
    }
  }

  pausePlay() {
    if (this.config.isDevice) {
      this.insomnia.allowSleepAgain();
      this.backgroundMode.disable();
    }

    this.player.pause();
  }

  resumePlay() {
    if (this.config.isDevice) {
      this.insomnia.keepAwake();
      this.backgroundMode.enable();
    }

    setTimeout(() => {
      const ret = this.player.play();

      // on some older Android devices, this will not return a promise
      if (ret && ret.catch) {
        ret.catch((error) => {
          // sometimes the error will not be available so this will throw an exception
          // console.log('Player error occurred', error, this.player.error.code ? this.player.error.code : 'Unknown error code');

          this.handleError(error);
        });
      }
    });
  }

  handleError(error) {
    if (this.config.isDevice) {
      this.insomnia.allowSleepAgain();
      this.backgroundMode.disable();
    }

    this.playerError.emit(error);
  }

  getCurrentTime() {
    return this.player.getPosition();
  }

  setCurrentTime(newTime) {
    this.player.seek(newTime);
  }

  forwardFifteenSec() {
    this.pausePlay();
    this.setCurrentTime(this.getCurrentTime() + 15);
    this.resumePlay();
  }

  backwardFifteenSec() {
    this.pausePlay();
    this.setCurrentTime(Math.max(this.getCurrentTime() - 15, 0));
    this.resumePlay();
  }

  handlePlaying() {
    console.log('video - playing');

    this.playerIsPaused = false;

    this.changeDetector.detectChanges();

    this.cancelAutoPauseTimeout();

    if (!this.playingStarted) {
      if (this.playedMinimum.observers.length > 0) {
        setTimeout(() => {
          this.playedMinimum.emit(this.MINIMUM_PLAYED_SECS);
        }, this.MINIMUM_PLAYED_SECS * 1000);
      }

      if (this.autoPauseAfter && this.autoPaused.observers.length > 0) {
        this.autoPauseAfterTimeout = setTimeout(() => {
          this.pausePlay();
          this.exitFullscreen();

          this.autoPaused.emit(this.autoPauseAfter);
        }, this.autoPauseAfter * 1000);
      }
    }

    this.playingStarted = true;
  }

  handlePause() {
    console.log('video - pause');

    this.playerIsPaused = true;

    // cancel any autopause if the user pauses manually
    if (this.autoPauseAfterTimeout) {
      this.cancelAutoPauseTimeout();
    }

    this.changeDetector.detectChanges();
  }

  handleEnded() {
    console.log('video - ended');
    this.changeDetector.detectChanges();
    this.completed.emit();
  }

  handleCanPlayThrough(buffer) {
    const allTracks = this.player.getCaptionsList();
    if (allTracks && this.showSubtitles) {
      const data = {
        tracks: allTracks,
        track: allTracks.findIndex(t => t.label === LANGS_CODE_NAME[this.userLanguage])
      };
      this.captionsChanged(data);
    } else if (allTracks) {
      const data = {
        tracks: allTracks,
        track: allTracks.findIndex(t => t.label === 'Off')
      };
      this.captionsChanged(data);
    }

    if (this.autoplay && buffer.bufferPercent === 100) {
      console.log('video - canplaythrough');
      this.canPlayThrough.emit(true);
    }
  }

  handleCanPlay() {
    console.log('video - canplay', this.autoplay, this.autoSkip);

    if (this.autoplay && !this.autoSkip) {
      this.canPlay.emit(true);
      this.resumePlay();
    }

    if (this.autoSkip) {
      this.autoSkipped.emit();
      this.playedMinimum.emit(0);
    }
  }

  enterFullscreen() {
    this.player.setFullscreen(true);
  }
  //
  loadVideo(src) {
    let prefix = '';

    // sanitize urls missing protocol
    if (src.indexOf('//') === 0) {
      prefix = 'https:';
    }

    return this.file.getFileLocalUrl(src)
      .then((localSrc) => {
        console.log('found local file', localSrc);

        return Promise.resolve(localSrc);
      })
      .catch(_ => {
        console.log('playing remote file', src);

        return Promise.resolve(prefix + src);
      });
  }

  setSrc() {
    this.captionsConfigInit = false;
    this.playingStarted = false;
    const mediaConfig = {playlist: `https://cdn.jwplayer.com/v2/media/${this.jw_key}`};

    this.player.setup(
      Object.assign({
        stretching: 'fill'
      }, mediaConfig)
    );
    setTimeout(this.listenPlayerEvents.bind(this));
    this.handleCanPlay();
  }

  exitFullscreen() {
    this.player.setFullscreen(false);
  }

  captionsChanged(event) {
    const selectedTrack = event.tracks[event.track];
    if (!event.type && !this.captionsConfigInit) {
      this.captionsConfigInit = true;

      if (this.userLanguage !== ClarityConfig.DEFAULT_LANGUAGE) {
        // Enable by default (user lang is other than "english" so we enable the subtitles
        const whichTrack = event.tracks.findIndex(t => t.label === LANGS_CODE_NAME[this.userLanguage]);
        this.player.setCurrentCaptions(whichTrack);
      } else {
        // User lang is same as the AppDefaultLang, we rely on the EnglishSubtitlesSetting to enable them by default or not
        if (this.showSubtitles) {
          const whichTrack = event.tracks.findIndex(t => t.label === LANGS_CODE_NAME[this.userLanguage]);
          this.player.setCurrentCaptions(whichTrack);
        } else {
          this.player.setCurrentCaptions(0);
        }
      }

      return;
    } else if (
      this.userLanguage !== ClarityConfig.DEFAULT_LANGUAGE ||
      this.previousCaptionEvent && this.previousCaptionEvent.track === event.track
    ) {
      return;
    }

    if (selectedTrack && selectedTrack.id === 'off') {
      this._disableSubtitles();
    } else if (selectedTrack.label === LANGS_CODE_NAME[this.userLanguage]) {
      this._enableSubtitles();
    }

    this.previousCaptionEvent = event;
  }

  private _enableSubtitles() {
    this.store.dispatch(new mediaActions.EnableEnglishSubtitles());
    this.showSubtitles = true;
  }

  private _disableSubtitles() {
    this.store.dispatch(new mediaActions.DisableEnglishSubtitles());
    this.showSubtitles = false;
  }

  private cancelAutoPauseTimeout() {
    clearTimeout(this.autoPauseAfterTimeout);
    this.autoPauseAfterTimeout = null;
  }

  private listenPlayerEvents() {
    this.player.on('pause', this.handlePause.bind(this));
    this.player.on('play', this.handlePlaying.bind(this));
    this.player.on('complete', this.handleEnded.bind(this));
    this.player.on('bufferChange', this.handleCanPlayThrough.bind(this));
    this.player.on('error', this.handleError.bind(this));
    this.player.on('captionsChanged', this.captionsChanged.bind(this));
  }

}
