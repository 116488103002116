import { Injectable } from '@angular/core';
import { ClarityConfig } from '../../config/clarity.config';
import { BrightcovePlayer } from 'capacitor-brightcove-player';
import { LoggerService } from '../logger.service';

@Injectable({providedIn: 'root'})
export class BrightcoveNativePlayerLoaderService {

  constructor(
    private config: ClarityConfig,
    private loggerService: LoggerService
  ) {}

  initialize() {
    if(!this.config.isBrightcoveEnabled()) {
      return Promise.resolve(true);
    }

    if (!this.config.isDevice) {
      this.loggerService.error('BrightcovePlayerService: ERROR Trying to load native player on a web context');

      return Promise.reject();
    }

    BrightcovePlayer.updateBrightcoveAccount({
      accountId: this.config.env.brightcove.accountId,
      policyKey: this.config.env.brightcove.policyKey
    }).catch(error => {
      this.loggerService.error('Error update brightcove account', error);
      throw error;
    });

    return Promise.resolve(true);
  }
}
