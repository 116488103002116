import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromAuth from '../../../store/sensitive/selectors/auth.selectors';
import { switchMap, take } from 'rxjs/operators';
import { SessionState } from '../../../store/session/session.reducers';

@Injectable({providedIn: 'root'})
export class ClarionAuthorizationInterceptor implements HttpInterceptor {
  private clarionToken: string = null;

  constructor(
    private store: Store<SessionState>
  ) {

    // subscribe to auth token changes
    this.store.select(fromAuth.getAuthToken)
      .subscribe((token) => {
        // console.log('clarion auth token updated');
        this.clarionToken = token;
      });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith('{iridium}')) {
      // handled by iridium-authorization.interceptor
      return next.handle(request);
    }

    return this.handleClarionRequest(request, next);
  }

  handleClarionRequest(request: HttpRequest<any>, next: HttpHandler) {
    if (this.clarionToken) {
      return next.handle(this.addClarionAuthorizationHeader(request, this.clarionToken));
    }

    // attempt token check if we don't have one
    return this.store.select(fromAuth.getAuthToken)
      .pipe(
        take(1),
        switchMap((token) => {
          if (token) {
            console.log('enabling clarion auth token');

            // add Authorization header to include the token
            request = this.addClarionAuthorizationHeader(request, token);
          }

          return next.handle(request);
        })
      );
  }

  private addClarionAuthorizationHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Token token=${token}`
      }
    });
  }
}
