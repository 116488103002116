import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cl-arrow-card',
  styleUrls: ['arrow-card.scss'],
  template: `
    <ion-card class="arrow-card" [class.greyed]="greyVersion">
        <ion-card-header class="card-header">
          {{header}}
        </ion-card-header>
        <ion-card-content class="card-content">
          {{content}}
        </ion-card-content>
        <div class="card-button">
          <ion-button
            class="dark-blue-theme dark-button"
            (click)="doAction()">
            {{button}}
            <ion-icon name="chevron-forward-outline"></ion-icon>
            <ion-ripple-effect></ion-ripple-effect>
          </ion-button>
        </div>
    </ion-card>
  `
})
export class ArrowCardComponent {
  @Input() header: string;
  @Input() content: string;
  @Input() button: string;
  @Input() greyVersion = false;
  @Output() action = new EventEmitter();

  doAction() {
    this.action.emit();
  }

}
