import { schema } from 'normalizr';
import { Checkin } from './checkin.schema';

export class CheckinAnxietyByDay {
  date: string;
  checkins: Checkin[];
}

export const checkinAnxietyByDaySchema = new schema.Entity(
  'checkinAnxietyByDays',
  undefined,
  { idAttribute: (checkinAnxietyByDay: CheckinAnxietyByDay) => checkinAnxietyByDay.date }
);
