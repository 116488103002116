import { schema } from 'normalizr';

export class Week {
  number: number;
  type: string;
  completedAt?: string;
  modules: Module[];
  recordId?: number;
  theme_week_id?: number;
}

export class Module {
  number?: number;
  type?: string;
  lessons?: Lesson[];
  recordId?: number;
  completedAt?: string;
  weekNumber: number;
}

export class Lesson {
  position?: number;
  type?: string;
  sameModal?: boolean;
  recordId?: number;
  moduleRecordId?: number;
  completedAt?: string;
  weekNumber: number;
  moduleNumber: number;
}

export class UserBootstrap {
  weeks: Week[];
  acceleratedTo?: number;
  restartedAt: string;
}

// TODO: Use UpdateData to push to existing Lesson and Module when new ngrx-normalizr version is released
export class Completed {
  id: string;
  completedAt: string;
}

export class Progress {
  id: 'modules' | 'lessons'; // "type" would be a more appropriate name.
  completed: Completed[];
}

export interface NormalizedProgress {
  [moduleIdOrLessonId: string]: Completed;
}

export const createLessonId = (lesson: Lesson) => `${lesson.weekNumber}-${lesson.moduleNumber}-${lesson.position}`;

export const lessonSchema = new schema.Entity(
  'lessons',
  {},
  {
    idAttribute: value => createLessonId(value)
  }
);

export const createModuleId = (module: Module) => `${module.weekNumber}-${module.number}`;

export const moduleSchema = new schema.Entity(
  'modules',
  {lessons: [lessonSchema]},
  {
    idAttribute: value => createModuleId(value)
  }
);

export const weekSchema = new schema.Entity(
  'weeks',
  {modules: [moduleSchema]},
  {idAttribute: value => value.number}
);

export const userBootstrapSchema = new schema.Entity(
  'userBootstraps',
  {weeks: [weekSchema]},
  {idAttribute: () => 'synced'}
);

export const completedSchema = new schema.Entity(
  'completed'
);

export const progressSchema = new schema.Entity(
  'progress', {
    completed: [completedSchema]
  }
);
