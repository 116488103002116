import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwiperComponent, SwiperModule } from 'swiper/angular';

import { IonicModule } from '@ionic/angular';
import { File } from '@ionic-native/file/ngx';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';

import { BrowserService } from '../app/services/browser.service';
import { FileService } from '../app/services/files/file.service';

import { WavesBackgroundComponent } from '../app/components/wrappers/waves-background.component';
import { ShadowWrapperComponent } from '../app/components/wrappers/shadow-wrapper.component';
import { CloseButtonComponent } from '../app/components/buttons/close-button.component';
import { BackButtonComponent } from '../app/components/buttons/back-button.component';
import { HeaderNavButtonsComponent } from '../app/components/buttons/header-nav-buttons.component';
import { ActionButtonComponent } from '../app/components/buttons/action-button.component';
import { InputFieldComponent } from '../app/components/fields/input-field.component';
import { SwitchFieldComponent } from '../app/components/fields/switch-field.component';
import { RangeSelectComponent } from '../app/components/fields/range-select.component';
import { IconTitleContainerComponent } from '../app/components/icon-title-container';
import { RegistrationWrapperComponent } from '../app/components/wrappers/registration-wrapper.component';
import { ActivityGraphComponent } from '../app/components/activity-graph.component';
import { VideoPlayerComponent } from '../app/components/players/video-player.component';
import { VideoPlayerJWPlayerComponent } from '../app/components/players/video-player-jwplayer.component';
import { AudioPlayerComponent } from '../app/components/players/audio-player.component';
import { AudioPlayerJWPlayerComponent } from '../app/components/players/audio-player-jwplayer.component';
import { NextButtonComponent } from '../app/components/buttons/next-button.component';
import { PrevButtonComponent } from '../app/components/buttons/prev-button.component';
import { ControlButtonsComponent } from '../app/components/buttons/control-buttons.component';
import { RadioListItemComponent } from '../app/components/wizards/radio-list-item.component';
import { ListSingleSelectComponent } from '../app/components/wizards/list-single-select.component';
import { RippleEffectDirective } from '../app/directives/ripple-effect.directive';
import { CheckinExerciseComponent } from '../app/components/wizards/checkin-exercise.component';
import { HorizontalSliderComponent } from '../app/components/wizards/horizontal-slider.component';
import { FeelingNowSelectorComponent } from '../app/components/wizards/feeling-now-selector.component';
import { SteppedProgressBarComponent } from '../app/components/wizards/stepped-progress-bar.component';
import { SimpleProgressBarComponent } from '../app/components/wizards/simple-progress-bar.component';
import { IntensitySelectorComponent } from '../app/components/wizards/intensity-selector.component';
import { VerticalSliderComponent } from '../app/components/wizards/vertical-slider.component';
import { ProcessTimePipe } from '../app/pipes/process-time.pipe';
import { ExercisesGroupComponent } from '../app/components/exercises/exercises-group.components';
import { ExerciseItemComponent } from '../app/components/exercises/exercise-item.components';
import { ListMultipleSelectComponent } from '../app/components/wizards/list-multiple-select.component';
import { CheckboxListItemComponent } from '../app/components/wizards/checkbox-list-item.component';
import { HoursAgoSelectorComponent } from '../app/components/wizards/hours-ago-selector.component';
import { AccordionComponent } from '../app/components/wizards/accordion.component';
import { StressTestButtonComponent } from '../app/components/wizards/stress-test-button.component';
import { ProcessDateInLocalPipe } from '../app/pipes/process-date-in-local.pipe';
import { PostsComponent } from '../app/components/posts/posts.component';
import { AwarenessQuizSliderComponent } from '../app/components/perform/self-assessment-slider.component';
import { GoalEditComponent } from '../app/pages/account/components/goal-edit.component';
import { SlideCardComponent } from '../app/components/slides/slide-card.component';
import { AwarenessGraphComponent } from '../app/components/awareness-graph.component';
import { MyStatsComponent } from '../app/pages/account/components/my-stats.component';
import { CommunityButtonComponent } from '../app/components/buttons/community-button.component';
import { AnxiousFeelingComponent } from '../app/components/wizards/anxious-feeling.component';
import { FeelingNowVerticalSelectorComponent } from '../app/components/wizards/feeling-now-vertical-selector.component';
import { ImFeelingLuckyButtonComponent } from '../app/components/wizards/im-feeling-lucky-button.component';
import { PickSensationComponent } from '../app/pages/wizard/components/stress-test/pick-sensation.components';
import { PickTriggerComponent } from '../app/pages/wizard/components/stress-meter/pick-trigger.components';
import { DailyGraphComponent } from '../app/components/daily-graph.component';
import { WeeklyGraphComponent } from '../app/components/weekly-graph.component';
import { CigarettesGraphComponent } from '../app/components/cigarettes-graph.component';
import { CigaretteStatsComponent } from '../app/components/cigarette-stats.component';
import { ProgramWeeksComponent } from '../app/components/journey/program-weeks.component';
import { JourneyWeekCardComponent } from '../app/components/journey/journey-week-card.component';
import { ModuleItemComponent } from '../app/components/journey/module-item.component';
import { GoalOverviewComponent } from '../app/pages/account/components/goal-overview.component';
import { PlayExerciseComponent } from '../app/pages/wizard/components/play-exercise/play-exercise.component';
import { translateConfig } from '../app/config/translate.config';
import { ListItemComponent } from '../app/components/wizards/list-item-component';
import { RelativeTimePipe } from '../app/pipes/relative-time.pipe';
import { DaysQuitRelativeTimePipe } from '../app/pipes/days-quit-relative-time.pipe';
import { EntryCardComponent } from '../app/components/posts/entry-card.component';
import { LikesAvatarsComponent } from '../app/components/posts/likes-avatars.component';
import { FooterComponent } from '../app/components/footer/footer.component';
import { SharecareLoginComponent } from 'src/app/components/buttons/sharecare-login.component';
import { TruncatedPostComponent } from '../app/components/posts/truncated-post.component';
import { TruncatePipe } from '../app/pipes/truncate.pipe';
import { TodaysLessonCardComponent } from '../app/components/slides/todays-lesson-card.components';
import { TabbedViewComponent } from 'src/app/components/tabbed-view/tabbed-view.component';
import { JourneyWeekCardWebComponent } from '../app/components/journey/journey-week-card.web-component';
import { ThemeJourneyWeekCardWebComponent } from '../app/components/journey/theme-journey-week-card.web-component';
import { ModuleItemWebComponent } from '../app/components/journey/module-item.web-component';
import { ModalComponent } from '../app/components/modal/modal.component';
import { ConversationCardComponent } from '../app/components/conversations/conversation-card.component';
import { LabelComponent } from '../app/components/labels/label.component';
import { MessageCardComponent } from '../app/components/conversations/message-card.component';
import { WeeklyVideoComponent } from '../app/pages/community/components/weekly-video.component';
import { SafeHtmlPipe } from '../app/pipes/safe-html';
import { AutogrowDirective } from '../app/directives/autogrow.directive';
import { TodaysLessonSlidesComponent } from '../app/components/slides/todays-lesson-slides.component';
import { EmptyModalComponent } from '../app/components/empty-modal/empty-modal.component';
import { StripHtmlTagsPipe } from '../app/pipes/strip-html-tags.pipe';
import { DownloadLinksComponent } from '../app/components/buttons/download-links.component';
import { TooltipDirective } from '../app/directives/tooltip.directive';
import { CigTrackerComponent } from 'src/app/components/cig-tracker/cig-tracker';
import { BottomSheetComponent } from 'src/app/components/bottom-sheet/bottom-sheet.component';
import { BadgeComponent } from 'src/app/components/badge/badge.component';
import { DottedStepsIndicatorComponent } from 'src/app/components/dotted-steps-indicator/dotted-steps-indicator.component';
import { WeightInfoDatePipe } from 'src/app/pipes/weight-info-date.pipe';
import { WeightUnitPipe } from 'src/app/pipes/weight-unit.pipe';
import { FirstNamePipe } from 'src/app/pipes/first-name.pipe';
import { CirclesBackgroundComponent } from 'src/app/components/wrappers/circles-background.component';
import { VideoPlayerBrightcoveWebComponent } from 'src/app/components/players/video-player-brightcove-web.component';
import { VideoPlayerBrightcoveComponent } from '../app/components/players/video-player-brightcove.component';
import { RecaptchaComponent } from '../app/components/recaptcha/recaptcha.component';
import { AudioPlayerBrightcoveComponent } from '../app/components/players/audio-player-brightcove.component';
import { PlayButtonComponent } from '../app/components/buttons/play-button.component';
import { RangeEventsDirective } from '../app/directives/ion-range.directive';

@NgModule({
  imports: [
    // Ionic - needed for components using Ionic components
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(translateConfig),
    SwiperModule
  ],
  providers: [
    BrowserService,
    FileService,
    File,
    ScreenOrientation,
    Insomnia
  ],
  declarations: [
    ModalComponent,
    EmptyModalComponent,
    IconTitleContainerComponent,
    WavesBackgroundComponent,
    ShadowWrapperComponent,
    RegistrationWrapperComponent,
    CloseButtonComponent,
    BackButtonComponent,
    NextButtonComponent,
    PrevButtonComponent,
    HeaderNavButtonsComponent,
    ActionButtonComponent,
    SharecareLoginComponent,
    InputFieldComponent,
    SwitchFieldComponent,
    RangeSelectComponent,
    ActivityGraphComponent,
    AwarenessGraphComponent,
    FooterComponent,
    DailyGraphComponent,
    WeeklyGraphComponent,
    CigarettesGraphComponent,
    CigaretteStatsComponent,
    VideoPlayerComponent,
    VideoPlayerJWPlayerComponent,
    VideoPlayerBrightcoveWebComponent,
    VideoPlayerBrightcoveComponent,
    AudioPlayerComponent,
    AudioPlayerJWPlayerComponent,
    AudioPlayerBrightcoveComponent,
    SteppedProgressBarComponent,
    SimpleProgressBarComponent,
    ControlButtonsComponent,
    RadioListItemComponent,
    ListItemComponent,
    ListSingleSelectComponent,
    RippleEffectDirective,
    CheckinExerciseComponent,
    HorizontalSliderComponent,
    HoursAgoSelectorComponent,
    AccordionComponent,
    ProgramWeeksComponent,
    JourneyWeekCardComponent,
    JourneyWeekCardWebComponent,
    ThemeJourneyWeekCardWebComponent,
    ModuleItemComponent,
    ModuleItemWebComponent,
    FeelingNowSelectorComponent,
    VerticalSliderComponent,
    IntensitySelectorComponent,
    ListMultipleSelectComponent,
    CheckboxListItemComponent,
    ProcessTimePipe,
    ProcessDateInLocalPipe,
    RelativeTimePipe,
    DaysQuitRelativeTimePipe,
    SafeHtmlPipe,
    StripHtmlTagsPipe,
    WeightInfoDatePipe,
    WeightUnitPipe,
    ExercisesGroupComponent,
    ExerciseItemComponent,
    PostsComponent,
    StressTestButtonComponent,
    ImFeelingLuckyButtonComponent,
    GoalEditComponent,
    TabbedViewComponent,
    GoalOverviewComponent,
    SlideCardComponent,
    AwarenessQuizSliderComponent,
    SlideCardComponent,
    MyStatsComponent,
    CommunityButtonComponent,
    AnxiousFeelingComponent,
    FeelingNowVerticalSelectorComponent,
    PickSensationComponent,
    PickTriggerComponent,
    PlayExerciseComponent,
    EntryCardComponent,
    ConversationCardComponent,
    LabelComponent,
    MessageCardComponent,
    TruncatedPostComponent,
    LikesAvatarsComponent,
    TruncatePipe,
    TodaysLessonSlidesComponent,
    TodaysLessonCardComponent,
    WeeklyVideoComponent,
    AutogrowDirective,
    DownloadLinksComponent,
    TooltipDirective,
    CigTrackerComponent,
    TooltipDirective,
    BottomSheetComponent,
    BadgeComponent,
    DottedStepsIndicatorComponent,
    FirstNamePipe,
    CirclesBackgroundComponent,
    RecaptchaComponent,
    PlayButtonComponent,
    RangeEventsDirective
  ],
  exports: [
    ModalComponent,
    EmptyModalComponent,
    IconTitleContainerComponent,
    WavesBackgroundComponent,
    ShadowWrapperComponent,
    RegistrationWrapperComponent,
    CloseButtonComponent,
    BackButtonComponent,
    NextButtonComponent,
    PrevButtonComponent,
    FooterComponent,
    HeaderNavButtonsComponent,
    ActionButtonComponent,
    InputFieldComponent,
    SwitchFieldComponent,
    RangeSelectComponent,
    ActivityGraphComponent,
    AwarenessGraphComponent,
    TabbedViewComponent,
    ProgramWeeksComponent,
    JourneyWeekCardComponent,
    JourneyWeekCardWebComponent,
    ThemeJourneyWeekCardWebComponent,
    ModuleItemComponent,
    ModuleItemWebComponent,
    DailyGraphComponent,
    WeeklyGraphComponent,
    CigarettesGraphComponent,
    CigaretteStatsComponent,
    VideoPlayerComponent,
    VideoPlayerJWPlayerComponent,
    VideoPlayerBrightcoveWebComponent,
    VideoPlayerBrightcoveComponent,
    AudioPlayerComponent,
    AudioPlayerJWPlayerComponent,
    AudioPlayerBrightcoveComponent,
    SteppedProgressBarComponent,
    SimpleProgressBarComponent,
    ControlButtonsComponent,
    RadioListItemComponent,
    ListItemComponent,
    ListSingleSelectComponent,
    RippleEffectDirective,
    AccordionComponent,
    PostsComponent,
    CheckinExerciseComponent,
    ListMultipleSelectComponent,
    CheckboxListItemComponent,
    HoursAgoSelectorComponent,
    HorizontalSliderComponent,
    FeelingNowSelectorComponent,
    VerticalSliderComponent,
    IntensitySelectorComponent,
    ProcessTimePipe,
    ProcessDateInLocalPipe,
    RelativeTimePipe,
    DaysQuitRelativeTimePipe,
    SafeHtmlPipe,
    StripHtmlTagsPipe,
    WeightInfoDatePipe,
    WeightUnitPipe,
    ExercisesGroupComponent,
    ExerciseItemComponent,
    StressTestButtonComponent,
    ImFeelingLuckyButtonComponent,
    AwarenessQuizSliderComponent,
    GoalEditComponent,
    GoalOverviewComponent,
    SlideCardComponent,
    MyStatsComponent,
    CommunityButtonComponent,
    AnxiousFeelingComponent,
    FeelingNowVerticalSelectorComponent,
    PickSensationComponent,
    PickTriggerComponent,
    PlayExerciseComponent,
    EntryCardComponent,
    ConversationCardComponent,
    LabelComponent,
    MessageCardComponent,
    TodaysLessonSlidesComponent,
    TodaysLessonCardComponent,
    PlayExerciseComponent,
    SharecareLoginComponent,
    WeeklyVideoComponent,
    AutogrowDirective,
    DownloadLinksComponent,
    TooltipDirective,
    TruncatePipe,
    CigTrackerComponent,
    TooltipDirective,
    BottomSheetComponent,
    BadgeComponent,
    DottedStepsIndicatorComponent,
    FirstNamePipe,
    CirclesBackgroundComponent,
    RecaptchaComponent,
    SwiperComponent
  ]
})

export class SharedModule {

}
