import { Action } from '@ngrx/store';

export const SET_ONBOARDING_COMPLETED = '[onboarding] Set Onboarding Completed';

export class SetOnboardingCompleted implements Action {
  readonly type = SET_ONBOARDING_COMPLETED;

  constructor(public payload: string) {
  }
}

export type OnboardingActions =
  | SetOnboardingCompleted;
