import { Action } from '@ngrx/store';

// download actions

export const SAVE_DOWNLOADED_FILE = '[media] Save Downloaded File';
export const REMOVE_ALL_FILES = '[media] Remove All Files';
export const SAVE_PLAYED_FILE = '[media] Save Played File';
export const SET_DEFAULT_MEDIAFILE = '[media] Set Default MediaFile';
export const ENABLE_EN_SUBTITLES = '[media] English Subtitles Enabled';
export const DISABLE_EN_SUBTITLES = '[media] English Subtitles Disabled';

export class SaveDownloadedFile implements Action {
  readonly type = SAVE_DOWNLOADED_FILE;

  constructor(public payload: string) {
  }
}

export class SavePlayedFile implements Action {
  readonly type = SAVE_PLAYED_FILE;

  constructor(public payload: string) {
  }
}

export class RemoveAllFiles implements Action {
  readonly type = REMOVE_ALL_FILES;
}

export class SetDefaultMediaFileForExercise implements Action {
  readonly type = SET_DEFAULT_MEDIAFILE;

  constructor(public exerciseId: number, public mediaFileFingerprint: string) {}
}

export class EnableEnglishSubtitles implements Action {
  readonly type = ENABLE_EN_SUBTITLES;
}

export class DisableEnglishSubtitles implements Action {
  readonly type = DISABLE_EN_SUBTITLES;
}
export type MediaActions =
  | RemoveAllFiles
  | SaveDownloadedFile
  | SetDefaultMediaFileForExercise
  | SavePlayedFile
  | EnableEnglishSubtitles
  | DisableEnglishSubtitles;
