import { Injectable } from '@angular/core';

import { LoadingController } from '@ionic/angular';

import { Observable , Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class LoadingService {

  static loadingOverlay: any = null; // TODO - migrate - find Loading alternative since it doesn't exist on @ionic/angular
  private loadingContent = '';

  private observableSubscription: Subscription = null;

  constructor(
    public loadingCtrl: LoadingController
  ) {

  }

  useLoadingObservable(observable: Observable<boolean>, content: any = '', page: any = false) {
    // make sure we unsubscribe from any previously set subscription and hide any loading screens
    this.hideLoadingOverlay();

    // save content if any specified
    if (content instanceof Observable) {
      content.subscribe((_content) => {
        this.setLoadingContent(_content);
      });
    }
    else {
      this.setLoadingContent(content);
    }

    this.loadingCtrl.create({
      message: this.loadingContent,
      backdropDismiss: false
    })
      .then((error) => {

        LoadingService.loadingOverlay = error;
        this.observableSubscription = observable.subscribe((isLoading) => {
          if (isLoading) {
            if (LoadingService.loadingOverlay) {
              LoadingService.loadingOverlay.present();
            }
          }
          else {
            this.hideLoadingOverlay();
          }
        });
      });
  }

  showLoadingOverlay(content: any = '', allowSoftClose = false) {
    this.hideLoadingOverlay();

    if (content instanceof Observable) {
      return content.pipe(take(1))
        .toPromise()
        .then((translatedContent) => this.displayLoadingOverlay(translatedContent, allowSoftClose));
    }
    else {
      return this.displayLoadingOverlay(content, allowSoftClose);
    }
  }

  hideLoadingOverlay() {
    if (this.observableSubscription) {
      this.observableSubscription.unsubscribe();
    }

    if (!LoadingService.loadingOverlay) {
      return false;
    }

    LoadingService.loadingOverlay.dismiss();
    LoadingService.loadingOverlay = null;
  }

  private setLoadingContent(content) {
    this.loadingContent = content;
  }

  private async displayLoadingOverlay(content, allowSoftClose = false) {
    LoadingService.loadingOverlay = await this.loadingCtrl.create({
      message: content,
      backdropDismiss: allowSoftClose
      // TODO: alternative - https://github.com/ionic-team/ionic/blob/5f3c7cd755d2a9c0b6cabf6c0900168421c1e082/angular/BREAKING.md#loading
      // dismissOnPageChange: allowSoftClose
    });

    await LoadingService.loadingOverlay.present();

    return LoadingService.loadingOverlay;
  }
}
