// program actions

import { Action } from '@ngrx/store';

// load user profile
export const LOAD_USER_PROFILE = '[user profile] Load';
export const LOAD_USER_PROFILE_FAIL = '[user profile] Load Fail';
export const LOAD_USER_PROFILE_SUCCESS = '[user profile] Load Success';

// update user profile
export const UPDATE_USER_PROFILE = '[user profile] Update';
export const UPDATE_USER_PROFILE_FAIL = '[user profile] Update fail';
export const UPDATE_USER_PROFILE_SUCCESS = '[user profile] Update Success';

export const SET_USER_PROFILE_UPDATED = '[user profile] Set User Profile Updated';

export class UpdateUserProfile implements Action {
  readonly type = UPDATE_USER_PROFILE;
  constructor(public payload: any) {}
}

export class UpdateUserProfileFail implements Action {
  readonly type = UPDATE_USER_PROFILE_FAIL;

  constructor(public payload: any) {
  }
}

export class UpdateUserProfileSuccess implements Action {
  readonly type = UPDATE_USER_PROFILE_SUCCESS;

  constructor(public payload: boolean) {}
}

export class LoadUserProfile implements Action {
  readonly type = LOAD_USER_PROFILE;
}

export class LoadUserProfileFail implements Action {
  readonly type = LOAD_USER_PROFILE_FAIL;

  constructor(public payload: any) {
  }
}

export class LoadUserProfileSuccess implements Action {
  readonly type = LOAD_USER_PROFILE_SUCCESS;
}

export class SetUserProfileUpdated implements Action {
  readonly type = SET_USER_PROFILE_UPDATED;
  constructor(public payload: boolean) {
  }
}

export type UserProfileActions =
  | UpdateUserProfile
  | UpdateUserProfileFail
  | UpdateUserProfileSuccess
  | LoadUserProfile
  | LoadUserProfileFail
  | LoadUserProfileSuccess
  | SetUserProfileUpdated;
