import { schema } from 'normalizr';

export interface WeightActivity {
  id?: number;
  value: number;
  unit: string;
  activity_at: string;
  source: string;
}

export const weightActivitySchema = new schema.Entity('weightActivities');
