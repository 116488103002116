import { ChangeDetectorRef, Component, Output, OnDestroy, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { SettingsComponent } from './settings.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription, Observable, Subject } from 'rxjs';
import { take , takeUntil, withLatestFrom } from 'rxjs/operators';
import { ConnectivityService } from '../../../services/connectivity.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { ClarityConfig } from '../../../config/clarity.config';
import { getProgramLanguages } from '../../../store/session/selectors/program.selectors';
import { getCurrentUserProgram } from '../../../store/normalized/selectors/user.selectors';
import { UpdateUserProgramLanguage } from '../../../store/session/actions/user-program.actions';
import { SessionState } from '../../../store/session/session.reducers';
import { isAuthenticating } from '../../../store/sensitive/selectors/auth.selectors';
import { State } from '../../../store/state.reducer';
import { hasEnglishSubtitles } from '../../../store/persistent/media/media.selectors';
import { EnableEnglishSubtitles, DisableEnglishSubtitles } from '../../../store/persistent/media/media.actions';
import { scaleUpDown } from 'src/app/utils/animations';

@Component({
  selector: 'cl-account-language',
  styleUrls: ['language.component.scss'],
  animations: [scaleUpDown],
  template: `
    <form [formGroup]="languageForm" autocomplete="off" (submit)="onSubmit()"
      [@state]="visibility" (@state.done)="animationDone($event)">

      <h3 class="top-header">{{'account_menu.language.app' | translate}}</h3>
      <ion-row>
        <cl-common-radio
          [options]="LANGUAGE_OPTIONS"
          formControlName="language_code"
          name="language_code">
        </cl-common-radio>
      </ion-row>

      <ng-container *ngIf="showSubtitleToggle">
        <h3>{{'account_menu.language.subtitles' | translate}}</h3>
        <ion-item class="default-subtitles">
          <ion-label class="ion-text-wrap">
            {{ 'account_menu.language.default_subtitles_toggle' | translate }}
          </ion-label>
          <ion-toggle color="toggle" mode="ios" formControlName="default_subtitles" name="default_subtitles"></ion-toggle>
        </ion-item>
      </ng-container>

      <ion-row class="action-button">
        <ion-col class="ion-text-center" auto>
          <cl-action-button
            label="{{'account_menu.language.save' | translate}}"
            [canClick]="canSave && languageForm.valid && !(authenticating$ | async)">
          </cl-action-button>
        </ion-col>
      </ion-row>
    </form>
  `
})

export class LanguageComponent implements SettingsComponent, OnDestroy {
  @Output() endAnimation = new EventEmitter();
  visibility = 'visible';

  public authenticating$: Observable<boolean> = this.store.select(isAuthenticating);

  userProgram: any;
  languageForm: FormGroup;
  formChangeSubscription: Subscription;
  canSave = false;
  showSubtitleToggle;

  public LANGUAGE_OPTIONS = this.config.getLanguageOptions();

  public readonly validations = {
    language_code: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.user.language_code_required'
      }
    },
    default_subtitles: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.user.language_code_required'
      }
    }
  };

  subtitlesEnabled;
  subtitlesSubscription: Subscription;
  private destroy$: Subject<void> = new Subject();

  constructor(
    private sessionStore: Store<SessionState>,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private connectivity: ConnectivityService,
    private translate: TranslateService,
    private store: Store<State>,
    public alertController: AlertController,
    private config: ClarityConfig
  ) {
    this.subtitlesSubscription = this.sessionStore.select(hasEnglishSubtitles)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((subtitlesEnabled) => {
        this.subtitlesEnabled = subtitlesEnabled;
      }
      );

    this.sessionStore.select(getCurrentUserProgram)
      .pipe(withLatestFrom(this.sessionStore.select(getProgramLanguages)), take(1))
      .subscribe(([userProgram, programLanguages]) => {
        // remove any language that's not available on the backend
        this.LANGUAGE_OPTIONS = this.LANGUAGE_OPTIONS.filter((option) => programLanguages.indexOf(option.value) > -1);

        this.userProgram = userProgram;
        const formGroup = this.generateFormGroup(userProgram);

        this.languageForm = this.formBuilder.group(formGroup);

        this.toggleSubtitleRow(this.userProgram.language_code);
        this.unsubSub();
      });
  }

  toggleSubtitleRow(lang: string) {
    this.showSubtitleToggle = lang === ClarityConfig.DEFAULT_LANGUAGE;

  }
  generateFormGroup(userData) {
    const formGroupData = {
      language_code: {
        defaultValue: 'en'
      },
      default_subtitles: {
        defaultValue: this.subtitlesEnabled
      }
    };

    const formGroup = {};

    Object.keys(formGroupData)
      .forEach((key) => {
        const data = formGroupData[key];
        const formValue = userData[key] || data.defaultValue;
        const formValidator = data.validators || this.validations[key].validators;
        formGroup[key] = new FormControl(formValue, formValidator);
      });

    return formGroup;
  }

  unsubSub() {
    if (this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
    }

    this.formChangeSubscription = this.languageForm.valueChanges.subscribe(() => {
      this.setCanSaveButton();

      this.toggleSubtitleRow(this.languageForm.value.language_code);

      this.cdr.detectChanges();
    });
  }

  private setCanSaveButton() {
    this.canSave = (this.languageForm.value.language_code !== this.userProgram.language_code) ||
        (this.languageForm.value.default_subtitles !== this.subtitlesEnabled)
      ? true
      : false;
  }

  ngOnDestroy() {
    if (this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmit() {
    if (this.connectivity.preventAccessWhenOffline()) {
      return false;
    }

    // we assign userData to toSubmit and override with the form data
    const toSubmit = {
      ...this.userProgram,
      ...this.languageForm.value
    };

    const {language_code: newLangCode} = this.languageForm.value;

    const langCodeTranslation = `auth.${newLangCode}`;

    if (this.userProgram.language_code !== newLangCode) {
      this.translate.get(
        ['auth.language', 'auth.are_you_sure_change_language', 'common.yes', 'common.cancel', langCodeTranslation]
      )
        .subscribe(async (translations) => {
          const alert = await this.alertController.create({
            header: translations['auth.language'],
            message: `${translations['auth.are_you_sure_change_language']}`,
            buttons: [
              {
                text: translations['common.cancel']
              },
              {
                text: translations['common.yes'],
                handler: () => {
                  this.saveSubtitlesSetting();
                  this.sessionStore.dispatch(new UpdateUserProgramLanguage(toSubmit));
                }
              }]
          });

          await alert.present();
        });
    } else {
      this.saveSubtitlesSetting();
      this.setCanSaveButton();
    }
  }

  saveSubtitlesSetting() {
    if (this.languageForm.value.default_subtitles) {
      this.sessionStore.dispatch(new EnableEnglishSubtitles());
    } else {
      this.sessionStore.dispatch(new DisableEnglishSubtitles());
    }
  }

  animationDone(event) {
    if (event.toState === 'hidden') {
      this.endAnimation.emit(true);
    }
  }

  closeComponent() {
    this.visibility = 'hidden';
  }

}
