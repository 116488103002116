import { Injectable } from '@angular/core';

import { ClarityConfig } from '../config/clarity.config';

import { HttpProvider } from '../providers/http/http.provider';

import { AuthToken } from '../store/session/models/auth-token.model';
import { Credentials, CredentialsReset, CredentialsToken } from '../store/session/models/credentials.model';
import { SharecareAuthParams } from '../store/session/models/sharecare-auth-params.model';

@Injectable({providedIn: 'root'})
export class AuthProvider {

  private loginEndpoint: string;
  private loginWithSharecareEndpoint: string;
  private resetPasswordEndpoint = '/users/reset_password';
  private logoutEndpoint = '/token';

  constructor(
    private http: HttpProvider,
    public config: ClarityConfig
  ) {
    console.log('auth service started');

    this.initEndpointsWithProgram();
  }

  private initEndpointsWithProgram() {
    const currentProgramCode = this.config.currentProgramCode.toUpperCase();

    this.loginEndpoint = `/programs/${currentProgramCode}/token`;
    this.loginWithSharecareEndpoint = `/programs/${currentProgramCode}/auth/sharecare`;
  }

  doLogin(credentials: Credentials | CredentialsToken) {
    this.initEndpointsWithProgram();

    return this.http.post<AuthToken>(
      this.loginEndpoint, {
        ...credentials,
        ...this.getCommonAuthParams()
      });
  }

  doLoginWithAuthorizationCode(params: SharecareAuthParams) {
    this.initEndpointsWithProgram();

    return this.http.post<AuthToken>(
      this.loginWithSharecareEndpoint, {
        redirect_uri: this.config.redirectUri,
        server_auth_code: params.server_auth_code,
        offeringCode: params.offeringCode ? params.offeringCode : null,
        sponsor: params.sponsor ? decodeURIComponent(params.sponsor) : null,
        ...this.getCommonAuthParams()
      });
  }

  doLowingWithPreauthToken(params: SharecareAuthParams) {
    this.initEndpointsWithProgram();

    return this.http.post<AuthToken>(
      this.loginEndpoint, {
        preauth_token: params.preauth_token,
        ...this.getCommonAuthParams()
      });
  }

  resetPassword(credentialsReset: CredentialsReset) {
    this.initEndpointsWithProgram();

    return this.http.post(this.resetPasswordEndpoint,
      {
        ...credentialsReset,
        program_code: this.config.currentProgramCode.toUpperCase()
      }
    );
  }

  doLogout() {
    return this.http.delete(this.logoutEndpoint);
  }

  private getCommonAuthParams() {
    return {
      app_build: this.config.getBuildNumber(),
      app_version: this.config.getAppVersion(),
      package_name: undefined,
      ...this.config.env.device
    };
  }
}
