import { createSelector } from '@ngrx/store';
import { createSchemaSelectors } from 'ngrx-normalizr';
import * as myCoachReducer from '../reducers/my-coach.reducer';
import { getMyCoachState } from '../../state.reducer';
import {
  Conversation,
  ConversationFeed,
  conversationFeedSchema,
  conversationSchema,
  MessageFeed,
  messageFeedSchema,
  myCoachNotificationsFeedSchema,
  myCoachNotificationsSchema
} from '../../normalized/schemas/my-coach.schema';
import { getResumedAt } from './sync.selectors';
import { NotificationFeed } from '../../normalized/schemas/inbox.schema';
import { getCurrentUserProgram } from '../../normalized/selectors/user.selectors';

export const getConversationsLoading = createSelector(
  getMyCoachState,
  myCoachReducer.conversationsLoading,
  getResumedAt,
  (state, loading, resumedAt) => {
    const currentTimestamp = new Date().getTime();
    if (resumedAt && (currentTimestamp - resumedAt) <= 500) {
      return false;
    }

    return loading;
  }
);

export const getConversationLoaded = createSelector(
  getMyCoachState,
  myCoachReducer.conversationLoaded
);

export const getMessageLoading = createSelector(
  getMyCoachState,
  myCoachReducer.messagesLoading
);

export const getConversationSuppressionId = createSelector(
  getMyCoachState,
  myCoachReducer.conversationSuppression
);

export const getConversationCreation = createSelector(
  getMyCoachState,
  myCoachReducer.conversationCreation
);

export const getConversationReaded = createSelector(
  getMyCoachState,
  myCoachReducer.conversationReaded
);

export const getNewConversationId = createSelector(
  getMyCoachState,
  myCoachReducer.newConversationId
);

export const getMessageSended = createSelector(
  getMyCoachState,
  myCoachReducer.messageSended
);

export const getConversationFeedId = createSelector(
  getMyCoachState,
  myCoachReducer.conversationFeedId
);

export const getMessageFeedId = createSelector(
  getMyCoachState,
  myCoachReducer.messageFeedId
);

export const getUnreadCoachMessagesCount = createSelector(
  getMyCoachState,
  myCoachReducer.unreadMessagesCount
);

const conversationFeedSchemaSelector = createSchemaSelectors<ConversationFeed>(conversationFeedSchema);
const conversationsSchemaSelector = createSchemaSelectors<Conversation>(conversationSchema);

const messageFeedSchemaSelector = createSchemaSelectors<MessageFeed>(messageFeedSchema);

const notificationsSchemaSelector = createSchemaSelectors<Comment>(myCoachNotificationsSchema),
  notificationFeedSchemaSelector = createSchemaSelectors<NotificationFeed>(myCoachNotificationsFeedSchema);

export const getMyCoachNotificationFeedId = createSelector(
  getMyCoachState,
  myCoachReducer.myCoachNotificationFeedId
);

const getNotificationFeeds = notificationFeedSchemaSelector.getEntities;

export const getNotificationFeedById = createSelector(
  notificationFeedSchemaSelector.getNormalizedEntities,
  getMyCoachNotificationFeedId,
  notificationFeedSchemaSelector.entityProjector
);

export const getConversationFeedById = createSelector(
  conversationFeedSchemaSelector.getNormalizedEntities,
  getConversationFeedId,
  conversationFeedSchemaSelector.entityProjector
);

export const getMessageFeedById = createSelector(
  messageFeedSchemaSelector.getNormalizedEntities,
  getMessageFeedId,
  messageFeedSchemaSelector.entityProjector
);

export const getOpenConversationId = createSelector(
  getMyCoachState,
  myCoachReducer.openConversationId
);

export const getMessagesTotalCountFromFeed = createSelector(
  getMessageFeedById,
  (messageFeed: MessageFeed) => messageFeed ? messageFeed.total_count : null
);

export const getMessagesFromFeed = createSelector(
  getMessageFeedById,
  (messageFeed: MessageFeed) => {
    if (!messageFeed || !messageFeed.list) {
      return [];
    }

    return messageFeed.list.sort((a, b) => {
      if (a.created_at === b.created_at) return (a.id < b.id ? -1 : 1);

      return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
    });
  }
);

export const getLastCoachMessageFromFeed = createSelector(
  getMessagesFromFeed,
  messages => messages.filter(message => message.type === 'coach').pop()
);

export const getConversationsFromFeed = createSelector(
  getConversationFeedById,
  (conversationFeed: ConversationFeed) => {
    if (!conversationFeed || !conversationFeed.list) {
      return [];
    }

    return conversationFeed.list.sort((a, b) =>
      new Date(b.last_message_at).getTime() - new Date(a.last_message_at).getTime()
    );
  }
);

export const getOpenedConversation = createSelector(
  getConversationFeedById,
  getOpenConversationId,
  (conversationFeed: ConversationFeed, conversationId: string) =>
    conversationFeed?.list.find(conversation => conversation.id.toString() === conversationId?.toString())
);

export const getConversationsFilter = createSelector(
  getMyCoachState,
  myCoachReducer.conversationsFilter
);

export const allConversationsLoaded = createSelector(
  getConversationFeedById,
  (feed: ConversationFeed) => feed && feed.list ? feed.list.length === feed.total_count : false
);

export const getConversationsPage = createSelector(
  getMyCoachState,
  myCoachReducer.conversationsPage
);

export const getInappCoaching = createSelector(
  getCurrentUserProgram,
  userProgram => userProgram.inapp_coaching
);

export const getMyCoachNotificationsFromFeed = createSelector(
  getNotificationFeedById,
  (notificationFeed: NotificationFeed) => {
    if (!notificationFeed || !notificationFeed.list) {
      return [];
    }

    return notificationFeed.list.sort((a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
  }
);

export const getMyCoachCurrentNotificationFeed = createSelector(
  getNotificationFeeds,
  (notificationFeeds) => {

    if (!notificationFeeds || notificationFeeds.length === 0) {
      return ({} as NotificationFeed);
    }

    return notificationFeeds[0];
  }
);

export const getAllMyCoachNotificationsLoaded = createSelector(
  myCoachReducer.myCoachAllNotificationsAreLoaded,
  getMyCoachCurrentNotificationFeed,
  (notificationsLoaded, notificationFeed): any => {
    if (!notificationFeed || !notificationFeed.list) return false;

    return !!notificationsLoaded || notificationFeed.total_count <= notificationFeed.list.length;
  }
);

export const isAllMyCoachNotificationsLoaded = createSelector(
  myCoachReducer.myCoachAllNotificationsAreLoaded,
  getMyCoachCurrentNotificationFeed,
  (notificationsLoaded, notificationFeed): any => notificationFeed && notificationFeed.list ? notificationFeed.list.length >= notificationFeed.total_count : 0
);

export const getMyCoachNotificationsLoading = createSelector(
  getMyCoachState,
  myCoachReducer.myCoachNotificationsAreLoading
);

export const getMyCoachNotificationsLoaded = createSelector(
  getMyCoachState,
  myCoachReducer.myCoachNotificationsAreLoaded
);

export const getAllMyCoachNotificationsLoading = createSelector(
  getMyCoachState,
  myCoachReducer.myCoachAllNotificationsAreLoading
);
