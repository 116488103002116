import { Injectable, OnDestroy } from '@angular/core';
import { IAPProduct, InAppPurchase2, IAPProductOptions, IAPQueryCallback } from '@ionic-native/in-app-purchase-2';

import { LoggerService } from '../logger.service';
import { ClarityConfig } from '../../config/clarity.config';
import { getLiveSubscription, LiveSubscription } from '../../store/normalized/selectors/subscription.selectors';
import { catchError, distinctUntilChanged, filter, take, tap, first, switchMap } from 'rxjs/operators';

import * as syncActions from '../../store/session/actions/sync.actions';
import * as subscriptionActions from '../../store/session/actions/subscription.actions';
import { SubscriptionsProvider } from '../../providers/subscriptions/subscriptions.provider';
import { Store } from '@ngrx/store';
import { LoadingService } from '../loading.service';
import { ToastService } from '../toast.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, combineLatest, timer } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { State } from '../../store/state.reducer';
import { ScriptLoaderService } from '../script-loader.service';
import { getCouponCode } from '../../store/session/selectors/subscription.selectors';
import { AlertsService } from '../alerts.service';

@Injectable({providedIn: 'root'})
export class SubscriptionsService implements OnDestroy {

  static ERRORS = {
    SUBSCRIPTION_INACTIVE: -1,
    SUBSCRIPTION_INVALID: -2,
    PURCHASE_NOT_FOUND: -3,
    MAX_SYNC_RETRIES_REACHED: -4,
    CANNOT_VALIDATE_SUBSCRIPTION: -5,
    PURCHASE_FAILED: -6,
    SAVE_CARD_FAILED: -7
  };

  public stripe: any;

  // Array example after the products are intialized
  sampleStoreProductOptions = [
    {
      id: 'cl.ern.monthly',
      alias: 'ern.monthly',
      type: 'paid subscription',
      state: 'registered' // 'valid', 'initiated'
    },
    {
      id: 'cl.ern.annual.129',
      alias: 'ern.annual',
      type: 'paid subscription',
      state: 'registered'
    }
  ];

  // Array example after products are registered (on Android)
  sampleStoreProducts = {
    byId: {
      'cl.ern.monthly': {
        id: 'cl.ern.monthly',
        alias: 'ern.monthly',
        type: 'paid subscription',
        state: 'valid',
        title: 'Monthly Access - $24.99/mo (Clarity)',
        description: 'Access to all app features - pay monthly',
        priceMicros: 119990000,
        price: 'RON 119.99',
        currency: 'RON',
        countryCode: null,
        loaded: true,
        canPurchase: true,
        owned: false,
        downloading: false,
        downloaded: false,
        additionalData: null,
        transaction: null,
        valid: true
      },
      'cl.ern.6months': {
        id: 'cl.ern.6months',
        alias: 'ern.6months',
        type: 'paid subscription',
        state: 'valid',
        title: 'Eat Right Now (Clarity)',
        description: 'Access to all program content and features - pay every 6 months',
        priceMicros: 479990000,
        price: 'RON 479.99',
        currency: 'RON',
        countryCode: null,
        loaded: true,
        canPurchase: true,
        owned: false,
        downloading: false,
        downloaded: false,
        additionalData: null,
        transaction: null,
        valid: true
      },
      'cl.ern.annual.129': {
        id: 'cl.ern.annual.129',
        alias: 'ern.annual',
        type: 'paid subscription',
        state: 'valid',
        title: 'Annual Access - $129.99/year (Clarity)',
        description: 'Access to all app features - pay once a year',
        priceMicros: 649990000,
        price: 'RON 649.99',
        currency: 'RON',
        countryCode: null,
        loaded: true,
        canPurchase: true,
        owned: false,
        downloading: false,
        downloaded: false,
        additionalData: null,
        transaction: null,
        valid: true
      }
    }
  };

  SYNC_RETRY_DELAY = 3; // seconds
  MAX_SYNC_RETRIES = 10; // 10 times * 4 sec = approx. 1 min with delays
  OBSERVABLE_TIME_LIMIT = 30; // 30 seconds to get purchase details form the app store

  VALID_PRODUCT_STATES = [
    'registered',
    'valid',
    'initiated',
    'approved'
  ];

  storeProductOptions: IAPProductOptions[];

  purchasesQueue: string[] = [];

  storeInitialized = false;

  whenUpdatedObservable: Observable<IAPProduct>;
  whenApprovedObservable: Observable<IAPProduct>;
  whenReadyObservable: Observable<any>;

  whenUpdatedHandler: IAPQueryCallback;
  whenApprovedHandler: IAPQueryCallback;
  whenReadyHandler: IAPQueryCallback;

  queueIsProcessing = false;

  observableSubscriptions: Subscription[] = [];

  public window: any = window;

  private inAppPurchase = InAppPurchase2;

  private selectedStripePlan = null;

  constructor(
    public config: ClarityConfig,
    private subscriptionsProvider: SubscriptionsProvider,
    private store: Store<State>,
    private loading: LoadingService,
    private toasts: ToastService,
    private translate: TranslateService,
    private logger: LoggerService,
    private alerts: AlertController,
    private alertsService: AlertsService,
    private scriptLoader: ScriptLoaderService
  ) {

  }

  public initialize(forceReinit = false) {
    if (!forceReinit && this.storeInitialized) {
      return Promise.resolve();
    }

    return this.translate.get('loading.initializing')
      .toPromise()
      .then((translation) => {
        this.loading.showLoadingOverlay(translation);

        // TODO: Remove for production
        // this.window.store.autoFinishTransactions = true;

        return Promise.resolve()
          .then(this.initStore.bind(this))
          .then(this.loadStoreProducts.bind(this))
          .then(() => {
            this.loading.hideLoadingOverlay();
            this.storeInitialized = true;
          });
      });
  }

  public orderProduct(productId: string) {
    this.logger.debug('ordering product', productId);

    this.initialize()
      .then(() => this.showSoftLoading())
      .then((loadingOverlay) => {
        // a much longer delay is needed here because some users don't have they iTunes/GooglePlay accounts setup
        // and if they navigate away, the purchase will be lost
        const observableSubscription = combineLatest([
          timer(0, this.OBSERVABLE_TIME_LIMIT * 20 * 1000)
            .pipe(
              tap((cnt) => {
                // the loading overlay can be canceled just by tapping on it now,
                // so we need to unsubscribe when that happens
                if (cnt === 0) {
                  loadingOverlay.onDidDismiss(() => {
                    observableSubscription.unsubscribe();
                  });

                  return false;
                }

                // too much time has passed -- normally this stage should never be reached
                this.whenCancelled();
              })
            ),
          this.whenApprovedObservable
            .pipe(
              tap(console.log.bind(this, 'tapped')),
              filter((product) => product.id === productId && (product.state === 'approved' || product.state === 'owned'))
            )
        ])
          .subscribe(([timerResponse, product]) => {
            this.pushToPurchasesQueue(product);
            this.orderFromQueue.call(this);
          });

        this.observableSubscriptions.push(observableSubscription);

        this.inAppPurchase.order(productId);
      });
  }

  getTranslation(key: string) {
    return this.translate.get(key)
      .toPromise();
  }

  public restorePurchases() {
    if (!this.config.isDevice) {
      this.logger.debug('Not running on device, cannot restorePurchases');

      return false;
    }

    this.logger.debug('restoring purchases');

    this.initialize()
      .then(this.showLoading.bind(this))
      .then(() => {
        const observableSubscription = combineLatest(
          [
            timer(0, this.OBSERVABLE_TIME_LIMIT * 1000)
              .pipe(
                tap((cnt) => {
                  if (cnt === 0) {
                    return false;
                  }

                  this.noValidSubscriptionsFound();

                  return observableSubscription.unsubscribe();
                })
              ),
            this.whenApprovedObservable
              .pipe(
                tap(console.log.bind(this, 'tapped')),
                distinctUntilChanged((prev, next) => prev.id === next.id)
              )
          ]
        )
          .subscribe(([time, product]) => {
            console.log('observed', JSON.stringify(product), time);
            console.log('transaction', product.transaction);

            this.pushToPurchasesQueue(product);
            this.restoreFromQueue.call(this);
          });

        this.observableSubscriptions.push(observableSubscription);

        this.window.store.refresh();
      });
  }

  public pushToPurchasesQueue(product: IAPProduct) {
    if (this.purchasesQueue.indexOf(product.id) === -1) {
      this.logger.debug('pushed to purchases queue', product);
      this.purchasesQueue.push(product.id);
    }
  }

  public restoreFromQueue() {
    this.logger.debug('restoring from queue', this.purchasesQueue);

    if (this.purchasesQueue.length <= 0) {
      this.logger.debug('queue is empty');

      return this.loading.hideLoadingOverlay();
    }

    if (this.queueIsProcessing) {
      return false;
    }

    const productId = this.purchasesQueue[0];
    const product: IAPProduct = this.window.store.get(productId);

    this.restoreProduct(product);
  }

  public orderFromQueue() {
    this.logger.debug('ordering from queue', this.purchasesQueue);

    if (this.purchasesQueue.length <= 0) {
      this.logger.debug('queue is empty');

      return this.loading.hideLoadingOverlay();
    }

    if (this.queueIsProcessing) {
      return false;
    }

    const productId = this.purchasesQueue[0];
    const product: IAPProduct = this.window.store.get(productId);

    this.orderQueuedProduct(product);
  }

  public orderQueuedProduct(product: IAPProduct) {
    this.queueIsProcessing = true;

    return this.translate.get('loading.validating')
      .toPromise()
      .then((translation) => this.loading.showLoadingOverlay(translation))
      .then(() => {
        this.logger.debug('initializing subscription');

        return this.subscriptionsProvider.initializeSubscription()
          .toPromise();
      })
      .then(this.loadAppStoreReceipts.bind(this))
      .then(() => {
        this.logger.debug('saving purchase');
        const purchaseData = this.getPurchaseData(product);

        return this.subscriptionsProvider.savePurchase(purchaseData)
          .toPromise();
      })
      .then(() => {
        this.logger.debug('starting interval');

        return this.checkSubscription();
      })
      .then((liveSubscription) => {
        this.logger.debug('got subscription', liveSubscription);

        if (liveSubscription.isActive) {
          return Promise.resolve(liveSubscription);
        }
        else {
          return Promise.reject(SubscriptionsService.ERRORS.SUBSCRIPTION_INACTIVE);
        }
      })
      .then((subscription) => {
        this.logger.debug('Subscription validated', subscription);

        product.finish();

        this.store.dispatch(new subscriptionActions.SubscriptionPurchased({subscription, product}));

        return this.getTranslation('subscriptions.subscription_purchased_successfully')
          .then((translation) => this.toasts.confirm(translation));
      })
      .catch((error) => {
        console.error('Error while ordering subscription', error);

        let message = 'errors.unknown';

        if (error === SubscriptionsService.ERRORS.SUBSCRIPTION_INACTIVE) {
          message = 'errors.subscriptions.subscription_is_not_valid';
        }
        else if (error === SubscriptionsService.ERRORS.PURCHASE_NOT_FOUND) {
          message = 'errors.subscriptions.no_active_subscriptions_found';
        }
        else if (error === SubscriptionsService.ERRORS.MAX_SYNC_RETRIES_REACHED) {
          message = 'errors.subscriptions.no_active_subscriptions_found';
        }

        return this.getTranslation(message)
          .then((translation) => this.toasts.error(translation));
      })
      .then(() => {
        this.logger.debug('Completed processing purchase!', product);

        this.removeFromPurchasesQueue(product.id);
        this.queueIsProcessing = false;
        this.restoreFromQueue.call(this);
      });
  }

  public restoreProduct(product: IAPProduct) {
    this.queueIsProcessing = true;

    return this.translate.get('loading.validating')
      .toPromise()
      .then((translation) => this.loading.showLoadingOverlay(translation))
      .then(this.loadAppStoreReceipts.bind(this))
      .then(() => {
        this.logger.debug('restoring purchase', product);
        const purchaseData = this.getPurchaseData(product);

        if (!purchaseData) {
          return Promise.reject(SubscriptionsService.ERRORS.PURCHASE_NOT_FOUND);
        }

        return this.subscriptionsProvider.restoreSubscription(purchaseData)
          .toPromise();
      })
      .then(() => {
        this.logger.debug('starting interval');

        return this.checkSubscription();
      })
      .then((liveSubscription) => {
        this.logger.debug('got subscription', liveSubscription);

        if (liveSubscription.isActive) {
          return Promise.resolve(liveSubscription);
        }
        else {
          return Promise.reject(SubscriptionsService.ERRORS.CANNOT_VALIDATE_SUBSCRIPTION);
        }
      })
      .then((subscription) => {
        this.logger.debug('Subscription validated', subscription);

        product.finish();

        this.store.dispatch(new subscriptionActions.SubscriptionRestored(subscription));

        this.getTranslation('subscriptions.subscription_restored_successfully')
          .then((translation) => this.toasts.confirm(translation));
      })
      .catch((error) => {
        console.error('Error while restoring subscription', error);

        let message = 'errors.subscriptions.unknown_error';

        if (error === SubscriptionsService.ERRORS.SUBSCRIPTION_INACTIVE) {
          message = 'errors.subscriptions.subscription_is_not_valid';
        }
        else if (error === SubscriptionsService.ERRORS.PURCHASE_NOT_FOUND) {
          message = 'errors.subscriptions.no_active_subscriptions_found';
        }
        else if (error === SubscriptionsService.ERRORS.MAX_SYNC_RETRIES_REACHED) {
          message = 'errors.subscriptions.no_active_subscriptions_found';
        }

        return this.getTranslation(message)
          .then((translation) => this.toasts.error(translation));
      })
      .then(() => {
        this.logger.debug('Completed processing purchase!', product);

        this.removeFromPurchasesQueue(product.id);
        this.queueIsProcessing = false;
        this.restoreFromQueue.call(this);
      });
  }

  public openAppStore() {
    this.inAppPurchase.manageSubscriptions();
  }

  selectStripePlan(plan) {
    this.selectedStripePlan = plan;
  }

  getSelectredStripePlan() {
    return this.selectedStripePlan;
  }

  checkCode(code: string) {
    return this.subscriptionsProvider.getCodeInfo(code);
  }

  purchaseStripeSubscription(cardElement, additionalData) {
    this.loading.showLoadingOverlay();

    return this.createStripeToken(cardElement, additionalData)
      .then((token) => this.saveStripeCard(token))
      .then((stripeCard) => this.orderStripeSubscription(stripeCard))
      .then((subscription) => {
        this.store.dispatch(new subscriptionActions.SubscriptionPurchased({subscription}));
      })
      .finally(() => {
        this.loading.hideLoadingOverlay();
      });
  }

  loadStoreProducts() {
    return this.subscriptionsProvider.loadStoreProducts()
      .pipe(
        catchError((error) => {
          this.logger.error('Error occurred while loading store products', error, 'loadStoreProducts');

          return new Observable((observer) => {
            this.translate.get([
              'common.cancel',
              'common.retry',
              'errors.common.cannot_contact_store',
              'errors.common.network_error_title',
              'errors.common.network_error_occurred'
            ])
              .pipe(take(1))
              .subscribe(async (translations) => {
                this.loading.hideLoadingOverlay();

                const alert = await this.alerts.create({
                  header: translations['errors.common.network_error_title'],
                  message: translations['errors.common.network_error_occurred'],
                  buttons: [
                    {
                      text: translations['common.cancel'],
                      role: 'cancel',
                      handler: () => {
                        console.log('Cancel clicked');
                        this.toasts.error(translations['errors.common.cannot_contact_store']);
                        this.whenCancelled();
                        observer.next([]);
                      }
                    },
                    {
                      text: translations['common.retry'],
                      handler: () => {
                        console.log('Retrying');
                        observer.next(this.loadStoreProducts());
                      }
                    }
                  ]
                });

                await alert.present();
              });
          });
        })
      )
      .toPromise()
      .then((products) => {
        this.buildStoreProductsOptions(products);

        this.initInAppProducts();

        this.refreshStore();
      });
  }

  public loadStripeScript() {
    return this.scriptLoader.load({
      src: 'https://js.stripe.com/v3',
      async: false
    })
      .pipe(first())
      .toPromise()
      .then(() => {
        this.stripe = window['Stripe'](this.config.stripeApiKey);
      });
  }

  initInAppProducts() {
    if (!this.config.isDevice) {
      this.logger.debug('Not running on device, cannot initInAppProducts');

      return false;
    }

    // register new products
    this.storeProductOptions.forEach((product) => {
      // registering the same product multiple times will make it unavailable
      if (!this.isProductRegistered(product.id)) {
        this.logger.debug('registering', product);
        this.inAppPurchase.register(product);
      }
    });
  }

  refreshStore() {
    this.inAppPurchase.refresh();
  }

  isProductRegistered(productId) {
    return this.inAppPurchase.products.find((product) => product.id === productId);
  }

  buildStoreProductsOptions(products) {
    if (!products || products.length < 1) {
      this.logger.debug('No store products found, cannot initialize InAppPurchases!');

      return false;
    }

    const storeProducts = [];

    products.forEach((product) => {
      storeProducts.push({
        id: product.name,
        alias: product.name_alias,
        type: this.inAppPurchase.PAID_SUBSCRIPTION
      });
    });

    this.storeProductOptions = storeProducts;
  }

  getAvailableStoreProducts() {
    const products: IAPProduct[] = [];

    if (!this.storeInitialized) {
      return products;
    }

    if (!this.inAppPurchase.products) {
      return products;
    }

    this.inAppPurchase.products.forEach((product) => {
      if (this.VALID_PRODUCT_STATES.indexOf(product.state) > -1 && product.loaded && product.priceMicros) {
        products.push(product);
      }
    });

    products.sort((left, right) => Number(left.priceMicros) - Number(right.priceMicros));

    return products;
  }

  whenError(error) {
    this.loading.hideLoadingOverlay();
    this.logger.debug('#whenError', error);

    // don't show any errors unless we are processing something, the plugin can be weird sometimes...
    if (!this.queueIsProcessing) {
      this.logger.debug('#whenError - queue is not processing - toast skipped');

      return Promise.resolve(false);
    }

    return this.getTranslation('errors.subscriptions.unknown_error')
      .then((translation) => this.toasts.error(translation));
  }

  whenCancelled() {
    this.loading.hideLoadingOverlay();
    this.logger.debug('#whenCancelled - clearing queue');

    this.observableSubscriptions.forEach((observableSubscription) => {
      observableSubscription.unsubscribe();
    });

    this.queueIsProcessing = false;
    this.purchasesQueue = [];
    this.observableSubscriptions = [];
  }

  // not used
  whenProductApproved(product) {
    this.logger.debug('#whenProductApproved', product);

    if (product.type !== 'paid subscription') {
      this.logger.debug('Ignoring approved product - not subscription:', product);

      return false;
    }
  }

  checkSubscription(): Promise<LiveSubscription> {
    const delay = (seconds) => new Promise(resolve => setTimeout(resolve, seconds * 1000));

    const getSubscription = () => this.store.select(getLiveSubscription)
      .pipe(take(1))
      .toPromise();

    const checkSubscriptionInterval = (limit = this.MAX_SYNC_RETRIES, count = 0) => {
      this.logger.debug('getting subscription');
      this.store.dispatch(new syncActions.SyncSubscription());

      // delay longer after the first retry
      const SYNC_DELAY = count === 0 ? 1 : this.SYNC_RETRY_DELAY;

      return delay(SYNC_DELAY)
        .then(() => {
          this.logger.debug('checking subscription');

          return getSubscription()
            .then((liveSubscription) => {
              this.logger.debug('checked subscription', liveSubscription);

              if (liveSubscription.isActive) {
                return Promise.resolve(liveSubscription);
              }

              if (count > limit) {
                return Promise.reject(SubscriptionsService.ERRORS.MAX_SYNC_RETRIES_REACHED);
              }

              return checkSubscriptionInterval(limit, count + 1);
            });
        });
    };

    return checkSubscriptionInterval();
  }

  getPurchaseData(product: IAPProduct) {
    if (!product.transaction) {
      console.error('No transaction data found', product);

      return null;
    }

    if (product.transaction.type === 'ios-appstore') {
      // for iOS we need to call the storekit directly because Ionic Native doesn't implement everything
      // flow is receipt is added as a new product, but we ignore it in #whenApproved, so we'll manually extract here
      return {
        receipt: this.window.storekit.appStoreReceipt,
        transaction_id: product.transaction.id
      };
    }
    else if (product.transaction.type === 'android-playstore') {
      return {
        receipt: product.transaction.receipt,
        transaction_id: product.transaction.id
      };
    }
  }

  whenProductVerified(product) {
    this.logger.debug('#whenProductVerified', product);

    product.finish();

    this.loading.hideLoadingOverlay();
    this.translate.get('subscriptions.subscription_purchased_successfully')
      .toPromise()
      .then((translation) => this.toasts.confirm(translation));
  }

  whenProductUnverified(data) {
    this.logger.debug('#whenProductUnverified', data);
  }

  ngOnDestroy() {
    this.logger.debug('Unloading inAppPurchase hooks');
    // unsubscribe all events
    this.inAppPurchase.off(this.whenReadyHandler);
    this.inAppPurchase.off(this.whenUpdatedHandler);
    this.inAppPurchase.off(this.whenApprovedHandler);
  }

  private loadAppStoreReceipts() {
    return new Promise((resolve) => {
      if (this.window.storekit) {
        this.window.storekit.loadReceipts(resolve);
      }
      else {
        console.log('No storekit available!');

        resolve(null);
      }
    });
  }

  private removeFromPurchasesQueue(productId) {
    const index = this.purchasesQueue.indexOf(productId);
    if (index !== -1) {
      this.purchasesQueue.splice(index, 1);
    }
  }

  private noValidSubscriptionsFound() {
    return this.getTranslation('errors.subscriptions.no_active_subscriptions_found')
      .then((translation) => {
        this.loading.hideLoadingOverlay();
        this.toasts.error(translation);
      });
  }

  private showLoading(message = 'loading.loading') {
    return Promise.resolve()
      .then(() => this.getTranslation(message))
      .then((translation) => this.loading.showLoadingOverlay(translation));
  }

  // TODO: migrate - was "Promise<Loading>" but Loading is not in ionic 4
  private showSoftLoading(message = 'loading.loading'): Promise<any> {
    return Promise.resolve()
      .then(() => this.getTranslation(message))
      .then((translation) => this.loading.showLoadingOverlay(translation, true));
  }

  private initStore() {
    if (!this.config.isDevice) {
      this.logger.debug('Not running on device, cannot initStore');

      return Promise.resolve();
    }

    // enable debugging
    if (this.config.onDevelopment()) {
      this.inAppPurchase.verbosity = this.inAppPurchase.DEBUG;
    }

    this.whenUpdatedObservable = new Observable((observer) => {
      this.whenUpdatedHandler = (product) => {
        observer.next.call(observer, product);
      };

      this.inAppPurchase.when('subscription')
        .updated(this.whenUpdatedHandler);
    });

    this.whenApprovedObservable = new Observable((observer) => {
      this.whenApprovedHandler = (product) => {
        observer.next.call(observer, product);
      };

      this.inAppPurchase.when('subscription')
        .approved(this.whenApprovedHandler);
    });

    this.whenReadyObservable = new Observable((observer) => {
      this.whenReadyHandler = () => {
        console.log('ready called');
        observer.next.call(observer);
      };

      this.inAppPurchase.ready(this.whenReadyHandler);
    });

    this.inAppPurchase.when('subscription')
      .cancelled(() => {
        this.whenCancelled();
      });

    this.inAppPurchase.error((error) => {
      this.whenError(error);
    });

    // register events
    // this.inAppPurchase.when('product')
    //   .approved(this.whenProductApproved.bind(this));

    return Promise.resolve();
  }

  private createStripeToken(cardElement, additionalData) {
    return this.stripe.createToken(cardElement, additionalData)
      .then((result) => result.token.id)
      .catch((error) => {
        console.log('Cannot save stripe token', error);

        throw error;
      });
  }

  private saveStripeCard(token) {
    return this.subscriptionsProvider.saveStripeCard(token)
      .pipe(take(1))
      .toPromise()
      .catch((result) => {
        console.log('Cannot save stripe card', result);

        switch (result.status) {
          case 422:
            if (result.error && result.error.errors) {
              this.toasts.error(result.error.errors.message);
            }
        }

        throw result.error;
      });
  }

  private orderStripeSubscription(card) {
    return this.store.select(getCouponCode)
      .pipe(switchMap((couponCode) => this.subscriptionsProvider.purchaseSubscription(card, this.selectedStripePlan.id, couponCode)))
      .pipe(take(1))
      .toPromise()
      .then((subscription) => {
        console.log('got subscription', subscription);

        if (['initialized', 'trialing', 'active'].indexOf(subscription.status) === -1) {
          throw SubscriptionsService.ERRORS.PURCHASE_FAILED;
        }

        return subscription;
      })
      .catch((error) => {
        this.alertsService.genericError(error);

        throw error;
      });
  }
}
