export enum AudioPlayerStatus {
  ERROR,
  NONE,
  MEDIA_LOADING,
  MEDIA_LOADED,
  POSITION,
  STARTING,
  RUNNING,
  PAUSED,
  STOPPED,
  COMPLETED,
  COMPLETED_WATCHED // what's the difference with COMPLETED?
}
