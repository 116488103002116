import { Action, ActionReducer } from '@ngrx/store';
import { FullState } from '..';

import { offlineStore } from '../offline/offline.store';
import { RESET_STATE_ON_LOGOUT } from '../sensitive/actions/auth.actions';

export function logoutMetaReducer(reducer: ActionReducer<FullState, Action>): ActionReducer<FullState, Action> {

  return function newReducer(state, action: Action) {
    // clear state in case of logout
    if (action.type === RESET_STATE_ON_LOGOUT) {
      offlineStore.clearStore()
        .catch((error) => {
          console.log('Error occurred while clearing the store', error);
        });

      // delete everything but keep `persistent`, which will keep onboarding, flags, etc...
      state = {
        hydrated: state.hydrated,
        persistent: state.persistent,

        normalized: undefined,
        sensitive: undefined,
        session: undefined
      };
    }

    return reducer(state, action);
  };
}
