import { Injectable } from '@angular/core';

import { catchError, concatMap, map, switchMap, take, tap, withLatestFrom, filter } from 'rxjs/operators';

import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AddChildData } from 'ngrx-normalizr';

import * as moment from 'moment';

import * as programActions from '../actions/program.actions';
import * as accountActions from '../actions/account.actions';
import * as navigationActions from '../actions/navigation.actions';
import * as interstitialsActions from '../../persistent/interstitials/interstitials.actions';
import * as subscriptionActions from '../actions/subscription.actions';
import * as trackingActions from '../actions/tracking.actions';

import {
  LiveBonusExercise,
  LiveLesson,
  getLiveProgram,
  getPlayModule,
  getProgramWeeks,
  getModuleById,
  getCurrentModule,
  LiveModule
} from '../selectors/program.selectors';
import { getAcceleratedTo, getRealNormalizedProgress } from '../../../../app/store/normalized/selectors/user-bootstrap.selectors';
import {
  getBonusExercisesModuleIds, getIntroduceGearsVideo, getBonusExercise
} from '../../../../app/store/normalized/selectors/exercises.selectors';
import { Exercise } from '../../normalized/schemas/exercise.schema';
import {
  isFirstBonusExerciseCompleted
} from '../../persistent/interstitials/interstitials.selectors';
import { ClarityConfig } from '../../../../app/config/clarity.config';
import { UserWeeksProvider } from '../../../../app/providers/user-weeks.provider';
import { Observable, of, forkJoin, EMPTY } from 'rxjs';
import { LoadingService } from '../../../../app/services/loading.service';
import { ToastService } from '../../../../app/services/toast.service';
import { UserProgramSessionProvider } from '../../../../app/providers/user-program-session.provider';
import { AlertsService } from '../../../../app/services/alerts.service';
import { BrowserService } from '../../../services/browser.service';
import { ConnectivityService } from '../../../../app/services/connectivity.service';
import { OnboardingService } from '../../../../app/services/onboarding.service';
import { RateService } from '../../../../app/services/rate.service';
import { AnalyticsService } from '../../../../app/services/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { getPlayedMedia } from '../../persistent/media/media.selectors';
import { UserProvider } from '../../../../app/providers/user.provider';
import { Completed, completedSchema, createLessonId, createModuleId, progressSchema } from '../../normalized/schemas/user-bootstrap.schema';
import { getLiveSubscription } from '../../normalized/selectors/subscription.selectors';
import { TrackSkipIntro } from '../actions/tools.actions';
import { SessionState } from '../session.reducers';
import { LockedInterstitialOptions, LockInterstitialService } from 'src/app/services/lock-interstitial.service';
import { getCurrentDppWlUserProgram } from '../../normalized/selectors/user.selectors';
import { differenceInMonths, parseJSON } from 'date-fns';
import { AnalyticsEvents } from 'src/app/services/analytics/analytics.events';

// This could be replaced in the future by a backend serving client this information, e.g. minimum supported version for each exercise.
const SUPPORTED_LESSON_KINDS: string[] = ['audio', 'video', 'perform', 'capture'];
const SUPPORTED_LESSON_ACTIONS: string[] = [
  'program',
  'set_goals',
  'set_mantra',
  'quitting_pact',
  'set_triggers',
  'breathe',
  'try_stress_test',
  'try_stress_meter',
  'anxiety_quiz',
  'week_review',
  'awareness_quiz',
  'resources',
  'check_weight',
  'week_planning',
  'simple_assessment'
];

@Injectable({providedIn: 'root'})
export class ProgramEffects {


  initLiveProgram$ = createEffect(() => this.actions$.pipe(ofType(programActions.INIT_LIVE_PROGRAM),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getLiveProgram)))
    ),
    switchMap(([action, liveProgram]) =>
      // notifications plugin must be initialized early to catch the click on notifications
      [
        new programActions.LoadLiveProgram(liveProgram),
        new subscriptionActions.SubscriptionRecheck(),
        new trackingActions.StartTrackingAnalytics()
      ]
    )
  ));


  loadProgramBootstrapSuccess$ = createEffect(() => this.actions$.pipe(ofType(programActions.LOAD_PROGRAM_BOOTSTRAP_SUCCESS),
    map((action: programActions.LoadProgramBootstrapSuccess) => action.payload),
    map((program) => new programActions.InitProgramBootstrap(program))
  ));

  tryToplayLesson$ = createEffect(() => this.actions$.pipe(ofType(programActions.TRY_TO_PLAY_LESSON),
    map((action: programActions.TryToPlayLesson) => action.payload),
    map((lesson: LiveLesson) => {
      const { kind, action }: { kind: string; action: string } = lesson.record.exercise;
      const lessonTypeSupported: boolean = SUPPORTED_LESSON_KINDS.includes(kind) && SUPPORTED_LESSON_ACTIONS.includes(action);

      if (lessonTypeSupported) {
        return new programActions.PlayLesson(lesson);
      } else {
        return new programActions.HandleUnsupportedLessonType(lesson);
      }
    })
  ));


  playLesson$ = createEffect(() => this.actions$.pipe(ofType(programActions.PLAY_LESSON),
    map((action: programActions.PlayLesson) => action.payload),
    // some lessons should be completed as soon as they are opened
    tap((lesson: LiveLesson) => {
      if (lesson.record.exercise.tags.find((tag) => tag.name === 'complete_early')) {
        setTimeout(() => {
          // delay request, otherwise Angular will cancel previous user_acitivity request if next one comes too fast
          this.store.dispatch(new programActions.CompleteLesson(lesson, { skipNavigationEffects: true }));
        }, 1000);
      }
    }),
    filter((lesson: LiveLesson) => !lesson.sameModal),
    map((lesson: LiveLesson) =>
      new navigationActions.OpenModal('PlayPage', {
        ...lesson,
        enterAnimation: 'IonicModalAnimations.slideEnterAnimation',
        leaveAnimation: 'IonicModalAnimations.slideLeaveAnimation'
      })
    )
  ));

  handleUnsupportedLessonType$ = createEffect(() => this.actions$.pipe(ofType(programActions.HANDLE_UNSUPPORTED_LESSON_TYPE),
    map((action: programActions.HandleUnsupportedLessonType) => action.payload),
    map(() =>
      new navigationActions.ShowInterstitial({
        page: 'InterstitialPage',
        params: {
          type: 'lesson-not-supported',
          subTitle: 'interstitials.lesson_not_supported_in_current_version',
          notes: 'interstitials.update_app_to_latest_version'
        }
      })
    )
  ));


  resumeLesson$ = createEffect(() => this.actions$.pipe(ofType(programActions.RESUME_LESSON),
    tap(() => {
      this.onboardingService.disableFastOnboarding();

      const elements = document.getElementsByTagName('video');

      if (elements && elements[0]) {
        elements[0].play();
      }
    })
  ), {dispatch: false});


  skipLesson$ = createEffect(() => this.actions$.pipe(ofType<programActions.SkipLesson>(programActions.SKIP_LESSON),
    map((action) => action.payload),
    map((lesson) => new programActions.CompleteLesson(lesson))
  ));


  ENABLE_FAST_ONBOARDING = createEffect(() => this.actions$.pipe(ofType<programActions.EnableFastOnboarding>(programActions.ENABLE_FAST_ONBOARDING),
    map((action) => action.payload),
    map((lesson) => {
      // use a service instead of the store -- this is temporary just on this session
      // and we don't really know when to reset it
      this.onboardingService.enableFastOnboarding();
      this.store.dispatch(new TrackSkipIntro());

      return new programActions.CompleteLesson(lesson);
    })
  ));


  playExercise$ = createEffect(() => this.actions$.pipe(ofType<programActions.PlayExercise>(programActions.PLAY_EXERCISE),
    map((action) => action.payload),
    map((bonusExercise: Exercise) =>
      new navigationActions.OpenModal('PlayPage', {exercise: true})
    )
  ));


  playBonusExercise$ = createEffect(() => this.actions$.pipe(ofType<programActions.PlayBonusExercise>(programActions.PLAY_BONUS_EXERCISE),
    map((action) => {
      const { payload: bonusExercise, options } = action;

      if (bonusExercise.isLocked) {
        return new navigationActions.ShowInterstitial({
          page: 'InterstitialPage',
          params: {
            type: 'locked',
            notes: 'interstitials.you_need_to_finish_module',
            playModule: bonusExercise.moduleNumber,
            bonusExercise: bonusExercise.exercise.title
          }
        });
      }

      return new navigationActions.OpenModal('PlayPage', {exercise: true, afterCompleteOptions: options});
    })
  ));

  playBreatheExercise$ = createEffect(() => this.actions$.pipe(
    ofType<programActions.PlayBreatheExercise>(programActions.PLAY_BREATHE_EXERCISE),
    withLatestFrom(
      this.store.select(getCurrentModule),
      this.store.select(getAcceleratedTo)
    ),
    map(([action, currentModule, acceleratedTo]) => {
      const module2Completed = (
        (currentModule.number === 2 && currentModule.isCompleted)
        || currentModule.number > 2 || acceleratedTo >= 2
      );

      const exercise = action.payload;

      return { exercise, module2Completed };
    }),
    map(({ exercise, module2Completed }) => {
      // breathe exercise in UA is enable only after module 2 completed
      // but is enable by default in ern & ctq
      if (
        (exercise && exercise.isLocked) ||
        (this.config.program.programCode === 'ua' && !module2Completed)
      ) {
        return new navigationActions.ShowInterstitial({
          page: 'InterstitialPage', params: {
            type: 'locked',
            subTitle: 'interstitials.breatheexercise_locked_module_2'
          }
        });
      }

      if (
        this.config.isERN() ||
        this.config.isCTQ() ||
        module2Completed
      ) {
        return new navigationActions.ShowBreatheExercise();
      }

    })
  ));

  // TODO: Refactor `playModule$` effect/action to reflect changes made to PlayLesson/TryToPlayLesson actions.
  // i.e `TryToOpenModule` effect that determines if module should be opened or some sort of interstitial should be opened instead
  // `OpenLessons` can be then renamed to `OpenModule` so that `TryToOpenModule` -> `OpenModule`.
  playModule$ = createEffect(() => this.actions$.pipe(ofType(programActions.PLAY_MODULE),
    switchMap(() => of([])
      .pipe(
        withLatestFrom(
          this.store.select(getCurrentModule),
          this.store.select(getPlayModule),
          this.store.select(getLiveSubscription),
          this.lockInterstitialService.getLockedModuleType(),
          (blank,
            currentModule: LiveModule,
            playModule,
            liveSubscription,
            lockedType: LockedInterstitialOptions) => {
            this.store.dispatch(new subscriptionActions.SubscriptionRecheck());

            if (
              playModule?.number > Number(this.config.program.trialModules)
              && !liveSubscription?.isActive
            ) {
              return this.store.dispatch(new navigationActions.ShowUpgradePopup());
            }

            if (playModule && !playModule.isAvailable) {
              if (lockedType.type !== 'default') {
                return this.store.dispatch(new navigationActions.ShowInterstitial({
                  params: {
                    type: lockedType.type,
                    ...lockedType.params
                  }
                }));
              }

              // @todo maybe move this part inside to lockInterstitialService
              return this.store.dispatch(new navigationActions.ShowInterstitial({
                page: 'InterstitialPage',
                params: {
                  type: 'locked',
                  lockedSubType: lockedType.lockedSubType,

                  currentModuleTitle: currentModule.record.title,
                  playModule: playModule.number,
                  playModuleTitle: playModule.record.title,
                  showLockDetails: true
                }
              }));
            }

            this.store.dispatch(new navigationActions.OpenLessons());
          })
      )
    )
  ), {dispatch: false});

  openModuleById$ = createEffect(() => this.actions$.pipe(ofType(programActions.OPEN_MODULE_BY_ID),
    switchMap((action: programActions.OpenModuleById) => of([])
      .pipe(
        withLatestFrom(
          this.store.select(getModuleById(action.payload)),
          (blank, module) => module !== undefined
            ? new programActions.PlayModule(module)
            : null
        )
      )
    )
  ));

  openBonusExerciseById$ = createEffect(() => this.actions$.pipe(ofType(programActions.OPEN_BONUS_EXERCISE_BY_ID),
    switchMap((action: programActions.OpenBonusExerciseById) => of([])
      .pipe(
        withLatestFrom(
          this.store.select(getBonusExercise(action.payload)),
          (blank, exercise) => module !== undefined
            ? new programActions.PlayBonusExercise(exercise, { skipInterstitial: true, closeModalStrategy: 'closeLastModal' })
            : null
        )
      )
    )
  ));

  playCurrentModule$ = createEffect(() => this.actions$.pipe(ofType(programActions.PLAY_CURRENT_MODULE),
    switchMap((action: programActions.PlayCurrentModule) => of([])
      .pipe(
        withLatestFrom(
          this.store.select(getCurrentModule),
          (blank, currentModule) => new programActions.PlayModule(currentModule)
        )
      )
    )
  ));

  playNextModule$ = createEffect(() => this.actions$.pipe(ofType(programActions.PLAY_NEXT_MODULE),
    switchMap((action: programActions.PlayNextModule) => of([])
      .pipe(
        withLatestFrom(
          this.store.select(getLiveProgram),
          (blank, liveProgram) => {
            const week = liveProgram.weeks.find(item => item.modules.find(mod => mod.completedAt === null));
            const module = week.modules.find(item => item.completedAt === null);

            return new programActions.PlayModule(module);
          })
      )
    )
  ));

  openWeeklyCall$ = createEffect(() => this.actions$.pipe(ofType(programActions.OPEN_WEEKLY_CALLS),
    switchMap((action: programActions.OpenWeeklyCalls) => of([])
      .pipe(
        withLatestFrom(
          this.store.select(getLiveSubscription),
          (blank, liveSubscription) => {

            if (this.connectivity.preventAccessWhenOffline()) {
              return false;
            }

            this.store.dispatch(new subscriptionActions.SubscriptionRecheck());

            if (!liveSubscription || !liveSubscription.isActive) {
              return this.store.dispatch(new navigationActions.ShowUpgradePopup());
            }

            this.browser.openWeeklyCalls();
          })
      )
    )
  ), {dispatch: false});


  openCommunity$ = createEffect(() => this.actions$.pipe(ofType<programActions.OpenCommunity>(programActions.OPEN_COMMUNITY),
    switchMap((action) => of([])
      .pipe(
        withLatestFrom(
          this.store.select(getLiveSubscription),
          (blank, liveSubscription) => {
            const {payload: url} = action;
            if (this.connectivity.preventAccessWhenOffline()) {
              return false;
            }

            this.analyticsService.trackEvent(AnalyticsEvents.CommunityOpenedPost, { url });

            this.store.dispatch(new subscriptionActions.SubscriptionRecheck());

            if (!liveSubscription || !liveSubscription.isActive) {
              return this.store.dispatch(new navigationActions.ShowUpgradePopup());
            }

            this.browser.openCommunityPath(url);
          })
      )
    )
  ), {dispatch: false});


  openCommunityJournal$ = createEffect(() => this.actions$.pipe(ofType<programActions.OpenCommunityJournal>(programActions.OPEN_COMMUNITY_JOURNAL),
    switchMap((url) => of([])
      .pipe(
        withLatestFrom(
          this.store.select(getLiveSubscription),
          (blank, liveSubscription) => {
            if (this.connectivity.preventAccessWhenOffline()) {
              return false;
            }

            this.analyticsService.trackEvent(AnalyticsEvents.CommunityStartedJournal);

            this.store.dispatch(new subscriptionActions.SubscriptionRecheck());

            if (!liveSubscription || !liveSubscription.isActive) {
              return this.store.dispatch(new navigationActions.ShowUpgradePopup());
            }

            this.browser.openCommunityJournal();
          })
      )
    )
  ), {dispatch: false});


  completedLesson$ = createEffect(() => this.actions$.pipe(ofType(programActions.COMPLETE_LESSON),
    // use switchMap so this doesn't get fired when the effects are loaded
    switchMap((action: programActions.CompleteLesson) => of([])
      .pipe(
        withLatestFrom(
          this.store.select(getRealNormalizedProgress),
          this.store.select(getPlayModule),
          (blank, progress, playModule) => {
            const lesson = action.payload;
            const skipNavigationEffects = action.options && action.options.skipNavigationEffects;

            const actions = [];
            const lessonId = createLessonId(lesson);

            if (!progress || !progress[lessonId]) {
              const now = new Date();

              actions.push(new AddChildData<Completed>({
                data: [{id: lessonId, completedAt: now.toISOString()}],
                childSchema: completedSchema,
                parentSchema: progressSchema,
                parentId: 'lessons'
              }));
            }

            for (let i = 0; i < playModule.lessons.length; i++) {
              // find completed lesson in module
              if (isSameLesson(playModule.lessons[i], lesson)) {
                // see if there's a new lesson available
                if (playModule.lessons[i + 1]) {
                  actions.push(new programActions.TryToPlayLesson({...playModule.lessons[i + 1], sameModal: true}));
                }
                // or complete module
                else {
                  if (skipNavigationEffects === true) {
                    actions.push(new programActions.CompleteModuleState(playModule));
                  } else {
                    actions.push(new programActions.CompleteModuleAndNavigate(playModule));
                  }
                }
              }
            }

            actions.push(new programActions.TrackCompleteLesson(lesson));
            actions.forEach((actionToDispatch) => this.store.dispatch(actionToDispatch));
          })
      )
    )
  ), {dispatch: false});


  completedModule$ = createEffect(() => this.actions$.pipe(
    ofType(programActions.COMPLETE_MODULE_AND_NAVIGATE),
    switchMap((action: programActions.CompleteModuleAndNavigate) => {
      const module = action.payload;

      const actions = [];
      actions.push(new programActions.CompleteModuleState(action.payload));

      if (module.number === 7 || module.isLast) {
        this.rateService.rate();
      }

      // if is current module, show completion success message. If not, close the modal.
      if (module.isCurrent) {
        actions.push(new programActions.ShowCompletionInterstitial());
      } else {
        actions.push(new navigationActions.CloseAllModals());
      }

      actions.forEach((actionToDispatch) => this.store.dispatch(actionToDispatch));

      return of([]);
    })
  ), {dispatch: false});


  completedModuleState$ = createEffect(() => this.actions$.pipe(
    ofType(programActions.COMPLETE_MODULE_STATE),
    switchMap((action: programActions.CompleteModuleState) => of([])
      .pipe(
        withLatestFrom(
          this.store.select(getRealNormalizedProgress),
          (_, progress) => {
            const module = action.payload;

            const actions = [];
            const moduleId = createModuleId(module);

            if (!progress || !progress[moduleId]) {
              const now = new Date();

              actions.push(new AddChildData<Completed>({
                data: [{id: moduleId, completedAt: now.toISOString()}],
                childSchema: completedSchema,
                parentSchema: progressSchema,
                parentId: 'modules'
              }));
            }

            actions.push(new programActions.TrackCompleteModule(module));

            actions.forEach((actionToDispatch) => this.store.dispatch(actionToDispatch));
          }
        )
      )
    )
  ), {dispatch: false});

  shownCompletionInterstitial$ = createEffect(() => this.actions$.pipe(
    ofType<programActions.ShowCompletionInterstitial>(programActions.SHOW_COMPLETION_INTERSTITIAL),
    switchMap(() => of([])
      .pipe(
        withLatestFrom(
          this.store.select(getPlayModule),
          this.store.select(getBonusExercisesModuleIds),
          this.store.select(getIntroduceGearsVideo),
          this.store.select(getPlayedMedia),
          this.store.select(getProgramWeeks),
          this.store.select(getCurrentDppWlUserProgram),
          (blank, module, bonusModules, introduceGearsVideo, playedMediaFiles, programWeeks, dppWlUserProgram) => {
            const actions = [];

            // show custom interstitial for introduction module
            if (module.number === 0) {
              actions.push(new navigationActions.ShowInterstitial({
                page: 'InterstitialPage',
                params: {
                  type: 'goodJob',
                  badge: this.config.program.avatar,
                  notes: 'interstitials.you_completed_introduction_module'
                }
              }));

              return actions.forEach((action) => this.store.dispatch(action));
            }
            // show custom interstitial for first module
            else if (module.number === 1) {
              actions.push(new navigationActions.ShowInterstitial({
                page: 'InterstitialPage',
                params: {
                  type: 'goodJob',
                  badge: this.config.program.avatar,
                  notes: 'interstitials.you_completed_first_module'
                }
              }));

              return actions.forEach((action) => this.store.dispatch(action));
            }

            // everything else
            else if (module.number >= 2) {
              // last module - core program completed, a theme week or a custom week
              if (module.isLast) {
                if (this.config.programDPPorWL()) {
                  actions.push(new navigationActions.OpenModal('InterstitialDppCongratsPage', {
                    type: 'finished',
                    title: 'interstitials.congratulations!',
                    subtitle: 'interstitials.youve_completed_the_ern_program',
                    subnotes: 'interstitials.take_a_moment_to_celebrate',
                    badge: 'dpp-program-finished-congrats.svg',
                    button: 'common.continue',
                    action: 'next-step'
                  }));
                } else {
                  let notesTranslationKey = '';

                  switch (module.type) {
                    case 'theme':
                      notesTranslationKey = 'interstitials.you_just_completed_a_theme_week';

                      // track completed event
                      const completedWeek = programWeeks.find((week) => week.number === module.weekNumber);
                      this.analyticsService.trackThemeWeekComplete(completedWeek);

                      break;
                    case 'custom':
                      notesTranslationKey = 'interstitials.you_just_completed_a_custom_week';

                      // track completed event
                      this.analyticsService.trackCustomWeekComplete();

                      break;
                    default:
                      notesTranslationKey = 'interstitials.that_was_final_core_module';
                  }

                  actions.push(new navigationActions.ShowInterstitial({
                    page: 'InterstitialPage',
                    params: {
                      type: 'completedProgram',
                      notes: notesTranslationKey,
                      showNextSteps: true
                    }
                  }));
                }
              }
              // not last module - include special onboarding, include bonus exercises or regular congrats
              else {
                const params: any = {};
                let isDppCongratsScreen = false;

                if (this.config.isCTQ()) {
                  if (module.number === 4) {
                    params.showOnboarding = 'weeklyVideoCalls';
                  }

                  if (module.number === 5) {
                    params.showOnboarding = 'introduceGears';
                    params.video = introduceGearsVideo;
                  }

                  if (module.number === 7) {
                    if (playedMediaFiles.indexOf(introduceGearsVideo.data_fingerprint || introduceGearsVideo.jw_key) === -1) {
                      params.showOnboarding = 'introduceGears';
                      params.video = introduceGearsVideo;
                      params.forceOnboarding = true;
                    }
                  }
                }
                // special congrats screens for DPPWL
                if (this.config.isERN() && this.config.programDPPorWL() && dppWlUserProgram) {
                  const elapsedMonthsSinceStart = differenceInMonths(new Date(), parseJSON(dppWlUserProgram.created_at));
                  params.userProgramCreatedAt = dppWlUserProgram.created_at;

                  const dppInterstitialParams = createDppInterstitialParams(module.number, elapsedMonthsSinceStart, params);
                  if (dppInterstitialParams) {
                    isDppCongratsScreen = true;
                    actions.push(new navigationActions.OpenModal('InterstitialDppCongratsPage', dppInterstitialParams));
                  }
                }

                if (this.config.isERN() && !this.config.programDPPorWL()) {
                  if (module.number === 5) {
                    params.showOnboarding = 'introduceGears';
                    params.video = introduceGearsVideo;
                  }

                  if (module.number === 6) {
                    params.showOnboarding = 'weeklyVideoCalls';
                  }

                  if (module.number === 7) {
                    if (playedMediaFiles.indexOf(introduceGearsVideo.data_fingerprint || introduceGearsVideo.jw_key) === -1) {
                      params.showOnboarding = 'introduceGears';
                      params.video = introduceGearsVideo;
                      params.forceOnboarding = true;
                    }
                  }
                }

                if (this.config.isUA()) {
                  if (module.number === 5) {
                    params.showOnboarding = 'weeklyVideoCalls';
                  }
                }

                // mention bonus modules when they are unlocked
                if (bonusModules[module.number]) {
                  params.type = 'greatJobSpecial';
                  params.subTitle = 'interstitials.you_completed_todays_module';
                  params.bonusExercisesLink = !!bonusModules[module.number];

                  actions.push(new navigationActions.ShowInterstitial({page: 'InterstitialPage', params}));
                }
                // common congrats interstitial after everything else
                // dont show this if there is already a dpp screen will be shown
                else if (!isDppCongratsScreen) {
                  params.type = 'goodJob';
                  params.badge = this.config.program.avatar;
                  params.subTitle = 'interstitials.you_completed_todays_module';
                  params.notes = 'interstitials.you_can_rewatch_modules';

                  actions.push(new navigationActions.ShowInterstitial({page: 'InterstitialPage', params}));
                }
              }

              actions.forEach((action) => this.store.dispatch(action));
            }
          }
        )
      )
    )
  ), {dispatch: false});


  completedBonusExercise$ = createEffect(() => this.actions$.pipe(
    ofType<programActions.CompleteBonusExercise>(programActions.COMPLETE_BONUS_EXERCISE),
    withLatestFrom(this.store.select(isFirstBonusExerciseCompleted)),
    switchMap(([action, firstBonusExerciseCompleted]) => {
      const bonusExercise: LiveBonusExercise = action.payload;
      const options = action.options;
      const actions = [];

      actions.push(new programActions.TrackBonusExercise(bonusExercise));

      switch (options.closeModalStrategy) {
        case 'closeLastModal':
          actions.push(new navigationActions.CloseLastModal());
          break;
        case 'closeAllModals':
        default:
          actions.push(new navigationActions.CloseAllModals());
          break;
      }

      if (!firstBonusExerciseCompleted) {
        actions.push(new interstitialsActions.SetFirstActionCompleted('firstBonusExerciseCompleted'));
        if (!options.skipInterstitial) {
          actions.push(new navigationActions.ShowInterstitial({
            page: 'InterstitialPage',
            params: {
              type: 'firstBonusExercise',
              badge: this.config.program.avatar,
              notes: 'interstitials.use_these_exercises',
              forceBackdrop: true
            }
          }));
        }
      }

      actions.forEach((actionToDispatch) => this.store.dispatch(actionToDispatch));

      return of([]);
    })
  ), { dispatch: false });


  startThemeWeek$ = createEffect(() => this.actions$.pipe(ofType<programActions.StartThemeWeek>(programActions.START_THEME_WEEK),
    map((action) => action.payload),
    switchMap((week) => {
      this.loading.showLoadingOverlay();

      return this.userWeeksProvider.startThemeWeek(week.id)
        .pipe(
          map(() => {
            this.loading.hideLoadingOverlay();
            this.onboardingService.checkShowingOnboarding({type: 'program_extended'});

            this.analyticsService.trackThemeWeekStart(week);

            // Send user to dashboard
            this.store.dispatch(new navigationActions.RootGoTo({
              page: 'TabsPage',
              route: this.config.program.programCode === 'ctq' ? 'tabs/home' : 'tabs/dashboard',
              params: {tab: 0}
            }));

            return new navigationActions.CloseAllModals();
          }),
          catchError((error) => {
            if (error.status === 417) {
              this.alerts.customError(error, 'myjourney.program_restarted', 'myjourney.restarted_program_must_be_completed');

              return [new navigationActions.CloseAllModals()];
            }
            else if (error.status === 409) {
              return new Observable<Action>((observer) => {
                this.translateService.get([
                  'myjourney.week_already_in_progress',
                  'myjourney.current_week_has_not_been_completed_confirmation',
                  'common.no',
                  'common.yes'
                ])
                  .pipe(take(1))
                  .toPromise()
                  .then(async (translations) => {
                    const alert = await this.alerts.alertController.create({
                      header: translations['myjourney.week_already_in_progress'],
                      message: translations['myjourney.current_week_has_not_been_completed_confirmation'],
                      buttons: [
                        {
                          text: translations['common.no'],
                          role: 'cancel',
                          handler: () => {
                            this.loading.hideLoadingOverlay();
                          }
                        },
                        {
                          text: translations['common.yes'],
                          handler: () => {
                            observer.next(new programActions.ReplaceThemeWeek(week));
                          }
                        }
                      ]
                    });

                    await alert.present();
                  });
              });
            }
            else {
              this.alerts.genericError(error);
            }

            return EMPTY;
          })
        );
    })
  ));


  startCustomWeek$ = createEffect(() => this.actions$.pipe(ofType<programActions.StartCustomWeek>(programActions.START_CUSTOM_WEEK),
    map((action) => action.payload),
    switchMap((week) => {
      this.loading.showLoadingOverlay();

      return this.userWeeksProvider.startUserWeek(week)
        .pipe(
          map(() => {
            this.loading.hideLoadingOverlay();
            this.toasts.translateConfirm('myjourney.program_extended_successfully');

            this.analyticsService.trackEvent(AnalyticsEvents.CustomWeekStarted, week);

            // Send user to dashboard
            // TODO: migrate - check this after tab refactor
            this.store.dispatch(new navigationActions.RootGoTo({
              page: 'TabsPage',
              route: 'tabs',
              params: {tab: 0}
            }));

            this.store.dispatch(new navigationActions.CloseAllModals());
          }),
          catchError((error) => {
            if (error.status === 417) {
              this.alerts.customError(error, 'myjourney.program_restarted', 'myjourney.restarted_program_must_be_completed');

              this.store.dispatch(new navigationActions.CloseAllModals());
            }
            else if (error.status === 409) {
              this.translateService.get([
                'myjourney.week_already_in_progress',
                'myjourney.current_week_has_not_been_completed_confirmation',
                'common.no',
                'common.yes'
              ])
                .subscribe(async (translations) => {
                  const alert = await this.alerts.alertController.create({
                    header: translations['myjourney.week_already_in_progress'],
                    message: translations['myjourney.current_week_has_not_been_completed_confirmation'],
                    buttons: [
                      {
                        text: translations['common.no'],
                        role: 'cancel',
                        handler: () => {
                          this.loading.hideLoadingOverlay();
                        }
                      },
                      {
                        text: translations['common.yes'],
                        handler: () => {
                          this.store.dispatch(new programActions.ReplaceCustomWeek(week));
                        }
                      }
                    ]
                  });

                  await alert.present();
                });
            }
            else {
              this.alerts.genericError(error);
            }

            return of({type: 'noop'});
          })
        );
    })
  ), {dispatch: false});


  replaceCustomWeek$ = createEffect(() => this.actions$.pipe(ofType<programActions.ReplaceCustomWeek>(programActions.REPLACE_CUSTOM_WEEK),
    map((action) => action.payload),
    switchMap((week) => this.userWeeksProvider.deleteLastCustomWeek()
      .pipe(
        map(() => {
          this.analyticsService.trackEvent(AnalyticsEvents.CustomWeekReplaced);

          return new programActions.StartCustomWeek(week);
        }),
        catchError((error) => {
          this.alerts.genericError(error);

          return of({type: 'noop'});
        })
      ))
  ));


  restartProgram$ = createEffect(() => this.actions$.pipe(ofType<programActions.RestartProgram>(programActions.RESTART_PROGRAM),
    switchMap(() => {
      this.loading.showLoadingOverlay();

      const resetProcedures = [
        this.userProgramSessionProvider.restartProgram()
      ];

      if (this.config.isCTQ()) {
        // reset the start date and the user will fill in the quit date
        const startDate = moment()
          .format('YYYY-MM-DD');

        resetProcedures.push(
          this.userProvider.updateUserAccount({start_date: startDate, end_date: null, quit_date: null})
        );
      }

      return forkJoin(resetProcedures)
        .pipe(
          map(() => {
            // enable onboarding
            this.onboardingService.enableProgramRestartedOnboarding();

            this.analyticsService.trackProgramRestart();

            if (this.config.isCTQ()) {
              // we need to reload the updated user data and trigger the account setup start
              this.store.dispatch(new accountActions.LoadUser());
            }
            else {
              // Send user to dashboard
              // TODO: migrate - check this after tab refactor
              this.store.dispatch(new navigationActions.RootGoTo({
                page: 'TabsPage',
                route: 'tabs',
                params: {tab: 0}
              }));
            }

            this.loading.hideLoadingOverlay();

            this.toasts.translateConfirm('myjourney.program_restarted_successfully');

            return new navigationActions.CloseAllModals();
          }),
          catchError((error) => {
            this.alerts.genericError(error);

            return [new navigationActions.CloseAllModals()];
          })
        );
    })
  ));

  constructor(
    private actions$: Actions,
    private store: Store<SessionState>,
    private userWeeksProvider: UserWeeksProvider,
    private userProgramSessionProvider: UserProgramSessionProvider,
    private alerts: AlertsService,
    private loading: LoadingService,
    private toasts: ToastService,
    public config: ClarityConfig,
    private browser: BrowserService,
    private connectivity: ConnectivityService,
    private onboardingService: OnboardingService,
    public rateService: RateService,
    private analyticsService: AnalyticsService,
    private translateService: TranslateService,
    private userProvider: UserProvider,
    private lockInterstitialService: LockInterstitialService
  ) {

  }

}

export function createDppInterstitialParams(moduleNumber: number, elapsedMonthsSinceStart: number, params: any = {}) {
  const firstHalfCompletedInTimeParams = {
    ...params,
    type: '16WeeksBefore6Months',
    title: 'interstitials.congratulations!',
    subtitle: 'interstitials.youve_completed_the_1st_phase',
    notes: 'interstitials.you_will_start_the_2nd_phase_on',
    subnotes: 'interstitials.in_the_meantime_choose_a_theme_week',
    badge: 'dpp-program-milestone-congrats.svg',
    button: 'interstitials.choose_theme_week'
  };
  const firstHalfCompletedLateParams = {
    type: '16WeeksAfter6Months',
    title: 'interstitials.congratulations!',
    subtitle: 'interstitials.youve_completed_all_of_the_sessions',
    notes: 'interstitials.take_a_moment_to_celebrate_you',
    subnotes: 'interstitials.see_you_back_here_tomorrow',
    badge: 'dpp-program-milestone-congrats.svg',
    button: 'common.continue',
    action: 'next-step'
  };
  const weekInSecondHalfCompletedInTimeParams = {
    ...params,
    type: 'weekInSecondHalfCompletedInTime',
    title: 'interstitials.way_to_go',
    subtitle: 'interstitials.youve_completed_all_the_modules',
    notes: 'interstitials.you_will_be_able_to_access_next_months',
    subnotes: 'interstitials.in_the_meantime_choose_a_theme_week',
    badge: 'dpp-program-milestone-congrats.svg',
    button: 'interstitials.choose_theme_week'
  };

  // @todo use custom-unlock-tag here instead of hardcoded module numbers
  if (moduleNumber === 112) {
    if (elapsedMonthsSinceStart < 6) {
      return firstHalfCompletedInTimeParams;
    } else {
      return firstHalfCompletedLateParams;
    }
  } else if (moduleNumber === 119) {
    if (elapsedMonthsSinceStart < 7) {
      return {
        ...weekInSecondHalfCompletedInTimeParams,
        unlockNextInMonths: 7
      };
    }
  } else if (moduleNumber === 126) {
    if (elapsedMonthsSinceStart < 8) {
      return {
        ...weekInSecondHalfCompletedInTimeParams,
        unlockNextInMonths: 8
      };
    }
  } else if (moduleNumber === 133) {
    if (elapsedMonthsSinceStart < 9) {
      return {
        ...weekInSecondHalfCompletedInTimeParams,
        unlockNextInMonths: 9
      };
    }
  } else if (moduleNumber === 140) {
    if (elapsedMonthsSinceStart < 10) {
      return {
        ...weekInSecondHalfCompletedInTimeParams,
        unlockNextInMonths: 10
      };
    }
  } else if (moduleNumber === 147) {
    if (elapsedMonthsSinceStart < 11) {
      return {
        ...weekInSecondHalfCompletedInTimeParams,
        unlockNextInMonths: 11
      };
    }
  }

  return null;
}

export const isSameLesson = (lessonA: LiveLesson, lessonB: LiveLesson) => createLessonId(lessonA) === createLessonId(lessonB);
