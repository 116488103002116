import { createSelector } from '@ngrx/store';
import { getCigarettesForToday } from '../../normalized/selectors/count-by-day.selectors';

export const getCigCountToday = createSelector(
  getCigarettesForToday,
  (cigarettesToday) => {
    if (!cigarettesToday || !cigarettesToday.count) {
      return 0;
    }

    return cigarettesToday.count;
  }
);
