import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as toolsActions from '../actions/tools.actions';
import * as programActions from '../actions/program.actions';
import * as userActivityActions from '../actions/user-activity.actions';
import * as trackingActions from '../actions/tracking.actions';
import * as notificationsActions from '../actions/notifications.actions';
import { CheckinsProvider } from '../../../../app/providers/wizards/checkins.provider';
import { map, switchMap, tap, withLatestFrom, take, catchError, concatMap } from 'rxjs/operators';
import { UserActivitiesProvider } from '../../../../app/providers/user-activities.provider';
import { StressTestProvider } from '../../../../app/providers/wizards/stress-test.provider';
import { StressMeterProvider } from '../../../../app/providers/wizards/stress-meters.provider';
import { AwarenessQuizzesProvider } from '../../../../app/providers/awareness-quizzes.provider';
import { Store } from '@ngrx/store';
import { SessionState } from '../session.reducers';
import { AddData, RemoveData } from 'ngrx-normalizr';
import { ExercisesProvider } from '../../../../app/providers/exercises.provider';
import { AnxietyQuizzesProvider } from '../../../../app/providers/anxiety-quizzes.provider';
import { ClarityConfig } from '../../../../app/config/clarity.config';
import { AnalyticsService } from '../../../../app/services/analytics/analytics.service';
import { CravingToolProvider } from '../../../../app/providers/wizards/craving-tool.provider';
import {
  CountByDay,
  checkinsByDaySchema,
  programDaysByDaySchema,
  stressTestsByDaySchema,
  userWeekDaysByDaySchema,
  wantOMeterByDaySchema,
  cravingToolsByDaySchema
} from '../../normalized/schemas/count-by-day.schema';
import { anxietyQuizSchema } from '../../normalized/schemas/anxiety-quiz.schema';
import { cravingToolSchema } from '../../normalized/schemas/craving-tool.schema';
import { recentStressMeterSchema } from '../../normalized/schemas/recent-stress-meter.schema';
import { awarenessQuizSchema, AwarenessQuiz } from '../../normalized/schemas/awareness-quiz.schema';
import {
  getWantOMeterForToday,
  getStressTestForToday,
  getCompletedModulesForToday,
  getCheckinsForToday,
  getCravingToolsForToday
} from '../../normalized/selectors/count-by-day.selectors';
import { getCurrentCravingTool } from '../../normalized/selectors/craving-tool.selectors';
import { of } from 'rxjs';
import { adaptBonusExerciseToAnalytics, adaptLessonToAnalytics, adaptModuleToAnalytics } from 'src/app/services/analytics/data-adapters';
import { AnalyticsEvents } from 'src/app/services/analytics/analytics.events';

@Injectable({providedIn: 'root'})
export class TrackingEffects {

  startTrackingAnalytics$ = createEffect(() => this.actions$.pipe(ofType(trackingActions.START_TRACKING_ANALYTICS),
    tap(() => {
      this.userActivitiesProvider.trackActivity({
        kind: 'app_launch',
        activity_at: new Date(),
        name: this.config.isDevice ? 'mobile' : 'web'
      })
        .pipe(take(1))
        .toPromise();

      // @todo registerUser is misleading here. Change to signinUser() of identifyUser() (second probably is better)
      this.analyticsService.registerUser()
        .then(() => {
          // delay app_launch to allow login/signup events first
          setTimeout(() => {
            this.analyticsService.trackEvent(AnalyticsEvents.AppLaunch);
          }, 3000);
        });
    })
  ), {dispatch: false});


  trackCheckin$ = createEffect(() => this.actions$.pipe(
    ofType<toolsActions.TrackCheckin>(toolsActions.TRACK_CHECKIN),
    withLatestFrom(this.store.select(getCheckinsForToday)
    ),
    switchMap(([action, checkinsForToday]) => {
      this.increaseCountForToday(checkinsForToday, checkinsByDaySchema);

      this.analyticsService.trackActivity('Checkin', action.payload);

      const event = {
        ['checkin']: AnalyticsEvents.CompletedCheckin,
        ['morning-motivation']: AnalyticsEvents.CompletedMorningMotivation,
        ['night-reflection']: AnalyticsEvents.CompletedNightReflection
      }[action.checkinType];

      this.analyticsService.trackEvent(event, { payload: action.payload });

      return this.checkinsProvider.trackCheckin(action);
    })
  ), {dispatch: false});


  trackStressTest$ = createEffect(() => this.actions$.pipe(ofType<toolsActions.TrackStressTest>(toolsActions.TRACK_STRESS_TEST),
    map((action) => action.payload),
    concatMap(stressTest => of(stressTest)
      .pipe(withLatestFrom(this.store.select(getStressTestForToday)))
    ),
    switchMap(([stressTest, stressTestForToday]) => {
      this.increaseCountForToday(stressTestForToday, stressTestsByDaySchema);

      this.analyticsService.trackActivity('Stress Test', stressTest);
      this.analyticsService.trackEvent(AnalyticsEvents.CompletedStressTest, { stressTest });

      return this.stressTestProvider.trackStressTest(stressTest);
    })
  ), {dispatch: false});


  trackSkipIntro$ = createEffect(() => this.actions$.pipe(ofType<toolsActions.TrackSkipIntro>(toolsActions.TRACK_SKIP_INTRO),
    switchMap(() => {
      const activityData = {
        kind: 'click',
        activity_at: new Date(),
        name: 'Skipped intro videos'
      };

      this.analyticsService.trackEvent(AnalyticsEvents.SkippedIntroVideos);

      return this.userActivitiesProvider.trackActivity(activityData);
    })
  ), {dispatch: false});


  trackStressMeter$ = createEffect(() => this.actions$.pipe(ofType<toolsActions.TrackStressMeter>(toolsActions.TRACK_STRESS_METER),
    map((action) => action.payload),
    concatMap(stressMeter => of(stressMeter)
      .pipe(withLatestFrom(this.store.select(getWantOMeterForToday)))
    ),
    switchMap(([stressMeter, wantOMeterForToday]) => {
      this.increaseCountForToday(wantOMeterForToday, wantOMeterByDaySchema);

      this.store.dispatch(new AddData({
        data: [stressMeter],
        schema: recentStressMeterSchema
      }));

      this.analyticsService.trackActivity(this.config.isUA() ? 'Stress Meter' : 'Want-O-Meter', stressMeter);
      this.analyticsService.trackEvent(this.config.isUA() ? AnalyticsEvents.CompletedStressMeter : AnalyticsEvents.CompletedWantOMeter, { stressMeter });

      return this.stressMeterProvider.trackStressMeter(stressMeter);
    })
  ), {dispatch: false});


  trackCravingTool$ = createEffect(() => this.actions$.pipe(
    ofType<toolsActions.TrackCravingTool>(toolsActions.TRACK_CRAVING_TOOL),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getCravingToolsForToday)))
    ),
    map(([action, cravingToolsForToday]) => {
      const {payload: cravingTool, done} = action;
      if (done) {
        this.store.select(getCurrentCravingTool)
          .pipe(take(1))
          .subscribe((tool) => {
            if (tool) {
              this.store.dispatch(new RemoveData({
                id: 'current',
                schema: cravingToolSchema
              }));
            }
          });
      }

      this.analyticsService.trackActivity('Craving Tool', cravingTool);
      this.analyticsService.trackEvent(AnalyticsEvents.CompletedCravingTool, { cravingTool });

      this.cravingToolProvider.trackCravingTool(cravingTool, {done})
        .pipe(
          take(1),
          catchError(() => of(cravingTool))
        )
        .subscribe((payload) => {
          if (done) {
            this.increaseCountForToday(cravingToolsForToday, cravingToolsByDaySchema);

            return;
          }
          this.store.dispatch(new AddData({
            data: [payload],
            schema: cravingToolSchema
          }));
        });
    })
  ), {dispatch: false});


  trackSmokingCravingTool$ = createEffect(() => this.actions$.pipe(
    ofType<toolsActions.TrackSmokingCravingTool>(toolsActions.TRACK_SMOKING_CRAVING_TOOL),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getCravingToolsForToday)))
    ),
    switchMap(([action, cravingToolsForToday]) => {
      const {payload: cravingTool} = action;

      this.analyticsService.trackActivity('Craving Tool', cravingTool);
      this.analyticsService.trackEvent(AnalyticsEvents.CompletedCravingTool, { cravingTool });
      this.increaseCountForToday(cravingToolsForToday, cravingToolsByDaySchema);

      return this.cravingToolProvider.trackSmokingCravingTool(cravingTool);
    })
  ), {dispatch: false});


  trackAwarenessQuiz$ = createEffect(() => this.actions$.pipe(ofType<toolsActions.TrackAwarenessQuiz>(toolsActions.TRACK_AWARENESS_QUIZ),
    map((action) => action.payload),
    switchMap((awarenessQuiz) => {

      this.analyticsService.trackActivity('Awareness Quiz', awarenessQuiz);
      this.analyticsService.trackEvent(AnalyticsEvents.CompletedAwarenessQuiz, { awarenessQuiz });

      return this.awarenessQuizProvider.trackAwarenessQuiz(awarenessQuiz)
        .pipe(
          tap(() => {
            this.store.dispatch(new AddData<AwarenessQuiz>({
              data: [awarenessQuiz],
              schema: awarenessQuizSchema
            }));
          })
        );
    })
  ), {dispatch: false});


  trackAnxietyQuiz = createEffect(() => this.actions$.pipe(ofType<programActions.TrackAnxietyQuiz>(programActions.TRACK_ANXIETY_QUIZ),
    map((action) => action.payload),
    switchMap((anxietyQuiz) => {
      this.analyticsService.trackActivity('Anxiety Quiz', anxietyQuiz);
      this.analyticsService.trackEvent(AnalyticsEvents.CompletedAnxietyQuiz, { anxietyQuiz });

      return this.anxietyQuizProvider.trackAnxietyQuiz(anxietyQuiz)
        .pipe(
          map(() => {
            this.store.dispatch(new AddData({
              data: [anxietyQuiz],
              schema: anxietyQuizSchema
            }));
          })
        );
    })
  ), {dispatch: false});


  trackBreatheExercise$ = createEffect(() => this.actions$.pipe(ofType<toolsActions.TrackBreatheExercise>(toolsActions.TRACK_BREATHE_EXERCISE),
    map((action) => action.payload),
    switchMap((breatheExercise) => {
      this.analyticsService.trackActivity('Breathe Exercise', breatheExercise);
      this.analyticsService.trackEvent(AnalyticsEvents.CompletedBreatheExercise, { breatheExercise });

      return this.exercisesProvider.trackBreath(breatheExercise);
    })
  ), {dispatch: false});


  trackModule$ = createEffect(() => this.actions$.pipe(ofType<programActions.TrackCompleteModule>(programActions.TRACK_COMPLETE_MODULE),
    map((action) => action.payload),
    concatMap(module => of(module)
      .pipe(withLatestFrom(this.store.select(getCompletedModulesForToday)))
    ),
    tap(([module, modulesForToday]) => {
      if (module.type === 'program') {
        this.increaseCountForToday(modulesForToday.programDay, programDaysByDaySchema);
      } else {
        this.increaseCountForToday(modulesForToday.userWeekDay, userWeekDaysByDaySchema);
      }

      this.store.dispatch(new notificationsActions.ResetNotifications());

      this.analyticsService.trackEvent(AnalyticsEvents.CompletedModule, adaptModuleToAnalytics(module));
    })
  ), {dispatch: false});


  trackLesson$ = createEffect(() => this.actions$.pipe(ofType<programActions.TrackCompleteLesson>(programActions.TRACK_COMPLETE_LESSON),
    map((action) => action.payload),
    switchMap((lesson) => {
      this.analyticsService.trackEvent(AnalyticsEvents.CompletedLesson, adaptLessonToAnalytics(lesson));

      const data = {
        activityable_id: lesson.recordId,
        activityable_type: lesson.type === 'program' ? 'ProgramDayExercise' : 'UserWeekDayExercise',
        day_id: lesson.record.program_day_id,
        kind: 'complete',
        activity_at: new Date(),
        name: lesson.record.exercise.title,
        data: {}
      };

      return this.userActivitiesProvider.trackCompletedLesson(data);
    })
  ), {dispatch: false});


  trackBonusExercise$ = createEffect(() => this.actions$.pipe(ofType<programActions.TrackBonusExercise>(programActions.TRACK_BONUS_EXERCISE),
    map((action) => action.payload),
    switchMap((bonusExercise) => {
      const data = {
        activityable_id: bonusExercise.id,
        activityable_type: 'Exercise',
        kind: 'complete',
        activity_at: new Date(),
        name: bonusExercise.exercise.title,
        data: {}
      };

      this.analyticsService.trackEvent(AnalyticsEvents.CompletedExercise, adaptBonusExerciseToAnalytics(bonusExercise));

      return this.userActivitiesProvider.trackCompletedBonusExercise(data);
    })
  ), {dispatch: false});


  trackActivity$ = createEffect(() => this.actions$.pipe(ofType<userActivityActions.TrackActivity>(userActivityActions.TRACK_ACTIVITY),
    map((action) => action.payload),
    switchMap((activityData) => {
      const data = {
        ...activityData,
        activity_at: new Date()
      };

      // make sure we have a data object
      data.data = data.data || {};

      this.analyticsService.trackEvent(AnalyticsEvents.UserActivity, {
        name: data.name
      });

      return this.userActivitiesProvider.trackActivity(data);
    })
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private config: ClarityConfig,
    private store: Store<SessionState>,
    private checkinsProvider: CheckinsProvider,
    private stressTestProvider: StressTestProvider,
    private stressMeterProvider: StressMeterProvider,
    private cravingToolProvider: CravingToolProvider,
    private awarenessQuizProvider: AwarenessQuizzesProvider,
    private anxietyQuizProvider: AnxietyQuizzesProvider,
    private userActivitiesProvider: UserActivitiesProvider,
    private exercisesProvider: ExercisesProvider,
    private analyticsService: AnalyticsService
  ) {

  }

  increaseCountForToday(countForToday: CountByDay, schema) {
    const newCountForToday = {...countForToday};
    newCountForToday.count += 1;

    this.store.dispatch(new AddData({
      data: [newCountForToday],
      schema
    }));
  }
}
