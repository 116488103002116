import { Component, HostBinding } from '@angular/core';
import { NavParams } from '@ionic/angular';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import { Store } from '@ngrx/store';
import { interstitialTypes } from './interstitial-types.const';
import { OnboardingService } from '../../services/onboarding.service';
import * as programActions from '../../store/session/actions/program.actions';
import { SessionState } from '../../store/session/session.reducers';
import { ClarityConfig } from '../../config/clarity.config';

@Component({
  selector: 'page-interstitial-default',
  styleUrls: ['interstitial-default.scss'],
  templateUrl: 'interstitial-default.html'
})
export class InterstitialDefaultPage {
  @HostBinding('class.desktop') isDesktop = this.config.isWebApp;

  interstitialType = this.navParams.get('type');
  interstitialSettings = interstitialTypes[this.interstitialType] || ({} as any);

  constructor(
    private store: Store<SessionState>,
    private config: ClarityConfig,
    public navParams: NavParams,
    private onboardingService: OnboardingService
  ) {
  }

  updateReminderSettings($event) {
    $event.stopPropagation();

    this.store.dispatch(new navigationActions.CloseAllModals());
    this.store.dispatch(new navigationActions.RootGoTo({ route: 'tabs/account/reminders' }));
  }

  openBonusExercise($event) {
    $event.stopPropagation();

    this.store.dispatch(new navigationActions.CloseAllModals());
    this.store.dispatch(new navigationActions.RootGoTo({
      route: 'tabs/exercises'
    }));
  }

  openJournal($event) {
    $event.stopPropagation();

    this.store.dispatch(new programActions.OpenCommunityJournal());
  }

  onLearnMore($event) {
    $event.stopPropagation();

    this.store.dispatch(new navigationActions.CloseAllModals());
    this.store.dispatch(new navigationActions.ShowOnboarding({
      page: 'OnboardingPage',
      params: {
        type: 'locked_reason'
      }
    }));
  }

  onClick() {
    if (this.navParams.get('closeAllOnDismiss') === false) {
      this.store.dispatch(new navigationActions.CloseInterstitial());
    }
    else {
      this.store.dispatch(new navigationActions.CloseAllModals());
    }

    if (this.navParams.get('showNextSteps')) {
      this.store.dispatch(new navigationActions.ShowNextSteps());
    }

    if (this.navParams.get('showOnboarding')) {
      const params = {
        type: this.navParams.get('showOnboarding'),
        video: this.navParams.get('video'),
        forceOnboarding: this.navParams.get('forceOnboarding')
      };

      this.onboardingService.checkShowingOnboarding(params);
    }
  }

}
