import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { OnboardingPage } from './onboarding';
import { HalfScreenComponent } from './components/half-screen.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../shared';
import { FullScreenComponent } from './components/full-screen.component';
import { ThirdScreenComponent } from './components/third-screen.component';
import { AutoScreenComponent } from './components/auto-screen.component';
import { translateConfig } from '../../config/translate.config';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    OnboardingPage,
    HalfScreenComponent,
    FullScreenComponent,
    ThirdScreenComponent,
    AutoScreenComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    RouterModule.forChild([{ path: '', component: OnboardingPage }]),
    TranslateModule.forChild(translateConfig),
    SharedModule
  ]
})
export class OnboardingPageModule {}
