
import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, timeout } from 'rxjs/operators';
import { EventsService } from '../../services/events.service';

@Injectable({providedIn: 'root'})
export class HttpProvider {

  static DEFAULT_CONNECTION_TIMEOUT = 45 * 1000;

  private api = '';

  // Extending the HttpClient through the Angular DI.
  public constructor(
    private http: HttpClient,
    private events: EventsService
  ) {
  }

  /**
   * GET request
   */
  // TODO: migrate - observable
  // public get<T>(endPoint: string, options?: HttpRequestOptions): Observable<T> {
  // public get<T>(endPoint: string, options?: HttpRequestOptions): Observable<{} | T> {
  //   return this.http.get<T>(this.api + endPoint, options)
  //     .pipe(
  //       timeout(HttpProvider.DEFAULT_CONNECTION_TIMEOUT),
  //       catchError((e) => this.handleUnauthorizedError(e)),
  //       catchError((e) => this.handleTimeoutError(e))
  //     );
  // }
  public get<T>(endPoint: string, options?: HttpRequestOptions): Observable<any> {
    return this.http.get<T>(this.api + endPoint, options)
      .pipe(
        timeout(HttpProvider.DEFAULT_CONNECTION_TIMEOUT),
        catchError((error) => this.handleUnauthorizedError(error)),
        catchError((error) => this.handleTimeoutError(error))
      );
  }

  /**
   * POST request
   */
  // public post<T>(endPoint: string, params: object, options?: HttpRequestOptions): Observable<{} | T> {
  //   return this.http.post<T>(this.api + endPoint, params, options)
  //     .pipe(
  //       timeout(HttpProvider.DEFAULT_CONNECTION_TIMEOUT),
  //       catchError((e) => this.handleUnauthorizedError(e)),
  //       catchError((e) => this.handleTimeoutError(e))
  //     );
  // }
  public post<T>(endPoint: string, params: object, options?: HttpRequestOptions): Observable<any> {
    return this.http.post<T>(this.api + endPoint, params, options)
      .pipe(
        timeout(HttpProvider.DEFAULT_CONNECTION_TIMEOUT),
        catchError((error) => this.handleUnauthorizedError(error)),
        catchError((error) => this.handleTimeoutError(error))
      );
  }

  /**
   * PATCH request
   */
  public patch<T>(endPoint: string, params: object, options?: HttpRequestOptions): Observable<any> {
    return this.http.patch<T>(this.api + endPoint, params, options)
      .pipe(
        timeout(HttpProvider.DEFAULT_CONNECTION_TIMEOUT),
        catchError((error) => this.handleUnauthorizedError(error)),
        catchError((error) => this.handleTimeoutError(error))
      );
  }

  /**
   * PUT request
   */
  public put<T>(endPoint: string, params: object, options?: HttpRequestOptions): Observable<any> {
    return this.http.put<T>(this.api + endPoint, params, options)
      .pipe(
        timeout(HttpProvider.DEFAULT_CONNECTION_TIMEOUT),
        catchError((error) => this.handleUnauthorizedError(error)),
        catchError((error) => this.handleTimeoutError(error))
      );
  }

  /**
   * DELETE request
   */
  public delete<T>(endPoint: string, options?: HttpRequestOptions): Observable<any> {
    return this.http.delete<T>(this.api + endPoint, options)
      .pipe(
        timeout(HttpProvider.DEFAULT_CONNECTION_TIMEOUT),
        catchError((error) => this.handleUnauthorizedError(error)),
        catchError((error) => this.handleTimeoutError(error))
      );
  }

  private handleUnauthorizedError(error) {
    if (error.status !== 401) {
      return observableThrowError(error);
    }

    // ignore iridium 401
    if (error.url.match(/iridium/)) {
      console.log('Got 401 from Iridium - ignoring!');

      return of({});
    }

    console.log('Got 401 - forcing Logout!');

    this.events.publish('clarity.unauthorized');

    return of({});
  }

  private handleTimeoutError(error) {
    if (error.name !== 'TimeoutError') {
      return observableThrowError(error);
    }

    return observableThrowError(new HttpErrorResponse({
      error,
      status: 408,
      statusText: 'Request Timeout'
    }));
  }
}

export interface HttpRequestOptions {
  headers?: HttpHeaders;
  observe?: 'body';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;
}

export function HttpProviderFactory(
  http: HttpClient,
  events: EventsService
) {
  return new HttpProvider(http, events);
}
