import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SessionState } from 'http2';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { getAcceleratedTo, getCompletedModules } from '../store/normalized/selectors/user-bootstrap.selectors';
import { getCurrentLesson, getCurrentModule, getPlayModule, LiveLesson, LiveModule } from '../store/session/selectors/program.selectors';
import { ClarityConfig } from '../config/clarity.config';

export type LockedInterstitialTypes =
  'default' |
  'locked.module_in_progress_missing_weight_capture' |
  'locked.dpp_module_not_yet_available';

export type LockedInterstitialSubTypes =
  'module_in_progress' |
  'accelerated_module_available' |
  'module_will_unlock_tomorrow' |
  'module_completely_locked';

export interface LockedInterstitialOptions {
  type: LockedInterstitialTypes;
  lockedSubType?: LockedInterstitialSubTypes;

  params?: any;
}

/**
 * The interstitial component grew too complex, specially for the "locked" type.
 * So in order to simplify it, this service intends to extract some of the logic
 * out of the component. It acts defining how the interstitial should be presented
 * with a simple string, based on the state of the store.
 */
@Injectable({providedIn: 'root'})
export class LockInterstitialService {
  constructor(
    private store: Store<SessionState>,
    private config: ClarityConfig
  ) {}

  private isOnlyMissingWeightCapture(currentLesson: LiveLesson) {
    return (
      !currentLesson.isCompleted &&
      currentLesson.record.exercise.kind === 'capture' &&
      currentLesson.record.exercise.action === 'check_weight'
    );
  }

  getLockedModuleType(): Observable<LockedInterstitialOptions> {
    return combineLatest([
      this.store.select(getCurrentModule),
      this.store.select(getCurrentLesson),
      this.store.select(getPlayModule),
      this.store.select(getAcceleratedTo),
      this.store.select(getCompletedModules)
    ]).pipe(
      take(1),
      map(([currentModule, currentLesson, playModule, acceleratedTo, completedModules]) => {
        const lastCompletedModule = completedModules.slice(-1)[0] || 0;
        const currentModuleInProgress = !currentModule.isCompleted;
        const nextModuleIsAllowedByAcceletared = lastCompletedModule + 1 <= acceleratedTo;
        const tryingToPlayNextAvailableModule = playModule.number === lastCompletedModule + 1;

        // IMPORTANT: if-order here matters

        if (currentLesson && this.isOnlyMissingWeightCapture(currentLesson)) {
          return {
            type: 'locked.module_in_progress_missing_weight_capture'
          };
        }

        if (currentModuleInProgress) {
          return {
            type: 'default',
            lockedSubType: 'module_in_progress'
          };
        }

        if (nextModuleIsAllowedByAcceletared) {
          return {
            type: 'default',
            lockedSubType: 'accelerated_module_available'
          };
        }

        // since this is currently available for DPP only, and we're using a component specific for dpp
        // we're tying the willUnlockAt with the isDPP condition. This will likely change in a near future
        if (this.config.programDPP() && playModule.willUnlockAt && playModule.willUnlockAt !== 'tomorrow') {
          return {
            type: 'locked.dpp_module_not_yet_available',
            params: {
              accessDate: playModule.willUnlockAt
            }
          };
        }

        if (tryingToPlayNextAvailableModule) {
          return {
            type: 'default',
            lockedSubType: 'module_will_unlock_tomorrow'
          };
        }

        // no module in progress, and trying to play a module far beyond reachable
        return {
          type: 'default',
          lockedSubType: 'module_completely_locked'
        };
      })
    );
  }
}
