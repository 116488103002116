import { createSchemaSelectors } from 'ngrx-normalizr';
import { Subscription, subscriptionSchema } from '../schemas/subscription.schema';
import { createSelector } from '@ngrx/store';
import { getCurrentUserProgram, getCurrentUserSSOProvider } from './user.selectors';
import equal from 'fast-deep-equal';

const subscriptionSchemaSelectors = createSchemaSelectors<Subscription>(subscriptionSchema);

export class LiveSubscription extends Subscription {
  isActive: boolean;
  isTrialing: boolean;
  isCanceled: boolean;
  isUnlimited: boolean;
  isLicensed: boolean;
  canBuy: boolean;
  canRestore: boolean;
  canLicense: boolean;
  canCancel: boolean;
  willRenew: boolean;
}

const activeStatuses = [
  'trialing',
  'active',
  'past_due'
];

// const providers = {
//   'stripe': 'Stripe',
//   'itunes': 'iTunes',
//   'googleplay': 'Google Play',
//   'license': 'License'
// };

const isAfterNow = (dateString) => {
  const date = new Date(dateString);

  // offset active until with 12 hours -- just in case the subscription is not validated immediately after it expires
  const checkDate = new Date(date.getTime() + 720 * 1000);

  return checkDate > new Date();
};

let subscriptionsCopy: Subscription[] = [];

export const getSubscriptions = createSelector(
  subscriptionSchemaSelectors.getEntities,
  (subscriptions) => {
    if (!equal(subscriptions, subscriptionsCopy)) {
      subscriptionsCopy = subscriptions;
    }

    return subscriptionsCopy;
  }
);

export const getCurrentSubscription = createSelector(
  getSubscriptions,
  (subscriptions: Subscription[]) => subscriptions && subscriptions.length > 0 ? subscriptions[0] : null
);

export const getLiveSubscription = createSelector(
  getCurrentUserProgram,
  getCurrentSubscription,
  (userProgram, subscription): LiveSubscription => {
    const liveSubscription: any = Object.assign(
      {
        provider: null,
        isActive: false,
        isTrialing: false,
        isCanceled: false,
        isUnlimited: false,
        isLicensed: false,
        canBuy: true,
        canRestore: true,
        canLicense: true,
        canCancel: false,
        willRenew: false
      },
      subscription
    );

    if (!subscription || !subscription.status) {
      return liveSubscription;
    }

    liveSubscription.isTrialing =
      subscription.status === 'trialing'
        ? true
        : false;

    liveSubscription.isTrialing =
      subscription.status === 'trialing'
        ? true
        : false;

    liveSubscription.isLicensed =
      subscription.provider === 'license'
        ? true
        : false;

    liveSubscription.canLicense =
      userProgram.license_code && userProgram.license_code !== ''
        ? false
        : true;

    liveSubscription.isActive =
      // lifetime licenses
      (activeStatuses.indexOf(subscription.status) > -1 && liveSubscription.isLicensed && !subscription.active_until)
      // lifetime stripe subscriptions
      || (activeStatuses.indexOf(subscription.status) > -1 && !liveSubscription.isLicensed && !subscription.active_until)
      // any other active subscription with a valid expiry
      || (activeStatuses.indexOf(subscription.status) > -1 && isAfterNow(subscription.active_until))
        ? true
        : false;

    liveSubscription.isUnlimited =
      liveSubscription.isActive && !liveSubscription.active_until
        ? true
        : false;

    liveSubscription.isCanceled =
      (liveSubscription.isLicensed && subscription.active_until !== null)
      || (subscription.provider === 'stripe' && subscription.cancel_requested !== null)
      || (subscription.provider === 'googleplay' && subscription.cancel_requested !== null)
      || (['googleplay', 'itunes'].indexOf(subscription.provider) > -1 && subscription.appstore_renewal_on === false)
        ? true
        : false;

    liveSubscription.canBuy =
      liveSubscription.provider === null
        ? true
        : false;

    liveSubscription.canCancel =
      !liveSubscription.isLicensed && liveSubscription.isActive && !liveSubscription.isCanceled
        ? true
        : false;

    liveSubscription.willRenew =
      !liveSubscription.isLicensed && liveSubscription.isActive && !liveSubscription.isTrialing && !liveSubscription.isCanceled
        ? true
        : false;

    console.log('$$$ SELECTOR getLiveSubscription');

    return liveSubscription;
  }
);

export const getLiveActiveSubscription = createSelector(
  getLiveSubscription,
  (subscription): null | LiveSubscription => subscription && subscription.isActive ? subscription : null
);

export const shouldHideSubscription = createSelector(
  getLiveSubscription,
  getCurrentUserSSOProvider,
  (subscription, ssoProvider) => {
    if (ssoProvider && subscription && subscription.isLicensed && subscription.isUnlimited) {
      return true;
    }

    return false;
  }
);
