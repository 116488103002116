import { Component } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store';
import { addMonths, format, parseJSON } from 'date-fns';

export type InterstitialDppCongratsType = '16WeeksBefore6Months'
  | '16WeeksAfter6Months'
  | 'weekInSecondHalfCompletedInTime'
  | 'finished';

@Component({
  selector: 'page-interstitial-dpp-congrats',
  styleUrls: ['interstitial-dpp-congrats.scss'],
  templateUrl: 'interstitial-dpp-congrats.html'
})
export class InterstitialDppCongratsPage {
  notesDate: string;

  constructor(
    private store: Store<State>,
    private nav: NavController,
    public navParams: NavParams
  ) {}

  ionViewWillEnter() {
    if (this.navParams.data.type === '16WeeksBefore6Months') {
      this.notesDate = format(addMonths(parseJSON(this.navParams.data.userProgramCreatedAt), 6), 'EEEE, LLL do');
    }
    if (this.navParams.data.type === 'weekInSecondHalfCompletedInTime') {
      this.notesDate = format(addMonths(parseJSON(this.navParams.data.userProgramCreatedAt), this.navParams.data.unlockNextInMonths), 'EEEE, LLL do');
    }
  }

  buttonAction($event) {
    $event.stopPropagation();

    if(this.navParams.data.action === 'next-step') {

      this.store.dispatch(new navigationActions.OpenModal('NextStepsPage', {lastWeekType: 'program', forceBackdrop: true}));
    } else {
      // theme week
      this.store.dispatch(new navigationActions.OpenModal('SelectThemePage', {
        enterAnimation: 'IonicModalAnimations.slideEnterAnimation',
        leaveAnimation: 'IonicModalAnimations.slideLeaveAnimation'
      }));
    }
  }

  onClick() {
    this.store.dispatch(new navigationActions.CloseAllModals());
  }
}
