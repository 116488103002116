import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { AlertController, IonToggle } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { State } from '../../../store';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { getOfflineStorageStatus } from '../../../store/session/selectors/download.selectors';
import * as downloadActions from '../../../store/session/actions/download.actions';
import { scaleUpDown } from 'src/app/utils/animations';

@Component({
  selector: 'cl-account-download',
  styleUrls: ['download.component.scss'],
  animations: [scaleUpDown],
  template: `
    <form  [formGroup]="download"   [@state]="visibility" (@state.done)="animationDone($event)">
      <h3>{{'account_menu.downloads.title' | translate}}</h3>
      <ion-item class="enable-download">
      <ion-label class="ion-text-wrap">
        {{ 'account_menu.downloads.enable_medias_download' | translate }}
      </ion-label>
      <ion-toggle #downloadToggle color="toggle" mode="ios" (ionChange)="toggleOfflineStorage()" formControlName="download"></ion-toggle>
      </ion-item>
      <div class="information">
        {{ (downloadToggle.checked ? 'account_menu.downloads.offline_capability' : 'account_menu.downloads.online_capability') | translate }}
      </div>
    </form>
  `
})

export class DownloadComponent {
  @Output() endAnimation = new EventEmitter();
  @ViewChild('downloadToggle') downloadToggle: IonToggle;
  visibility = 'visible';
  download: FormGroup;
  private offlineStorage$ = this.store.select(getOfflineStorageStatus);
  private preventDisplayAlert = false;

  constructor(
    private alertController: AlertController,
    private store: Store<State>,
    private translate: TranslateService,
    private formBuilder: FormBuilder) {
    this.offlineStorage$.pipe(first())
      .subscribe(offlineStorage => {
        this.download = this.formBuilder.group({
          download: [offlineStorage, Validators.required]
        });
      });
  }

  animationDone(event) {
    if (event.toState === 'hidden') {
      this.endAnimation.emit(true);
    }
  }

  async toggleOfflineStorage() {
    if(this.preventDisplayAlert) {
      this.preventDisplayAlert = false;

      return;
    }

    const toggleValue = this.download.get('download').value;

    this.translate.get([
      'account_menu.downloads.disable_downloads',
      'account_menu.downloads.enable_downloads',
      'common.yes',
      'common.cancel',
      'account_menu.downloads.are_you_sure'
    ])
      .toPromise()
      .then(async translations => {
        const alert = await this.alertController.create({
          header: translations['account_menu.downloads.are_you_sure'],
          message: translations[toggleValue ? 'account_menu.downloads.enable_downloads' : 'account_menu.downloads.disable_downloads'],
          backdropDismiss: false,
          buttons: [
            {
              text: translations['common.cancel'],
              handler: () => {
                this.preventDisplayAlert = true;
                this.download.controls['download'].setValue(!toggleValue);
              }
            },
            {
              text: translations['common.yes'],
              handler: () => {
                this.store.dispatch(new downloadActions.SetOfflineStorageStatus(toggleValue));
              }
            }]
        });

        await alert.present();
      });
  }

  closeComponent() {
    this.visibility = 'hidden';
  }
}
