import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cl-price-card',
  styleUrls: ['price-card.component.scss'],
  template: `
    <ion-row>
      <ion-col class="ion-text-center">
        <button
          class="plan-box"
          [class.special-discount]="specialDiscount"
          [class.activated]="selected"
          [disabled]="disabled"
          (click)="onPurchase()">
          <p class="plan-length">{{length}}</p>
          <p class="plan-price" [class.slim]="!pricePerWeek"><span *ngIf="fullPrice" class="full-price">{{fullPrice}}</span>{{price}}</p>
          <p class="plan-highlight" *ngIf="pricePerWeek">{{pricePerWeek}}</p>
          <p class="plan-highlight slim-font" *ngIf="renewalNotice">{{'subscriptions.renewal_notice' | translate}}</p>
        </button>
      </ion-col>
    </ion-row>
  `
})

export class PriceCardComponent {

  @Input() length: string;
  @Input() price: string;
  @Input() pricePerWeek: string;
  @Input() fullPrice: string;
  @Input() specialDiscount: boolean;
  @Input() disabled: boolean;
  @Input() selected: boolean;
  @Input() renewalNotice: boolean;

  @Output() purchase = new EventEmitter();

  onPurchase() {
    this.purchase.emit();
  }
}
