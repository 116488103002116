import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, of, Subscription, SubscriptionLike as ISubscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { ClarityConfig } from '../../../config/clarity.config';
import { getDefaultGoalsSelection, userGoalsSelector } from '../../../store/normalized/selectors/goals.selectors';
import { getRecentTriggerName } from '../../../store/normalized/selectors/recent-stress-meter.selectors';
import { getCurrentUserCigaretteSavings, getCurrentUserStartDate, getUserMantra } from '../../../store/normalized/selectors/user.selectors';
import { isLoadingUserGoals } from '../../../store/session/selectors/user-goals.selectors';
import { State } from '../../../store/state.reducer';
import { SettingsComponent } from './settings.component';
import { SessionState } from '../../../store/session';
import { getIsEdditingSettingsGoals } from '../../../store/session/selectors/account.selectors';
import * as accountActions from '../../../store/session/actions/account.actions';
import { scaleUpDown } from 'src/app/utils/animations';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-account-goals',
  styleUrls: ['goal-settings.component.scss'],
  animations: [scaleUpDown],
  template: `
  <div [@state]="visibility" (@state.done)="animationDone($event)">
    <cl-header-nav-buttons
      *ngIf="isEdditingSettingsGoals$ | async"
      color="primary"
      [title]="'goals_menu.my_goals' | translate"
      [closeButton]="false"
      (back)="showOverview()"
      [backButton]="true">
    </cl-header-nav-buttons>

    <cl-quitting-plan *ngIf="config.program.programCode === 'ctq' && !(isEdditingSettingsGoals$ | async)"></cl-quitting-plan>

    <cl-goals-overview *ngIf="!(isEdditingSettingsGoals$ | async)"
                       [startDate]="startDate$ | async"
                       [cigaretteSavings]="cigaretteSavings$ | async"
                       [goalsSyncing]="goalsSyncing$ | async"
                       [customGoals]="customGoals$ | async"
                       [checkedDefaultGoals]="checkedDefaultGoals$ | async"
                       [mantra]="mantra | async"
                       [lastTrigger]="lastTrigger$ | async"
                       (edit)="showEditGoals()">
    </cl-goals-overview>

    <cl-goals-edit
      *ngIf="isEdditingSettingsGoals$ | async"
      [initialCustomGoals]="customGoals$ | async"
      [initialDefaultGoals]="selectedDefaultGoals$ | async"
      (action)="showOverview()"
    >
    </cl-goals-edit>
  </div>
  `
})
export class GoalSettingsComponent implements SettingsComponent, OnDestroy {
  @Output() endAnimation = new EventEmitter();
  @Output() scrollTop = new EventEmitter();
  visibility = 'visible';

  selectedDefaultGoals$ = this.store.select(getDefaultGoalsSelection);
  isEdditingSettingsGoals$ = this.sessionStore.select(getIsEdditingSettingsGoals);
  customGoals$ = this.store.select(userGoalsSelector);
  startDate$ = !this.config.isCTQ() ? this.store.select(getCurrentUserStartDate) : of();
  cigaretteSavings$ = this.store.select(getCurrentUserCigaretteSavings);
  userMantra$ = this.store.select(getUserMantra);
  lastTrigger$ = this.store.select(getRecentTriggerName)
    .pipe(
      map((triggerName) => {
        const noTriggerText = `wizard_items.no_trigger_yet_${this.config.currentProgram()}`;

        return triggerName ? triggerName : noTriggerText;
      })
    );
  goalsSyncing$ = this.store.select(isLoadingUserGoals);

  public checkedDefaultGoals$ = this.selectedDefaultGoals$.pipe(
    map(goals => goals.filter(goal => goal.checked))
  );

  public mantra = combineLatest([this.userMantra$, this.translate.get('goals_menu.no_mantra')])
    .pipe(
      map(([userMantra, mantraPlaceholder]) => {
        if (userMantra) {
          return userMantra;
        }

        return mantraPlaceholder;
      })
    );

  private subscription: ISubscription;
  private syncingSubscription: Subscription;

  constructor(
    private store: Store<State>,
    private sessionStore: Store<SessionState>,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    public config: ClarityConfig
  ) {
    this.syncingSubscription = this.goalsSyncing$.subscribe(() => {
      setTimeout(() => {
        try {
          this.cdr.detectChanges();
        }
        catch (error) {
          console.log('Cannot detect changes!');
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.syncingSubscription && this.syncingSubscription.unsubscribe();
    this.subscription && this.subscription.unsubscribe();
  }

  showEditGoals() {
    this.sessionStore.dispatch(new accountActions.SetIsEdditingSettingsGoals(true));

    try {
      this.cdr.detectChanges();
      this.scrollTop.emit();
    } catch (error) {
      // console.log('cannot detect changes')
    }
  }

  showOverview() {
    this.sessionStore.dispatch(new accountActions.SetIsEdditingSettingsGoals(false));

    try {
      this.cdr.detectChanges();
    } catch (error) {
      // console.log('cannot detect changes')
    }
  }

  animationDone(event) {
    if (event.toState === 'hidden') {
      this.endAnimation.emit(true);
    }
  }

  closeComponent() {
    this.visibility = 'hidden';

    try {
      this.cdr.detectChanges();
    } catch (error) {
      // console.log('cannot detect changes')
    }
  }

}
