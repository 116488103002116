import { Injectable } from '@angular/core';
import { ClarityConfig } from '../config/clarity.config';
import { Store } from '@ngrx/store';
import { getCurrentUser, getUserDebuggingData } from '../store/normalized/selectors/user.selectors';
import { take } from 'rxjs/operators';

import * as Sentry from '@sentry/capacitor';
import { init as sentryAngularInit }  from '@sentry/angular';

import { State } from '../store/state.reducer';
import { flatten } from 'flat';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class LoggerService {
  private userData$ = this.store.select(getCurrentUser);
  private debuggingData$ = this.store.select(getUserDebuggingData);

  constructor(
    public config: ClarityConfig,
    public store: Store<State>
  ) {

  }

  public initialize() {
    if (this.config.env.sentry.enabled) {
      this.initSentry();
    }

    return Promise.resolve();
  }

  public initUserData() {
    this.userData$
      .subscribe((user) => {
        if (this.config.env.sentry.enabled) {
          Sentry.configureScope((scope) => {
            const data: any = {};

            if (user && user.user_id) {
              data.id = user && user.user_id.toString();
              data.email = user.email;
              data.extra = this.prepareDataForSentry(user);
            }

            scope.setUser(data);
          });
        }
      });
  }

  public ionicError(error) {
    return this.trackError(error);
  }

  public info(...data) {
    console.log(...data);
  }

  public debug(...data) {
    if (this.config.onDevelopment() || this.config.env.forceDebugging) {
      this.info('#DBG', ...data);
    }

    if (this.config.env.remoteDebugging) {
      this.trackMessage({
        type: '#REMOTEDBG',
        content: data,
        context: ''
      });
    }
  }

  public error(type: string, error: any = {}, context = 'Unknown', extraData = null) {
    const errorInfo = {
      type,
      content: error,
      context,
      extraData
    };

    try {
      console.error(errorInfo.type, error);
    } catch (cError) {
      console.log('Cannot log error to Console!');
    }

    this.trackMessage(errorInfo);
  }

  public warning(type: string, warning: any = {}, context = 'Unknown', extraData = null) {
    const warningInfo = {
      type,
      content: warning,
      context,
      extraData
    };

    try {
      console.warn(warningInfo.type, warning);
    } catch (cError) {
      console.log('Cannot log warning to Console!');
    }

    this.trackMessage(warningInfo, Sentry.Severity.Warning);
  }

  public sentryLog(type: string, log: any = {}, context = 'Unknown', extraData = null) {
    const logInfo = {
      type,
      content: log,
      context,
      extraData
    };

    try {
      console.log(logInfo.type, log);
    } catch (cError) {
      console.log('Cannot log log to Console!');
    }

    this.trackMessage(logInfo, Sentry.Severity.Log);
  }

  private sanitizeHttpErrorResponse(error: HttpErrorResponse) {
    return {
      error: error.error,
      statusText: error.statusText,
      url: error.url,
      status: error.status
    };
  }

  prepareDataForSentry(data) {
    return flatten(data);
  }

  private trackMessage(message: { type: string; content: any; context: string; extraData?: any }, severity = Sentry.Severity.Error) {
    Sentry.configureScope((scope) => {
      scope.setTag('type', message.type);
      scope.setTag('context', message.context);

      if (message.extraData) {
        scope.setExtra('extra', this.prepareDataForSentry(message.extraData));
      }

      this.debuggingData$
        .pipe(take(1))
        .subscribe((debugData) => {
          try {
            scope.setExtra('debug', this.prepareDataForSentry(debugData));
          } catch (error) {
            console.error('Cannot set extra debug data!');
          }

          try {
            const content = message.content instanceof HttpErrorResponse ? this.sanitizeHttpErrorResponse(message.content) : message.content;

            Sentry.captureMessage(JSON.stringify(content), severity);
          } catch (error) {
            console.error('ERROR: Cannot trackMessage to Sentry!');
          }
        });
    });
  }

  private trackError(error) {
    // Send error to Sentry
    if (this.config.env.sentry.enabled) {
      try {
        Sentry.captureException(error);
      } catch (sError) {
        // this.trackMessage(e);
        console.error('ERROR: Cannot captureException to Sentry!', error);
      }
    }
  }

  private initSentry() {
    Sentry.init({
      enabled: this.config.env.sentry.enabled,
      dsn: this.config.env.sentry.dsn,
      environment: this.config.env.environment,
      ignoreErrors: [/TimeoutError/, /AbortError/, /NotSupportedError/],
      integrations(integrations) {
        return integrations.filter(i => i.name !== 'TryCatch');
      },
      // No need for this, Sentry will be pick it up automatically
      release: window['SENTRY_RELEASE'] && window['SENTRY_RELEASE'].id,
      beforeSend: (event) => {
        // re-write stack trace to match source maps
        const stacktrace = event.stacktrace ||
          (
            event.exception &&
            event.exception.values &&
            event.exception.values.length > 0
          ) &&
          event.exception.values[0].stacktrace;

        if (stacktrace) {
          stacktrace.frames.forEach((frame) => {
            frame.filename = frame.filename.substring(frame.filename.lastIndexOf('/'));
          });
        }

        return event;
      }
    }, sentryAngularInit);

    // configure app settings
    Sentry.configureScope((scope) => {
      scope.setTag('environment', this.config.currentEnv());
      scope.setTag('program', this.config.program.programCode);
      scope.setTag('appVersion', this.config.getAppVersion());
      scope.setTag('buildNumber', this.config.getBuildNumber()
        .toString());

      scope.setExtra('env', this.prepareDataForSentry(this.config.env));
      scope.setExtra('program', this.prepareDataForSentry(this.config.program));

      if (window['SENTRY_RELEASE']) {
        scope.setExtra('__sentry_release', window['SENTRY_RELEASE'].id);
      }
    });

    this.initUserData();
  }
}
