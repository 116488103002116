import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OnboardingCloseEvent } from 'src/app/constants/onboarding-types.const';
import { OnboardingCustomSettings } from 'src/app/store/session/actions/navigation.actions';

@Component({
  selector: 'cl-third-screen',
  styleUrls: ['third-screen.component.scss'],
  template: `
    <div class="upper-transparency"></div>
    <div class="onboarding-content">
      <img class="close-button" src="assets/imgs/close.png" (click)="onClose()"/>
      <p class="page-title blue-heading text-align-center">{{settings.header | translate}}</p>
      <p class="page-details blue-heading text-align-center lateral-padding">{{settings.body | translate}}</p>

      <p class="footer-link orange-heading" *ngIf="settings.link && !settings.button"
         (click)="onClose()">{{settings.link | translate}}</p>
      <p class="footer-link footer-double-link orange-heading" *ngIf="settings.link && settings.button"
         (click)="onClose()">{{settings.link | translate}}</p>

      <div class="button-wrapper" *ngIf="settings.button">
        <div class="center-button">
          <cl-action-button
            label="{{settings.button | translate}}"
            [canClick]="true"
            (action)="onClose()">
          </cl-action-button>
        </div>
      </div>
    </div>
  `
})
export class ThirdScreenComponent {

  @Input() settings: OnboardingCustomSettings;

  @Output() closeClick = new EventEmitter<OnboardingCloseEvent>();

  onClose() {
    this.closeClick.emit();

    if (this.settings.actAsConfirm && this.settings.callbackOnButton) {
      this.settings.callbackOnButton();
    }
  }
}
