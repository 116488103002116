import { Injectable } from '@angular/core';

import { AlertController } from '@ionic/angular';

import { LoadingService } from './loading.service';
import { LoggerService } from './logger.service';
import { TranslateService } from '@ngx-translate/core';
import { from as fromPromise } from 'rxjs';
import { take } from 'rxjs/operators';
import { ClarityConfig } from '../config/clarity.config';
import { SharecareSSOService } from './sharecare-sso.service';
import { Store } from '@ngrx/store';
import { State } from '../store';

@Injectable({providedIn: 'root'})
export class AlertsService {

  constructor(
    public alertController: AlertController,
    private logger: LoggerService,
    private loading: LoadingService,
    private translate: TranslateService,
    private sharecareSSO: SharecareSSOService,
    private config: ClarityConfig,
    public store: Store<State>
  ) {

  }

  managedBySharecare() {
    return fromPromise(this.translate.get(['sharecare_login.title', 'sharecare_login.message', 'common.continue', 'common.dismiss'])
      .toPromise()
      .then(async (translations) => {
        const alert = await this.alertController.create({
          header: translations['sharecare_login.title'],
          message: translations['sharecare_login.message'],
          buttons: [
            {
              text: translations['common.dismiss'],
              role: 'cancel'
            },
            {
              text: translations['common.continue'],
              handler: () => {
                this.sharecareSSO.initSharecareLogin();
              }
            }
          ]
        });

        await alert.present();
      }));
  }

  localStorageNotification() {
    return fromPromise(this.translate.get(['common.storage', 'errors.storage.not_found', 'common.dismiss'])
      .toPromise()
      .then(async (translations) => {
        const alert = await this.alertController.create({
          header: translations['common.storage'],
          subHeader: translations['errors.storage.not_found'],
          buttons: [translations['common.dismiss']]
        });

        await alert.present();
      }));
  }

  cookiesNotification() {
    return fromPromise(this.translate.get(['common.cookies', 'errors.cookies.blocked', 'common.dismiss'])
      .toPromise()
      .then(async (translations) => {
        const alert = await this.alertController.create({
          header: translations['common.cookies'],
          subHeader: translations['errors.cookies.blocked'],
          buttons: [translations['common.dismiss']]
        });

        await alert.present();
      }));
  }

  offlineNotification() {
    return fromPromise(this.translate.get(['common.offline', 'errors.offline.no_internet_connection', 'common.dismiss'])
      .toPromise()
      .then(async (translations) => {
        const alert = await this.alertController.create({
          header: translations['common.offline'],
          subHeader: translations['errors.offline.no_internet_connection'],
          buttons: [translations['common.dismiss']]
        });

        await alert.present();
      }));
  }

  genericError(error, logError = false, dismissLoading = true) {
    // TODO: For whatever reason this will throw a "Failed to fetch" error
    if (logError) {
      // this.logger.error('Generic error');
    }

    if (dismissLoading) {
      this.loading.hideLoadingOverlay();
    }

    return fromPromise(this.translate
      .get(['errors.common.unknown_error', 'errors.common.unknown_error_restart_current_week', 'common.dismiss'])
      .toPromise()
      .then(async (translations) => {
        const alert = await this.alertController.create({
          header: translations['errors.common.unknown_error'],
          subHeader: translations['errors.common.unknown_error_restart_current_week'],
          buttons: [translations['common.dismiss']]
        });

        await alert.present();
      }));
  }

  connectionError(error, logError = false, dismissLoading = true) {
    if (logError) {
      this.logger.error(error);
    }

    if (dismissLoading) {
      this.loading.hideLoadingOverlay();
    }

    return fromPromise(this.translate
      .get(['errors.common.error', 'errors.common.just_network_error', 'common.dismiss'])
      .toPromise()
      .then(async (translations) => {
        const alert = await this.alertController.create({
          header: translations['errors.common.error'],
          subHeader: translations['errors.common.just_network_error'],
          buttons: [translations['common.dismiss']]
        });

        await alert.present();
      }));
  }

  playerError(error, logError = false, dismissLoading = false) {
    if (logError) {
      this.logger.error(error.message, error, 'PlayerError');
    }

    if (dismissLoading) {
      this.loading.hideLoadingOverlay();
    }

    return fromPromise(this.translate
      .get(['errors.common.error', 'errors.common.player_error', 'common.dismiss'])
      .toPromise()
      .then(async (translations) => {
        const alert = await this.alertController.create({
          header: translations['errors.common.error'],
          subHeader: translations['errors.common.player_error'],
          buttons: [translations['common.dismiss']]
        });

        await alert.present();
      }));
  }

  sharecareError() {
    let messageTranslationKey = '';

    if (this.config.isUA()) {
      messageTranslationKey = 'errors.auth.sharecare_not_qualified_ua_error_message';
    } else if (this.config.isCTQ()) {
      messageTranslationKey = 'errors.auth.sharecare_not_qualified_ctq_error_message';
    } else if (this.config.isERN()) {
      messageTranslationKey = 'errors.auth.sharecare_not_qualified_ern_error_message';
    }

    return fromPromise(this.translate
      .get(['errors.auth.sharecare_account', messageTranslationKey, 'common.dismiss'])
      .toPromise()
      .then(async (translations) => {
        const alert = await this.alertController.create({
          header: translations['errors.auth.sharecare_account'],
          message: translations[messageTranslationKey],
          buttons: [translations['common.dismiss']]
        });

        // for whatever reason calling this earlier, doesn't work
        alert.onWillDismiss()
          .then(() => {
            this.loading.hideLoadingOverlay();
          });

        await alert.present();
      }));
  }

  customError(error, title, text, logError = false, dismissLoading = true) {
    if (logError) {
      this.logger.error(error);
    }

    if (dismissLoading) {
      this.loading.hideLoadingOverlay();
    }

    return fromPromise(
      this.translate.get([title, text, 'common.dismiss'])
        .pipe(take(1))
        .toPromise()
        .then(async (translations) => {
          const alert = await this.alertController.create({
            header: translations[title],
            subHeader: translations[text],
            buttons: [translations['common.dismiss']]
          });

          await alert.present();
        }));
  }
}
