// program reducer

import * as programActions from '../actions/program.actions';

export interface PlayState {
  week: number;
  module: number;
  lesson: number;
  exercise: number;
}

export interface ProgramState {
  initialized: boolean;
  introModule: boolean;

  play: PlayState;

  lastCompletedLesson: number;
  lessonCompleting: boolean;

  lastCompletedModule: number;
  moduleCompleting: boolean;

  activeUserWeekNumber: number;
}

export const initialProgramState: ProgramState = {
  initialized: false,
  introModule: false,

  play: {
    week: null,
    module: null,
    lesson: null,
    exercise: null
  },

  lastCompletedLesson: null,
  lastCompletedModule: null,

  lessonCompleting: false,
  moduleCompleting: false,

  activeUserWeekNumber: null
};

export function programReducer(state = initialProgramState, action: programActions.ProgramActions): ProgramState {
  switch (action.type) {
    case programActions.LOAD_LIVE_PROGRAM: {
      const liveProgram = action.payload;

      return {
        ...state,
        introModule: liveProgram && liveProgram.weeks[0] && liveProgram.weeks[0].modules[0].number === 0 ? true : false
      };
    }

    case programActions.PLAY_LESSON: {
      const weekNumber = action.payload.weekNumber;
      const moduleNumber = action.payload.moduleNumber;
      const lessonPosition = action.payload.position;

      return {
        ...state,

        play: {
          week: weekNumber,
          module: moduleNumber,
          lesson: lessonPosition,
          exercise: null
        }
      };
    }

    case programActions.PLAY_MODULE: {
      const module = action.payload;

      return {
        ...state,

        play: {
          week: module.weekNumber,
          module: module.number,
          lesson: null,
          exercise: null
        }
      };
    }

    case programActions.PLAY_BONUS_EXERCISE:
    case programActions.PLAY_EXERCISE: {
      const exerciseId = action.payload ? action.payload.id : null;

      return {
        ...state,

        play: {
          week: null,
          module: null,
          lesson: null,
          exercise: exerciseId
        }
      };
    }

    case programActions.SET_ACTIVE_USER_WEEK_NUMBER: {
      return {
        ...state,
        activeUserWeekNumber: action.payload
      };
    }

    // case programActions.COMPLETE_MODULE: {
    //   console.log('programAction - COMPLETE_MODULE');
    //
    //   return {
    //     ...state
    //   };
    // }
    //
    // case programActions.COMPLETE_LESSON: {
    //   console.log('programAction - COMPLETE_LESSON');
    //
    //   return {
    //     ...state,
    //   };
    // }
  }

  return state;
}

export const lessonCompleting = (state: ProgramState) => state.lessonCompleting;
export const lastCompletedLesson = (state: ProgramState) => state.lastCompletedLesson;
export const moduleCompleting = (state: ProgramState) => state.moduleCompleting;
export const lastCompletedModule = (state: ProgramState) => state.lastCompletedModule;
export const play = (state: ProgramState) => state.play;
export const introModule = (state: ProgramState) => state.introModule;
export const activeUserWeekNumber = (state: ProgramState) => state.activeUserWeekNumber;

// HELPERS

// OBSOLETE CODE - DEMO FOR GENERATING UserBootstraps
//
// export const RECORD_TYPE_PROGRAM = 'program';
// export const RECORD_TYPE_THEME = 'theme';
// export const RECORD_TYPE_CUSTOM = 'custom';
//
// export interface WeekState {
//   number: number;
//   type: string;
//   modules: ModuleState[];
// }
//
// export interface LiveWeekState extends WeekState {
//   modules: LiveModuleState[];
// }
//
// export interface ModuleState {
//   number: number;
//   weekNumber: number;
//   type: string;
//   lessons: LessonState[];
//   recordId: number;
//   // completedAt: string;
//   // isCompleted: boolean;
//   // isAvailable: boolean;
//   // isAccelerated: boolean;
//   // isCurrent: boolean;
//   // isLive: boolean;
// }
//
// export interface LiveModuleState extends ModuleState {
//   lessons: LiveLessonState[];
//   record: ProgramDay;
//   prevModule: ModuleState;
//   nextModule: ModuleState;
//   isFirst: boolean;
//   isLast: boolean;
//   allPrevDone: boolean;
// }
//
// export interface LessonState {
//   position: number;
//   weekNumber: number;
//   moduleNumber: number;
//   type: string;
//   recordId: number;
//   moduleRecordId: number;
//   // completedAt: string;
//   // isCompleted: boolean;
//   // isAvailable: boolean;
//   // isAccelerated: boolean;
//   // isCurrent: boolean;
//   // isLive: boolean;
// }
//
// export interface LiveLessonState extends LessonState {
//   record: ProgramDayExercise;
//   prevLesson: LessonState;
//   nextLesson: LessonState;
//   isFirst: boolean;
//   isLast: boolean;
//   allPrevDone: boolean;
// }
//
// export interface CurrentState {
//   week: number;
//   module: number;
//   lesson: number;
// }
//
// export interface ProgressState {
//   weeks: WeekState[];
// }
//
// export interface LiveProgressState {
//   weeks: LiveWeekState[];
// }
//
// const initWeek = function(initNumber = 1): WeekState {
//   return {
//     number: initNumber,
//     type: RECORD_TYPE_PROGRAM,
//     modules: []
//   };
// };
//
// const initModule = function(initNumber = 1, initType = RECORD_TYPE_PROGRAM): ModuleState {
//   return {
//     number: initNumber,
//     weekNumber: null,
//     type: initType,
//     lessons: [],
//     recordId: null,
//     // completedAt: null,
//     // isCompleted: false,
//     // isAvailable: false,
//     // isAccelerated: false,
//     // isCurrent: false,
//     // isLive: false
//   };
// };
//
// const initLesson = function(initPosition = 1, initType = RECORD_TYPE_PROGRAM): LessonState {
//   return {
//     position: initPosition,
//     weekNumber: null,
//     moduleNumber: null,
//     type: initType,
//     recordId: null,
//     moduleRecordId: null,
//     // completedAt: null,
//     // isCompleted: false,
//     // isAvailable: false,
//     // isAccelerated: false,
//     // isCurrent: false,
//     // isLive: false
//   };
// };
//
// const initProgressState = function(program): ProgressState {
//   const weeks = [];
//
//   let previousWeek = null;
//   let currentWeek = initWeek();
//
//   let previousModule = null;
//   let currentModule = initModule();
//
//   // build weeks structure
//   program.program_days.forEach((day) => {
//
//     // start a new week after current one is full
//     if (currentWeek.modules.length >= 7) {
//       // introduction modules should be include in first week resulting in 8 modules
//       if (currentWeek.number === 1 && currentWeek.modules[0].number === 0 && currentWeek.modules.length >= 8) {
//         weeks.push({...currentWeek});
//         previousWeek = {...currentWeek};
//         currentWeek = initWeek(currentWeek.number + 1);
//       }
//       else if ((
//    (currentWeek.number === 1 && currentWeek.modules[0].number !== 0) || currentWeek.number > 1) && currentWeek.modules.length >= 7) {
//         weeks.push({...currentWeek});
//         previousWeek = {...currentWeek};
//         currentWeek = initWeek(currentWeek.number + 1);
//       }
//     }
//
//     // init currentModule
//     currentModule.number = day.number;
//     currentModule.recordId = day.id;
//
//     // Add week number to module
//     currentModule.weekNumber = currentWeek.number;
//
//     // // set available state
//     // if (currentModule.number === 1 || (!previousModule && currentModule.number ===0 )) {
//     //   currentModule.isAvailable = true;
//     //   currentModule.isCurrent = true;
//     // }
//
//     let previousLesson = null;
//     let currentLesson = initLesson();
//
//     // go through lessons and push them to currentModule
//     day.program_daily_exercises.forEach((daily_exercise) => {
//
//       // init currentLesson
//       currentLesson.position = daily_exercise.position;
//       currentLesson.recordId = daily_exercise.id;
//       currentLesson.moduleRecordId = daily_exercise.program_day_id;
//
//       // Add week and module to lesson
//       currentLesson.weekNumber = currentModule.weekNumber;
//       currentLesson.moduleNumber = currentModule.number;
//
//       // // set available state
//       // if (currentModule.isAvailable && currentLesson.position === 1) {
//       //   currentLesson.isAvailable = true;
//       //   currentLesson.isCurrent = true;
//       // }
//
//       currentModule.lessons.push(currentLesson);
//
//       previousLesson = currentLesson;
//       currentLesson = initLesson();
//     });
//
//     currentWeek.modules.push(currentModule);
//
//     previousModule = currentModule;
//     currentModule = initModule();
//   });
//
//   // push remaining week in case it's not a full week
//   if (currentWeek.modules.length > 0) {
//     weeks.push({...currentWeek});
//   }
//
//   return {
//     weeks: weeks
//   };
// };
//
// function completeLesson(weeks, completedLesson) {
//   weeks.forEach((week, weekIdx) => {
//     week.modules.forEach((module, moduleIdx) => {
//       module.lessons.forEach((lesson, lessonIdx) => {
//         if (lesson.programDayExerciseId === completedLesson.programDayExerciseId) {
//           const now = new Date();
//           weeks[weekIdx].modules[moduleIdx].lessons[lessonIdx].isCompleted = true;
//           weeks[weekIdx].modules[moduleIdx].lessons[lessonIdx].completedAt = now.toISOString();
//         }
//       });
//     });
//   });
//
//   return weeks;
// }
//
// function findCurrent(liveProgram): CurrentState {
//   const current = {
//     week: null,
//     module: null,
//     lesson: null
//   };
//
//   for (let i = 0; i < liveProgram.weeks.length; i++) {
//     const currentModule = liveProgram.weeks[i].modules.find((module) => module.isCurrent);
//
//     if (currentModule) {
//       const currentLesson = currentModule.lessons.find((lesson) => lesson.isCurrent);
//
//       current.week = currentModule.weekNumber;
//       current.module = currentModule.number;
//       current.lesson = currentLesson.position;
//
//       break;
//     }
//   }
//
//   return current;
// }
