import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { OnboardingCloseEvent } from 'src/app/constants/onboarding-types.const';
import { OnboardingCustomSettings } from '../../../store/session/actions/navigation.actions';
import { State } from '../../../store/state.reducer';

@Component({
  selector: 'cl-half-screen',
  styleUrls: ['half-screen.component.scss'],
  template: `
    <div class="upper-transparency"></div>

    <div class="onboarding-content">
      <div class="header">
        <ion-row *ngIf="!settings.hideCloseIcon" class="first-row">
          <img class="close-button" src="assets/imgs/close.png" (click)="onClose()"/>
        </ion-row>

        <div class="margin-top-minus-20">
          <p class="page-title blue-heading text-align-center full-width">{{settings.header | translate}}</p>
        </div>
      </div>

      <div class="third-row">
        <p
          class="page-details blue-heading text-align-center full-width vertical-align-center lateral-padding"
          [innerHTML]="settings.body | translate">
        </p>
      </div>

      <div class="fourth-row">
        <ion-col *ngIf="settings.button && settings.actAsConfirm">
          <div class="flex-container" *ngIf="settings.link">
            <div class="half">
              <cl-action-button
                label="{{settings.link | translate}}"
                [canClick]="true"
                [square]="true"
                [whiteBg]="true"
                (action)="onLinkClick()">
              </cl-action-button>
            </div>
            <div class="half">
              <cl-action-button
                label="{{settings.button | translate}}"
                [canClick]="true"
                [square]="true"
                (action)="onButtonClick()">
              </cl-action-button>
            </div>
          </div>

          <ion-row *ngIf="!settings.link">
            <ion-col class="ion-text-center">
              <cl-action-button
                label="{{settings.button | translate}}"
                [canClick]="true"
                (action)="onButtonClick()">
              </cl-action-button>
            </ion-col>
          </ion-row>
        </ion-col>

        <p *ngIf="settings.link && !settings.button"
           class="footer-link orange-heading"
           (click)="onClose()">
          {{settings.link | translate}}
        </p>

        <p *ngIf="settings.link && settings.button && !settings.actAsConfirm"
           class="footer-link footer-double-link orange-heading"
           (click)="onClose()">
          {{settings.link | translate}}
        </p>

        <div class="button-wrapper" *ngIf="settings.button && !settings.actAsConfirm">
          <div class="center-button">
            <cl-action-button
              label="{{settings.button | translate}}"
              [canClick]="true"
              (action)="onClose()">
            </cl-action-button>
          </div>
        </div>
      </div>
    </div>
  `
})
export class HalfScreenComponent {

  @Input() settings: OnboardingCustomSettings;

  @Output() closeClick = new EventEmitter<OnboardingCloseEvent>();

  constructor(
    public store: Store<State>
  ) {

  }

  onClose(onboardingCloseEvent?: OnboardingCloseEvent) {
    this.closeClick.emit(onboardingCloseEvent);
  }

  onLinkClick() {
    this.onClose({ isComplete: !Boolean(this.settings.dontCompleteOnLinkClick) });

    if (this.settings.actAsConfirm && this.settings.actionOnLink) {
      this.store.dispatch(this.settings.actionOnLink);
    }
  }

  onButtonClick() {
    this.onClose({ isComplete: !Boolean(this.settings.dontCompleteOnButtonClick) });

    if (this.settings.actAsConfirm && this.settings.callbackOnButton) {
      this.settings.callbackOnButton();
    }

    if (this.settings.actAsConfirm && this.settings.actionOnButton) {
      this.store.dispatch(this.settings.actionOnButton);
    }
  }
}
