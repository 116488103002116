export const sanitizeAuthorizeToken = (token) => {
  const match = token.match(/^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/);

  if (!match) {
    return false;
  }

  return match[0];
};

export const sanitizePreauthToken = (token) => {
  const match = token.match(/^[A-Za-z0-9-]*$/);

  if (!match) {
    return false;
  }

  return match[0];
};

export const sanitizeOfferingCode = (offeringCode) => {
  // accepted chars letters, _ and -
  const match = offeringCode.match(/^[a-zA-Z_-]*$/);

  if (!match) {
    return false;
  }

  return match[0];
};

export const sanitizeUrl = (url) => url.replace(/[^-A-Za-z0-9+&@#/%?=~_|!:,.;\(\)]/g, '');

export const getJsonFromUrl = (url) => {
  if (!url) url = location.search;

  const query = url.substr(1);
  const result = {};

  query.split('&')
    .forEach((part) => {
      const item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });

  return result;
};
