import { Action, ActionReducer, MetaReducer } from '@ngrx/store';
import { AddChildData, AddData, RemoveData, SetData } from 'ngrx-normalizr';

import { offlineStore, OfflineSyncAction, OFFLINE_SYNC_ACTION } from '../offline/offline.store';
import {
  CLOSE_ALL_MODALS,
  CLOSE_MODAL,
  CLOSE_PLAYER,
  OPEN_MODAL,
  OPEN_PLAYER,
  OPEN_WIZARD,
  SHOW_ONBOARDING
} from '../session/actions/navigation.actions';
import { PLAY_LESSON, PLAY_MODULE } from '../session/actions/program.actions';
import { NgZone } from '@angular/core';
import { FullState } from '../state.reducer';

const SYNC_QUEUE_DELAY_SECONDS = 3;

let syncQueue: FullState = {} as FullState;
let syncTimeoutRef = null;

const ignoreActions = [];

ignoreActions.push(OFFLINE_SYNC_ACTION);
ignoreActions.push('@ngrx/store/init');
ignoreActions.push('@ngrx/effects/init');
ignoreActions.push('@ngrx/store/update-reducers');

// navigation actions
ignoreActions.push(CLOSE_ALL_MODALS);
ignoreActions.push(OPEN_MODAL);
ignoreActions.push(CLOSE_MODAL);
ignoreActions.push(OPEN_WIZARD);

ignoreActions.push(PLAY_LESSON);
ignoreActions.push(PLAY_MODULE);
ignoreActions.push(OPEN_PLAYER);
ignoreActions.push(CLOSE_PLAYER);

ignoreActions.push(SHOW_ONBOARDING);

export function offlineReducerFactory(zone: NgZone): MetaReducer<any> {
  return (reducer: ActionReducer<FullState, Action>): ActionReducer<FullState, Action> => {

    const hydrationStatusState: any = {};

    return function newReducer(state: FullState, action: Action) {
      // rehydrate state from offline store
      if (action.type === OFFLINE_SYNC_ACTION) {
        console.log('offline sync complete. Setting hydration to true');

        let hydratedState = (action as OfflineSyncAction).payload;
        hydrationStatusState['hydrated'] = true;

        // this code existes to ensure authenticating would be false during app launch
        // this was added at 2020 and is probably not needed anymore,
        // since the state life-cycle is more robust now. But we keep it just for peace of mind
        if (hydratedState?.sensitive?.auth) {
          hydratedState = {
            ...hydratedState,
            sensitive: {
              ...hydratedState.sensitive,
              auth: {
                ...hydratedState.sensitive.auth,
                error: null,
                authenticating: false
              }
            }
          };
        }

        state = Object.assign({}, state, action['payload'], hydratedState);
      }

      // run reducer and update hydration status
      const nextState: FullState = Object.assign({}, reducer(state, action), hydrationStatusState);

      // sync offline store without ignored actions
      if (ignoreActions.indexOf(action.type) === -1) {
        // re-sync offline immediatelly when normalized records are updated
        if (action instanceof AddData || action instanceof AddChildData || action instanceof SetData) {
          checkForAllowedSync(nextState);

          zone.runOutsideAngular(() => {
            Object
              .keys(action.payload.entities)
              .forEach((key) => offlineStore.storeNormalizedData(key, nextState.normalized.entities[key]));

            // for nested data, we need to also update the parent schema in the store,
            // otherwise it'll cause an inconsistent state if app is closed and opened again
            // Although this solution has two problems:
            // - if the nested data is more than 2 levels, this solution won't work
            // - the ideal solution would be to grab the entire state, find differences between in-memory & store,
            //   and update these keys. In the future we can search for a third-party solution since it's a complex topic
            if (action instanceof AddChildData) {
              checkForAllowedSync(nextState);

              const parentKey = action.payload.parentSchemaKey;
              offlineStore.storeNormalizedData(parentKey, nextState.normalized.entities[parentKey]);
            }
          });
        }
        // re-sync offline immediately when normalized records are removed
        else if (action instanceof RemoveData) {
          zone.runOutsideAngular(() => {
            checkForAllowedSync(nextState);

            offlineStore.storeNormalizedData(action.payload.key, nextState.normalized.entities[action.payload.key]);

            if (action.payload.removeChildren) {
              Object.keys(action.payload.removeChildren)
                .forEach((key) => offlineStore.storeNormalizedData(key, nextState.normalized.entities[key]));
            }
          });
        }
        // queue all other requests to debounce when state gets updated too often
        else {
          queueStateToSyncOffline(nextState);

          scheduleStateSynchronization();
        }
      }

      return nextState;
    };
  };

  function checkForAllowedSync(state: FullState, logError = true) {
    // sync to offline storage is allowed only if hydrated is set to true
    if (!state.hydrated && logError) {
      console.error('WARNING! Running sync to offline without hydration. Might be a race condition, please debug!', state);
    }

    return Boolean(state.hydrated);
  }

  // grabs the computed state from Ngrx, and set to a global object `syncQueue`
  // to later be stored at the device store
  function queueStateToSyncOffline(state: FullState) {
    Object.keys(state)
      .forEach((key) => {
        switch (key) {
          // ignore normalized
          case 'normalized':
            return false;

            // queue what needs to be synced
          default:
            syncQueue[key] = state[key];
        }
      });
  }

  function scheduleStateSynchronization() {
    // clear previously scheduled sync
    clearTimeout(syncTimeoutRef);

    // schedule sync [again]
    zone.runOutsideAngular(() => {
      syncTimeoutRef = setTimeout(() => {
        if (!checkForAllowedSync(syncQueue, false)) {
          console.warn('trying to sync offline with state not hydrated. Delaying it...');

          return setTimeout(() => scheduleStateSynchronization(), 250);
        }

        const tempQueue = {...syncQueue};

        // flush queue
        syncQueue = {} as FullState;

        // sync queued data
        console.log('will store data to offline storage');
        Object.keys(tempQueue)
          .filter(key => key !== 'hydrated') // ignore hydration keys
          .forEach((key) => {
            offlineStore.storeData(key, tempQueue[key]);
          });

        // clear the timeout
        syncTimeoutRef = null;
      }, 1000 * SYNC_QUEUE_DELAY_SECONDS);
    });
  }
}
