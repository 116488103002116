import { Injectable } from '@angular/core';
import { HttpProvider } from './http/http.provider';
import { UserProfile } from '../store/normalized/schemas/user.schema';
import moment from 'moment-timezone';

@Injectable({providedIn: 'root'})
export class UserProfileProvider {

  private profileEndpoint = '{community}/profile';

  constructor(
    private http: HttpProvider
  ) {}

  updateUserProfile(userProfile: UserProfile) {
    return this.http.patch(this.profileEndpoint, {
      avatar : {
        data : userProfile.avatar
      },
      username: userProfile.username,
      agree_to_tos: true,
      quit_date: userProfile.quit_date ?
        moment(userProfile.quit_date)
          .format('YYYY-MM-DD') : null
    });
  }

  getUserProfile() {
    return this.http.get<UserProfile>(this.profileEndpoint);
  }

}
