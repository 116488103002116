import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { SessionState } from 'http2';
import { ClarityConfig } from 'src/app/config/clarity.config';
import { BrowserService } from 'src/app/services/browser.service';
import { CloseAllModals } from 'src/app/store/session/actions/navigation.actions';

@Component({
  selector: 'cl-temporary-malfunction',
  styleUrls: ['temporary-malfunction.component.scss'],
  template: `
    <cl-waves-background intro-bg>
      <cl-close-button (closeClick)="onClose()"></cl-close-button>

      <div class="container">
        <p>Ooops! Something went wrong with Google Play and we cannot complete your subscription.</p>

        <cl-shadow-wrapper>
          <p>Please check the following options:</p>

          <ol>
            <li>
              Use the web version of the app to subscribe using your credit card by using the link below:
              <br />
              <a href="https://{{config.webappHost}}/#/tabs/account/subscription">{{ 'https://' + config.webappHost }}</a>
            </li>
            <li>
              Check the article or Submit a Request to our support team in the Help Center
              <cl-action-button
                (action)="openHelpCenter()"
                class="open-help"
                [label]="'account_menu.help.open_help_center' | translate"
                [canClick]="true"
              ></cl-action-button>
            </li>
          </ol>
        </cl-shadow-wrapper>
      </div>
    </cl-waves-background>
  `
})
export class TemporaryMalfunctionPage {

  constructor(
    private store: Store<SessionState>,
    private browser: BrowserService,
    public config: ClarityConfig
  ) {

  }

  openHelpCenter() {
    this.browser.goTo(this.config.env.helpCenterUrl);
  }

  onClose() {
    this.store.dispatch(new CloseAllModals());
  }
}
