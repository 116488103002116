import { Injectable, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { ClarityConfig } from 'src/app/config/clarity.config';
import { LoggerService } from 'src/app/services/logger.service';
import { UserActivitiesProvider } from 'src/app/providers/user-activities.provider';

import * as fromNormalizedCore from 'src/app/store';
import { getCurrentUser, getCurrentUserProgram } from 'src/app/store/normalized/selectors/user.selectors';
import { isFullSynced } from 'src/app/store/session/selectors/sync.selectors';
import { EventsService } from 'src/app/services/events.service';
import { ThemeWeek } from 'src/app/store/normalized/schemas/theme-week.schema';
import { LiveWeek } from 'src/app/store/session/selectors/program.selectors';
import { MarketingAnalyticsInterface, UserAnalyticsInterface } from './analytics.interface';
import { FacebookService } from './adapters/facebook.service';
import { IridiumService } from './adapters/iridium.service';
import { KochavaService } from './adapters/kochava.service';
import { MixpanelService } from './adapters/mixpanel.service';
import { AnalyticsEvents, SubscriptionEvents } from './analytics.events';

@Injectable({providedIn: 'root'})
export class AnalyticsService implements OnDestroy {

  currentUser$ = this.store.select(getCurrentUser);

  private subscriptions: Subscription[] = [];

  private allServices = ['iridium', 'mixpanel', 'kochava', 'facebook'];

  private enabledMarketingServices: MarketingAnalyticsInterface[] = []; // iridium, facebook, kochava
  private enabledAnalyticsServices: UserAnalyticsInterface[] = []; // iridium, mixpanel

  constructor(
    public config: ClarityConfig,
    public logger: LoggerService,
    private store: Store<fromNormalizedCore.State>,
    private events: EventsService,
    private userActivitiesProvider: UserActivitiesProvider, // @todo remove
    private iridiumService: IridiumService,
    private facebookService: FacebookService,
    private kochavaService: KochavaService,
    private mixpanelService: MixpanelService
  ) {
  }

  public initialize(): Promise<any> {
    this.events.subscribe(this.config.events.logout, () => this.resetService());

    const promises = [];

    if (this.config.iridiumAllowed()) {
      // iridium is both user analytics, and marketing analytics service
      promises.push(
        this.iridiumService.initialize()
          .then(() => this.enabledMarketingServices.push(this.iridiumService))
          .then(() => this.enabledAnalyticsServices.push(this.iridiumService))
      );
    }

    promises.push(
      this.facebookService.initialize()
        .then(() => this.enabledMarketingServices.push(this.facebookService)),
      this.kochavaService.initialize()
        .then(() => this.enabledMarketingServices.push(this.kochavaService))
    );

    if (this.config.env.mixpanel.enabled) {
      promises.push(this.mixpanelService.initialize()
        .then(() => this.enabledAnalyticsServices.push(this.mixpanelService)));
    }

    return Promise.all(promises);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public resetService() {
    return Promise.all([
      ...this.enabledAnalyticsServices.map((service) => service.resetService()),
      ...this.enabledMarketingServices.map((service) => service.resetService())
    ]);
  }

  // @todo refactor flow of this method,
  // because this is being called in a lot of different places,
  // at every app launch, and repeatidely (2x or 3x) at login/signup
  // so registerUser() is a misleading name here
  public registerUser() {
    return this.loadUserData()
      .then((data) => {
        this.logger.debug('Registering analytics user');

        // we can prevent any service from being used from the backend
        const excluded_tracking_services = data.userProgram.exclude_tracking || '';

        // make sure we exclude tracking accordingly
        this.allServices.forEach((serviceName) => {
          if (excluded_tracking_services.match(new RegExp(serviceName))) {
            this.disableService(serviceName);
          }
        });

        return Promise.all([
          ...this.enabledAnalyticsServices.map((service) => service.registerUser(data)),
          ...this.enabledMarketingServices.map((service) => service.registerUser(data))
        ]);
      });
  }

  private disableService(serviceNameToDisable: string) {
    const marketingIndex = this.enabledMarketingServices.findIndex((service) => service.SERVICE_NAME === serviceNameToDisable);

    if (marketingIndex > -1) {
      this.enabledMarketingServices[marketingIndex].resetService();
      this.enabledMarketingServices.splice(marketingIndex, 1);
    }

    const analyticsIndex = this.enabledAnalyticsServices.findIndex((service) => service.SERVICE_NAME === serviceNameToDisable);

    if (analyticsIndex > -1) {
      this.enabledAnalyticsServices[analyticsIndex].resetService();
      this.enabledAnalyticsServices.splice(analyticsIndex, 1);
    }
  }

  public userLoggedIn() {
    return this.registerUser()
      .then(() =>
        this.trackEvent(AnalyticsEvents.UserLogin)
      );
  }

  public userSignedUp() {
    return this.registerUser()
      // @todo refactor registerUser() flow and remove the kochava call here
      .then(() => this.loadUserData())
      .then((user) => this.kochavaService.registrationComplete(user))

      .then(() =>
        this.trackEvent(AnalyticsEvents.UserSignup)
      );
  }

  public userLoggedOut() {
    return this.trackEvent(AnalyticsEvents.UserLogout);
  }

  public async updateUser() {
    const userData = await this.loadUserData();

    return Promise.all(
      this.enabledAnalyticsServices.map((service) => service.updateUser(userData))
    );
  }

  public trackSubscription(progressType: SubscriptionEvents, subscription: any = {}, product: any = {}) {
    return Promise.all([
      ...this.enabledMarketingServices.map((service) => service.trackSubscription(progressType, subscription, product)),
      this.trackEvent(progressType, { subscription, product })
    ]);
  }

  // @todo remove and rename all trackProgram to trackEvent
  public trackProgram(activityType, data = {}) {
    return Promise.all(
      this.enabledAnalyticsServices.map((service) => service.trackEvent(activityType, data))
    );
  }

  public trackProgramUpdate(name: string, data = {}) {
    const activityData = {
      kind: 'program_update',
      activity_at: new Date(),
      name,
      data
    };

    return this.userActivitiesProvider.trackActivity(activityData);
  }

  public trackActivity(eventName: string, data = {}) {
    const activityData = {
      kind: 'complete',
      activity_at: new Date(),
      name: eventName,
      data
    };

    return this.userActivitiesProvider.trackActivity(activityData)
      .pipe(take(1))
      .toPromise();
  }

  public trackEvent(eventType: AnalyticsEvents | SubscriptionEvents, eventData = {}) {
    return Promise.all(
      this.enabledAnalyticsServices.map((service) => service.trackEvent(eventType, eventData))
    );
  }

  // @todo remove utility function from this service
  public trackThemeWeekStart(week: ThemeWeek) {
    this.trackProgram('program-extended');
    this.trackProgram(`theme-week-started-${week.id}`, {
      name: week.title
    });
    this.trackProgramUpdate('Theme Week Started');
  }

  // @todo remove utility function from this service
  public trackThemeWeekComplete(week: LiveWeek) {
    this.trackProgram(`theme-week-completed-${week.theme_week_id}`);
    this.trackProgramUpdate('Theme Week Completed');
  }


  // @todo remove utility function from this service
  public trackCustomWeekComplete() {
    this.trackProgram('custom-week-completed');
    this.trackProgramUpdate('Custom Week Completed');
  }


  // @todo remove utility function from this service
  public trackProgramRestart() {
    return Promise.all([
      this.trackProgram('program-restarted'),
      this.trackProgramUpdate('Program Restarted')
    ]);
  }

  private loadUserData() {
    return this.store.select(isFullSynced)
      .pipe(
        filter((synced) => synced),
        switchMap(() => this.currentUser$
          .pipe(
            withLatestFrom(this.store.select(getCurrentUserProgram)),
            map(([user, userProgram]) => ({
              user,
              userProgram
            })),
            filter((data) => !!data.user.id)
          )),
        take(1)
      )
      .toPromise();
  }
}
