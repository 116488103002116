import { Component, EventEmitter, Input, Output, OnDestroy, HostBinding } from '@angular/core';
import { LiveBonusExercise, getCurrentModule } from '../../store/session/selectors/program.selectors';
import { Store } from '@ngrx/store';
import * as programActions from '../../store/session/actions/program.actions';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import { take, withLatestFrom } from 'rxjs/operators';
import { ClarityConfig } from '../../config/clarity.config';
import { Exercise } from '../../store/normalized/schemas/exercise.schema';
import { getAcceleratedTo } from '../../store/normalized/selectors/user-bootstrap.selectors';
import { SessionState } from '../../store/session/session.reducers';
import { Subscription } from 'rxjs';
import { scaleUpDown } from 'src/app/utils/animations';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'exercises-group',
  styleUrls: ['exercises-group.component.scss'],
  animations: [scaleUpDown],
  template: `
    <div class="exercises-block" [class.wizard]="isWizard || isLive"
      [@state]="visibility" (@state.done)="animationDone($event)">
      <p class="exercises-block-title" *ngIf="title">{{title}}</p>
      <div class="exercises-list-container">
        <ng-container *ngIf="!isDesktop; else twoColumnsTemplate">
          <exercise-item
            *ngFor="let exercise of _exercises" [exerciseItem]="exercise"
            [isWizard]="isWizard"
            [isLive]="isLive"
            (click)="openExercise(exercise)">
          </exercise-item>
        </ng-container>

        <ng-template #twoColumnsTemplate>
          <div class="flex-wrapper">
            <div class="flex-column" [class.expand]="!_exercisesColumnTwo ||( _exercisesColumnTwo && !_exercisesColumnTwo.length)">
              <exercise-item
                *ngFor="let exercise of _exercisesColumnOne" [exerciseItem]="exercise"
                [isWizard]="isWizard"
                (click)="openExercise(exercise)">
              </exercise-item>
            </div>
            <div class="flex-column" *ngIf="_exercisesColumnTwo && _exercisesColumnTwo.length">
              <exercise-item
                *ngFor="let exercise of _exercisesColumnTwo" [exerciseItem]="exercise"
                [isWizard]="isWizard"
                (click)="openExercise(exercise)">
              </exercise-item>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  `
})

export class ExercisesGroupComponent implements OnDestroy {
  @HostBinding('class.desktop') isDesktop = this.config.isWebApp;

  _exercises: LiveBonusExercise[] | Exercise[];
  @Input()
  set exercises(value) {
    this._exercises = value;
    if (this._exercises && this.isDesktop) {
      this.splitTwoColumns();
    }
  }

  @Input() title: string;
  @Input() isWizard: boolean;
  @Input() isLive: boolean;
  @Input() noLateralPadding: boolean;

  exercisesSubscription: Subscription;

  @Output() playExercise = new EventEmitter();
  @Output() endAnimation = new EventEmitter();
  visibility = 'visible';

  _exercisesColumnOne: LiveBonusExercise[] | Exercise[];
  _exercisesColumnTwo: LiveBonusExercise[] | Exercise[];

  constructor(
    private config: ClarityConfig,
    private store: Store<SessionState>
  ) {
  }

  private splitTwoColumns() {
    if (!this._exercises || !this._exercises.length) {
      return;
    }

    this._exercisesColumnOne = this._exercises.slice(0, Math.ceil(this._exercises.length / 2));
    this._exercisesColumnTwo = this._exercises.slice(Math.ceil(this._exercises.length / 2), this._exercises.length);
  }

  createSelector(selector) {
    this.exercisesSubscription = this.store.select(selector)
      .subscribe((result: LiveBonusExercise[] | Exercise[]) => {
        this._exercises = result;

        if (this._exercises && this.isDesktop) {
          this.splitTwoColumns();
        }
      });
  }

  openExercise(exercise): any {
    // Breathe exercise
    // @todo remove this logic and use the new PlayBreatheExercise instead
    if (exercise.exercise && exercise.exercise.tags.find((tag) => tag.name === 'breathe_into_anxiety')) {
      // UA
      if (this.config.program.programCode === 'ua') {
        return this.store.select(getCurrentModule)
          .pipe(withLatestFrom(this.store.select(getAcceleratedTo)), take(1))
          .subscribe(([currentModule, acceleratedTo]) => {
            if ((currentModule.number === 2 && currentModule.isCompleted)
              || currentModule.number > 2 || acceleratedTo >= 2) {

              return this.store.dispatch(new navigationActions.ShowBreatheExercise());
            } else {
              return this.store.dispatch(new navigationActions.ShowInterstitial({
                page: 'InterstitialPage', params: {
                  type: 'locked',
                  subTitle: 'interstitials.breatheexercise_locked_module_2'
                }
              }));
            }
          });
      }
      // ERN & CTQ
      else if (this.config.program.programCode === 'ern' || this.config.program.programCode === 'ctq') {
        return this.store.dispatch(new navigationActions.ShowBreatheExercise());
      }
    }
    // other exercises
    else {
      this.isWizard
        ? this.playExercise.emit(exercise)
        : this.store.dispatch(new programActions.PlayBonusExercise(exercise));
    }
  }

  animationDone(event) {
    if (event.toState === 'hidden') {
      this.endAnimation.emit(true);
    }
  }

  closeComponent() {
    this.visibility = 'hidden';
  }

  ngOnDestroy() {
    this.exercisesSubscription && this.exercisesSubscription.unsubscribe();
  }
}
