import { schema } from 'normalizr';
import { SmokingType } from './user.schema';

export class CravingTool {
  id?: number;
  date: string;
  craving_type: string;
  satisfaction_before_eating: number;
  satisfaction_after_eating: number;
  eat_amount: number;
  satisfaction_after_5mins?: number;
  satisfaction_after_20mins?: number;
  recording_initial_at: string;
  recording_5mins_at?: string;
  recording_20mins_at?: string;
  eat_mindfully: boolean;
  completed: boolean;
}

export class SmokeCravingTool {
  id?: number;
  date: string;
  recording_initial_at: string;
  satisfaction_before_smoking: number;
  satisfaction_after_smoking: number;
  skipped: boolean;
  smoke_exercise: boolean;
  rain_exercise: boolean;
  completed: boolean;
  smokingType?: SmokingType;
}

export const cravingToolSchema = new schema.Entity(
  'cravingTool',
  {},
  {idAttribute: () => 'current'}
);

export const smokeCravingToolSchema = new schema.Entity(
  'smokeCravingTool',
  {},
  {idAttribute: () => 'current'}
);
