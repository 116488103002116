// AUTO-GENERATED -- DO NOT MODIFY -- source is found in /config/app.config.ts
/* tslint:disable */

//noinspection JSUnusedGlobalSymbols

export const config = {
  common: {
    releaseId: '1657032607847' || null,
    forceDebugging: false, // log debugger messages no matter the env - automatically on for development
    remoteDebugging: false, // use with caution, will send any logger.debug() to Sentry and co
    protocol: 'https:',
    vapingReleaseDate: '2020-07-14',
    sentry: {
      enabled: true,
      dsn: 'https://fb16085182f24cf2beb3673adb01a700@sentry.io/1530800'
    },
    mixpanel: {
      enabled: true,
      token: '6a7435e77679f40394a470fcb06e8a8f'
    },
    firebase: {
      enabled: false
    },
    brightcove: {
      accountId: '6093793384001',
      playerId: '6eO1k7VXK',
      policyKey: 'BCpkADawqM3VwKujInOHAoc0va-Uc682TA7VITTTsQQ1oaCr9uRV7Nixe0Hw_SNcVTtVN4VlQJlyBnrJbV-B5hEV9Ffuv9oYN8Y0pSETIWAwDuZAKEwI6WuoKwMz6bzRCdOmc46f8SIwYPJT'
    },
    jwplayer: {
      playerId: 'KFMseWJE'
    },
    device: {
      device_available: false,
      device_cordova: 'unknown',
      device_isVirtual: false,
      device_manufacturer: 'unknown',
      device_model: 'unknown',
      device_platform: 'unknown',
      device_serial: 'unknown',
      device_uuid: null,
      device_version: 'unknown'
    },

    // urls
    companyWebsite: 'drjud.com',
    companyWebsiteUrl: 'https://drjud.com',
    privacyTermsUri: '/terms',
    helpCenterUrl: 'https://claritasmindsciences.zendesk.com/hc/',

    // help center articles
    howToResetPasswordArticle: 'en-us/articles/4412704404371-How-can-I-reset-my-password-',

    // subscriptions
    linkToItunesSubscriptions: 'itmss://buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/manageSubscriptions',

    // animations
    pushPageTransition: {
      animate: true,
      animation: 'md-transition',
      direction: 'forward'
    },
    popPageTransition: {
      animate: true,
      animation: 'md-transition',
      direction: 'back'
    },
    openPlayerTransition: {
      animate: true,
      animation: 'ios-transition',
      direction: 'forward',
      duration: 200
    },
    closePlayerTransition: {
      animate: true,
      animation: 'ios-transition',
      direction: 'back',
      duration: 200
    },
    wizardTransition: {
      enterAnimation: 'modal-md-slide-in',
      leaveAnimation: 'modal-md-slide-out'
    },
    onboardingTransition: {
      enterAnimation: 'modal-md-slide-in',
      leaveAnimation: 'modal-md-slide-out'
    },

    // to be generated by program
    programCode: null,
    name: null,
    gaTrackingId: null,
    totalModules: null,
    trialModules: null,
    appUrl: null,
    webUrl: null,
    communityAuthEndpoint: null,

    // app hosts and params used by the config service to generate all hosts
    appDomain: null, // will be set for each program and overriden by envinronment if available
    apiHostname: null, // will be generate for each environment
    webappHostname: null, // will be set for each environment
    sharecareOauthUrl: null, // wil be set for each environment

    // Sharecare SSO
    sharecareClientId: 'ms-cravingtoquit',
    sharecareInitPath: '/init_sharecare', // will be set for each environment
    authorizePath: '/authorize',
    preauthPath: '/preauth',
    ssoErrorPath: '/sso_error',

    // Device Integrations
    fitbitCallbackDevicesPath: '/fitbit_callback_devices',
    fitbitCallbackWeightPath: '/fitbit_callback_weight',

    // to be generated by env
    apiEndpointPrefix: '/api/v5',
    webSocketEndpoint: '/cable',
    environment: null,
    apiHost: null,
    apiUseSsl: null,
    useIridium: null,
    iridiumApiHost: null,
    iridiumApiEndpointPrefix: null,
    iridiumApiUseSsl: null,

    // community related
    communityApiHost: null,
    communityApiEndpointPrefix: null,
    communityCableEndpoint: null,

    // deprecated
    apiUrl: null,
    webSocketProtocol: 'ws',
    webSocketHost: null,

    // Don't change, use config.xml instead
    app: {
      app_version: '5.10.4',
      app_build: 128427,
      package_name: undefined
    }
  },

  // Namespaces
  events: {
    connection: 'clarity.connection',
    logout: 'clarity.logout',
    unauthorized: 'clarity.unauthorized'
  },

  program: {

    programCode: 'ua',
    dppwlProgramCode: null,
    name: 'Unwinding Anxiety',
    shortName: 'Unwind',
    languages: ['en', 'es'],
    totalModules: 30,
    trialModules: 4,
    internalServerHost: 'localhost',
    internalServerPort: 8282,
    appUrl: 'https://app.unwindinganxiety.com',
    dppwlUrl: null,
    webUrl: 'https://www.unwindinganxiety.com',
    appStoreId: '1247855111',
    googlePlayId: 'com.claritasmindsciences.UnwindingAnxiety',
    appStoreUrl: 'https://itunes.apple.com/us/app/unwinding-anxiety/id1247855111?ls=1&mt=8',
    googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.claritasmindsciences.UnwindingAnxiety',
    communityAuthEndpoint: '/programs/UA/auth/token',
    communityViaLaptopTopicUri: '/t/how-do-i-access-my-account-on-my-computer-vs-app/3450',
    supportEmail: 'support@unwindinganxiety.com',
    avatar: 'user-checkmark-nobg-jud',
    appDomain: 'unwindinganxiety.com',
    dppwlDomain: null,
    mobileUrlScheme: 'unwindinganxiety',
    weeklyCallsEndpoint: '/ua_weekly_calls',
    dppwlWeeklyCallsEndpoint: null,
    stripe: {
      enabled: true,
      key: 'pk_test_iymFFot7Sd5xOsHukb2pDMP4',
    },
    jwplayer: true,
    intercom: {
      enabled: false
    },
    iridium: {
      enabled: true
    },
    gtm: {
      containerId: 'GTM-MRPDXMB'
    },
    kochava: {
      androidAppGuid: 'koua-stg-android-vs9seicz1',
      iosAppGuid: 'koua-stg-ios-62h',
      webAppGuid: 'koua-stg-web-3ovo3'
    },

    blank: null
  },

  environment: {



    environment: 'qa',
    communityApiHost: 'qa-community.mindsciences.net',
    communityApiEndpointPrefix: '/api/v2',
    communityCableEndpoint: 'qa-community-cable.mindsciences.net',
    apiUseSsl: true,
    urlVideoSalt: '7Y900q46BJwGuRobTEw5',
    useIridium: true,
    iridiumApiHost: 'iridium-qa.mindsciences.net',
    iridiumApiEndpointPrefix: '/api/v3',
    iridiumApiUseSsl: true,
    recaptchaSiteKey: '6LcG3dgbAAAAALRulYzFBkRZ87wGzUnm3BlEH-0W',
    // deprecated
    apiUrl: 'https://qa-app.claritasmind.com/api/v4',
    webSocketProtocol: 'wss',
    webSocketHost: 'qa-app.claritasmind.com',
    sharecareOauthUrl: 'https://auth.stage.sharecare.com/oauth2/authorize',
    apiHostname: 'qa-app',
    webappHostname: 'qa-webapp',



    blank: null
  },

  buildPlatform: 'web',
  end: true
};
