import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as mediaActions from './media.actions';
import { tap } from 'rxjs/operators';
import { FileService } from '../../../services/files/file.service';

@Injectable({providedIn: 'root'})
export class MediaEffects {

  constructor(
    private actions$: Actions,
    private fileService: FileService
  ) {}

  
  removeAllFiles$ = createEffect(() => this.actions$.pipe(ofType<mediaActions.RemoveAllFiles>(mediaActions.REMOVE_ALL_FILES),
    tap(() => {
      this.fileService.removeAllDownloadedFiles();
    })
  ), { dispatch: false });
}
