import { Injectable } from '@angular/core';
import { ClarityConfig } from '../config/clarity.config';
import { AlertsService } from './alerts.service';
import { EventsService } from './events.service';
import { Network, ConnectionType, ConnectionStatus } from '@capacitor/network';

@Injectable({providedIn: 'root'})
export class ConnectivityService {
  private isConnected: boolean;
  private connectionType: ConnectionType;

  constructor(
    public config: ClarityConfig,
    private events: EventsService,
    private alerts: AlertsService
  ) {
  }

  initialize() {
    Network.getStatus().then((connectionStatus: ConnectionStatus) => {
      this.connectionType = connectionStatus.connectionType;
    });
    // subscribe to change events
    Network.addListener('networkStatusChange', (connectionStatus: ConnectionStatus) => {
      this.connectionType = connectionStatus.connectionType;

      if (connectionStatus.connected) {
        this.onConnected();
      } else {
        this.onDisconnected();
      }
    });

    // init state
    if (navigator.onLine) {
      this.onConnected();
    }
    else {
      this.onDisconnected();
    }

    return Promise.resolve(true);
  }

  isOnline() {
    // return this.isConnected;
    // We got some reports where app was complaining about being offline although it was not, switching to this approach
    return navigator.onLine;
  }

  isOffline() {
    return !this.isConnected;
  }

  preventAccessWhenOffline() {
    if (this.isOnline()) {
      return false;
    }

    this.alerts.offlineNotification();

    return true;
  }

  isOnWifi() {
    return this.isConnectedWifi();
  }

  public onDisconnected() {
    console.info('Connection went down!');
    this.notifyEvent('offline');
    this.isConnected = false;
  }

  private onConnected() {
    this.isConnected = true;
    console.info('Connection went up!');
    this.notifyEvent('online');

    // check for wifi with a slight delay to allow state change
    setTimeout(() => {
      if (this.isConnectedWifi()) {
        this.notifyEvent.call(this, 'wifi-on');
      } else {
        this.notifyEvent.call(this, 'wifi-off');
      }
    }, 1000);
  }

  private isConnectedWifi() {
    return !this.isConnected
      ? false
      // check connection type for device, desktop will always be on wifi
      : this.config.isDevice
        ? this.connectionType === 'wifi'
        : true;
  }

  private notifyEvent(state) {
    this.events.publish(this.config.events.connection + state);
  }
}
