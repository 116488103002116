import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NavController } from '@ionic/angular';
import { Subject } from 'rxjs';

import { LoadingService } from '../../../../services/loading.service';
import { Exercise } from '../../../../store/normalized/schemas/exercise.schema';
import { AlertsService } from '../../../../services/alerts.service';
import { ClarityConfig } from '../../../../config/clarity.config';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-play-exercise',
  styleUrls: ['play-exercise.component.scss'],
  template: `
    <div class="page-wrapper" [class.wizard]="isWizard">
      <div class="no-padding-top">
        <div class="title-holder" *ngIf="showTitle">
          <div>
            <h1 class="exercise-title">{{exercise?.title}}</h1>
          </div>
        </div>

        <div [class.player-holder]="playerHolder" class="ion-padding">
          <div class="ion-text-center no-padding">

            <ng-container *ngIf="exercise?.kind === 'video'; else audioTemplate">
              <cl-video-player-brightcove
                *ngIf="useBrightcovePlayer"
                [brightcove_key]="exercise?.media_file?.brightcove_key"
                [brightcove_poster_url]="exercise?.media_file?.brightcove_poster_url"
                [autoplay]="autoplay"
                [controls]="playerController"
                (playerError)="onError($event)">
              </cl-video-player-brightcove>

              <cl-video-player-brightcove-web
                *ngIf="useBrightcovePlayerWeb"
                [brightcove_key]="exercise?.media_file?.brightcove_key"
                [autoplay]="autoplay"
                [controls]="playerController"
                (playerError)="onError($event)">
              </cl-video-player-brightcove-web>

              <cl-video-player-jwplayer
                *ngIf="useJWPlayer"
                [jw_key]="exercise?.media_file?.jw_key"
                [autoplay]="autoplay"
                [controls]="playerController"
                (playerError)="onError($event)">
              </cl-video-player-jwplayer>

              <cl-video-player
                *ngIf="useDefaultPlayer"
                [src]="exercise.media_file?.data"
                [subtitle]="exercise.media_file.subtitle"
                [autoplay]="autoplay"
                [controls]="playerController"
                [inlineSrc]="inlineSrc"
                [isInlineOnIos]="isInlineOnIos"
                (playerError)="onError($event)">
              </cl-video-player>
            </ng-container>

            <ng-template #audioTemplate>
              <cl-audio-player-brightcove
                *ngIf="useBrightcovePlayerWeb  || useBrightcovePlayer"
                [autoplay]="autoplay"
                [brightcove_key]="exercise?.media_file?.brightcove_key"
                [controls]="playerController"
                (playerError)="onError($event)">
              </cl-audio-player-brightcove>

              <cl-audio-player-jwplayer
                *ngIf="useJWPlayer"
                [cardBg]="isWizard"
                [autoplay]="autoplay"
                [jw_key]="exercise?.media_file?.jw_key"
                [controls]="playerController"
                (playerError)="onError($event)">
              </cl-audio-player-jwplayer>

              <cl-audio-player
                *ngIf="useDefaultPlayer"
                [autoplay]="autoplay"
                [cardBg]="isWizard"
                [src]="exercise.media_file?.data"
                [controls]="playerController"
                (playerError)="onError($event)">
              </cl-audio-player>
            </ng-template>

          </div>
        </div>
        <div class="description" *ngIf="isDescriptionVisible">
          <p [innerHTML]="exercise.caption"></p>
        </div>
      </div>
    </div>
  `
})

// TODO: Move this to Shared module
export class PlayExerciseComponent {
  @Input() exercise: Exercise;
  @Input() autoplay = true;
  @Input() showTitle = true;
  @Input() isWizard = false;
  @Input() playerHolder = true;
  @Input() inlineSrc = false;
  @Input() isDescriptionVisible = true;
  @Input() isInlineOnIos = false;

  @Input() playerController: Subject<string>;


  get useBrightcovePlayerWeb() {
    return this.config.isBrightcoveWebEnabled() && this.exercise?.media_file?.brightcove_key;
  }

  get useBrightcovePlayer() {
    return this.config.isBrightcoveEnabled() && this.exercise?.media_file?.brightcove_key;
  }

  get useJWPlayer() {
    return !this.useBrightcovePlayerWeb && !this.useBrightcovePlayer && this.config.jwplayerEnabled() && this.exercise?.media_file?.jw_key;
  }

  get useDefaultPlayer() {
    return !this.useBrightcovePlayerWeb && !this.useBrightcovePlayer && !this.useJWPlayer;
  }

  constructor(
    public navCtrl: NavController,
    public loading: LoadingService,
    public config: ClarityConfig,
    public alerts: AlertsService
  ) {
  }

  onError(error) {
    this.alerts.playerError(error);
  }
}
