import { Injectable } from '@angular/core';
import { HttpProvider } from './http/http.provider';
import { CountByDay } from '../store/normalized/schemas/count-by-day.schema';
import moment from 'moment';
import { ClarityConfig } from '../config/clarity.config';
import { CigCount } from '../store/normalized/schemas/cig-count.shema';
import { OfflineHttpProvider } from './http/offline-http.provider';

@Injectable({providedIn: 'root'})
export class CigCountProvider {

  private cigCountEndpoint = '/cig_counts';

  constructor(
    protected http: HttpProvider,
    protected config: ClarityConfig,
    private offlineHttp: OfflineHttpProvider
  ) {

  }

  loadCigCount() {
    return this.config.isCTQ() ?
      this.http.get<CigCount>(this.cigCountEndpoint + '/' + moment()
        .format('YYYY-MM-DD'))
      : null;
  }

  getCigCountByDay() {
    return this.http.get<CountByDay>(this.cigCountEndpoint + '/count_by_day' + '?tz=' + this.getTZ());
  }

  trackCigCount(payload) {
    return this.offlineHttp.patch(this.cigCountEndpoint + '/' + payload.date, payload);
  }

  // THIS DOESN'T CHANGE ANYTHING -- backend will not take it into consideration, all records are sorted based on date field
  // We use the date field now which stores the date (YYYY-MM-DD) as recorded from the app
  getTZ() {
    let offset = new Date().getTimezoneOffset() / 60;
    let ret = 'GMT';
    if (offset > 0) {
      ret += '-' + offset;
    } else if (offset < 0) {
      offset = -offset;
      ret += '%2B' + offset;
    }

    return ret;
  }
}
