// account reducer

import { ERROR_CONNECTION_TIMEOUT, ERROR_INVALID_INPUT, ERROR_REQUEST_LIMIT_EXCEEDED, ERROR_UNAUTHORIZED_ACCESS } from '../../../../app/config/error.handler';

import { HttpError } from '../models/http-error.model';
import * as accountActions from '../actions/account.actions';
import { MenuItem } from '../../../pages/account/account-menu';

export interface AccountState {
  setupStarted: boolean;
  setupComplete: boolean;
  registering: boolean;
  loading: boolean;
  updating: boolean;
  loaded: boolean;
  updateError: HttpError;
  signupError: HttpError;
  accountMenu: {
    selectedSubMenu: string;
    selectedMenuTab: string;
    isEdditingSettingsGoals: boolean;
    rootMenu: MenuItem;
  };
}

export const initialAccountState: AccountState = {
  setupStarted: false,
  setupComplete: false,
  registering: false,
  loading: false,
  updating: false,
  loaded: false,
  updateError: null,
  signupError: null,
  accountMenu: {
    selectedSubMenu: null,
    selectedMenuTab: null,
    isEdditingSettingsGoals: false,
    rootMenu: null
  }
};

export function accountReducer(state = initialAccountState, action: accountActions.AccountActions): AccountState {
  switch (action.type) {
    case accountActions.ACCOUNT_SETUP_START: {
      return {
        ...state,
        setupStarted: true
      };
    }

    case accountActions.ACCOUNT_SETUP_COMPLETE: {
      return {
        ...state,
        setupStarted: false,
        setupComplete: true
      };
    }

    case accountActions.LOAD_USER: {
      return {
        ...state,
        loading: true
      };
    }

    case accountActions.LOAD_USER_REFRESH:
    case accountActions.LOAD_USER_SUCCESS: {
      const user = action.payload;

      let accountSetup = {};

      // mark account setup complete if this is not a signup
      if (!state.setupStarted && user.complete) {
        accountSetup = {
          setupComplete: true
        };
      }

      if (!user.complete) {
        accountSetup = {
          setupComplete: false
        };
      }

      return {
        ...state,
        ...accountSetup,
        registering: false,
        loading: false,
        updating: false,
        loaded: true
      };
    }

    case accountActions.LOAD_USER_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case accountActions.ACCOUNT_SETUP_PROCESS:
    case accountActions.ACCOUNT_DPP_SETUP_PROCESS:
    case accountActions.UPDATE_USER_ACCOUNT: {
      return {
        ...state,
        updating: true
      };
    }

    case accountActions.UPDATE_USER_ACCOUNT_SUCCESS: {
      return {
        ...state,
        updating: false,
        loaded: true
      };
    }

    case accountActions.UPDATE_USER_ACCOUNT_FAIL: {
      const httpResponse = action.payload;
      let type = null;

      switch (httpResponse.status) {
        case 422:
          type = ERROR_INVALID_INPUT;
          break;
        case 401:
          type = ERROR_UNAUTHORIZED_ACCESS;
          break;
      }

      return {
        ...state,
        updateError: {
          type,
          httpStatus: httpResponse.status,
          httpError: httpResponse.error,
          httpMessage: httpResponse.message
        },
        updating: false
      };
    }

    case accountActions.SIGNUP: {
      return {
        ...state,
        registering: true
      };
    }

    case accountActions.SIGNUP_FAIL: {
      const httpResponse = action.payload;
      let type = null;

      switch (httpResponse.status) {
        case 422:
          type = ERROR_INVALID_INPUT;
          break;
        case 408:
          type = ERROR_CONNECTION_TIMEOUT;
          break;
      }

      return {
        ...state,
        signupError: {
          type,
          httpStatus: httpResponse.status,
          httpError: httpResponse.error,
          httpMessage: httpResponse.message
        },
        registering: false
      };
    }

    case accountActions.CLEAR_SIGNUP_STATE: {
      return {
        ...state,
        signupError: null
      };
    }

    case accountActions.SET_USER_PROGRAM: {
      return {
        ...state,
        registering: true
      };
    }

    case accountActions.SET_USER_PROGRAM_SUCCESS: {
      return {
        ...state,
        registering: false
      };
    }

    case accountActions.SET_USER_PROGRAM_FAIL: {
      return {
        ...state,
        registering: false
      };
    }

    case accountActions.CHANGE_PASSWORD: {
      return {
        ...state,
        updating: true
      };
    }

    case accountActions.CHANGE_PASSWORD_FAIL: {
      return {
        ...state,
        updating: false
      };
    }

    case accountActions.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        updating: false
      };
    }

    case accountActions.SET_SELECTED_SUB_MENU: {
      return {
        ...state,
        accountMenu: {
          ...state.accountMenu,
          selectedSubMenu: action.payload
        }
      };
    }

    case accountActions.SET_ROOT_ACCOUNT_MENU: {
      return {
        ...state,
        accountMenu: {
          ...state.accountMenu,
          rootMenu: action.payload
        }
      };
    }

    case accountActions.SET_SELECTED_MENU_TAB: {
      return {
        ...state,
        accountMenu: {
          ...state.accountMenu,
          selectedMenuTab: action.payload
        }
      };
    }

    case accountActions.SET_IS_EDITING_SETTINGS_GOALS: {
      return {
        ...state,
        accountMenu: {
          ...state.accountMenu,
          isEdditingSettingsGoals: action.payload
        }
      };
    }
  }

  return state;
}

export const registering = (state: AccountState) => state.registering;
export const loading = (state: AccountState) => state.loading;
export const loaded = (state: AccountState) => state.loaded;
export const updating = (state: AccountState) => state.updating;
export const setupStarted = (state: AccountState) => state.setupStarted;
export const setupComplete = (state: AccountState) => state.setupComplete;
export const updateError = (state: AccountState) => state.updateError;
export const signupError = (state: AccountState) => state.signupError;
export const selectedSubMenu = (state: AccountState) => state.accountMenu.selectedSubMenu;
export const selectedMenuTab = (state: AccountState) => state.accountMenu.selectedMenuTab;
export const rootAccountMenu = (state: AccountState) => state.accountMenu.rootMenu;
export const isEdditingSettingsGoals = (state: AccountState) => state.accountMenu.isEdditingSettingsGoals;
export const menuObjectBasedOnSelectedTab = (state: AccountState): any =>
  state.accountMenu.rootMenu?.options?.[state.accountMenu.selectedMenuTab]?.menu || {} as MenuItem;
