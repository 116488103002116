import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { ClarityConfig } from '../config/clarity.config';
import { Platform } from '@ionic/angular';
import { isAuthenticating } from '../store/sensitive/selectors/auth.selectors';
import { SessionState } from '../store/session/session.reducers';
import { LoadingService } from './loading.service';

import { Browser } from '@capacitor/browser';
import { LoggerService } from './logger.service';

@Injectable({providedIn: 'root'})
export class SharecareSSOService {

  public authenticating$: Observable<boolean> = this.store.select(isAuthenticating);

  private sharecareAuthParams: any = {};

  constructor(
    private store: Store<SessionState>,
    private config: ClarityConfig,
    private platform: Platform,
    private loading: LoadingService,
    private logger: LoggerService
  ) {
  }

  get sharecareSSOLink() {
    if (!this.config.isDevice) {
      return this.getAppSsoLink();
    }

    return this.getBackendSsoLink();
  }

  get appUri() {
    if (!this.config.isDevice) {
      const location = (window as any).location;

      return location.origin + location.pathname + location.search + `#${this.config.env.preauthPath}`;
    }

    if (this.config.isCTQ()) {
      return 'cravingtoquit://preauth';
    }

    if (this.config.isUA()) {
      return 'unwindinganxiety://preauth';
    }

    if (this.config.isERN ()) {
      return 'eatrightnow://preauth';
    }
  }

  private getBackendSsoLink() {
    const sharecareAuthParams = this.joinSharecareAuthParams();
    const authParamsAnd = sharecareAuthParams ? sharecareAuthParams + '&' : '';

    const queryString = `${authParamsAnd}app_uri=${encodeURIComponent(this.appUri)}`;

    const link = `${this.config.env.apiUseSsl ? 'https://' : 'http://'}${this.config.apiHost}/auth/sharecare?${queryString}`;

    console.log('SSO via Backend', link);

    return link;
  }

  private getAppSsoLink() {
    const {sharecareClientId, sharecareOauthUrl} = this.config.env;
    const link = `${sharecareOauthUrl}?client_id=${sharecareClientId}&response_type=code&redirect_uri=${this.getRedirectUri()}`;

    console.log('SSO via App', link);

    return link;
  }

  private getRedirectUri() {
    const uri = this.config.redirectUri + this.getRedirectUriParams();

    return encodeURIComponent(uri);
  }

  private getRedirectUriParams() {
    if (!this.sharecareAuthParams) {
      return '';
    }

    return '?' + this.joinSharecareAuthParams();
  }

  private joinSharecareAuthParams(encode = false) {
    return Object.keys(this.sharecareAuthParams)
      .map((key) => encode ? `${key}=${encodeURIComponent(this.sharecareAuthParams[key])}` : `${key}=${this.sharecareAuthParams[key]}`)
      .join('&');
  }

  hideSharecareLoginBrowser() {
    if (this.config.isDevice && this.config.isIos) {
      Browser.close();
    }
  }

  initSharecareLogin(params = {}) {
    this.loading.showLoadingOverlay('', true);

    if (params && Object.keys(params).length > 0) {
      this.sharecareAuthParams = params;
    }

    if (!this.config.isDevice) {
      return this.startLoginInCurrentWindow();
    }

    this.startLoginInCapacitorBrowser();
  }

  startLoginInCapacitorBrowser() {
    const url = this.sharecareSSOLink;

    Browser.removeAllListeners()
      .then(() => {
        Browser.addListener('browserFinished', () => {
          console.log('Capacitor Browser closed!');
          this.loading.hideLoadingOverlay();
        });

        console.log('Opening URL in Capacitor Browser!', url);

        Browser.open({url});
      })
      .catch((error) => {
        this.logger.error('Error while starting login in Capacitor Browser', error, 'Sharecare SSO');
      });
  }

  startLoginInSystemBrowser() {
    (window as any).open(this.sharecareSSOLink, '_system', 'location=yes');
  }

  startLoginInCurrentWindow() {
    (window as any).location.replace(this.sharecareSSOLink);
  }

}
