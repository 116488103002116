import { Injectable, NgZone } from '@angular/core';
import { ActionCableService } from './action-cable.service';
import { ClarityConfig } from '../../config/clarity.config';
import { Action, Store } from '@ngrx/store';
import { getCurrentProfileId } from '../../store/normalized/selectors/user-profile.selectors';
import { SessionState } from '../../store/session';
import * as notificationActions from '../../store/session/actions/notifications.actions';
import * as myCoachActions from '../../store/session/actions/my-coach.actions';
import { NotifyNewPosts, NotifyPostLike } from '../../store/session/actions/social.actions';
import { NotifyCommentLike, NotifyNewComment } from '../../store/session/actions/post.actions';
import { Observable } from 'rxjs';
import { getResumedAt } from '../../store/session/selectors/sync.selectors';
import { first } from 'rxjs/operators';
import { LoggerService } from '../logger.service';

@Injectable({providedIn: 'root'})
export class CommunityActionCableService {

  private profileId: number;
  private resumedAt$: Observable<number> = this.store.select(getResumedAt);

  private applicationChannel: any;
  private profileChannel: any;

  constructor(
    private cableService: ActionCableService,
    private config: ClarityConfig,
    private store: Store<SessionState>,
    private logger: LoggerService,
    private zone: NgZone
  ) {
    this.setProfileId();
  }

  initActionCable(): void {
    if (!this.profileId) {
      this.logger.error('community-action-cable', 'starting actioncable with empty profile_id');
    }

    this.zone.runOutsideAngular(() => {
      this.profileChannel = this.cableService.createChannel(
        {
          channel: 'ProfileChannel',
          profile_id: this.profileId
        },
        {
          received: message => {
            // console.log('message received @ profile channel', message);

            if (message.type === 'notification_created') {
              this.dispatchIfNotJustResumed(new notificationActions.LoadNotifications());
            }

            if (message.type === 'message_created') {
              this.dispatchIfNotJustResumed(new myCoachActions.NotifyNewMessage(message));
            }
          },
          disconnected: () => this.logger.warning('community-action-cable', 'action cable disconnected')
        }, this.getWebSocketUrl());

      this.applicationChannel = this.cableService.createChannel(
        {
          channel: 'ApplicationChannel'
        },
        {
          received: message => {
            if (message.profile_id === this.profileId) {
              console.error(
                'community-action-cable',
                `received profile_id (${message.profile_id}) different than expected (${this.profileId})`
              );

              return;
            }

            if (message.type === 'post_created') {
              this.dispatchIfNotJustResumed(new NotifyNewPosts(message));
            }

            if (message.type === 'like_created') {
              if (typeof message.post_id !== 'undefined') {
                this.dispatchIfNotJustResumed(new NotifyPostLike(message));
              } else if (typeof message.comment_id !== 'undefined') {
                this.dispatchIfNotJustResumed(new NotifyCommentLike(message));
              }
            }

            if (message.type === 'comment_created') {
              this.dispatchIfNotJustResumed(new NotifyNewComment(message));
            }
          },
          disconnected: () => this.logger.warning('community-action-cable', 'action cable disconnected')
        }, this.getWebSocketUrl());
    });
  }

  getWebSocketUrl(): string {
    const {apiUseSsl, webSocketEndpoint, communityCableEndpoint} = this.config.env;

    return `${apiUseSsl ? 'wss://' : 'ws://'}${communityCableEndpoint}${webSocketEndpoint}?token=${this.cableService.getToken()}`;
  }

  private dispatchIfNotJustResumed(action: Action) {
    this.store.select(getResumedAt)
      .pipe(first())
      .subscribe(resumedAt => {
        const currentTimestamp = new Date().getTime();

        if (!resumedAt || currentTimestamp - resumedAt > 1000) {
          this.store.dispatch(action);
        }
      });
  }

  private setProfileId() {
    this.store.select(getCurrentProfileId)
      .subscribe(id => {
        if (id) {
          this.profileId = parseInt(id, 10);
        }
      });
  }
}
