import { Component, Injector } from '@angular/core';
import { WizardComponent } from '../wizard.component';
import { CheckinsService } from '../../../../services/wizards/checkins.service';
import * as navigationActions from '../../../../store/session/actions/navigation.actions';
import { Store } from '@ngrx/store';
import { SessionState } from '../../../../store/session/session.reducers';
import { BrowserService } from '../../../../services/browser.service';
import { ClarityConfig } from '../../../../config/clarity.config';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cl-morning-motivation',
  styleUrls: ['../../wizard.scss', 'morning-motivation.component.scss'],
  templateUrl: 'morning-motivation.component.html'
})
export class MorningMotivationComponent extends WizardComponent {

  public feelings = [];
  public feelingChoice = null;

  anxiousFeeling = 0;
  feelBefore = 50;
  todayModule: string;
  todayModuleStarted: boolean;
  expanded: number;

  public checkinExercise = null;
  public checkinQuestion = null;
  public feelNow = null;

  protected wizardConfig = {
    title: 'wizards.morning_motivation',
    totalSteps: 4,
    initialStep: 1
  };

  constructor(
    protected store: Store<SessionState>,
    protected browser: BrowserService,
    protected injector: Injector,
    public config: ClarityConfig,
    protected analyticsService: AnalyticsService,
    protected translate: TranslateService
  ) {
    super(store, browser, injector, config, analyticsService, translate);
  }

  initStep1() {
    this.onEnableNext();
    this.loadFeelingChoice();
  }

  initStep2() {
    this.anxiousFeeling = this.service.getAnxiety() * 10;
    this.onEnableNext();
  }

  initStep3() {
    this.onEnableNext();
  }

  initStep4() {
    this.onEnableNext();
  }

  onDone() {
    this.service.saveMorningMotivation();
    this.wizardComplete.emit();
  }

  openCommunity() {
    this.browser.openCommunityPath('/');
  }

  onFeelingSelected(feeling) {
    this.service.setFeeling(feeling);
    this.loadFeelingChoice();
  }

  private loadFeelingChoice() {
    this.feelingChoice = this.service.getFeeling();

    if (this.feelingChoice) {
      this.onEnableNext();
    } else {
      this.onDisableNext();
    }
  }

  setAnxiousFeelingChoice(anxiety: number) {
    this.service.setAnxiety(anxiety);
  }

  setFeelBefore(value: number) {
    this.service.setFeelBefore(value);
    this.feelBefore = value;
  }

  protected _init() {
    this.service = this.injector.get<CheckinsService>(CheckinsService);

    return this.service.initModel()
      .then(() => {
        this.feelings = this.service.getFeelingsList();
        this.checkinExercise = this.service.getCheckinExercise();
        this.service.setTag('morning_motivation');
        this.todayModule = this.service.getTodayModule();
        this.todayModuleStarted = this.service.getTodayModuleStarted();
      });
  }

  playModule(isPrevious) {
    this.service.playModule(isPrevious);

    // delay the save action so the animation can complete loading modal
    setTimeout(() => {
      this.wizardComplete.emit();
      this.service.saveMorningMotivation();
    }, 2000);
  }

  goNextStep() {
    this.nextStep.emit();
  }

  onFeelNow(feelNowValue) {
    this.service.setFeelNowValue(feelNowValue);
  }

  startNewWeek() {
    this.store.dispatch(new navigationActions.ShowNextSteps());
  }
}
