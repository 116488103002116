import { Component } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import SwiperCore, { EffectFade, Swiper } from 'swiper';
SwiperCore.use([ EffectFade ]);
/**
 * This is a helper class to easily manipulate slides steps that needs validation.
 *
 * To use it, simply extend this class, add your `<swiper>` to the html template,
 * implement the methods `isStepValid()`, `slideFinished()`, and done, you can call the `tryNavigateToNext()`
 *
 * Example:
 *
 * ```
 * Component({
 *   template: `<swiper>
 *     <ng-template swiperSlide>slide 1</ng-template>
 *     <ng-template swiperSlide>slide 2</ng-template>
 *     <ion-button (click)="tryNavigateNext()">next</ion-button>
 *   </swiper>`
 * })
 * class MySlideComponent extends StepValidationSlideComponent {
 *   async isStepValid(step: number) {
 *     return true; // will make navigation works at every step
 *   }
 *
 *   async slideFinished() {
 *     console.log('slide finished!!!!');
 *  }
 * }
 *```
 */
@Component({
  selector: 'cl-step-validation-slide',
  template: ''
})
export class StepValidationSlideComponent {
  swiper: Swiper;
  swiperInit$ = new Subject<void>();

  selectedSlide$ = new BehaviorSubject<number>(0);
  selectedSlideNumber$ = new BehaviorSubject<number>(0);
  selectedSlideElement$ = this.selectedSlideNumber$.pipe(
    switchMap(async (number) => {
      try {
        const element: HTMLElement = this.swiper.el || (this.swiper as any).el;

        return element.querySelectorAll('section')[number];
      } catch(error) {
        console.error('selectedSlideElement$ error', error);
      }
    })
  );

  onSwiper(slides) {
    this.swiper = slides;
    this.swiperInit$.next();

    this.swiper.on('slideChangeTransitionStart', () => {
      this.selectedSlideNumber$.next(this.swiper.activeIndex);
    });
  }

  updateSelectedSlide() {
    const index = this.swiper.activeIndex;
    this.selectedSlide$.next(index);
  }

  public async tryNavigateToNext(skip = false) {
    if (
      skip ||
      this.isCurrentStepValid()
    ) {
      return this.navigateToNext();
    }
  }

  public async tryNavigateToPrevious(skip = false) {
    return this.navigateToPrevious();
  }

  private isCurrentStepValid() {
    const index = this.swiper.activeIndex;

    return this.isStepValid(index);
  }

  private async navigateToNext() {
    if (this.swiper.isEnd) {
      return this.slideFinished();
    }

    // unlock, slide, lock again.
    this.swiper.allowSlideNext = true;
    this.swiper.slideNext();
    this.swiper.allowSlideNext = false;
  }

  private async navigateToPrevious() {
    // unlock, slide, lock again.
    this.swiper.allowSlidePrev = true;
    this.swiper.slidePrev();
    this.swiper.allowSlidePrev = false;
  }

  public isStepValid(step: number) {
    // implement this in child class
    throw new Error('[StepValidationSlideComponent] isStepValid is not implemented. Please implement it in the child class');
  }

  public slideFinished() {
    // implement this in child class
    throw new Error('[StepValidationSlideComponent] slideFinished is not implemented. Please implement it in the child class');
  }
}
