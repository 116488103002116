import { Injectable } from '@angular/core';

import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';

import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AddData, RemoveData, SetData } from 'ngrx-normalizr';

import { TranslateService } from '@ngx-translate/core';

import * as accountActions from '../actions/account.actions';
import * as syncActions from '../actions/sync.actions';
import * as socialActions from '../actions/social.actions';
import * as remindersActions from '../actions/reminders.actions';
import * as programActions from '../actions/program.actions';
import * as authActions from '../../sensitive/actions/auth.actions';
import * as integrationsActions from '../actions/integrations.actions';

import { ExercisesProvider } from '../../../../app/providers/exercises.provider';
import { ProgramProvider } from '../../../../app/providers/program.provider';
import { RemindersProvider } from '../../../../app/providers/reminders.provider';
import { UserProvider } from '../../../../app/providers/user.provider';
import { UserRemindersProvider } from '../../../../app/providers/user-reminders.provider';
import { SubscriptionsProvider } from '../../../../app/providers/subscriptions/subscriptions.provider';
import { LoadingService } from '../../../../app/services/loading.service';
import { getLastSyncEverythingAt, isSyncing } from '../selectors/sync.selectors';
import { ThemeWeeksProvider } from '../../../../app/providers/theme-weeks.provider';
import { CheckinsProvider } from '../../../../app/providers/wizards/checkins.provider';
import { ListItemsProvider } from '../../../../app/providers/list-items.provider';
import { GoalsProvider } from '../../../../app/providers/goals.provider';
import { UserActivitiesProvider } from '../../../../app/providers/user-activities.provider';
import { UserBootstrapProvider } from '../../../../app/providers/user-bootstrap.provider';
import { UserProgressProvider } from '../../../../app/providers/user-progress.provider';
import { SymptomsProvider } from '../../../../app/providers/symptoms.provider';
import { CommunityProvider } from '../../../../app/providers/community.provider';
import { StressTestProvider } from '../../../../app/providers/wizards/stress-test.provider';
import { StressMeterProvider } from '../../../../app/providers/wizards/stress-meters.provider';
import { UserProgramSessionProvider } from '../../../../app/providers/user-program-session.provider';
import { WeightActivitiesProvider } from 'src/app/providers/weight-activities.provider';
import { AlertController } from '@ionic/angular';
import { LoggerService } from '../../../../app/services/logger.service';
import { ToastService } from '../../../../app/services/toast.service';
import { AwarenessQuizzesProvider } from '../../../../app/providers/awareness-quizzes.provider';
import { UserProgramProvider } from '../../../../app/providers/user-program.provider';
import * as userProgramActions from '../actions/user-program.actions';
import {
  User,
  UserFavorite,
  userFavoriteSchema,
  UserGoal,
  userGoalsSchema,
  UserProgram,
  userProgramSchema,
  UserProgramSession,
  userProgramSessionSchema,
  userSchema
} from '../../normalized/schemas/user.schema';
import { AnxietyQuizzesProvider } from '../../../../app/providers/anxiety-quizzes.provider';
import { ClarityConfig } from '../../../../app/config/clarity.config';
import { CigCountProvider } from '../../../../app/providers/cig-count.provider';
import { UserDataSyncChannelService } from '../../../../app/services/actioncable/user-data-sync-channel.service';
import { SubtitlesProvider } from '../../../../app/providers/subtitles.provider';
import { FileService } from '../../../../app/services/files/file.service';

import {
  bookmarkedPostSchema,
  CommunityPost,
  communityPostSchema,
  Journal,
  journalSchema,
  VideoChat,
  videoChatsSchema
} from '../../normalized/schemas/community.schema';
import { CheckinAnxietyByDay, checkinAnxietyByDaySchema } from '../../normalized/schemas/checkin-anxiety-by-day.schema';
import { Subtitle, subtitleSchema } from '../../normalized/schemas/mediaFile.schema';
import { AnxietyQuiz, anxietyQuizSchema } from '../../normalized/schemas/anxiety-quiz.schema';
import { AwarenessQuiz, awarenessQuizSchema } from '../../normalized/schemas/awareness-quiz.schema';
import {
  checkinsByDaySchema,
  cigarettesByDaySchema,
  CountByDay,
  cravingToolsByDaySchema,
  programDaysByDaySchema,
  stressTestsByDaySchema,
  userWeekDaysByDaySchema,
  wantOMeterByDaySchema,
  worryToolByDaySchema
} from '../../normalized/schemas/count-by-day.schema';
import { RecentStressMeter, recentStressMeterSchema } from '../../normalized/schemas/recent-stress-meter.schema';
import { Subscription, subscriptionSchema } from '../../normalized/schemas/subscription.schema';
import { ListItem, listItemSchema } from '../../normalized/schemas/list-item.schema';
import {
  CheckinExercise,
  checkinExerciseSchema,
  CheckinQuestion,
  checkinQuestionSchema
} from '../../normalized/schemas/checkin.schema';
import { BonusExercise, bonusExerciseSchema, Exercise, exerciseSchema } from '../../normalized/schemas/exercise.schema';
import { Symptoms, symptomsSchema } from '../../normalized/schemas/symptoms.schema';
import { ThemeWeek, themeWeekSchema } from '../../normalized/schemas/theme-week.schema';
import {
  Progress,
  progressSchema,
  UserBootstrap,
  userBootstrapSchema
} from '../../normalized/schemas/user-bootstrap.schema';
import { ProgramBootstrap, programBootstrapSchema, ProgramDay, programDaySchema } from '../../normalized/schemas/program-bootstrap.schema';
import { Reminder, reminderSchema, UserReminder, userReminderSchema } from '../../normalized/schemas/reminder.schema';
import { getBookmarkedPosts } from '../../normalized/selectors/community.selectors';
import { WeightActivity, weightActivitySchema } from '../../normalized/schemas/weight-activity.schema';
import { getUserFavs } from '../selectors/user-favorites.selectors';
import { SessionState } from '../session.reducers';
import { CravingToolProvider } from 'src/app/providers/wizards/craving-tool.provider';
import { UserFavoritesProvider } from '../../../providers/user-favorites.provider';
import { MinutesActivitiesProvider } from '../../../providers/minutes-activities.provider';
import { MinutesActivity, minutesActivitySchema } from '../../normalized/schemas/minutes-activity.schema';
import { WorryToolProvider } from 'src/app/providers/wizards/worry-tool.provider';
import { ConnectedApplicationsProvider } from 'src/app/providers/connected-applications.provider';
import { Integration } from '../models/integration.model';
import { ProgramDaysProvider } from 'src/app/providers/program-days.provider';
import { IridiumProvider } from 'src/app/services/analytics/iridium/iridium.provider';
import { TemporaryTokenProvider } from 'src/app/providers/temporary-token.provider';
import { SyncResolutionService } from '../../../services/sync-resolution.service';
import { getMinutesActivity } from '../../normalized/selectors/minutes-activity.selectors';

// auxiliary method to transform the countByDay endpoints payload
// into processable entities
const objectToListOfCountByDays = (actionByDayPayload = {}) => Object.keys(actionByDayPayload)
  .map((date) => {
    const count = actionByDayPayload[date];

    return {date, count};
  });

@Injectable({providedIn: 'root'})
export class SyncEffects {

  currentLang: string;

  // TODO: Stop HTTP requests and actions after logout
  // https://stackoverflow.com/questions/44455244/ngrx-cancel-dispatched-actions-after-logout-action


  initActionCable$ = createEffect(() => this.actions$.pipe(ofType(syncActions.INIT_ACTIONCABLE),
    map(() => {
      this.userDataSyncChannelService.initActionCable();

      // no need to return anything
      // return new programActions.LoadActionCable();
    })
  ), {dispatch: false});

  applicationDataSyncChannelConnected$ = createEffect(() => this.actions$.pipe(ofType(syncActions.APPLICATION_DATA_SYNC_CHANNEL_CONNECTED),
    map(() => {
      // Dispatch specific actions when application data actioncable is reconnected
      if(this.config.programDPPorWL()) {
        this.sessionState.dispatch(new integrationsActions.SyncOfflineManualActivities());
      }
    })
  ), {dispatch: false});

  syncEverything$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_EVERYTHING),
    // TODO: Figure out why this is happening -- this fix doesn't belong here
    // work-around to make sure folders exist before we start downloading
    switchMap(() => this.fileService.initialize()),
    catchError(() => of(null)),
    tap(() => {
      // hide initial loading screen
      this.loading.hideLoadingOverlay();

      this.loading.useLoadingObservable(
        this.sessionState.select(isSyncing),
        this.translate.get('auth.loading_your_data')
      );
    }),
    switchMap(() => forkJoin(this.getAllProviders())
      .pipe(
        map((data) => new syncActions.SyncLoadEverything(data)),
        catchError((error) => {
          this.logger.error('Error occurred while syncing everything', error, 'syncEverything$');

          return new Observable<Action>((observer) => {
            this.translate.get([
              'common.cancel',
              'common.retry',
              'errors.common.login_failed_message',
              'errors.common.network_error_title',
              'errors.common.network_error_occurred'
            ])
              .pipe(take(1))
              .subscribe(async (translations) => {
                // this.presentAlert(observer, translations);
                this.loading.hideLoadingOverlay();
                const alert = await this.alerts.create({
                  header: translations['errors.common.network_error_title'],
                  message: translations['errors.common.network_error_occurred'],
                  buttons: [
                    {
                      text: translations['common.cancel'],
                      role: 'cancel',
                      handler: () => {
                        console.log('Cancel clicked');
                        this.loading.hideLoadingOverlay();
                        this.toasts.error(translations['errors.common.login_failed_message']);
                        observer.next(new authActions.LogoutFromError());
                      }
                    },
                    {
                      text: translations['common.retry'],
                      handler: () => {
                        console.log('Retrying');
                        observer.next(new syncActions.SyncEverything());
                      }
                    }
                  ]
                });

                await alert.present();
              });
          });
        })
      ))
  ));


  syncLoadEverything$ = createEffect(() => this.actions$.pipe(ofType<syncActions.SyncLoadEverything>(syncActions.SYNC_LOAD_EVERYTHING),
    map(() => this.sessionState.dispatch(new syncActions.SyncEverythingComplete()))
  ), {dispatch: false});

  syncEverythingComplete = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_EVERYTHING_COMPLETE),
    switchMap(() => {
      const actions: any[] = [
        new programActions.InitLiveProgram(),
        new syncActions.InitActionCable(),
        new authActions.LoginComplete()
      ];

      if (this.config.isCTQ()) {
        actions.push(new socialActions.InitActionCable());
      }

      return actions;
    })
  ));

  syncUser$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_USER),
    switchMap(() => this.userProvider.loadUserAccount()
      .pipe(
        tap((userAccount) => this.loadDataUserAccount(userAccount)),
        catchError((error) => {
          console.error('Cannot sync user account', error);

          return of(null);
        })
      ))
  ), {dispatch: false});

  syncSubscription$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_SUBSCRIPTION),
    switchMap(() => this.subscriptionsProvider.loadSubscriptions()
      .pipe(
        tap((subscription) => this.loadDataSubscription(subscription)),
        catchError((error) => {
          // do not overwrite the existing subscription if the request fails
          this.logger.error('subscriptionError', error, 'syncSubscriptions$');

          return of(null);
        })
      ))
  ), {dispatch: false});

  syncUserProgramSession$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_USER_PROGRAM_SESSION),
    switchMap(() => this.userProgramSessionProvider.getUserProgramSession()
      .pipe(
        tap((userProgramSession) => this.loadDataUserProgramSession(userProgramSession)),
        catchError((error) => {
          console.error('Cannot sync user program session', error);

          return of(null);
        })
      ))
  ), {dispatch: false});

  syncUserReminders$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_USER_REMINDERS),
    switchMap(() => this.userRemindersProvider.loadUserReminders()
      .pipe(
        tap((userReminders) => this.loadDataUserReminders(userReminders)),
        map(() => new syncActions.SyncUserRemindersSuccess()),
        catchError((error) => {
          console.error('Cannot sync user reminders', error);

          return of(new syncActions.SyncUserRemindersFail());
        })
      ))
  ));

  syncUserProgress$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_USER_PROGRESS),
    switchMap(() => this.userProgressProvider.getUserProgress()
      .pipe(
        tap((userProgress) => this.loadDataUserProgress(userProgress)),
        map(() => new syncActions.SyncUserProgressSuccess()),
        catchError((error) => {
          console.error('Cannot sync user progress', error);

          return of(new syncActions.SyncUserProgressFail());
        })
      ))
  ));


  syncBonusExercises$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_BONUS_EXERCISES),
    switchMap(() => this.exercisesProvider.loadBonusExercises()
      .pipe(
        tap((bonusExercises) => this.loadDataBonusExercises(bonusExercises)),
        map(() => new syncActions.SyncBonusExercisesSuccess()),
        catchError((error) => {
          console.error('Cannot sync bonus exercises', error);

          return of(new syncActions.SyncBonusExercisesFail());
        })
      ))
  ));


  syncUserBootstrap$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_USER_BOOTSTRAP),
    switchMap(() => this.userBootstrapProvider.getUserBootstrap()
      .pipe(
        tap((userBootstrap) => this.loadDataUserBootstrap(userBootstrap)),
        map(() => new syncActions.SyncUserBootstrapSuccess()),
        catchError((error) => {
          console.error('Cannot sync user bootstrap', error);

          return of(new syncActions.SyncUserBootstrapFail());
        })
      ))
  ));


  syncProgram$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_PROGRAM),
    switchMap(() => this.programProvider.loadProgramBootstrap()
      .pipe(
        tap((program) => this.loadDataProgram(program)),
        map(() => new syncActions.SyncProgramSuccess()),
        catchError((error) => {
          console.error('Cannot sync program', error);

          return of(new syncActions.SyncProgramFail());
        })
      ))
  ));

  syncUserProgram$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_USER_PROGRAM),
    switchMap(() => this.userProgramProvider.getUserProgram()
      .pipe(
        tap((userProgram) => {
          // if the language was changed on another device, we need to re-sync everything
          if (this.currentLang && userProgram.language_code && this.currentLang !== userProgram.language_code) {
            this.sessionState.dispatch(new userProgramActions.UpdateUserProgramLanguageSuccess(userProgram));
          }
          else {
            this.loadDataUserProgram(userProgram);
          }
        }),
        // Intercom needs to allow the messenger if this was enabled through the UserProgram
        map((data) => new syncActions.SyncUserProgramComplete()),
        catchError((error) => {
          console.error('Cannot sync user program', error);

          this.sessionState.dispatch(new syncActions.SyncUserProgramComplete());

          return of(new userProgramActions.LoadUserProgramFail(error));
        })
      ))
  ));

  syncUserGoals$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_USER_GOALS),
    switchMap(() => this.goalsProvider.loadUserGoals()
      .pipe(
        tap((userGoals) => this.loadDataUserGoals(userGoals)),
        map(() => new syncActions.SyncUserGoalsComplete()),
        catchError((error) => {
          console.error('Cannot sync user goals', error);

          this.sessionState.dispatch(new syncActions.SyncUserGoalsComplete());

          return of(null);
        })
      ))
  ));


  syncUserAccount$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_USER_ACCOUNT),
    switchMap(() => this.userProvider.loadUserAccount()
      .pipe(
        tap((userAccount) => this.loadDataUserAccount(userAccount)),
        map(() => new syncActions.SyncUserAccountComplete()),
        catchError((error) => {
          console.error('Cannot sync user account', error);

          this.sessionState.dispatch(new syncActions.SyncUserAccountComplete());

          return of(null);
        })
      ))
  ));


  syncIridiumToken$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_IRIDIUM_TOKEN),
    switchMap(() => {
      if (!this.config.iridiumAllowed()) {
        return of(null);
      }

      return this.iridiumToken()
        .pipe(
          tap(() => this.sessionState.dispatch(new syncActions.SyncIridiumTokenComplete())),
          catchError((error) => {
            console.error('Cannot sync Iridium token', error);
            this.sessionState.dispatch(new syncActions.SyncIridiumTokenComplete());

            return of(null);
          })
        );
    })
  ), {dispatch: false});


  syncCommunityPosts$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_COMMUNITY_POSTS),
    switchMap(() => this.communityProvider.loadCommunityPosts()
      .pipe(
        tap((communityPosts) => this.loadDataCommunityPosts(communityPosts)),
        catchError((error) => {
          console.error('Cannot sync community posts', error);

          return of(null);
        })
      ))
  ), {dispatch: false});

  syncCommunityBookmarkedPosts$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_COMMUNITY_BOOKMARKED_POSTS),
    switchMap(() => this.communityProvider.loadBookmarkedPosts()
      .pipe(
        tap((bookmarkedPosts) => this.loadDataBookmarkedPosts(bookmarkedPosts)),
        catchError((error) => {
          console.error('Cannot sync community posts', error);

          return of(null);
        })
      ))
  ), {dispatch: false});

  syncCommunityJournal$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_COMMUNITY_JOURNAL),
    switchMap(() => this.communityProvider.loadJournal()
      .pipe(
        tap((journal: Journal) => this.loadDataJournal(journal)),
        catchError((error) => {
          console.error('Cannot sync community journal', error);

          return of(null);
        })
      ))
  ), {dispatch: false});

  syncCigCounts$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_CIGS),
    switchMap(() => this.cigCountProvider.getCigCountByDay()
      .pipe(
        tap((data) => this.loadDataCigCountByDay(data)),
        catchError((error) => {
          console.error('Cannot sync cigarettes count by day', error);

          return of(null);
        })
      ))
  ), {dispatch: false});

  syncCheckins$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_CHECKINS),
    switchMap(() => {
      const observables = [];

      // all programs needs checkin counts by day
      observables.push(
        this.checkinsProvider.getCountByDay()
          .pipe(
            tap((checkinsByDay) => this.loadDataCheckinsByDay(checkinsByDay)),
            catchError((error) => {
              console.error('Cannot sync checkins by day', error);

              return of(null);
            })
          )
      );

      // UA needs to pull anxiety values also
      if (this.config.isUA()) {
        observables.push(
          this.checkinsProvider.getAnxietyByDay()
            .pipe(
              tap((checkinAnxietyByDay: CheckinAnxietyByDay) => this.loadDataCheckinAnxietyByDay(checkinAnxietyByDay)),
              catchError((error) => {
                console.error('Cannot sync anxiety by day', error);

                return of(null);
              })
            )
        );
      }

      return forkJoin(observables);
    })
  ), {dispatch: false});

  syncStressTests$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_STRESS_TESTS),
    switchMap(() => this.stressTestProvider.getCountByDay()
      .pipe(
        tap((stressTestsByDay) => this.loadDataStressTestsByDay(stressTestsByDay)),
        catchError((error) => {
          console.error('Cannot sync stress tests by day', error);

          return of(null);
        })
      ))
  ), {dispatch: false});

  syncCravingMeters$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_CRAVING_METERS),
    switchMap(() => this.stressMeterProvider.getCountByDay()
      .pipe(
        tap((cravingMetersByDay) => this.loadDataCravingMetersByDay(cravingMetersByDay)),
        catchError((error) => {
          console.error('Cannot sync carving meters by day', error);

          return of(null);
        })
      ))
  ), {dispatch: false});

  syncDisOMeters$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_DIS_O_METERS),
    switchMap(() => this.cravingToolProvider.getCountByDay()
      .pipe(
        tap((disOMetersByDay) => this.loadDataCravingToolsByDay(disOMetersByDay)),
        catchError((error) => {
          console.error('Cannot sync dis o meters by day', error);

          return of(null);
        })
      ))
  ), {dispatch: false});

  syncAwarenessQuizzes$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_AWARENESS_QUIZZES),
    switchMap(() => this.awarenessQuizzesProvider.loadAwarenessQuizzes()
      .pipe(
        tap((awarenessQuizzes) => this.loadDataAwarenessQuizzes(awarenessQuizzes)),
        catchError((error) => {
          console.error('Cannot sync awareness quizzes by day', error);

          return of(null);
        })
      ))
  ), {dispatch: false});

  syncAnxietyQuizzes$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_ANXIETY_QUIZZES),
    switchMap(() => this.anxietyQuizzesProvider.loadAnxietyQuizzes()
      .pipe(
        tap((anxietyQuizzes) => this.loadDataAnxietyQuizzes(anxietyQuizzes)),
        catchError((error) => {
          console.error('Cannot sync anxiety quizzes by day', error);

          return of(null);
        })
      ))
  ), {dispatch: false});

  syncProgramDaysByDay$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_PROGRAM_DAYS_BY_DAY),
    switchMap(() => this.programDaysByDay())
  ), { dispatch: false });

  syncVideoChatSchedules$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_VIDEO_CHAT_SCHEDULES),
    switchMap(() => this.communityProvider.loadVideoChat()
      .pipe(
        tap((videoChatSchedules) => this.loadDataVideoChats(videoChatSchedules)),
        catchError((error) => {
          console.error('Cannot sync video chat schedules', error);

          return of(null);
        })
      ))
  ), {dispatch: false});

  syncWeightActivities$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_WEIGHT_ACTIVITIES),
    switchMap(() => this.weightActivitiesProvider.loadWeightActivities()
      .pipe(
        tap((weightActivities) => this.loadDataWeightActivities(weightActivities)),
        catchError((error) => {
          console.error('Cannot sync weight activities', error);

          return of(null);
        })
      ))
  ), {dispatch: false});

  syncMinutesActivities$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_MINUTES_ACTIVITIES),
    switchMap(() => this.minutesActivitiesProvider.loadMinutesActivities()
      .pipe(
        tap((minutesActivities) => this.loadDataMinutesActivities(minutesActivities)),
        catchError((error) => {
          console.error('Cannot sync minutes activities', error);

          return of(null);
        })
      ))
  ), {dispatch: false});

  syncConnectedApplications$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_CONNECTED_APPLICATIONS),
    switchMap(() => this.connectedApplicationsProvider.getConnectedApplications()
      .pipe(
        tap(connectedApplications => this.loadConnectedApplications(connectedApplications)),
        catchError((error) => {
          console.error('Cannot sync connected applications', error);

          return of(null);
        })
      ))
  ), {dispatch: false});

  syncThemeWeeks$ = createEffect(() => this.actions$.pipe(
    ofType(syncActions.SYNC_THEME_WEEKS),
    switchMap(() => this.themeWeeksProvider.loadData()
      .pipe(
        tap((themeWeeks) => this.loadDataThemeWeeks(themeWeeks)),
        catchError((error) => {
          console.error('Cannot sync theme weeks', error);

          return of(null);
        })
      ))
  ), { dispatch: false });

  syncWorryTool$ = createEffect(() => this.actions$.pipe(
    ofType(syncActions.SYNC_WORRY_TOOL),
    switchMap(() => this.worryToolProvider.getWorryToolCountByDay()
      .pipe(
        tap((worryTool) => this.loadWorryToolCount(worryTool)),
        catchError((error) => {
          console.error('Cannot sync worry tool', error);

          return of(null);
        })
      ))
  ), { dispatch: false });

  syncUserFavs$ = createEffect(() => this.actions$.pipe(
    ofType(syncActions.SYNC_USER_FAVS),
    switchMap(() => this.favoritesProvider.loadUserFavorites()
      .pipe(
        withLatestFrom(this.sessionState.select(getUserFavs)),
        map(([favs, localFavs]) => {

          localFavs.forEach(fav => {
            this.sessionState.dispatch(new RemoveData({
              id: `${fav.id}`,
              schema: userFavoriteSchema
            }));
          });

          this.loadDataUserFavorites(favs);
        }),
        catchError((error) => {
          console.error('Cannot sync user favorites', error);

          return of(null);
        })))
  ), { dispatch: false });

  syncGoals$ = createEffect(() => this.actions$.pipe(
    ofType(syncActions.SYNC_GOALS),
    switchMap(() => this.goals())
  ), { dispatch: false });

  syncTriggers$ = createEffect(() => this.actions$.pipe(
    ofType(syncActions.SYNC_TRIGGERS),
    switchMap(() => this.triggers())
  ), { dispatch: false });

  syncBodyParts$ = createEffect(() => this.actions$.pipe(
    ofType(syncActions.SYNC_BODY_PARTS),
    switchMap(() => this.bodyParts())
  ), { dispatch: false });

  syncBodySides$ = createEffect(() => this.actions$.pipe(
    ofType(syncActions.SYNC_BODY_SIDES),
    switchMap(() => this.bodySides())
  ), { dispatch: false });

  syncSensations$ = createEffect(() => this.actions$.pipe(
    ofType(syncActions.SYNC_SENSATIONS),
    switchMap(() => this.sensations())
  ), { dispatch: false });

  syncFeelings$ = createEffect(() => this.actions$.pipe(
    ofType(syncActions.SYNC_FEELINGS),
    switchMap(() => this.feelings())
  ), { dispatch: false });

  syncProgramDays$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_PROGRAM_DAYS),
    switchMap(() => this.programDaysProvider.getProgramDays()
      .pipe(
        tap((programDays) => this.loadDataProgramDays(programDays)),
        catchError((error) => {
          console.error('Cannot sync program days', error);

          return of(null);
        })
      ))
  ), {dispatch: false});

  syncExercises$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_EXERCISES),
    switchMap((action: syncActions.SyncExercises) => (
      // in the case it's the first exercise sync, lastTimestamp will be empty
      // TO DO shouldn't we check here for getLastSyncExercises?
      action.lastTimestamp ?
        of(action.lastTimestamp) :
        this.sessionState.select(getLastSyncEverythingAt)
    )),
    switchMap((timestamp: string) => this.exercisesProvider.loadExercisesUpdatedAfter(timestamp)
      .pipe(
        tap((exercises) => this.loadDataExercises(exercises)),
        catchError((error) => {
          console.error('Cannot sync exercises', error);

          return of(null);
        })
      ))
  ), {dispatch: false});

  constructor(
    private config: ClarityConfig,
    private actions$: Actions,
    private sessionState: Store<SessionState>,
    private loading: LoadingService,
    private translate: TranslateService,
    private alerts: AlertController,
    private toasts: ToastService,
    private logger: LoggerService,
    private userDataSyncChannelService: UserDataSyncChannelService,
    private fileService: FileService,
    private syncResolutionService: SyncResolutionService,
    // providers
    private userProvider: UserProvider,
    private userProgramProvider: UserProgramProvider,
    private remindersProvider: RemindersProvider,
    private userRemindersProvider: UserRemindersProvider,
    private userBootstrapProvider: UserBootstrapProvider,
    private userProgressProvider: UserProgressProvider,
    private programProvider: ProgramProvider,
    private programDaysProvider: ProgramDaysProvider,
    private exercisesProvider: ExercisesProvider,
    private subscriptionsProvider: SubscriptionsProvider,
    private themeWeeksProvider: ThemeWeeksProvider,
    private symptomsProvider: SymptomsProvider,
    private checkinsProvider: CheckinsProvider,
    private listItemsProvider: ListItemsProvider,
    private goalsProvider: GoalsProvider,
    private communityProvider: CommunityProvider,
    private userActivitiesProvider: UserActivitiesProvider,
    private stressTestProvider: StressTestProvider,
    private stressMeterProvider: StressMeterProvider,
    private userProgramSessionProvider: UserProgramSessionProvider,
    private awarenessQuizzesProvider: AwarenessQuizzesProvider,
    private anxietyQuizzesProvider: AnxietyQuizzesProvider,
    private cigCountProvider: CigCountProvider,
    private subtitlesProvider: SubtitlesProvider,
    private cravingToolProvider: CravingToolProvider,
    private favoritesProvider: UserFavoritesProvider,
    private weightActivitiesProvider: WeightActivitiesProvider,
    private minutesActivitiesProvider: MinutesActivitiesProvider,
    private worryToolProvider: WorryToolProvider,
    private connectedApplicationsProvider: ConnectedApplicationsProvider,
    private iridiumProvider: IridiumProvider,
    private temporaryTokenProvider: TemporaryTokenProvider
  ) {

  }

  removeExerciseFromName(name: string) {
    return name.replace('/exercises/', '');
  }

  getAllProviders() {
    const commonProviders = [];

    if (!this.config.jwplayerEnabled()) {
      // subtitles are referenced by the exercises so they must be loaded before the exercises or it will crash silently!
      commonProviders.push(
        this.subtitles()
          .pipe(switchMap(() => forkJoin([
            this.program.bind(this)(),
            this.bonusExercises.bind(this)(),
            this.rainExercises.bind(this)()
          ])))
      );
    }
    else {
      // if jwplayer is not enabled, we still need to push the 3 endpoints
      commonProviders.push(
        this.program(),
        this.bonusExercises(),
        this.rainExercises()
      );
    }

    commonProviders.push(
      this.userAccount(), this.userProgram(), this.userProgramSession(), this.subscription(),
      this.userBootstrap(), this.userProgress(), this.themeWeeks(),
      this.reminders(), this.userReminders(),
      this.goals(), this.userGoals(), this.userFavorites(),
      this.recentTriggers(),
      this.programDaysByDay(), this.userWeekDaysByDay(), this.checksInsByDay(), this.cravingMetersByDay(),
      this.feelings(), this.triggers(), this.symptoms(),
      this.checkinExercises(), this.checkinQuestions(),
      this.videoChats()
    );

    if (this.config.iridiumAllowed()) {
      commonProviders.push(this.iridiumToken());
    }

    if (this.config.isERN()) {
      return [
        ...commonProviders,
        this.weightActivities(),
        this.minutesActivities(),
        this.stressTestsByDay(),
        this.cravingToolsByDay(),
        this.awarenessQuizzes(),
        this.journal(),
        this.latestPosts(),
        this.latestBookmarkedPosts(),
        this.connectedApplications()
      ];
    }
    else if (this.config.isUA()) {
      return [
        ...commonProviders,
        this.stressTestsByDay(),
        this.anxietyQuizzes(),
        this.checkinAnxietyByDay(),
        this.sensations(),
        this.bodySides(),
        this.bodyParts(),
        this.awarenessQuizzes(),
        this.journal(),
        this.latestPosts(),
        this.latestBookmarkedPosts(),
        this.worryToolByDay()
      ];
    }
    else if (this.config.isCTQ()) {
      return [...commonProviders, ...[
        this.cigaretteCountByDay(),
        this.cravingToolsByDay()
      ]];
    }
  }

  private subtitles() {
    return this.subtitlesProvider.loadSubtitles()
      .pipe(
        switchMap(
          data => Promise.all(
            // TO DO fix: data could be null/undefined here, so data.map can fail
            data.map(
              (subtitle) => this.fileService.saveSubtitle(subtitle)
                .then((filePath) => ({id: subtitle.id, language_code: subtitle.language_code, file_path: filePath}))
            ))
            .catch((error) => {
              this.logger.error('Cannot save subtitle files', error, 'SyncEffects');

              return [];
            })
        ),
        map(data => this.loadSubtitles(data))
      );
  }

  // private removeSubtitles() {
  //   return new SetData({data: [], schema: normalized.subtitleSchema});
  // }
  //
  // private removeMediaFiles() {
  //   return new SetData({data: [], schema: normalized.mediaFileSchema});
  // }

  private userAccount() {
    return this.userProvider.loadUserAccount()
      .pipe(tap(data => this.loadDataUserAccount(data)));
  }

  private userProgram() {
    return this.userProgramProvider.getUserProgram()
      .pipe(tap((data) => {
        this.loadDataUserProgram(data);
      }));
  }

  private reminders() {
    return this.remindersProvider.loadReminders()
      .pipe(tap((data) => this.loadDataReminders(data)));
  }

  private userReminders() {
    return this.userRemindersProvider.loadUserReminders()
      .pipe(tap((data) => this.loadDataUserReminders(data)));
  }

  private userBootstrap() {
    return this.userBootstrapProvider.getUserBootstrap()
      .pipe(tap((data) => this.loadDataUserBootstrap(data)));
  }

  private userProgress() {
    return this.userProgressProvider.getUserProgress()
      .pipe(tap((data) => this.loadDataUserProgress(data)));
  }

  private program() {
    return this.programProvider.loadProgramBootstrap()
      .pipe(tap((data) => this.loadDataProgram(data)));
  }

  private themeWeeks() {
    return this.themeWeeksProvider.loadData()
      .pipe(tap((data) => this.loadDataThemeWeeks(data)));
  }

  private symptoms() {
    return this.symptomsProvider.loadData()
      .pipe(tap((data) => this.loadDataSymptoms(data)));
  }

  private checkinExercises() {
    return this.checkinsProvider.loadCheckinExercises()
      .pipe(tap((data) => this.loadDataCheckinExercises(data)));
  }

  private checkinQuestions() {
    return this.checkinsProvider.loadCheckinQuestions()
      .pipe(tap((data) => this.loadDataCheckinQuestions(data)));
  }

  private feelings() {
    return this.listItemsProvider.loadFeelings()
      .pipe(tap((data) => this.loadDataFeelings(data)));
  }

  private triggers() {
    return this.listItemsProvider.loadTriggers()
      .pipe(tap((data) => this.loadDataTriggers(data)));
  }

  private goals() {
    return this.listItemsProvider.loadGoals()
      .pipe(tap((data) => this.loadDataGoals(data)));
  }

  private userFavorites() {
    return this.favoritesProvider.loadUserFavorites()
      .pipe(tap((data) => this.loadDataUserFavorites(data)));
  }

  private sensations() {
    return this.listItemsProvider.loadSensations()
      .pipe(tap((data) => this.loadDataSensations(data)));
  }

  private bodyParts() {
    return this.listItemsProvider.loadBodyParts()
      .pipe(tap((data) => this.loadDataBodyParts(data)));
  }

  private bodySides() {
    return this.listItemsProvider.loadBodySides()
      .pipe(tap((data) => this.loadDataBodySides(data)));
  }

  private userGoals() {
    return this.goalsProvider.loadUserGoals()
      .pipe(tap((data) => this.loadDataUserGoals(data)));
  }

  private bonusExercises() {
    return this.exercisesProvider.loadBonusExercises()
      .pipe(tap((data) => this.loadDataBonusExercises(data)));
  }

  private rainExercises() {
    return this.exercisesProvider.loadExercisesByTag('rain')
      .pipe(tap((data) => this.loadDataRainExercises(data)));
  }

  private journal() {
    return this.communityProvider.loadJournal()
      .pipe(tap((data) => this.loadDataJournal(data)));
  }

  private latestPosts() {
    return this.communityProvider.loadCommunityPosts()
      .pipe(tap((data) => this.loadDataCommunityPosts(data)));
  }

  private latestBookmarkedPosts() {
    return this.communityProvider.loadBookmarkedPosts()
      .pipe(tap((data) => this.loadDataBookmarkedPosts(data)));
  }

  private videoChats() {
    return this.communityProvider.loadVideoChat()
      .pipe(tap((data) => this.loadDataVideoChats(data)));
  }

  private iridiumToken() {
    return this.temporaryTokenProvider.getTemporaryTokenAndUriForIridium().pipe(
      // 401 error codes are caught and mapped to empty responses in http.provider.ts
      filter(tokenResponse => Boolean(tokenResponse.uri) && Boolean(tokenResponse.token)),
      tap(tokenResponse => this.sessionState.dispatch(new authActions.SetIridiumHost(tokenResponse.uri))),
      switchMap(tokenResponse => this.iridiumProvider.getTokenWithTemporaryToken(tokenResponse.token)),
      filter(data => Boolean(data.token)),
      tap(data => this.loadDataIridiumToken(data.token))
    );
  }

  private recentTriggers() {
    return this.stressMeterProvider.getRecent()
      .pipe(tap((data) => this.loadDataRecentTriggers(data)));
  }

  private checksInsByDay() {
    return this.checkinsProvider.getCountByDay()
      .pipe(tap((data) => this.loadDataCheckinsByDay(data)));
  }

  private stressTestsByDay() {
    return this.stressTestProvider.getCountByDay()
      .pipe(tap((data) => this.loadDataStressTestsByDay(data)));
  }

  private cravingMetersByDay() {
    return this.stressMeterProvider.getCountByDay()
      .pipe(tap((data) => this.loadDataCravingMetersByDay(data)));
  }

  private cravingToolsByDay() {
    return this.cravingToolProvider.getCountByDay()
      .pipe(tap((data) => this.loadDataCravingToolsByDay(data)));
  }

  private programDaysByDay() {
    return this.userActivitiesProvider.getProgramDaysByDay()
      .pipe(tap((data) => this.loadDataProgramDaysByDay(data)));
  }

  private userWeekDaysByDay() {
    return this.userActivitiesProvider.getUserWeekDaysByDay()
      .pipe(tap((data) => this.loadDataUserWeeksDaysByDay(data)));
  }

  private cigaretteCountByDay() {
    return this.cigCountProvider.getCigCountByDay()
      .pipe(tap((data) => this.loadDataCigCountByDay(data)));
  }

  private userProgramSession() {
    return this.userProgramSessionProvider.getUserProgramSession()
      .pipe(tap((data) => this.loadDataUserProgramSession(data)));
  }

  private awarenessQuizzes() {
    return this.awarenessQuizzesProvider.loadAwarenessQuizzes()
      .pipe(tap((data) => this.loadDataAwarenessQuizzes(data)));
  }

  private anxietyQuizzes() {
    return this.anxietyQuizzesProvider.loadAnxietyQuizzes()
      .pipe(tap((data) => this.loadDataAnxietyQuizzes(data)));
  }

  private checkinAnxietyByDay() {
    return this.checkinsProvider.getAnxietyByDay()
      .pipe(tap((data) => this.loadDataCheckinAnxietyByDay(data)));
  }

  private worryToolByDay() {
    return this.worryToolProvider.getWorryToolCountByDay()
      .pipe(tap((data) => this.loadWorryToolCount(data)));
  }

  private subscription() {
    return this.subscriptionsProvider.loadSubscriptions()
      .pipe(tap((data) => this.loadDataSubscription(data)));
  }

  private weightActivities() {
    return this.weightActivitiesProvider.loadWeightActivities()
      .pipe(tap((data) => this.loadDataWeightActivities(data)));
  }

  private minutesActivities() {
    return this.minutesActivitiesProvider.loadMinutesActivities()
      .pipe(
        tap(data => this.loadDataMinutesActivities(data)),
        catchError(_ => of(null))
      );
  }

  private connectedApplications() {
    return this.connectedApplicationsProvider.getConnectedApplications()
      .pipe(tap(data => this.loadConnectedApplications(data)));
  }

  private loadSubtitles(data) {
    this.sessionState.dispatch(new AddData<Subtitle>({
      data,
      schema: subtitleSchema
    }));
  }

  private loadDataUserAccount(data) {
    this.currentLang = data.language_code;

    this.sessionState.dispatch(new AddData<User>({
      data: [data],
      schema: userSchema
    }));

    this.sessionState.dispatch(new accountActions.LoadUserRefresh(data));
  }

  private loadDataCheckinAnxietyByDay(checkinAnxietyByDay: any) {
    const checkinAnxietyByDayNormalized = Object.keys(checkinAnxietyByDay || {})
      .map((key) => ({
        date: key,
        checkins: checkinAnxietyByDay[key]
      }));

    this.sessionState.dispatch(new AddData<CheckinAnxietyByDay>({
      data: checkinAnxietyByDayNormalized,
      schema: checkinAnxietyByDaySchema
    }));
  }

  private loadDataAnxietyQuizzes(anxietyQuizzes: any) {
    this.sessionState.dispatch(new AddData<AnxietyQuiz>({
      data: anxietyQuizzes || [],
      schema: anxietyQuizSchema
    }));
  }

  private loadDataAwarenessQuizzes(awarenessQuizzes: any) {
    this.sessionState.dispatch(new AddData<AwarenessQuiz>({
      data: awarenessQuizzes || [],
      schema: awarenessQuizSchema
    }));
  }

  private loadDataUserProgramSession(userProgramSessions: any) {
    this.sessionState.dispatch(new AddData<UserProgramSession>({
      data: [userProgramSessions],
      schema: userProgramSessionSchema
    }));
  }

  private loadDataUserWeeksDaysByDay(userWeeksDaysByDay: any) {
    this.sessionState.dispatch(new AddData<CountByDay>({
      data: objectToListOfCountByDays(userWeeksDaysByDay),
      schema: userWeekDaysByDaySchema
    }));
  }

  private loadDataProgramDaysByDay(programsByDay: any) {
    this.sessionState.dispatch(new AddData<CountByDay>({
      data: objectToListOfCountByDays(programsByDay),
      schema: programDaysByDaySchema
    }));
  }

  private loadDataCravingMetersByDay(wantOMetersByDay: any) {
    this.sessionState.dispatch(new AddData<CountByDay>({
      data: objectToListOfCountByDays(wantOMetersByDay),
      schema: wantOMeterByDaySchema
    }));
  }

  private loadDataCravingToolsByDay(cravingToolsByDay: any) {
    this.sessionState.dispatch(new AddData<CountByDay>({
      data: objectToListOfCountByDays(cravingToolsByDay),
      schema: cravingToolsByDaySchema
    }));
  }

  private loadDataStressTestsByDay(stressTestsByDay: any) {
    this.sessionState.dispatch(new AddData<CountByDay>({
      data: objectToListOfCountByDays(stressTestsByDay),
      schema: stressTestsByDaySchema
    }));
  }

  private loadDataCheckinsByDay(checkinsByDay: any) {
    this.sessionState.dispatch(new AddData<CountByDay>({
      data: objectToListOfCountByDays(checkinsByDay),
      schema: checkinsByDaySchema
    }));
  }

  private loadDataCigCountByDay(cigCountByDay: any) {
    this.sessionState.dispatch(new AddData<CountByDay>({
      data: objectToListOfCountByDays(cigCountByDay || {}),
      schema: cigarettesByDaySchema
    }));
  }

  private loadDataRecentTriggers(recentTriggers: any) {
    this.sessionState.dispatch(new AddData<RecentStressMeter>({
      data: recentTriggers || [],
      schema: recentStressMeterSchema
    }));
  }

  private loadDataVideoChats(videoChats: any) {
    this.sessionState.dispatch(new SetData<VideoChat>({
      data: videoChats,
      schema: videoChatsSchema
    }));
  }

  private loadDataIridiumToken(token: string) {
    this.sessionState.dispatch(new authActions.SetIridiumToken(token));
  }

  private loadDataCommunityPosts(posts: any) {
    if (posts) {
      this.sessionState.dispatch(new SetData<CommunityPost>({
        data: posts,
        schema: communityPostSchema
      }));
    }
  }

  private loadDataBookmarkedPosts(posts: any) {
    if (posts && posts.length > 0) {
      this.sessionState.dispatch(new SetData<CommunityPost>({
        data: posts,
        schema: bookmarkedPostSchema
      }));
    }
    // TODO: Replace with ClearData when updated
    // everything was unboormarked, remove posts from store one by one
    else if (posts && posts.length === 0) {
      this.sessionState.select(getBookmarkedPosts)
        .pipe(
          take(1),
          tap((bookmarkedPosts) => {
            if (bookmarkedPosts && bookmarkedPosts.length > 0) {
              bookmarkedPosts.forEach((post) => this.sessionState.dispatch(new RemoveData({
                id: post.id.toString(),
                schema: bookmarkedPostSchema
              })));
            }
          })
        )
        .toPromise();
    }
  }

  private loadDataJournal(journal: any) {
    if (journal) {
      this.sessionState.dispatch(new AddData<Journal>({
        data: [journal],
        schema: journalSchema
      }));
    }
  }

  private loadDataSubscription(subscription: any) {
    this.sessionState.dispatch(new AddData<Subscription>({
      data: [subscription],
      schema: subscriptionSchema
    }));
  }


  private loadDataUserGoals(userGoals: any) {
    this.sessionState.dispatch(new AddData<UserGoal>({
      data: userGoals,
      schema: userGoalsSchema
    }));
  }

  private loadDataBodySides(bodySides: any) {
    if (!bodySides || bodySides?.length === 0) {
      this.logger.error('No bodySides data retrieved from the API', bodySides);
    }

    this.sessionState.dispatch(new AddData<ListItem>({
      data: bodySides,
      schema: listItemSchema
    }));
  }

  private loadDataUserFavorites(userFavs: any) {
    this.sessionState.dispatch(new AddData<UserFavorite>({
      data: userFavs,
      schema: userFavoriteSchema
    }));
  }

  private loadDataBodyParts(bodyParts: any) {
    if (!bodyParts || bodyParts?.length === 0) {
      this.logger.error('No bodyParts data retrieved from the API', bodyParts);
    }

    this.sessionState.dispatch(new AddData<ListItem>({
      data: bodyParts,
      schema: listItemSchema
    }));
  }

  private loadDataSensations(sensations: any) {
    if (!sensations || sensations?.length === 0) {
      this.logger.error('No sensation data retrieved from the API', sensations);
    }

    this.sessionState.dispatch(new AddData<ListItem>({
      data: sensations,
      schema: listItemSchema
    }));
  }

  private loadDataGoals(goals: any) {
    if (!goals || goals?.length === 0) {
      this.logger.error('No goals data retrieved from the API', goals);
    }

    this.sessionState.dispatch(new AddData<ListItem>({
      data: goals,
      schema: listItemSchema
    }));
  }

  private loadDataTriggers(triggers: any) {
    if (!triggers || triggers?.length === 0) {
      this.logger.error('No triggers data retrieved from the API', triggers);
    }

    this.sessionState.dispatch(new AddData<ListItem>({
      data: triggers,
      schema: listItemSchema
    }));
  }

  private loadDataFeelings(feelings: any) {
    if (!feelings || feelings?.length === 0) {
      this.logger.error('No feelings data retrieved from the API', feelings);
    }

    this.sessionState.dispatch(new AddData<ListItem>({
      data: feelings,
      schema: listItemSchema
    }));
  }

  private loadDataCheckinQuestions(checkinQuestions: any) {
    this.sessionState.dispatch(new AddData<CheckinQuestion>({
      data: checkinQuestions,
      schema: checkinQuestionSchema
    }));
  }

  private loadDataCheckinExercises(checkinExercises: any) {
    this.sessionState.dispatch(new AddData<CheckinExercise>({
      data: checkinExercises,
      schema: checkinExerciseSchema
    }));
  }

  private loadDataExercises(ExercisesList: any) {
    this.sessionState.dispatch(new AddData<Exercise>({
      data: ExercisesList,
      schema: exerciseSchema
    }));
  }

  private loadDataRainExercises(RainExercisesList: any) {
    this.sessionState.dispatch(new AddData<Exercise>({
      data: RainExercisesList,
      schema: exerciseSchema
    }));
  }

  private loadDataBonusExercises(BonusExercisesList: any) {
    this.sessionState.dispatch(new AddData<BonusExercise>({
      data: BonusExercisesList,
      schema: bonusExerciseSchema
    }));
  }

  private loadDataSymptoms(symptoms: any) {
    this.sessionState.dispatch(new AddData<Symptoms>({
      data: symptoms,
      schema: symptomsSchema
    }));
  }

  private loadDataThemeWeeks(themeWeeks: any) {
    this.sessionState.dispatch(new SetData<ThemeWeek>({
      data: themeWeeks,
      schema: themeWeekSchema
    }));

    // Currently not needed!
    // this.sessionState.dispatch(new AddData<normalized.UserWeek>({
    //   data: userWeeks,
    //   schema: normalized.userWeeksSchema
    // }));
  }

  private loadWorryToolCount(worryTool: any): void {
    this.sessionState.dispatch(new AddData<CountByDay>({
      data: objectToListOfCountByDays(worryTool || {}),
      schema: worryToolByDaySchema
    }));
  }

  private loadDataUserProgress(userProgress: any) {
    // This is not needed anymore, completed records will get wiped automatically when progress is updated with content

    // BUG: In some cases, users' progress will be reset without an obvious reason. The issue cannot be reproduced,
    // but one error log does show the state including only the 'none' set of data dispatched below.
    // It's possible for unknown (i.e. connection) reasons, the data set is empty and thus everything is removed and
    // replaced with the work around below.
    // The only solution at this time is to log errors and make sure the new progress is not empty before clearing.

    // if (userProgress && userProgress.progress && userProgress.progress.modules && userProgress.progress.lessons) {
    //   // reset completed before loading progress
    //   this.sessionState.dispatch(new SetData<Completed>({
    //     data: [],
    //     schema: completedSchema
    //   }));
    //
    //   // workaround ro clear completed progress before we re-sync
    //   this.sessionState.dispatch(new SetData<Completed>({
    //     data: [{id: 'none', completedAt: ''}],
    //     schema: completedSchema
    //   }));
    // }
    // else {
    //   this.logger.error('Invalid userProgress detected', userProgress, 'loadDataUserProgress');
    // }

    const { progress: { modules, lessons }, active_user_week_number } = userProgress;

    this.sessionState.dispatch(new SetData<Progress>({
      data: [
        {id: 'modules', completed: modules},
        {id: 'lessons', completed: lessons}
      ],
      schema: progressSchema
    }));

    this.sessionState.dispatch(new programActions.SetActiveUserWeekNumber(active_user_week_number));
  }

  private loadDataUserBootstrap(userBootstrap: any) {
    this.sessionState.dispatch(new AddData<UserBootstrap>({
      data: [userBootstrap.bootstrap],
      schema: userBootstrapSchema
    }));
  }

  private loadDataProgram(program: any) {
    this.sessionState.dispatch(new AddData<ProgramBootstrap>({
      data: [program],
      schema: programBootstrapSchema
    }));
  }

  private loadDataUserReminders(userReminders: any) {
    this.sessionState.dispatch(new AddData<UserReminder>({
      data: userReminders,
      schema: userReminderSchema
    }));

    this.sessionState.dispatch(new remindersActions.LoadUserRemindersSuccess(userReminders));
  }

  private loadDataReminders(reminders: any) {
    this.sessionState.dispatch(new AddData<Reminder>({
      data: reminders,
      schema: reminderSchema
    }));

    this.sessionState.dispatch(new remindersActions.LoadRemindersSuccess(reminders));
  }

  private loadDataUserProgram(userProgram: any) {
    this.sessionState.dispatch(new AddData<UserProgram>({
      data: [userProgram],
      schema: userProgramSchema
    }));
  }

  private loadDataProgramDays(programDays: any) {
    this.sessionState.dispatch(new AddData<ProgramDay>({
      data: programDays,
      schema: programDaySchema
    }));
  }

  private loadDataWeightActivities(weightActivities: WeightActivity[]) {
    this.sessionState.dispatch(new SetData<WeightActivity>({
      data: weightActivities,
      schema: weightActivitySchema
    }));
  }

  private loadDataMinutesActivities(minutesActivities: MinutesActivity[]) {
    this.sessionState.select(getMinutesActivity).pipe(take(1))
      .subscribe(storageMinutesActivity => {
        this.sessionState.dispatch(new SetData<MinutesActivity>({
          data: this.syncResolutionService.resolveConflict(minutesActivities, storageMinutesActivity, ['minutes','source','activity_at']),
          schema: minutesActivitySchema
        }));
      });
  }

  private loadConnectedApplications(connectedApplications: Integration[]) {
    const isFitbitConnected = connectedApplications.some(integration => integration.key === 'fitbit' && integration.connected);

    this.sessionState.dispatch(new integrationsActions.GetFitbitConnectionStatusAndNavigateSuccess('', isFitbitConnected));
  }

  // fakeInstance(instance) {
  //   return instance.id < 0;
  // }
  //
  // removeOldEntries(entries: any[], schema) {
  //   entries.forEach((entry) => {
  //     if (!this.fakeInstance(entry)) {
  //       this.sessionState.dispatch(new RemoveData({
  //         id: `${entry.id}`,
  //         schema
  //       }));
  //     }
  //   });
  // }

}
