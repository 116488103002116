import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, Output
} from '@angular/core';
import { CustomWeek } from 'src/app/store/session/actions/program.actions';
import { LiveModule } from '../../../store/session/selectors/program.selectors';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-custom-path-drag',
  styleUrls: ['custom-path-drag.component.scss'],
  template: `
    <h4 class="subtitle-custom-path">{{"custom_path.drag" | translate}}</h4>

    <div class="modules-list">
      <ion-list reorder="true" (ionItemReorder)="reorderItems($event)" show-reorder="true">
        <ion-reorder-group id="reorder" disabled="false">
          <ion-item *ngFor="let module of modules" no-lines>
            <cl-module-item
              [isCustomPath]="true"
              [module]="module"
              [selectedModules]="modules"
              [expanded]="true">
            </cl-module-item>
            <ion-label></ion-label>
            <ion-reorder slot="end"></ion-reorder>
          </ion-item>
        </ion-reorder-group>
      </ion-list>
    </div>

    <ion-row class="action-buttons-holder">
      <ion-col class="ion-text-center" auto>
        <cl-action-button
          label="{{'dashboard.start_new_week' | translate}}"
          [canClick]="true"
          (action)="startCustomWeek()">
        </cl-action-button>
      </ion-col>
    </ion-row>
  `
})
export class CustomPathDragComponent {
  @Input() modules: LiveModule[];
  @Output() onStartCustomWeek = new EventEmitter<CustomWeek>();

  constructor() {}

  reorderItems(indexes) {
    const element = this.modules[indexes.detail.from];
    this.modules.splice(indexes.detail.from, 1);
    this.modules.splice(indexes.detail.to, 0, element);

    indexes.detail.complete();
  }

  startCustomWeek() {
    const week = {
      program_days: this.modules.map((module) => module.recordId)
    };

    this.onStartCustomWeek.emit(week);
  }
}
