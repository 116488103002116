import { createSchemaSelectors } from 'ngrx-normalizr';
import { StripePlan, stripePlanSchema, StripeProduct } from '../schemas/stripe-plan.schema';
import { createSelector } from '@ngrx/store';

const stripePlansSchemaSelectors = createSchemaSelectors<StripePlan>(stripePlanSchema);

export const getStripePlans = stripePlansSchemaSelectors.getEntities;

export const formatPrice = (curr: string, num: number, maximumFractionDigits = 2, minimumFractionDigits = 2) => {
  if (!num) {
    return null;
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: curr,
    minimumFractionDigits,
    maximumFractionDigits
  }).format(num);
};

export const getCurrencyLabel = (currency) => {
  if (currency && currency.length > 1) {
    return `${currency} `;
  } else {
    return `${currency}`;
  }
};

export const getProductType = (product) => {
  switch (product.interval) {
    case 'life':
      return 'lifetime';

    case 'month':
      if (product.interval_count === 1) {
        return 'monthly';
      }

      return `${product.interval_count}months`;

    case 'year':
      return 'annual';
  }
};

export const getPricePerWeek = (product): number => {
  switch (getProductType(product)) {
    case 'lifetime':
    case '6months':
      return null;

    case 'monthly':
      return Math.ceil(Number(product.discounted_price_cents) / 100 * 12 / 52);

    case 'annual':
      return Math.ceil(Number(product.discounted_price_cents) / 100 / 52);
  }
};

export const getStripeProducts = createSelector(
  getStripePlans,
  (plans): StripeProduct[] => {
    if (!plans || !plans.length) {
      return [];
    }

    const products = plans.map((plan) => {
      const currency = plan.currency.toUpperCase();

      return {
        ...plan,
        type: 'subscription',
        currency: getCurrencyLabel(currency),
        length: `${plan.name}`,
        priceFormatted: formatPrice(currency, plan.discounted_price_cents / 100),
        fullPriceFormatted: plan.full_price_cents !== plan.discounted_price_cents
          ? formatPrice(currency, plan.full_price_cents / 100)
          : null,
        pricePerWeekFormatted: formatPrice(currency, getPricePerWeek(plan), 0, 0),
        priceMicros: plan.discounted_price_cents ? plan.discounted_price_cents * 10000 : plan.full_price_cents * 10000
      };
    });

    return products;
  }
);
