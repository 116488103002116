import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthToken } from '../store/session/models/auth-token.model';
import { HttpProvider } from './http/http.provider';

interface TemporaryTokenResponse {
  token: string;
}

@Injectable({providedIn: 'root'})
export class TemporaryTokenProvider {
  private temporaryTokenEndpoint = '/temporary_tokens';

  constructor(private http: HttpProvider) {}

  generateTemporaryToken(payload = {}): Observable<string> {
    return this.http.post<TemporaryTokenResponse>(this.temporaryTokenEndpoint, payload)
      .pipe(map(response => response.token));
  }

  getTemporaryTokenAndUriForIridium(): Observable<AuthToken> {
    const params = new HttpParams().set('type', 'temporary_token');

    return this.http.get<AuthToken>('/iridium/token', { params });
  }
}
