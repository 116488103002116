import { Injectable } from '@angular/core';
import { ClarityConfig } from 'src/app/config/clarity.config';

import { Facebook } from '@ionic-native/facebook/ngx';
import { LoggerService } from 'src/app/services/logger.service';
import { MarketingAnalyticsInterface, UpdateUserDataType } from '../analytics.interface';

@Injectable({providedIn: 'root'})
export class FacebookService implements MarketingAnalyticsInterface {

  public SERVICE_NAME = 'facebook';

  constructor(
    private config: ClarityConfig,
    private fb: Facebook,
    private logger: LoggerService
  ) {
  }

  public initialize(): Promise<any> {
    if (!this.config.isDevice) {
      console.log('[Facebook] skipping initialize');

      return Promise.resolve();
    }

    if (this.config.isIos) {
      this.fb.setAdvertiserTrackingEnabled(true).then(() => {
        this.logEvent('app_init');
      });
    } else {
      this.logEvent('app_init');
    }

    return Promise.resolve();
  }

  public resetService(): Promise<void> {
    return Promise.resolve();
  }

  public registerUser(data: UpdateUserDataType): Promise<any> {
    return Promise.resolve(false);
  }

  public trackSubscription(eventType: string, subscription: any = {}, product: any = {}) {
    // @todo confirm if this if is correct
    if (eventType !== 'subscription-purchased') {
      console.log('Facebook skipping trackSubscription');

      return;
    }

    // Add logs for CLARITY-794. It Seems like there is a random error.
    // In some cases, value & currency are not sent to facebook.
    // We need to check if this issue come from clarity code / ionic plugin / facebook SDK.
    if(!product.priceMicros || !product.currency) {
      return this.logger.error(`Facebook subscribe event issue. Missing price or currency. Price : ${product.priceMicros} , Currency : ${product.currency}`,
        JSON.stringify(product));
    }

    this.logEvent('Subscribe', {
      subscription_id: subscription.id,
      value: product.priceMicros / 1000000, // 139990000 to 139.99,
      currency: product.currency
    });
  }

  private logEvent(eventName: string, params = {}) {
    if (!this.config.isDevice) {
      return Promise.resolve(false);
    }

    return this.fb.logEvent(eventName, params)
      .then((res) => {
        console.log(`[Facebook] SUCCESS logEvent ${eventName}`, JSON.stringify(res));
      })
      .catch((error) => {
        console.log(`[Facebook] ERROR logEvent ${eventName}`, JSON.stringify((error)));
        this.logger.error(error);
      });
  }

}
