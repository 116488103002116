import { Injectable } from '@angular/core';
import { Device, OperatingSystem } from '@capacitor/device';
import { ClarityConfig } from 'src/app/config/clarity.config';
import { LoggerService } from '../logger.service';
import { IRootPlugin } from './iroot.plugin';

@Injectable({providedIn: 'root'})
export class IRootService {
  constructor(
    private readonly iRootPlugin: IRootPlugin,
    private readonly config: ClarityConfig,
    private readonly logger: LoggerService
  ) {}

  // this.logger must not be used in this function unless
  // the app.component initialization order is changed to support that
  isRooted(isRootedCallback: () => void) {
    return new Promise<void>((resolve, reject) => {
      if (this.config.isWebApp) {
        return resolve();
      }

      Device.getInfo().then(deviceInfo => {
        if (deviceInfo.isVirtual) {
          console.log('Running app on virtual device. Skipping isRooted check.');

          return resolve();
        }

        if (deviceInfo.operatingSystem !== 'ios') {
          console.log(`Running on ${deviceInfo.operatingSystem}. Skipping isRooted check.`);

          return resolve();
        }

        this.iRootPlugin.isRooted(
          isRooted => {
            if (isRooted) {
              isRootedCallback();
              reject('Device is rooted or jailbroken.');
            } else {
              resolve();
            }
          },
          error => {
            console.error('Error during root detection:', error);
            resolve();
          }
        );
      });
    });
  }

  logRootStatus() {
    return new Promise<void>((resolve, _) => {
      if (this.config.isWebApp) {
        return resolve();
      }

      Device.getInfo().then(deviceInfo => {
        if (deviceInfo.operatingSystem !== 'ios' && deviceInfo.operatingSystem !== 'android') {
          this.logger.error(`Unknown OS on isRooted check: ${deviceInfo.operatingSystem}`);
        }

        return resolve();
      });
    });
  }
}
