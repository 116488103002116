import { createSchemaSelectors } from 'ngrx-normalizr';
import { NotificationFeed, notificationFeedSchema, notificationsSchema } from '../../normalized/schemas/inbox.schema';
import { createSelector } from '@ngrx/store';
import { getNotificationState } from '../../state.reducer';
import {
  allNotificationsAreLoaded,
  allNotificationsAreLoading,
  notificationFeedId,
  notificationsAreLoaded,
  notificationsAreLoading,
  unreadCoachNotifications
} from '../reducers/notifications.reducer';
import {
  getAllMyCoachNotificationsLoaded,
  getAllMyCoachNotificationsLoading,
  getMyCoachNotificationsFromFeed,
  getMyCoachNotificationsLoaded,
  getMyCoachNotificationsLoading,
  getUnreadCoachMessagesCount,
  isAllMyCoachNotificationsLoaded
} from './my-coach.selectors';

const notificationsSchemaSelector = createSchemaSelectors<Comment>(notificationsSchema),
  notificationFeedSchemaSelector = createSchemaSelectors<NotificationFeed>(notificationFeedSchema);

export const getNotificationFeedId = createSelector(
  getNotificationState,
  notificationFeedId
);

const getNotificationFeeds = notificationFeedSchemaSelector.getEntities;

export const getNotificationFeedById = createSelector(
  notificationFeedSchemaSelector.getNormalizedEntities,
  getNotificationFeedId,
  notificationFeedSchemaSelector.entityProjector
);

export const getCommunityNotificationsFromFeed = createSelector(
  getNotificationFeedById,
  (notificationFeed: NotificationFeed) => {
    if (!notificationFeed || !notificationFeed.list) {
      return [];
    }

    return notificationFeed.list.sort((a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
  }
);

export const getCurrentNotificationFeed = createSelector(
  getNotificationFeeds,
  (notificationFeeds) => {

    if (!notificationFeeds || notificationFeeds.length === 0) {
      return ({} as NotificationFeed);
    }

    return notificationFeeds[0];
  }
);

export const getAllCommunityNotificationsLoaded = createSelector(
  allNotificationsAreLoaded,
  getCurrentNotificationFeed,
  (notificationsLoaded, notificationFeed): any => {
    if (!notificationFeed || !notificationFeed.list) return false;

    return !!notificationsLoaded || notificationFeed.total_count <= notificationFeed.list.length;
  }
);

export const isAllCommunityNotificationsLoaded = createSelector(
  allNotificationsAreLoaded,
  getCurrentNotificationFeed,
  (notificationsLoaded, notificationFeed): any => notificationFeed && notificationFeed.list ? notificationFeed.list.length >= notificationFeed.total_count : 0
);

export const getCommunityNotificationsLoading = createSelector(
  getNotificationState,
  notificationsAreLoading
);

export const getCommunityNotificationsLoaded = createSelector(
  getNotificationState,
  notificationsAreLoaded
);

export const getAllCommunityNotificationsLoading = createSelector(
  getNotificationState,
  allNotificationsAreLoading
);

export const getLastUnreadNotificiationId = createSelector(
  getCommunityNotificationsFromFeed,
  (notifications) => {
    if (notifications && notifications.length) {
      return notifications[0].id;
    }

    return null;
  }
);

export const getUnreadCoachNotifications = createSelector(
  getNotificationState,
  unreadCoachNotifications
);

export const getUnreadAllNotifications = createSelector(
  getCurrentNotificationFeed,
  getUnreadCoachMessagesCount,
  (notificationsFeed, unreadCoachMessagesCount) => {
    if (!notificationsFeed || !notificationsFeed.total_unread_count) {
      // show unread coach messages count if there are no new notifications
      return unreadCoachMessagesCount;
    }

    return notificationsFeed.total_unread_count;
  }
);

export const getNotificationsLoading = createSelector(
  getCommunityNotificationsLoading,
  getMyCoachNotificationsLoading,
  (communityLoading, myCoachLoading) => communityLoading || myCoachLoading
);

export const getNotificationsLoaded = createSelector(
  getCommunityNotificationsLoaded,
  getMyCoachNotificationsLoaded,
  (communityLoaded, myCoachLoaded) => communityLoaded && myCoachLoaded
);

export const getAllNotificationsLoaded = createSelector(
  getAllCommunityNotificationsLoaded,
  getAllMyCoachNotificationsLoaded,
  (communityLoaded, myCoachLoaded) => communityLoaded && myCoachLoaded
);

export const getAllNotificationsLoading = createSelector(
  getAllCommunityNotificationsLoading,
  getAllMyCoachNotificationsLoading,
  (communityLoading, myCoachLoading) => communityLoading || myCoachLoading
);

export const isAllNotificationsLoaded = createSelector(
  isAllCommunityNotificationsLoaded,
  isAllMyCoachNotificationsLoaded,
  (communityLoaded, myCoachLoaded) => communityLoaded && myCoachLoaded
);

export const getNotificationsFromFeed = createSelector(
  getCommunityNotificationsFromFeed,
  getMyCoachNotificationsFromFeed,
  isAllNotificationsLoaded,
  (communityNotifications, myCoachNotifications, allNotificationsLoaded) => {
    const notifications = [...communityNotifications, ...myCoachNotifications]
      .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

    if (!allNotificationsLoaded) {
      return notifications.slice(0, 10);
    }

    return notifications;
  }
);
