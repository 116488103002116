// navigation actions

import { Action } from '@ngrx/store';
import { LiveBonusExercise, LiveLesson } from '../selectors/program.selectors';
import { MediaFile } from '../../normalized/schemas/mediaFile.schema';
import { CravingToolStatus } from '../../../services/wizards/craving-tool.service';
import { AfterCompleteLessonOptions } from './program.actions';
import { InAppMessage } from '../../normalized/schemas/my-coach.schema';
import { LockedInterstitialSubTypes } from 'src/app/services/lock-interstitial.service';

export interface NavigationOptions {
  route?: string;
  page?: string;
  params?: any;
}

export interface InterstitialOptions {
  type: string;
  badge?: string;
  hideBadge?: boolean;
  title?: string;
  subTitle?: string;
  notes?: string;
  reminderSettingsLink?: boolean;
  bonusExercisesLink?: boolean;
  communityLink?: boolean;
  goal?: string;
  largeText?: boolean;

  showOnboarding?: string;
  video?: MediaFile;
  forceOnboarding?: boolean;

  showNextSteps?: boolean;
  closeAllOnDismiss?: boolean;
  buttonText?: string;
  forceBackdrop?: boolean;
}

export interface LockedInterstitialOptions {
  type: 'locked';
  lockedSubType: LockedInterstitialSubTypes;

  notes: string;
  bonusExercise?: string;
  playModule?: number;
  playModuleTitle?: string;
  showLockDetails?: boolean;
  currentModule?: number;
  currentModuleTitle?: string;
}

export interface OnboardingCustomSettings {
  size: string;
  header: string;
  body?: string;
  button?: string;
  link?: string;
  dontCompleteOnLinkClick?: boolean;
  dontCompleteOnButtonClick?: boolean;
  actionOnButton?: Action;
  callbackOnButton?: Function;
  actionOnLink?: Action;
  actAsConfirm?: boolean;
  hideCloseIcon?: boolean;
  hideInHelpScreen?: boolean;
  dppwlProgram?: boolean;
}

export interface OnboardingOptions {
  type: string;
  video?: MediaFile;
  onboardingSettings?: OnboardingCustomSettings;
}

export interface WizardNavigationOptions {
  type: string;
  afterCompleteOptions?: AfterCompleteLessonOptions;
}

export interface InterstitialNavigationOptions extends NavigationOptions {
  /** @deprecated this property isn't needed anymore */
  page?: 'InterstitialPage';
  params: LockedInterstitialOptions | InterstitialOptions;
}

export interface OnboardingNavigationOptions extends NavigationOptions {
  params: OnboardingOptions;
}

// entry point redirecting
export const REDIRECT_AFTER_LOGIN = '[navigation] Redirect After Login';

// main gotos
export const ROOT_GOTO = '[navigation] ROOT GO TO';
export const CURRENT_GOTO = '[navigation] Current GO TO';
export const GOTO_DASHBOARD = '[navigation] Dashboard';
export const GOTO_HOME = '[navigation] Home';
export const GOTO_LOGIN = '[navigation] Login';
export const GOTO_UPGRADE = '[navigation] Upgrade';
export const GOTO_LESSONS = '[navigation] Lessons';

// specific pages
export const OPEN_LESSONS = '[navigation] Open Lessons';
export const OPEN_MODAL = '[navigation] Open Modal';
export const CLOSE_LESSONS = '[navigation] Close Lessons';
export const CLOSE_MODAL = '[navigation] Close Modal';
export const OPEN_PLAYER = '[navigation] Open Player';
export const CLOSE_PLAYER = '[navigation] Close Player';
export const CLOSE_EXERCISE_PLAYER = '[navigation] Close Exercise Player';
export const OPEN_PERFORM = '[navigation] Open Perform';
export const CLOSE_PERFORM = '[navigation] Close Perform';
export const OPEN_CRAVING_TOOL = '[navigation] CravingTool';
export const OPEN_BONUS_EXERCISE = '[navigation] Bonus Exercise';
export const OPEN_WIZARD = '[navigation] Wizard';
export const OPEN_MY_COACH = '[navigation] Open MyCoach';
export const OPEN_MY_COACH_CONVERSATION = '[navigation] Open MyCoach Conversation';
export const OPEN_WEEKLY_CALLS = '[navigation] Open Weekly Calls';
export const LAUNCH_INTERCOM = '[navigation] Launch Intercom';
export const TABS_GOTO = '[navigation] Tabs GO TO';
export const OPEN_INAPP_MESSAGE = '[navigation] Open Inapp Message';

// interstitials
export const SHOW_INTERSTITIAL = '[navigation] Show Interstitial';

// onboarding
export const SHOW_ONBOARDING = '[navigation] Show Onboarding';

// subscriptions
export const SHOW_UPGRADE_POPUP = '[navigation] Show Upgrade Popup';
export const SHOW_UPGRADE_SUCCESS_POPUP = '[navigation] Show Upgrade Success Popup';

// next steps
export const SHOW_NEXT_STEPS = '[navigation] Show Next Steps';

// breathe exercise
export const SHOW_BREATHE_EXERCISE = '[navigation] Show Breathe Exercise';

// other navigation
export const CLOSE_LAST_MODAL = '[navigation] Close last modal';
export const CLOSE_ALL_MODALS = '[navigation] Close all modals';
export const CLOSE_INTERSTITIAL = '[navigation] Close Interstitial';

export class RedirectAfterLogin implements Action {
  readonly type = REDIRECT_AFTER_LOGIN;
}

export class RootGoTo implements Action {
  readonly type = ROOT_GOTO;

  constructor(public payload: NavigationOptions) {
  }
}

export class CurrentGoTo implements Action {
  readonly type = CURRENT_GOTO;

  constructor(public payload: NavigationOptions) {
  }
}

export class TabsGoTo implements Action {
  readonly type = TABS_GOTO;

  constructor(public payload: number, public section: string = null) {
  }
}

export class GotoDashboard implements Action {
  readonly type = GOTO_DASHBOARD;
}

export class GotoHome implements Action {
  readonly type = GOTO_HOME;
}

export class GotoLogin implements Action {
  readonly type = GOTO_LOGIN;
}

export class GotoUpgrade implements Action {
  readonly type = GOTO_UPGRADE;

  constructor(public payload: boolean) {
  }
}

export class GotoLessons implements Action {
  readonly type = GOTO_LESSONS;
}

export class CloseExercisePlayer implements Action {
  readonly type = CLOSE_EXERCISE_PLAYER;
}

export class ClosePlayer implements Action {
  readonly type = CLOSE_PLAYER;
}

export class OpenLessons implements Action {
  readonly type = OPEN_LESSONS;
}

export class CloseLessons implements Action {
  readonly type = CLOSE_LESSONS;
}

export class CloseModal implements Action {
  readonly type = CLOSE_MODAL;

  constructor(public payload: {
    /** @deprecated use modalId instead */
    modal?: any;
    role?: string;
    modalId?: string;
  }) {
  }
}

export class OpenPerform implements Action {
  readonly type = OPEN_PERFORM;
}

export class ClosePerform implements Action {
  readonly type = CLOSE_PERFORM;
}

export class OpenCravingTool implements Action {
  readonly type = OPEN_CRAVING_TOOL;

  constructor(public payload: CravingToolStatus) {
  }
}

export class OpenModal implements Action {
  readonly type = OPEN_MODAL;

  constructor(public payload: string, public options: {
    record?: any;
    cssClass?: string;
    multipleModal?: boolean;
    forceBackdrop?: boolean;
    enterAnimation?: any;
    leaveAnimation?: any;
    route?: any;
    redirectOnClose?: any;
    onDidDismiss?: any;
    onWillDismiss?: any;
    onDidPresent?: any;
    [key: string]: any;
  } = {}) {
  }
}

export class OpenBonusExercise implements Action {
  readonly type = OPEN_BONUS_EXERCISE;

  constructor(public payload: LiveBonusExercise) {
  }
}

export class OpenWizard implements Action {
  readonly type = OPEN_WIZARD;

  constructor(public payload: WizardNavigationOptions) {
  }
}

export class OpenMyCoach implements Action {
  readonly type = OPEN_MY_COACH;
}

export class OpenMyCoachConversation implements Action {
  readonly type = OPEN_MY_COACH_CONVERSATION;

  constructor(
    public payload: string,
    public options: { showBack: boolean } = { showBack: true }
  ) {
  }
}

export class OpenWeeklyCalls implements Action {
  readonly type = OPEN_WEEKLY_CALLS;
}

export class LaunchIntercom implements Action {
  readonly type = LAUNCH_INTERCOM;
}

export class OpenInAppMessage implements Action {
  readonly type = OPEN_INAPP_MESSAGE;

  constructor(public payload: InAppMessage) {
  }
}

export class ShowInterstitial implements Action {
  readonly type = SHOW_INTERSTITIAL;

  constructor(public payload: InterstitialNavigationOptions) {
  }
}

export class ShowOnboarding implements Action {
  readonly type = SHOW_ONBOARDING;

  constructor(public payload: OnboardingNavigationOptions) {
  }
}

export class ShowUpgradePopup implements Action {
  readonly type = SHOW_UPGRADE_POPUP;
}
export class ShowUpgradeSuccessPopup implements Action {
  readonly type = SHOW_UPGRADE_SUCCESS_POPUP;
}

export class ShowNextSteps implements Action {
  readonly type = SHOW_NEXT_STEPS;
}

export class ShowBreatheExercise implements Action {
  readonly type = SHOW_BREATHE_EXERCISE;
}

export class CloseAllModals implements Action {
  readonly type = CLOSE_ALL_MODALS;

  constructor(public payload?: string) {
  }
}

export class CloseLastModal implements Action {
  readonly type = CLOSE_LAST_MODAL;
}

export class CloseInterstitial implements Action {
  readonly type = CLOSE_INTERSTITIAL;
}

export type NavigationActions =
  RedirectAfterLogin
  | GotoDashboard
  | GotoHome
  | GotoLogin
  | GotoUpgrade
  | GotoLessons
  | RootGoTo
  | CurrentGoTo
  | ClosePlayer
  | CloseExercisePlayer
  | OpenLessons
  | CloseLessons
  | OpenPerform
  | ClosePerform
  | TabsGoTo
  | OpenCravingTool
  | OpenBonusExercise
  | OpenModal
  | OpenWizard
  | OpenMyCoach
  | OpenMyCoachConversation
  | OpenWeeklyCalls
  | LaunchIntercom
  | OpenInAppMessage
  | ShowInterstitial
  | ShowOnboarding
  | ShowUpgradePopup
  | ShowUpgradeSuccessPopup
  | ShowBreatheExercise
  | ShowNextSteps
  | CloseModal
  | CloseAllModals
  | CloseLastModal
  | CloseInterstitial;
