import { createSchemaSelectors } from 'ngrx-normalizr';
import { ThemeWeek, themeWeekSchema } from '../schemas/theme-week.schema';
import { createSelector } from '@ngrx/store';
import equal from 'fast-deep-equal';

const themeWeekSchemaSelectors = createSchemaSelectors<ThemeWeek>(themeWeekSchema);

let themeWeeksCopy: ThemeWeek[] = [];

export const getThemeWeeks = createSelector(
  themeWeekSchemaSelectors.getEntities,
  (themeWeeks): ThemeWeek[] => {
    const sortedThemeWeeks = themeWeeks
      ? themeWeeks.sort((week1, week2) => week1.position && week2.position ? week1.position - week2.position : 0)
      : [];

    if (!equal(sortedThemeWeeks, themeWeeksCopy)) {
      themeWeeksCopy = sortedThemeWeeks;
    }

    return themeWeeksCopy;
  }
);

let normalizedThemeWeeksCopy: Record<any, ThemeWeek> = {};

export const getNormalizedThemeWeeks = createSelector(
  themeWeekSchemaSelectors.getNormalizedEntities,
  (entities) => {
    if (!equal(entities.themeWeeks, normalizedThemeWeeksCopy)) {
      normalizedThemeWeeksCopy = entities.themeWeeks;
    }

    return normalizedThemeWeeksCopy;
  }
);
