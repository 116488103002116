import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, AfterViewInit, OnDestroy, HostBinding, ViewChild, ElementRef } from '@angular/core';

import { NavController, AlertController } from '@ionic/angular';

import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { ClarityConfig } from '../../config/clarity.config';
import { LoggerService } from '../../services/logger.service';
import { LoadingService } from '../../services/loading.service';
import { BrowserService } from '../../services/browser.service';
import { SubscriptionsService } from '../../services/subscriptions/subscriptions.service';

import { getLiveSubscription } from '../../store/normalized/selectors/subscription.selectors';
import { State } from '../../store/state.reducer';
import { ActivatedRoute } from '@angular/router';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import { StripeFormComponent } from './components/stripe-form.component';
import { FormGroup, Validators } from '@angular/forms';
import { CheckProgramCode, LoadStripePlans } from '../../store/session/actions/subscription.actions';
import { TranslateService } from '@ngx-translate/core';
import { getCurrencyLabel, getStripeProducts } from '../../store/normalized/selectors/stripe-plans.selectors';
import { isLoadingStripePlans } from '../../store/session/selectors/subscription.selectors';
import { getOfficialReleaseVersion } from 'src/app/store/sensitive/selectors/auth.selectors';
import { ReleaseService } from 'src/app/services/release.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'page-subscribe',
  styleUrls: ['subscribe.scss'],
  templateUrl: 'subscribe.html'
})
export class SubscribePage implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class.desktop') isDesktop = this.config.isWebApp;

  public modal;

  officialReleaseInfo$ = this.store.select(getOfficialReleaseVersion);
  malfunctionWarningShown = false;

  liveSubscription$ = this.store.select(getLiveSubscription);
  paramsSubscription: Subscription;
  observableSubscriptions: Subscription[] = [];

  appStoreProductsLoaded = false;

  fromAccountSetup: boolean;

  isLoadingStripeProducts$ = this.store.select(isLoadingStripePlans);
  stripeProducts$ = this.store.select(getStripeProducts);
  stripeFormReady = false;

  products: any;
  selectedProduct: any;

  public validations = {
    license_code: {
      validators: Validators.compose([
        Validators.required,
        Validators.minLength(2)
      ]),
      errors: {
        required: 'errors.subscriptions.license_code_required',
        minlength: 'errors.subscriptions.license_code_min_chars'
      }
    }
  };

  public codeForm: FormGroup;

  @ViewChild('cardElement', { static: true }) cardElement: ElementRef;

  constructor(
    public config: ClarityConfig,
    public navCtrl: NavController,
    private logger: LoggerService,
    private loading: LoadingService,
    private browser: BrowserService,
    private store: Store<State>,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private subscriptionsService: SubscriptionsService,
    private alertCtrl: AlertController,
    private translate: TranslateService,
    private releaseService: ReleaseService
  ) {
    this.paramsSubscription = this.route.queryParams.subscribe(((params) => {
      this.fromAccountSetup = params.fromAccountSetup || null;
    }));
  }

  get hasAppStoreProducts() {
    const hasProducts = this.getAppStoreProducts().length > 0;

    // cache the state since this cannot change once loaded and there's no observable firing if this updates anyway
    // this will fix a bug where the loading products screen displayed incorrectly after ordering a product
    if (hasProducts) {
      this.appStoreProductsLoaded = true;
    }

    return this.appStoreProductsLoaded;
  }

  ngOnInit() {
    if (this.config.stripeWebEnabled()) {
      this.store.dispatch(new LoadStripePlans());
    }

    this.releaseService.forceRefreshReleases();
    this.showTemporaryMalfunctionWarning();
  }

  showTemporaryMalfunctionWarning() {
    if (!this.config.isAndroid) {
      return;
    }

    this.officialReleaseInfo$.subscribe(release => {
      console.log('official release release: ', release);

      if (!release) {
        return;
      }

      // show popup until android subscription issue is fixed (CLARITY-730 & CLARITY-731)
      if (release.mandatory && !this.malfunctionWarningShown) {
        this.malfunctionWarningShown = true;
        this.store.dispatch(new navigationActions.OpenModal('TemporaryMalfunctionPage'));
      }
    });
  }

  ngAfterViewInit() {
    if (this.config.isDevice) {
      this.initSubscriptionsService();
    }
  }

  private initSubscriptionsService() {
    this.observableSubscriptions.push(
      this.liveSubscription$
        .subscribe((liveSubscription) => {
          if (liveSubscription && liveSubscription.isActive) {
            this.goBack();
          }
        })
    );

    this.subscriptionsService.initialize(true)
      .then(() => {
        this.observableSubscriptions.push(
          this.subscriptionsService.whenReadyObservable.subscribe(() => {
            this.loading.hideLoadingOverlay();
            this.ref.detectChanges();
          })
        );

        this.observableSubscriptions.push(
          this.subscriptionsService.whenUpdatedObservable.subscribe(() => {
            this.ref.detectChanges();
          })
        );
      });
  }

  onOrder(productId) {
    this.subscriptionsService.orderProduct(productId);
  }

  getAppStoreProducts() {
    return this.subscriptionsService.getAvailableStoreProducts();
  }

  getStripeProducts() {

  }

  getProductLengthTranslateKey(productId) {
    return 'subscriptions.length_' + this.getProductType(productId);
  }

  getProductDiscountSpecial(product) {
    let id = product;
    if (!this.config.isDevice && this.config.stripeWebEnabled()) {
      id = `${product.interval_count}${product.interval}`;
    }

    switch (this.getProductType(id)) {
      case '6months':
        return true;

      default:
        return false;
    }
  }

  getProductPricePerWeekTranslateKey(product) {
    let id = product;
    if (!this.config.isDevice && this.config.stripeWebEnabled()) {
      id = `${product.interval_count}${product.interval}`;
    }

    switch (this.getProductType(id)) {
      case 'lifetime':
        return null;

      case '6months':
        return 'subscriptions.recommended';

      default:
        return 'subscriptions.less_than_x_per_week';
    }
  }

  getProductPricePerWeekParams(product) {
    if (!this.config.isDevice && this.config.stripeWebEnabled()) {
      return {value: product.pricePerWeekFormatted};
    }

    switch (this.getProductType(product.id)) {
      case '6months':
        return {};

      case 'monthly':
        return {value: `${getCurrencyLabel(product.currency.toUpperCase())}${Math.ceil(Number(product.priceMicros) / 1000000 * 12 / 52)}`};

      case 'annual':
        return {value: `${getCurrencyLabel(product.currency.toUpperCase())}${Math.ceil(Number(product.priceMicros) / 1000000 / 52)}`};

      case 'lifetime':
        return null;
    }
  }

  getProductType(productId) {
    const id = productId.toLowerCase();

    if (/monthly/.test(id) || /1month/.test(id)) {
      return 'monthly';
    }
    else if (/6months/.test(id) || /6month/.test(id)) {
      return '6months';
    }
    else if (/annual/.test(id) || /1year/.test(id)) {
      return 'annual';
    }
    else if (/life/.test(id)) {
      return 'lifetime';
    }
    else {
      this.logger.error('Cannot translate InAppProduct name:', productId, 'getProductLength');

      return id;
    }
  }

  showCouponPromptForm() {
    this.translate.get([
      'subscriptions.program_code',
      'subscriptions.enter_coupon_code_instructions',
      'subscriptions.apply',
      'errors.subscriptions.code_is_required',
      'common.cancel'
    ])
      .subscribe(async (translationResults) => {
        const alert = await this.alertCtrl.create({
          cssClass: 'code-prompt',
          header: translationResults['subscriptions.program_code'],
          message: translationResults['subscriptions.enter_coupon_code_instructions'],
          inputs: [
            {
              name: 'code',
              placeholder: '00-00-00'
            }
          ],
          buttons: [
            {
              text: translationResults['common.cancel'],
              role: 'cancel'
            },
            {
              text: translationResults['subscriptions.apply'],
              handler: data => {
                this.store.dispatch(new CheckProgramCode(data.code));
                // this.getCodeInfo(data.code);
              }
            }
          ]
        });

        await alert.present();

        // Focus the input field
        alert.querySelector('input')
          .focus();
      });
  }

  openStripeFormModal(product) {
    this.stripeFormReady = true;

    this.subscriptionsService.selectStripePlan(product);

    this.store.dispatch(new navigationActions.OpenModal('EmptyModalComponent', {
      header: 'stripe.subscription',
      embedComponent: StripeFormComponent,
      componentProps: {
        headline: 'stripe.full_access',
        fromAccountSetup: this.fromAccountSetup
      },
      forceBackdrop: true
    }));

    this.ref.detectChanges();
  }

  goBack() {
    if (!this.fromAccountSetup) {
      this.store.dispatch(new navigationActions.CloseModal({modalId: this.modal.id}));
    } else {
      this.navCtrl.navigateBack('upgrade', {queryParams: {fromAccountSetup: this.fromAccountSetup}});
    }
  }

  openPrivacyTerms() {
    this.browser.goTo(this.config.privacyTermsUrl);
  }

  ngOnDestroy() {
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
    this.observableSubscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
