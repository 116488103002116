import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import * as WeightActivitiesActions from '../actions/weight-activities.actions';
import { WeightActivitiesProvider } from 'src/app/providers/weight-activities.provider';
import { WeightActivitiesState } from '../reducers/weight-activities.reducer';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { selectIsUpdatingUserWeight } from '../selectors/weight-activities.selectors';
import { LoadingService } from 'src/app/services/loading.service';
import { of } from 'rxjs';
import { weightActivitySchema } from '../../normalized/schemas/weight-activity.schema';
import { AddData, RemoveData } from 'ngrx-normalizr';
import { userActivitySchema } from '../../normalized/schemas/user-activity.schema';
import { ToastService } from 'src/app/services/toast.service';

@Injectable({ providedIn: 'root' })
export class WeightActivitiesEffects {

  constructor(
    private actions$: Actions,
    private store: Store<WeightActivitiesState>,
    private loadingService: LoadingService,
    private weightActivitiesProvider: WeightActivitiesProvider,
    private translate: TranslateService,
    private toastService: ToastService
  ) { }

  trackWeightActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WeightActivitiesActions.ADD_USER_WEIGHT),
      tap(() => {
        this.loadingService.useLoadingObservable(
          this.store.select(selectIsUpdatingUserWeight),
          this.translate.get('weight_tracking.weight_track_saving')
        );
      }),
      switchMap((action: WeightActivitiesActions.AddUserWeight) => this.weightActivitiesProvider.createWeightActivity(action.payload.weightActivity)
        .pipe(
          map((response) => new WeightActivitiesActions.AddUserWeightSuccess({ weightActivities: response })),
          catchError((error) => of(new WeightActivitiesActions.AddUserWeightFail()))
        )
      )
    )
  );

  addWeightActivitiesData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WeightActivitiesActions.ADD_USER_WEIGHT_SUCCESS),
      switchMap((action: WeightActivitiesActions.AddUserWeightSuccess) =>
        of(new AddData({
          data: [action.payload.weightActivities],
          schema: userActivitySchema
        })
        ))
    )
  );

  addUserWeightFail$ = createEffect(() => this.actions$.pipe(
    ofType(WeightActivitiesActions.ADD_USER_WEIGHT_FAIL),
    tap(() => this.toastService.error(this.translate.get('weight_tracking.weight_track_fail')))
  ),
  {dispatch: false}
  );

  removeWeightActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WeightActivitiesActions.REMOVE_USER_WEIGHT),
      tap(() => {
        this.loadingService.useLoadingObservable(
          this.store.select(selectIsUpdatingUserWeight),
          this.translate.get('weight_tracking.weight_track_removing')
        );
      }),
      switchMap((action: WeightActivitiesActions.RemoveUserWeight) => this.weightActivitiesProvider.removeWeightActivity(action.payload.id)
        .pipe(
          map((response) => new WeightActivitiesActions.RemoveUserWeightSuccess({ id: action.payload.id })),
          catchError((error) => of(new WeightActivitiesActions.RemoveUserWeightFail()))
        )
      )
    )
  );

  removeWeightSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WeightActivitiesActions.REMOVE_USER_WEIGHT_SUCCESS),
      switchMap((action: WeightActivitiesActions.RemoveUserWeightSuccess) => {
        this.toastService.confirm(this.translate.get('weight_tracking.weight_removed_success'));

        return of(new RemoveData({
          id: `${action.payload.id}`,
          schema: weightActivitySchema
        }));
      })
    )
  );

}
