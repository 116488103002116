import { schema } from 'normalizr';

export class User {
  id: number;
  user_id: number;
  email: string;
  first_name: string;
  last_name: string;
  gender: string;
  age: number;
  weight: number;
  weight_unit: string;
  cigs_per_day: number;
  cig_pack_currency: string;
  cig_pack_cost: number;
  nrt_available: boolean;
  start_date: Date;
  end_date: Date;
  quit_date: Date;
  complete: UserProgram | false;
  dpp_wl_type: 'dpp' | 'wl';
  sso_provider: string;
  provider_type: string;
  sc_consumer: boolean;
}

export class UserProgram {
  id: number;
  user_id: number;
  program_id: number;
  program_code: string;
  agree_to_tos: boolean;
  coupon_code: string;
  accelerate: number;
  license_code: string;
  referer_code: string;
  language_code: string;
  user_name: string;
  inapp_coaching: boolean;
  inapp_messaging: boolean;
  program_restarted_at: string;
  goal_ids: number[];
  trigger_ids: any[]; // @deprecated
  exclude_tracking: string;
  sso_provider: string;
  provider_type: string;
  created_at: string;
  smoking_type?: SmokingType;
  dpp_wl_type?: ProgramType;
  program_module_number_allow_user_week?: string; // after this module user can start custom/theme week
}

export class UserProgramSession {
  id: number;
  user_program_id: number;
  active: boolean;
  mantra: string;
}

export interface UserProgramInfo {
  enrollment_motivation: string;
  enrollment_source: string;
  coach_info: string;
  friendly_name: string;
  contact_phone: string;
  education: string;
  complete?: string;
  address?: any; // this field is not present in the backend API
}

export class UserGoal {
  id: number;
  name: string;
}

export enum SmokingType {
  CIGARETTE = 'cigarette',
  VAPING = 'vaping'
}

// Applies to ERN only to differentiate between normal users and DPP or WL ones
export enum ProgramType {
  ERN = 'ern',
  DPP = 'dpp',
  WL = 'wl'
}

export class UserProfile {
  username: string;
  id: string;
  avatar: string;
  roles?: string[];
  external_id?: number;
  quit_date: string;
  standalone_client?: string;
  terms_agreed?: boolean;
  firstName?: string;
  lastName?: string;
  avatarUrl?: string;
}

export class UserAgent extends UserProfile {
  bio?: string;
  name?: string;
}

export class UserFavoriteCreate {
  id?: number;
  type: string;
  record_id: number;
  record?: any;
}

export class UserFavorite {
  id: number;
  favourable_type: string;
  favourable_record?: any;
  favourable_id: number;
  user_program_id: number;
  created_at: string;
  updated_at: string;
}
export const userFavoriteSchema = new schema.Entity(
  'userFavs'
);

export const userGoalsSchema = new schema.Entity(
  'userGoals'
);

export const userProgramSchema = new schema.Entity(
  'userPrograms',
  {},
  {idAttribute: () => 'current'}
);

export const userProgramSessionSchema = new schema.Entity(
  'userProgramSession',
  {},
  {idAttribute: () => 'current'}
);

export const userSchema = new schema.Entity(
  'users',
  {},
  {idAttribute: () => 'current'}
);

export const userProfileSchema = new schema.Entity(
  'userProfile',
  {},
  {idAttribute: () => 'current'}
);
