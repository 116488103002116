// user authentication actions

import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { Release } from 'src/app/providers/releases.provider';
import { LoginFailError } from '../../session/models/login-fail-error.model';
import { SharecareAuthParams } from '../../session/models/sharecare-auth-params.model';
import { Credentials, CredentialsReset, CredentialsToken } from '../../session/models/credentials.model';

// login
export const LOGIN = '[auth] Login';
export const LOGIN_WITH_AUTHORIZATION_CODE = '[auth] Login With Authorization Code';
export const LOGIN_WITH_PREAUTH_TOKEN = '[auth] Login With Preauth Token';
export const LOGIN_COMPLETE = '[auth] Login Complete';
export const LOGIN_SUCCESS = '[auth] Login Success';
export const LOGIN_FAIL = '[auth] Login Fail';
export const RESET_AUTHENTICATING = '[auth] Reset Authenticating';

export const CLEAR_LOGIN_STATE = '[auth] Clear Login State';

// logout
export const LOGOUT = '[auth] Logout';
export const LOGOUT_ALERT = '[auth] Logout Alert';
export const LOGOUT_ALERT_CANCELED = '[auth] Logout Alert Canceled';
export const LOGOUT_FROM_ERROR = '[auth] Logout From Error';
export const RESET_STATE_ON_LOGOUT = '[auth] Reset State On Logout';
export const LOGGED_OUT = '[auth] Logged Out';

// check is authenticated
export const CHECK_AUTH = '[auth] Check Auth';
export const CHECK_AUTH_SUCCESS = '[auth] Check Auth Success';
export const CHECK_AUTH_FAIL = '[auth] Check Auth Fail';

export const RESET_PASSWORD = '[auth] Reset Password';
export const RESET_PASSWORD_SUCCESS = '[auth] Reset Password Success';
export const RESET_PASSWORD_FAIL = '[auth] Reset Password Fail';

export const SET_OFFICIAL_VERSION = '[auth] Set Official Version';
export const SET_OFFICIAL_RELEASE_INFO = '[auth] Set Official Release Info';

// iridium token and host
export const SET_IRIDIUM_TOKEN = '[auth] Set Iridium Token';
export const SET_IRIDIUM_HOST = '[auth] Set Iridium Host';

export class ResetPassword implements Action {
  readonly type = RESET_PASSWORD;

  constructor(public payload: CredentialsReset) {
  }
}

export class ResetPasswordSuccess implements Action {
  readonly type = RESET_PASSWORD_SUCCESS;

  constructor(public payload: string) {
  }
}

export class ResetPasswordFail implements Action {
  readonly type = RESET_PASSWORD_FAIL;

  constructor(public payload: { error: HttpErrorResponse }) {
  }
}

export class Login implements Action {
  readonly type = LOGIN;

  constructor(public payload: Credentials | CredentialsToken) {
  }
}

export class LoginWithAuthorizationCode implements Action {
  readonly type = LOGIN_WITH_AUTHORIZATION_CODE;

  constructor(public payload: SharecareAuthParams) {}
}

export class LoginWithPreauthToken implements Action {
  readonly type = LOGIN_WITH_PREAUTH_TOKEN;

  constructor(public payload: SharecareAuthParams) {}
}

export class LoginComplete implements Action {
  readonly type = LOGIN_COMPLETE;
}

export class LoginSuccess implements Action {
  readonly type = LOGIN_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoginFail implements Action {
  readonly type = LOGIN_FAIL;

  constructor(public payload: LoginFailError) {
  }
}

export class ClearLoginState implements Action {
  readonly type = CLEAR_LOGIN_STATE;
}

export class ResetAuthenticating implements Action {
  readonly type = RESET_AUTHENTICATING;
}

export class Logout implements Action {
  readonly type = LOGOUT;
}

export class LogoutAlert implements Action {
  readonly type = LOGOUT_ALERT;
}

export class LogoutAlertCanceled implements Action {
  readonly type = LOGOUT_ALERT_CANCELED;
}

export class LogoutFromError implements Action {
  readonly type = LOGOUT_FROM_ERROR;
}

export class ResetStateOnLogout implements Action {
  readonly type = RESET_STATE_ON_LOGOUT;
}

export class LoggedOut implements Action {
  readonly type = LOGGED_OUT;
}

export class CheckAuth implements Action {
  readonly type = CHECK_AUTH;
}

export class CheckAuthSuccess implements Action {
  readonly type = CHECK_AUTH_SUCCESS;

  constructor(public payload: any) {
  }
}

export class CheckAuthFail implements Action {
  readonly type = CHECK_AUTH_FAIL;

  constructor(public payload: any) {
  }
}

export class SetOfficialVersion implements Action {
  readonly type = SET_OFFICIAL_VERSION;

  constructor(public payload: any) {
  }
}

export class SetOfficialReleaseInfo implements Action {
  readonly type = SET_OFFICIAL_RELEASE_INFO;

  constructor(public payload: Release) {
  }
}

export class SetIridiumToken implements Action {
  readonly type = SET_IRIDIUM_TOKEN;

  constructor(public payload: any) {
  }
}

export class SetIridiumHost implements Action {
  readonly type = SET_IRIDIUM_HOST;

  constructor(public payload: string) {
  }
}

export type AuthActions =
  | ResetPassword
  | ResetPasswordSuccess
  | ResetPasswordFail
  | Login
  | LoginWithAuthorizationCode
  | LoginWithPreauthToken
  | LoginComplete
  | LoginSuccess
  | LoginFail
  | ClearLoginState
  | ResetAuthenticating
  | Logout
  | LogoutAlert
  | LogoutAlertCanceled
  | LogoutFromError
  | ResetStateOnLogout
  | LoggedOut
  | CheckAuth
  | CheckAuthSuccess
  | CheckAuthFail
  | SetIridiumToken
  | SetIridiumHost
  | SetOfficialVersion
  | SetOfficialReleaseInfo;
