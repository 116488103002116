import { schema } from 'normalizr';

export class Notification {
  id: number;
  created_at: Date;
  comment_id: number;
  post_id: number;
  read: boolean;
  type: string;
  avatar: string;
  username: string;
  conversation_id?: number;
}

export class NotificationFeed {
  id: string;
  total_count: number;
  total_unread_count: number;
  list: Notification[];
}

export const notificationsSchema = new schema.Entity(
  'notifications'
);

export const notificationFeedSchema = new schema.Entity(
  'notificationFeed',
  {list: [notificationsSchema]}
);
