import { Component } from '@angular/core';
import { SettingsComponent } from './settings.component';
import { BrowserService } from '../../../services/browser.service';
import { ClarityConfig } from '../../../config/clarity.config';
import * as navigationActions from '../../../store/session/actions/navigation.actions';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store';
import { SmokingType } from '../../../store/normalized/schemas/user.schema';
import { getCurrentUserProgram } from '../../../store/normalized/selectors/user.selectors';

@Component({
  selector: 'cl-account-about-web',
  styleUrls: ['about-web.component.scss'],
  template: `
    <h2>{{ 'account_menu.about.title' | translate }}</h2>
    <div class="about-holder ion-padding">
      <p class="about-intro">
        <ng-container *ngIf="smokingType === 'cigarette'; else vapeTermsTemplate">
          {{"account_menu.about.terms" | translate}}
        </ng-container>
        <ng-template #vapeTermsTemplate>
          {{"account_menu.about.terms_vape" | translate}}
        </ng-template>
      </p>

      <p class="about-intro from-dr-jud">{{'account_menu.about.from_dr_jud' | translate}}</p>

      <p class="about-intro link" (click)="openTerms()">{{"account_menu.about.terms_info_link" | translate}}</p>

      <div class="action-buttons">
        <ion-row class="action-button">
          <ion-col class="ion-text-center" auto>
            <cl-action-button
              class="outsider"
              (action)="openIntroVideo()"
              [label]="'welcome_tour.sliders.video.text' | translate : {program: config.program.name}"
              [canClick]="true">
            </cl-action-button>
          </ion-col>
        </ion-row>

        <ion-row class="action-button hidden-btn">
          <ion-col class="ion-text-center" auto>
            <ion-button (click)="openDebug()" fill="clear"></ion-button>
          </ion-col>
        </ion-row>
      </div>

      <cl-download-links [showSubtitle]="false" *ngIf="config.isWebApp"></cl-download-links>

      <ion-row class="copyright">
        <ion-col class="ion-text-center">
          <p class="illustrations-info" [innerHTML]="'account_menu.about.illustrations' | translate"></p>
          <p [innerHTML]="'account_menu.about.copyright' | translate: copyrightParams"></p>
        </ion-col>
      </ion-row>
    </div>
  `
})
export class AboutWebComponent implements SettingsComponent {

  smokingType = SmokingType.CIGARETTE;

  constructor(
    public config: ClarityConfig,
    private browser: BrowserService,
    private store: Store<State>
  ) {
    this.store.select(getCurrentUserProgram)
      .subscribe((userProgram) => {
        this.smokingType = userProgram.smoking_type;
      });
  }

  get copyrightParams() {
    return {
      programName: this.config.program.name,
      appVersion: this.config.env.app.app_version,
      appBuild: this.config.env.app.app_build,
      currentEnvironment: this.config.currentEnv(),
      copyrightYear: new Date().getFullYear()
    };
  }

  openIntroVideo() {
    this.store.dispatch(new navigationActions.OpenModal('WelcomeVideoPage'));
  }

  openTerms() {
    this.openLink(this.config.privacyTermsUrl);
  }

  openWebsite() {
    this.openLink(this.config.env.companyWebsiteUrl);
  }

  openLink(link: string) {
    this.browser.goTo(link);
  }

  async openDebug() {
    this.store.dispatch(new navigationActions.OpenModal('DebugPage'));
  }

}
