import { Component, Input, Output, EventEmitter, ApplicationRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store';

import { ClarityConfig } from '../../../config/clarity.config';
import { LiveSubscription, shouldHideSubscription } from '../../../store/normalized/selectors/subscription.selectors';
import { getCurrentUserNRTAvailable } from '../../../store/normalized/selectors/user.selectors';
import { map, take } from 'rxjs/operators';
import {
  getAccountSelectedSubMenu, getIsEdditingSettingsGoals,
  getMenuObjectBasedOnSelectedTab, getRootAccountMenu
} from '../../../store/session/selectors/account.selectors';
import * as accountActions from '../../../store/session/actions/account.actions';
import { SessionState } from '../../../store/session';
import { MenuItem, MenuItemOptions } from '../account-menu';
import { LoggerService } from '../../../services/logger.service';
import { slideInOut } from 'src/app/utils/animations';

@Component({
  selector: 'cl-settings-web',
  styleUrls: ['settings-web.component.scss'],
  animations: [slideInOut],
  template: `
    <div [@state]="visibility"
         (@state.done)="animationDone($event)">
      <cl-header-nav-buttons
        *ngIf="!(isEdditingSettingsGoals$ | async) && (selectedSubMenu$ | async)"
        color="primary"
        title="{{ (selectedSubMenu$ | async ) && ('account_menu.'+ (selectedSubMenu$ | async) +'.title' | translate) }} "
        [closeButton]="false"
        (back)="backToSettings()"
        [backButton]="true">
      </cl-header-nav-buttons>

      <div class="flex-wrapper" [class.option-expanded]="">
        <ng-container *ngFor="let option of menuOptions; let i = index">
          <ng-container *ngIf="option.onlyMobile ? config.isDevice : true">
            <cl-option-card-web
              *ngIf="!option.disabled && (
                (
                  option.showOnSubscription &&
                  (option.subscription | async)
                ) || (
                  !option.showOnSubscription &&
                  !(option.hideOnSSO && (shouldHideSubscription$ | async))
                )
              )"
              [key]="option.key"
              title="{{option.title | translate}}"
              icon={{option.icon}}
              (scrollTop)="scrollToTop()"
              [optionComponent]="option.template"
              [componentProps]="option.componentProps"
              [expanded]="option.key === (selectedSubMenu$ | async)"
              [disabled]="option.disabled"
              [hidden]="(selectedSubMenu$ | async) && option.key !== (selectedSubMenu$ | async)"
            >
            </cl-option-card-web>
          </ng-container>
        </ng-container>
      </div>
    </div>
  `
})

export class SettingsWebComponent {

  @Output() endAnimation = new EventEmitter();
  @Output() scrollTop = new EventEmitter();

  visibility = 'visible';
  menuOptions;
  selectedSubMenu$ = this.sessionStore.select(getAccountSelectedSubMenu);
  isEdditingSettingsGoals$ = this.sessionStore.select(getIsEdditingSettingsGoals);

  @Input() subscription: LiveSubscription;

  menuRoot = 'account'; // this is magically changed by tabbedViewComponent::createComponent

  shouldHideSubscription$ = this.store.select(shouldHideSubscription);

  constructor(
    private store: Store<State>,
    private sessionStore: Store<SessionState>,
    public config: ClarityConfig,
    private logger: LoggerService,
    private appRef: ApplicationRef
  ) {
    this._setMenu();
  }

  scrollToTop() {
    this.scrollTop.emit();
  }

  backToSettings() {
    this.sessionStore.dispatch(new accountActions.SetSelectedSubMenu(null));
  }

  async _setMenu() {
    this.sessionStore.dispatch(new accountActions.SetSelectedSubMenu(null));

    const rootMenu = await this.sessionStore.select(getRootAccountMenu).pipe(take(1))
      .toPromise();

    if (rootMenu?.options?.[this.menuRoot]?.menu) {
      this.sessionStore.dispatch(new accountActions.SetSelectedMenuTab(this.menuRoot));
    } else {
      this.logger.error(`WARNING! Couldnt find a menu option for path ${this.menuRoot}`, console.trace());
    }

    this.appRef.tick();

    this.menuOptions = await this.selectedMenuOptions();
  }

  async selectedMenuOptions() {
    const currentMenuObject: MenuItem = await this.sessionStore.select(getMenuObjectBasedOnSelectedTab)
      .pipe(take(1))
      .toPromise();

    const values = Object
      .entries(currentMenuObject.options)
      .map(([key, menuOptions]) => Object.assign({}, menuOptions, { key }))
      .map((key: MenuItemOptions) => {
        if(key.key === 'nrt') {
          key = {
            ...key,
            subscription : this.store.select(getCurrentUserNRTAvailable)
              .pipe(
                map((nrt) => !!nrt)
              )
          };
        }

        return key;
      })
      .filter(key =>
        // TODO We disable the media download section until CLARITY-820 is merged into the develop branch
        !(this.config.isBrightcoveEnabled() && key.template === 'DownloadComponent')
      );

    return values;
  }

  animationDone(event) {
    if (event.toState === 'hidden') {
      this.endAnimation.emit(true);
    }
  }

  closeComponent() {
    this.visibility = 'hidden';
  }
}
