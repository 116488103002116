import { Injectable } from '@angular/core';

import { DirectoryReader, Entry, File, FileEntry, IFile } from '@ionic-native/file/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { AlertController } from '@ionic/angular';

import * as path from 'path';
import * as url from 'url';

import { Md5Service } from './md5.service';
import { LoggerService } from '../logger.service';
import { ClarityConfig } from '../../config/clarity.config';

import { Subtitle } from '../../store/normalized/schemas/mediaFile.schema';
import { config } from '../../config/clarity.constants';
import { Filesystem } from '@capacitor/filesystem';

import { Store } from '@ngrx/store';
import { SessionState } from '../../store/session/session.reducers';
import { SetFlag } from '../../store/persistent/flags/flags.actions';
import { hasCompletedWriteAccessCheck } from '../../store/persistent/flags/flags.selectors';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

export function normalizeURL(urlStr) {
  const ionic = window['Ionic'];
  if (ionic && ionic.WebView && ionic.WebView.convertFileSrc) {
    return ionic.WebView.convertFileSrc(urlStr);
  }

  return urlStr;
}

// Used to set the correct host:port for the internal Ionic server -- must be used for any local urls
export const clarityNormalizeURL = (urlStr: string) => normalizeURL(urlStr)
  .replace(
    'localhost:8080',
    `${config.program.internalServerHost}:${config.program.internalServerPort}`
  );

// TODO: Refactor this to return an object, not an instance (these cannot be stored in the store)
export class ClarityFileError {

  static errorTypes = Object.freeze({
    FILE_TRANSFER_ERROR: 'file transfer error',
    MD5_ERROR: 'md5 error',
    MD5_MISMATCH: 'md5 mismatch',
    NOT_ENOUGH_SPACE: 'not enough space',
    FILE_ERROR: 'file error',
    FILE_SIZE_MISMATCH: 'file size mismatch',
    NO_WIFI: 'no wifi connection',
    NO_PERMISSIONS: 'no permissions',
    ALREADY_DOWNLOADING: 'already downloading',
    UNKNOWN_ERROR: 'unknown error'
  });

  message: string;
  errorType: string;
  originalError: any;

  constructor(
    {
      message = 'unknown error',
      error = null,
      errorType = ClarityFileError.errorTypes.UNKNOWN_ERROR
    }
  ) {
    this.errorType = errorType;
    this.message = message;
    this.originalError = error;

    if (error) {
      this.errorOfError(error);
    }
  }

  private errorOfError(error) {
    if (error instanceof ClarityFileError) {
      Object.assign(this, error);

      return;
    }

    if (error.status === 404) {
      this.errorType = ClarityFileError.errorTypes.FILE_TRANSFER_ERROR;
      this.message = '404: Not Found';
    }

    return;
  }
}

@Injectable({providedIn: 'root'})
export class FileService {
  static DEBUGGING = false;
  file;
  private tempDir = 'temp';
  private exercisesDir = 'exercises';
  private imagesDir = 'images';
  private subtitlesDir = 'subtitles';

  debugMessage = (...data) => {
    if (!FileService.DEBUGGING) {
      return false;
    }

    console.log(...data);
  };

  constructor(
    private md5: Md5Service,
    private logger: LoggerService,
    private http: HTTP,
    public clarityConfig: ClarityConfig,
    private alertCtrl: AlertController,
    private store: Store<SessionState>,
    private translate: TranslateService
  ) {
    this.file = new File();
  }

  get baseDirectory() {
    // prioritize external storage if available
    return this.file.externalDataDirectory || this.file.dataDirectory;
  }

  get exercisesDirectory() {
    return this.baseDirectory + this.exercisesDir + '/';
  }

  get imagesDirectory() {
    return this.baseDirectory + this.imagesDir + '/';
  }

  get tempDirectory() {
    return this.baseDirectory + this.tempDir + '/';
  }

  get subtitlesDirectory() {
    // prefer the main app directory for protection
    return this.file.dataDirectory + this.subtitlesDir + '/';
  }

  get deviceDoesntRequirePermissions() {
    if (!this.clarityConfig.isDevice) {
      this.debugMessage('Not running on device, permissions are always granted');

      return true;
    }

    if (this.clarityConfig.isIos) {
      this.debugMessage('Running on iOS, permissions are always granted');

      return true;
    }
  }

  createPermissionDir() {
    if (this.deviceDoesntRequirePermissions) {
      return Promise.resolve(true);
    }

    const authorizationDir = 'auth';
    const testFileName = 'permissions.txt';
    const testFileContent = 'true';

    return this.createDir(this.baseDirectory, authorizationDir, false)
      .then(
        () => this.file.writeFile(
          this.baseDirectory + authorizationDir,
          testFileName,
          testFileContent,
          {replace: true}
        )
          .then((fileEntry) => fileEntry.name)
      );
  }

  async testPermissions() {
    if (this.deviceDoesntRequirePermissions) {
      return true;
    }

    const completedCheck = await this.store.select(hasCompletedWriteAccessCheck)
      .pipe(take(1))
      .toPromise();

    try {
      await this.createPermissionDir()
        .catch((error) => {
          if (!completedCheck) {
            return this.requestPermissions()
              .then(() => this.createPermissionDir());
          }
          else {
            throw error;
          }
        });
    } finally {
      this.store.dispatch(new SetFlag('writeAccessCheckCompleted'));
    }
  }

  checkPermissions() {
    return this.testPermissions();
  }

  initialize() {
    if (!this.clarityConfig.isDevice || this.clarityConfig.runningOnIonicDevApp) {
      const fileServiceNotAvailable = 'File service is not available';
      console.log(this.clarityConfig.runningOnIonicDevApp
        ? `App running under Ionic DevApp, ${fileServiceNotAvailable}`
        : `Not running on device, ${fileServiceNotAvailable}`, url
      );

      return Promise.resolve(false);
    }

    return this.checkPermissions()
      .then(() => this.buildDirectoryStructure())
      .then(() => true)
      .catch(() => false);
  }

  requestPermissions() {
    return new Promise((resolve, reject) => {
      this.translate.get([
        'file_storage.file_storage',
        'file_storage.permission_text',
        'common.ok'
      ])
        .subscribe(async (translations) => {
          const alert = await this.alertCtrl.create({
            header: translations['file_storage.file_storage'],
            subHeader: translations['file_storage.permission_text'],
            buttons: [{
              text: translations['common.ok'],
              handler: () => {

                Filesystem.requestPermissions()
                  .then((result) => {
                    if (result.publicStorage === 'granted') {
                      resolve(result);
                    }
                    else {
                      reject(result);
                    }
                  })
                  .catch(error => {
                    this.logger.error('Error while requesting file permissions', error, 'FileService');

                    reject(false);
                  });
              }
            }]
          });

          await alert.present();
        });
    });
  }

  async removeDirRec(dirName) {
    await this.checkPermissions();

    return this.file.removeRecursively(this.baseDirectory, dirName);
  }

  async getFreeDiskSpace() {
    await this.checkPermissions();

    return this.file.getFreeDiskSpace();
  }

  abortAllDownloads() {
    this.stop();
  }

  async removeAllDownloadedFiles() {
    await this.checkPermissions();

    return Promise.all([
      this.removeDirRec(this.exercisesDir),
      this.removeDirRec(this.tempDir)
    ])
      .then(() => this.buildDirectoryStructure());
  }

  public async checkFileSize(filePath, expectedSize) {
    if (!this.clarityConfig.isDevice) {
      console.log('Not running on device, checkFileSize is always false');

      return Promise.reject(false);
    }

    console.log('checking file size');
    const fileExists = await this.fileExists(filePath);

    if (!fileExists) {
      throw new ClarityFileError({
        message: 'file not found',
        errorType: ClarityFileError.errorTypes.FILE_ERROR
      });
    }

    const reportedSize = await this.getFileSize(filePath);

    if (reportedSize && reportedSize !== expectedSize) {
      console.log('file size does not match');
      throw new ClarityFileError({
        message: 'file size mismatch',
        errorType: ClarityFileError.errorTypes.FILE_SIZE_MISMATCH
      });
    }

    console.log('file size is correct');

    return true;
  }

  public async getFileSize(filePath) {
    const fileName = this.getFilenameFromPath(filePath);

    return this.file.resolveDirectoryUrl(this.exercisesDirectory)
      .then(dataDir => this.file.getFile(dataDir, fileName, {create: false, exclusive: false})
        .then((fe) => this.getFileFromEntry(fe)
          .then((file) => file.size)))
      .catch((error) => {
        // if we cant find the file / get its size we throw a file error
        // another download is needed anyway
        throw new ClarityFileError({
          message: 'file not found',
          errorType: ClarityFileError.errorTypes.FILE_ERROR
        });
      });
  }

  async saveSubtitle(subtitle: Subtitle): Promise<string> {
    await this.checkPermissions();

    if (!this.clarityConfig.isDevice) {
      console.log('Not running on device, cannot save subtitle');

      return Promise.resolve('');
    }

    const subFileName = `${subtitle.id}_${subtitle.language_code}.vtt`;

    return this.file.writeFile(this.subtitlesDirectory, subFileName, subtitle.data, {replace: true})
      .catch((error: any) => {
        // FIXME This will not work on web, need to find a work around - it might not even work on mobile with Ionic 4
        if (error.code === 'FileError.PATH_EXISTS_ERR') {
          return this.file.resolveDirectoryUrl(this.subtitlesDirectory)
            .then(dataDir => this.file.getFile(dataDir, subFileName, {}));
        }

        throw error;
      })
      .then((fileEntry) => fileEntry.name);
  }

  getFilenameFromPath(filePath) {
    const name = path.basename(url.parse(filePath).pathname);

    return this.generatePrefixName(filePath, name);
  }

  imageExists(source) {
    const fileName = this.getFilenameFromPath(source);

    return this.file.checkFile(this.imagesDirectory, fileName);
  }

  fileExists(source) {
    const fileName = this.getFilenameFromPath(source);

    return this.file.checkFile(this.exercisesDirectory, fileName);
  }

  public getImageLocalUrl(source, checkSum) {
    const fileName = this.getFilenameFromPath(source);
    const storagePath = this.imagesDirectory;

    return this.imageExists(source)
      .then((exists) => {
        const beforeUrl = storagePath + fileName;

        return clarityNormalizeURL(beforeUrl);
      });
  }

  public getSubtitleLocalUrl(source) {
    // backward compatibility when subtitles were stored in externalDataDirectory (Android)
    const oldSubtitleDirectory = this.baseDirectory + 'subtitles/';
    if (this.file.cordovaFileError && !this.file.dataDirectory) {
      return new Promise((resolve, reject) => {
        reject('@ionic-native/file/ngx not supported in this device');
      });
    }

    return this.file.checkFile(this.subtitlesDirectory, source)
      .then(() => clarityNormalizeURL(this.subtitlesDirectory + source))
      .catch(() => this.file.checkFile(oldSubtitleDirectory, source)
        .then(() => clarityNormalizeURL(oldSubtitleDirectory + source))
      );
  }

  public getFileLocalUrl(source) {
    if (!this.clarityConfig.isDevice) {
      console.log('Not running on device, local files are not available');

      return Promise.reject(false);
    }

    const videoName = this.getFilenameFromPath(source);
    const storageVideoPath = this.exercisesDirectory;

    return this.fileExists(source)
      .then((exists) => {
        const beforeUrl = storageVideoPath + videoName;

        return clarityNormalizeURL(beforeUrl);
      });
  }

  generatePrefixName(filePath: string, name: string) {
    const dirName = path.dirname(url.parse(filePath).pathname);
    // const dirName = url.parse(filePath).pathname;
    const prefixMatch = dirName.match(/\d+\/\d+\/\d+/i)[0];
    const prefix = prefixMatch.replace(/\//g, '-');

    return `${prefix}-${name}`;
  }

  removeMediaFile(src) {
    return this.file.removeFile(this.exercisesDirectory, src.replace('/exercises/', ''))
      .then((remRes) => remRes.success)
      .catch(() => false);
  }

  public async downloadByUrl(urlStr, initialName, expectedSum) {
    if (!this.clarityConfig.isDevice || this.clarityConfig.runningOnIonicDevApp) {
      console.log(this.clarityConfig.runningOnIonicDevApp ? 'App running under Ionic DevApp' : 'Not running on device, downloading is simulated', url);

      return Promise.resolve(true);
    }

    await this.checkPermissions();
    const name = this.generatePrefixName(urlStr, initialName);

    let actualSum = null;
    let entry = null;

    // attempt download
    try {
      const downloadUrl = 'https:' + urlStr;
      const toPath = this.tempDirectory + name;

      console.log('starting download', downloadUrl, name);

      // download file
      entry = await this.http.downloadFile(downloadUrl, null, null, toPath);
      console.log('downloaded', entry);
    } catch (error) {
      throw new ClarityFileError({message: 'error downloading file', error});
    }

    // check md5
    try {
      actualSum = await this.md5.sum(entry);
      console.log('md5', actualSum);

      if (actualSum !== expectedSum) {
        console.log('md5 is NOT correct');

        await this.file.removeFile(this.tempDirectory, name);
        console.log('removed temp file');

        throw new ClarityFileError({message: 'md5 mismatch', errorType: ClarityFileError.errorTypes.MD5_MISMATCH});
      }
    } catch (error) {
      throw new ClarityFileError({message: 'md5 lib error', error});
    }

    // save file
    try {
      console.log('md5 is correct');

      await this.file.moveFile(this.tempDirectory, name, this.exercisesDirectory, name);
      console.log('saved file');

      return true;
    } catch (error) {
      throw new ClarityFileError({message: 'file handling error', error});
    }
  }

  getFileFromEntry(entry: FileEntry) {
    return new Promise<IFile>((resolve, reject) => {
      entry.file(
        (fileObj) => resolve(fileObj),
        (error) => reject(error)
      );
    });
  }

  getEntryMetadata(entry: Entry) {
    return new Promise((resolve, reject) => {
      entry.getMetadata(
        (metadata) => resolve(metadata),
        (error) => reject(error)
      );
    });
  }

  readEntries(dirReader: DirectoryReader) {
    return new Promise<Entry[]>((resolve, reject) => {
      dirReader.readEntries(
        (good: Entry[]) => resolve(good),
        (bad: any) => reject(bad)
      );
    });
  }

  getAllFilesList() {
    if (!this.clarityConfig.isDevice || this.clarityConfig.runningOnIonicDevApp) {
      const emptyListMsg = 'files list will always be empty';
      console.log(this.clarityConfig.runningOnIonicDevApp ? `App running under Ionic DevApp, ${emptyListMsg}` : `Not on device, ${emptyListMsg}`, url);

      return Promise.resolve([]);
    }

    return this.file.resolveDirectoryUrl(this.exercisesDirectory)
      .then(entry => entry.createReader())
      .then(dirReader => this.readEntries(dirReader)
        .then(entries => Promise.all(entries.map(entry => this.getEntryMetadata(entry)
          .then(metadata => ({
            item: entry.fullPath,
            meta: metadata
          }))
          .catch(error => ({
            item: entry.fullPath,
            error
          }))))));
  }

  stop() {
    // TODO: Implement aborting downloads
  }

  private async createDir(baseDir, dirName, removeIfExists = false) {
    return this.file.createDir(baseDir, dirName, removeIfExists)
      .catch((error) => {
        // dir already exists we return the directoryEntry
        if (error && error.code === 12) {
          return this.file.resolveDirectoryUrl(baseDir + dirName);
        }

        return Promise.reject(error);
      });
  }

  private async buildDirectoryStructure() {
    if (!this.clarityConfig.isDevice || this.clarityConfig.runningOnIonicDevApp) {
      const fileServiceNotAvailable = 'File service is not available';
      console.log(this.clarityConfig.runningOnIonicDevApp ?
        `App running under Ionic DevApp, ${fileServiceNotAvailable}` :
        `Not running on device, ${fileServiceNotAvailable}`, url
      );

      return Promise.resolve(false);
    }

    return Promise.all([
      this.createDir(this.baseDirectory, this.tempDir, true),
      this.createDir(this.baseDirectory, this.imagesDir, false),
      this.createDir(this.file.dataDirectory, this.subtitlesDir, false),
      this.createDir(this.baseDirectory, this.exercisesDir, false)
    ])
      .catch((error) => {
        this.logger.error('Error occurred while building directory structure', error, 'fileService');

        return false;
      });
  }

}
