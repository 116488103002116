import { schema } from 'normalizr';

export class Subtitle {
  id: number;
  data?: string;
  file_path?: string;
  language_code: string;
}

export class MediaFile {
  jw_key?: string;
  brightcove_key?: string;
  brightcove_poster_url?: string;
  data_fingerprint?: string;
  data_file_name: string;
  data_content_type: string;
  data_file_size: number;
  data: string;
  subtitle?: Subtitle;
  length: number;
}

export const subtitleSchema = new schema.Entity(
  'subtitles'
);

export const mediaFileSchema = new schema.Entity(
  'mediaFiles',
  {
    subtitle: subtitleSchema
  },
  {
    idAttribute: (mediaFile) => mediaFile.jw_key || mediaFile.data_fingerprint || mediaFile.brightcove_key
  }
);
