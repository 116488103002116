import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import { AddData } from 'ngrx-normalizr';

import { ERROR_CONNECTION_TIMEOUT, ERROR_INVALID_INPUT, ERROR_REQUEST_LIMIT_EXCEEDED } from '../../../../app/config/error.handler';
import { UserProvider } from '../../../../app/providers/user.provider';
import { ToastService } from '../../../../app/services/toast.service';
import { LoadingService } from '../../../../app/services/loading.service';
import { AnalyticsService } from '../../../../app/services/analytics/analytics.service';

import * as accountActions from '../actions/account.actions';
import * as authActions from '../../sensitive/actions/auth.actions';
import * as syncActions from '../actions/sync.actions';
import * as navigationActions from '../actions/navigation.actions';
import * as userGoalsActions from '../actions/user-goals.actions';
import { isUserUpdating, isAccountSetupStarted, getSignupError, getUpdateError } from '../selectors/account.selectors';
import { User, UserProgramInfo, userSchema } from '../../../../app/store/normalized/schemas/user.schema';

const extractHttpErrors = (error) => error.httpError && error.httpError.errors && error.httpError.errors.messages
  ? error.httpError.errors.messages
  : error.httpError
    ? {errors: [error.httpError.httpMessage]}
    : null;

import { UserProgramProvider } from '../../../../app/providers/user-program.provider';
import { SessionState } from '../session.reducers';
import { getCurrentUserProgram } from '../../normalized/selectors/user.selectors';
import { HttpErrorCode } from '../models/http-error.model';
import { AlertsService } from '../../../services/alerts.service';
import { GtmCustomEvent, GtmService } from '../../../services/analytics/gtm.service';
import { ClarityConfig } from '../../../config/clarity.config';
import { HttpErrorResponse } from '@angular/common/http';
import { UserProgramInfoProvider } from 'src/app/providers/user-program-info.provider';
import { SignupData } from '../models/signup-data.model';

@Injectable({providedIn: 'root'})
export class AccountEffects {


  signUp$ = createEffect(() => this.actions$.pipe(ofType(accountActions.SIGNUP),
    map((action: accountActions.Signup) => action.payload),
    switchMap((userData: SignupData) =>
      // TODO: Fix this in the provider
      //  - it will not return the http observable so errors and data cannot be processed correctly
      this.userProvider.doSignup(userData)
        .pipe(
          map(() => new accountActions.SignupSuccess(userData)),
          catchError(error => of(new accountActions.SignupFail(error)))
        )
    )
  ));


  signUpFailed$ = createEffect(() => this.actions$.pipe(ofType(accountActions.SIGNUP_FAIL),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getSignupError)))
    ),
    tap(([_, error]) => {

      switch (error.type) {
        case ERROR_CONNECTION_TIMEOUT:
          return this.toasts.error(this.translate.get('errors.common.network_error'));
        case ERROR_REQUEST_LIMIT_EXCEEDED:
          return;
        case ERROR_INVALID_INPUT:
          const errorCodes = error.httpError.errors.codes;

          if (errorCodes.includes(HttpErrorCode.SharecareEmailTaken)) {
            return this.alerts.managedBySharecare();
          }

          if (errorCodes.includes(HttpErrorCode.EmailTaken)) {
            return this.toasts.error(this.translate.get(`errors.signup.${HttpErrorCode.EmailTaken}`));
          }

          if (errorCodes.includes(HttpErrorCode.PasswordUnsafe)) {
            return this.toasts.error(this.translate.get(`errors.signup.${HttpErrorCode.PasswordUnsafe}`));
          }

          return this.toasts.error(this.translateUpdateUserErrors(extractHttpErrors(error)));
      }
    })
  ), {dispatch: false});


  signUpSuccess$ = createEffect(() => this.actions$.pipe(ofType(accountActions.SIGNUP_SUCCESS),
    map((action: accountActions.SignupSuccess) => action.payload),
    map((userData: SignupData) => new accountActions.SetUserProgram(userData)),
    tap(() => {
      this.gtmService.triggerEvent(GtmCustomEvent.UserSignup);
    })
  ));


  setUserProgram$ = createEffect(() => this.actions$.pipe(ofType(accountActions.SET_USER_PROGRAM),
    map((action: accountActions.SetUserProgram) => action.payload),
    switchMap((userData: SignupData) => this.userProvider.setUserProgram(userData)
      .pipe(
        map(() => new accountActions.SetUserProgramSuccess(userData)),
        catchError(error => of(new accountActions.SetUserProgramFail(error)))
      ))
  ));


  setUserProgramSuccess$ = createEffect(() => this.actions$.pipe(ofType(accountActions.SET_USER_PROGRAM_SUCCESS),
    map((action: accountActions.SetUserProgramSuccess) => action.payload),
    map((userData: SignupData) => new authActions.Login({email: userData.email, password: userData.password}))
  ));


  setUserProgramFail$ = createEffect(() => this.actions$.pipe(ofType<accountActions.SetUserProgramFail>(accountActions.SET_USER_PROGRAM_FAIL),
    map((action) => action.payload),
    tap((error) => {
      if (error.httpStatus === 408) {
        return this.toasts.error(this.translate.get('errors.common.network_error'));
      }

      return this.toasts.error(this.translateUpdateUserErrors(extractHttpErrors(error)));
    })
  ), {dispatch: false});


  accountSetupProcess$ = createEffect(() => this.actions$.pipe(ofType(accountActions.ACCOUNT_SETUP_PROCESS),
    tap(() => {
      this.loadingService.useLoadingObservable(
        this.store.select(isUserUpdating),
        this.translate.get('auth.saving_your_account')
      );
    }),
    switchMap((action: accountActions.AccountSetupProcess) => {
      const {language_code, smoking_type} = action.payload;

      return forkJoin([
        this.userProgramProvider.updateUserProgram({language_code, smoking_type}),
        this.userProvider.updateUserAccount(action.payload)
      ])
        .pipe(
          map(() => {
            if (language_code) {
              this.translate.use(language_code);
            }

            return new accountActions.UpdateUserAccountSuccess(action.payload);
          }),
          catchError(error => of(new accountActions.UpdateUserAccountFail(error)))
        );
    })
  ));


  accountDppSetupProcess$ = createEffect(() => this.actions$.pipe(ofType(accountActions.ACCOUNT_DPP_SETUP_PROCESS),
    tap(_ => {
      this.loadingService.useLoadingObservable(
        this.store.select(isUserUpdating),
        this.translate.get('auth.saving_your_account')
      );
    }),
    switchMap((action: accountActions.AccountDppSetupProcess) => {
      const userProgramInfo: UserProgramInfo = {
        contact_phone: action.payload.phoneNumber,
        friendly_name: action.payload.nickname,
        coach_info: action.payload.aboutYou,
        enrollment_motivation: action.payload.motivations.join(', '),
        enrollment_source: action.payload.doctorsMotivations,
        education: action.payload.education
      };

      const customGoal = [];
      if (action.payload.goalsElse) {
        customGoal.push({ name: action.payload.goalsElse });
      }

      this.store.dispatch(new userGoalsActions.UpdateUserGoals({
        add: customGoal,
        remove: [],
        defaults: action.payload.goals,
        hideLoader: true
      }));

      const dppObservables = [];
      if (this.config.programDPPorWL()) {
        dppObservables.push(this.userProvider.updateUserAccount({
          age: 13,
          gender: 0,
          weight_unit: 'lb'
        }));
      }

      return forkJoin([
        ...dppObservables,
        this.userProgramInfoProvider.updateUserProgramInfo(userProgramInfo)
      ])
        .pipe(
          map(() => new accountActions.UpdateUserAccountSuccess(action.payload)),
          catchError(error => of(new accountActions.UpdateUserAccountFail(error)))
        );
    })
  ));

  updateUserAccount$ = createEffect(() => this.actions$.pipe(ofType(accountActions.UPDATE_USER_ACCOUNT),
    tap(() => {
      this.loadingService.useLoadingObservable(
        this.store.select(isUserUpdating),
        this.translate.get('common.updating_data')
      );
    }),
    switchMap((action: accountActions.UpdateUserAccount) => this.userProvider.updateUserAccount(action.payload)
      .pipe(
        map((user: User) => {
          if (user) {
            return new AddData<User>({data: [user], schema: userSchema});
          }

          return new accountActions.UpdateUserAccountSuccess(action.payload);
        }),
        catchError(error => of(new accountActions.UpdateUserAccountFail(error)))
      ))
  ));


  changePassword$ = createEffect(() => this.actions$.pipe(ofType(accountActions.CHANGE_PASSWORD),
    switchMap((action: accountActions.ChangePassword) => {
      this.loadingService.useLoadingObservable(
        this.store.select(isUserUpdating),
        this.translate.get('auth.password_change_loading')
      );

      return this.userProvider.changePassword(action.payload)
        .pipe(
          map(() => new accountActions.ChangePasswordSuccess(action.payload)),
          catchError((error: HttpErrorResponse) => of(new accountActions.ChangePasswordFail({ error })))
        );
    })
  ));

  changePasswordSuccess$ = createEffect(() => this.actions$.pipe(ofType(accountActions.CHANGE_PASSWORD_SUCCESS),
    tap(() => {
      this.store.dispatch(new accountActions.SetSelectedSubMenu(null));
      this.toasts.confirm(this.translate.get('auth.password_change_success'));
      this.loadingService.hideLoadingOverlay();

    })
  ), {dispatch: false});

  changePasswordFail$ = createEffect(() => this.actions$.pipe(
    ofType(accountActions.CHANGE_PASSWORD_FAIL),
    map((action: accountActions.ChangePasswordFail) => {
      let errorMessage: string;
      const { errors = {} } = action.payload?.error?.error;

      this.loadingService.hideLoadingOverlay();

      if (errors.codes && errors.codes[0]) {
        errorMessage = `auth.${errors.codes[0]}`;
      } else {
        errorMessage = 'errors.common.generic_error_please_retry';
      }
      this.toasts.error(this.translate.get(errorMessage));
    })
  ), {dispatch: false});

  updateUserAccountSuccess$ = createEffect(() => this.actions$.pipe(ofType(accountActions.UPDATE_USER_ACCOUNT_SUCCESS),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(isAccountSetupStarted)))
    ),
    tap(() => {
      this.store.dispatch(new accountActions.SetSelectedSubMenu(null));

      this.toasts.confirm(this.translate.get('auth.profile_updated_successfully'));
    }),
    map(([, accountSetupStarted]) => {
      if (accountSetupStarted) {
        return new accountActions.LoadUser();
      }
      else {
        return new syncActions.SyncUser();
      }
    })
  ));


  updateUserAccountFailed$ = createEffect(() => this.actions$.pipe(ofType(accountActions.UPDATE_USER_ACCOUNT_FAIL),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getUpdateError)))
    ),
    tap(([, error]) => {
      if (error.httpStatus === 408) {
        return this.toasts.error(this.translate.get('errors.common.network_error'));
      }

      return this.toasts.error(this.translateUpdateUserErrors(extractHttpErrors(error)));
    })
  ), {dispatch: false});


  loadUser$ = createEffect(() => this.actions$.pipe(ofType(accountActions.LOAD_USER),
    switchMap(() => this.userProvider.loadUserAccount()
      .pipe(
        // remove complete attribute if it's not an object - normalizr doesn't like it
        map((data: User) => {
          if (!data.complete) {
            delete (data.complete);
          }

          return data;
        }),
        // if we have a language code, it's the right time to enable it
        switchMap((data: User) => {
          if (!data.complete || !data.complete['language_code']) {
            return of(data);
          }

          return this.translateService.use(data.complete['language_code'])
            .pipe(
              map(() => data)
            );
        }),
        mergeMap((data: User) => [
          new AddData<User>({data: [data], schema: userSchema}),
          new accountActions.LoadUserSuccess(data)
        ]),
        catchError((error) => of(new accountActions.LoadUserFail(error)))
      ))
  ));


  loadUserSuccess$ = createEffect(() => this.actions$.pipe(ofType<accountActions.LoadUserSuccess>(accountActions.LOAD_USER_SUCCESS),
    map((action) => action.payload),
    concatMap(account => of(account)
      .pipe(withLatestFrom(
        this.store.select(isAccountSetupStarted),
        this.store.select(getCurrentUserProgram)
      ))
    ),
    map(([account, accountSetupStarted, userProgram]) => {
      // if this is an account setup, go straight to redirect
      if (!accountSetupStarted || !account.complete) {
        return new navigationActions.RedirectAfterLogin();
      }

      if (userProgram.sso_provider) {
        return new accountActions.SignupComplete();
      }

      // add account setup complete action
      return new navigationActions.GotoUpgrade(true);
    })
  ));


  signupComplete$ = createEffect(() => this.actions$.pipe(ofType<accountActions.SignupComplete>(accountActions.SIGNUP_COMPLETE),
    switchMap(() => [
      new accountActions.AccountSetupComplete(),
      new navigationActions.RedirectAfterLogin()
    ])
  ));


  constructor(
    private actions$: Actions,
    private userProvider: UserProvider,
    private userProgramProvider: UserProgramProvider,
    private userProgramInfoProvider: UserProgramInfoProvider,
    private store: Store<SessionState>,
    private toasts: ToastService,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private analyticsService: AnalyticsService,
    private translateService: TranslateService,
    private alerts: AlertsService,
    private gtmService: GtmService,
    private config: ClarityConfig
  ) {
  }

  // TODO: Refactor API to return error codes or translation keys instead of text messages
  translateUpdateUserErrors(errors) {
    const errorMessages = {
      ERROR_INVALID_INPUT: 'errors.user.invalid_input',
      ERROR_UNAUTHORIZED_ACCESS: 'errors.auth.invalid_credentials'
    };

    if (errors && errors.email) {
      return this.translate.get('errors.email.' + errors.email[0]);
    }

    if (errors && errors.password) {
      return this.translate.get('errors.password.' + errors.password[0]);
    }

    if (errors && errors.weight) {
      return this.translate.get('errors.weight.' + errors.weight[0]);
    }

    if (errors && errors.end_date) {
      return this.translate.get('errors.end_date.' + errors.end_date[0]);
    }

    return this.translate.get(errorMessages[errors] || 'errors.common.network_error');

    // const translations = [];
    //
    // translations.push();
    //
    // Object.keys(errors)
    //   .forEach((key) => {
    //     errors[key].forEach((message) => {
    //       translations.push('errors.user.' + key + '_' + message.replace(/ /g, '_'));
    //     });
    //   });
    //
    // return translations;
  }

}
