import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AudioPlayerStatus } from '../../components/players/audio-player-status.enum';
import { ClarityConfig } from '../../config/clarity.config';
import { BrightcoveAudioPlayerService } from './brightcove-audio-player-service.interface';
import { LoggerService } from '../logger.service';

@Injectable({providedIn: 'root'})
export class BrightcoveWebAudioPlayerService implements OnDestroy, BrightcoveAudioPlayerService {

  private player: any;
  private playing = false;
  private accountId = this.config.env.brightcove.accountId;
  private playerId = this.config.env.brightcove.playerId;
  private readonly status$: BehaviorSubject<AudioPlayerStatus> = new BehaviorSubject<AudioPlayerStatus>(AudioPlayerStatus.NONE);
  private readonly millisecondsPositionSubject: Subject<number> = new Subject<number>();

  position$ = this.millisecondsPositionSubject.asObservable();

  constructor(
    private readonly config: ClarityConfig,
    private readonly loggerService: LoggerService
  ) {
  }

  ngOnDestroy() {
    this.unload();
  }

  downloadFile(): Promise<void> {
    this.loggerService.error('BrightcoveWebAudioPlayerService: ERROR downloadFile not supported');

    return Promise.reject();
  }

  load(config: { brightcove_key: string; webPlayerElement: ElementRef }): Promise<void> {
    if (this.config.isDevice) {
      this.loggerService.error('BrightcoveWebAudioPlayerService: ERROR Trying to load web player on a native context');

      return Promise.reject();
    }

    if (!window['bc']) {
      this.loggerService.error('BrightcoveWebAudioPlayerService: ERROR window.bc is undefined');

      return Promise.reject();
    }

    if (this.player) {
      // we can get into this situation when
      // switching alternatives files on an exercise
      this.unload();
    }

    this.status$.next(AudioPlayerStatus.MEDIA_LOADING);

    const playerHTML = `
      <video-js
        id="brightcove-player"
        data-video-id="${config.brightcove_key}"
        data-account="${this.accountId}"
        data-player="${this.playerId}"
        data-embed="default"
        height="40"
        controls>
      </video-js>`;

    config.webPlayerElement.nativeElement.innerHTML = playerHTML;

    const playerConfig = {
      audioOnlyMode: true,
      controlBar: {
        fullscreenToggle: false,
        pictureInPictureToggle: false
      },
      userActions: {
        doubleClick: false
      }
    };

    this.player = window['bc']('brightcove-player', playerConfig);

    this.listenPlayerEvents();

    return Promise.resolve();
  }

  unload(): Promise<void> {
    this.playing = false;

    this.player.dispose();
    this.player = undefined;

    return Promise.resolve();
  }

  play(): Promise<void> {
    this.player.play();

    return Promise.resolve();
  }

  pause(): Promise<void> {
    this.player.pause();

    return Promise.resolve();
  }

  togglePlayPause() {
    if (this.playing) {
      return this.pause();
    }

    return this.play();
  }

  stop(): Promise<void> {
    this.millisecondsPositionSubject.next(this.player.currentTime() * 1000);
    this.player.pause();

    return Promise.resolve();
  }

  seekTo(seconds: number) {
    if (isNaN(seconds)) {
      return Promise.resolve();
    }

    this.player.currentTime(seconds < this.getDuration() ? Math.max(0, seconds) : this.getDuration());

    return Promise.resolve();
  }

  getCurrentTime() {
    return this.player.currentTime();
  }

  jumpBy(seconds: number) {
    this.pause();

    if (seconds > 0) {
      this.seekTo(this.getCurrentTime() + seconds);
    } else {
      this.seekTo(Math.max(this.getCurrentTime() - 15, 0));
    }

    this.play();

    return Promise.resolve();
  }

  getDuration() {
    // native api sends ms as value, we should refactor it
    return this.getDurationSeconds() * 1000;
  }

  private getDurationSeconds() {
    return this.player && this.player.duration();
  }

  get playerStatus() {
    return this.status$;
  }

  getRemainingLoopTime() {
    this.loggerService.error('BrightcoveWebAudioPlayerService: ERROR getRemainingLoopTime not supported');

    return -1;
  }

  isLooping(): Promise<boolean> {
    this.loggerService.error('BrightcoveWebAudioPlayerService: ERROR isLooping not supported');

    return Promise.reject();
  }

  setLooping() {
    this.loggerService.error('BrightcoveWebAudioPlayerService: ERROR setLooping not supported');

    return Promise.reject();
  }

  private listenPlayerEvents() {
    this.player.on('loadstart', this.handleLoadStart.bind(this));
    this.player.on('loadedmetadata', this.handleLoadedMetadata.bind(this));
    this.player.on('canplaythrough', this.handleCanPlayThrough.bind(this));
    this.player.on('play', this.handlePlay.bind(this));
    this.player.on('pause', this.handlePause.bind(this));
    this.player.on('timeupdate', this.handleTimeUpdate.bind(this));
    this.player.on('ended', this.handleEnded.bind(this));
    this.player.on('error', this.handleError.bind(this));
  }

  private handleError(error) {
    this.loggerService.error('BrightcoveWebAudioPlayerService: ERROR handleError', error);
  }

  private handleLoadedMetadata() {
    console.log('audio - loadedmetadata');
    this.status$.next(AudioPlayerStatus.MEDIA_LOADED);
  }

  private handleTimeUpdate() {
    this.millisecondsPositionSubject.next(this.getCurrentTime() * 1000);
  }

  private handleLoadStart() {
    console.log('audio - loadstart');
    this.status$.next(AudioPlayerStatus.MEDIA_LOADING);
  }

  private handlePlay() {
    console.log('audio - play');
    this.status$.next(AudioPlayerStatus.RUNNING);
  }

  private handlePause() {
    console.log('audio - pause');
    this.status$.next(AudioPlayerStatus.PAUSED);
  }

  private handleEnded() {
    console.log('audio - ended');
    this.status$.next(AudioPlayerStatus.COMPLETED_WATCHED);
  }

  private handleCanPlayThrough() {
    console.log('audio - canplaythrough');
  }
}
