import { schema } from 'normalizr';

export class RecentStressMeter {
  trigger_id: number;
  exercise_id: number;
  anxiety: number;
  strength: number;
  after_feeling_strength: number;
  user_action: string;
  recorded_at: string;
}

export const recentStressMeterSchema = new schema.Entity(
  'recentStressMeter',
  undefined,
  {idAttribute: () => 'current'}
);
