import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  OnInit,
  Input,
  OnDestroy
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import {map, withLatestFrom} from 'rxjs/operators';
import { WeightActivity } from 'src/app/store/normalized/schemas/weight-activity.schema';
import { getNotManualWeightActivitiesByDatesDescending } from 'src/app/store/normalized/selectors/weight-activity.selectors';
import { State } from '../../../store/state.reducer';
import { subDays, isAfter } from 'date-fns';
import { LiveLesson } from 'src/app/store/session/selectors/program.selectors';
import { NavController } from '@ionic/angular';
import { CloseAllModals } from 'src/app/store/session/actions/navigation.actions';
import { getCurrentUser } from '../../../store/normalized/selectors/user.selectors';
import { convertWeight } from '../../../utils/weight-conversions';

const getMeasuresPrior = (days: number) => (weightActivities: WeightActivity[]) => {
  const xDaysAgo = subDays(new Date(), days);

  return weightActivities
    .filter(({ activity_at }) => isAfter(new Date(activity_at), xDaysAgo));
};

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-weight-capture-lesson',
  styleUrls: ['weight-capture-lesson.component.scss'],
  template: `
    <ng-container *ngIf="(lesson.isCompleted || lastValidActivity) then weightFound else noWeightFound">
    </ng-container>

    <ng-template #noWeightFound>
      <p [innerHTML]="'lessons.you_need_to_weight_yourself' | translate"></p>

      <img src="assets/imgs/fitbit-scale-missing-measure.png">

      <p [innerHTML]="'lessons.step_on_your_scale' | translate"></p>

      <p class="check-your-weight">
        {{ 'lessons.check_your_weight_in_1' | translate }}
        <span class="link" (click)="goToMyWeight()">{{ 'lessons.check_your_weight_in_2' | translate }}</span>
        {{ 'lessons.check_your_weight_in_3' | translate }}
      </p>

    </ng-template>

    <ng-template #weightFound>
      <p [innerHTML]="'lessons.you_already_weighed_yourself' | translate"></p>

      <div class="last-weight-section">
        <ng-container *ngIf="lastValidActivity">
          <div>
            <p class="last-weight-title">{{ 'lessons.your_last_weight_record' | translate }}</p>
            <p class="last-weight-weight">{{lastValidActivity.value | number:'1.1-1' }} {{lastValidActivity | weightUnit}}</p>
            <p class="last-weight-date">
              <ion-icon name="calendar-outline"></ion-icon>
              {{lastValidActivity.activity_at | weightInfoDate : 'mediumDate' : 'UTC' | titlecase}}
            </p>
          </div>
        </ng-container>
      </div>

      <p class="link" (click)="goToMyWeight()">{{ 'lessons.check_all_recordings' | translate }}</p>

      <p [innerHTML]="'lessons.dont_forget_to_weight_every_week' | translate"></p>
    </ng-template>
  `
})
export class WeightCaptureLessonComponent implements OnInit, OnDestroy {

  @Input() lesson: LiveLesson;
  @Output() enableNext = new EventEmitter();
  @Output() disableNext = new EventEmitter();

  private weightActivities$: Observable<WeightActivity[]> = this.store.select(getNotManualWeightActivitiesByDatesDescending);
  private activitiesInTheLast7Days$: Observable<WeightActivity[]> = this.weightActivities$.pipe(map(getMeasuresPrior(7)));
  private currentUser$ = this.store.select(getCurrentUser);
  private lastValidActivity$: Observable<WeightActivity> = this.activitiesInTheLast7Days$.pipe(
    withLatestFrom(this.currentUser$),
    map(([weightActivities, user]) => weightActivities?.length > 0 ? ({
      ...weightActivities[0],
      value: convertWeight(weightActivities[0].value, weightActivities[0].unit, user.weight_unit),
      unit: user.weight_unit
    }) : undefined));

  public lastValidActivity: WeightActivity;

  private handleActivitiesSubscription: Subscription;

  constructor(
    private store: Store<State>,
    private navCtrl: NavController
  ) {
  }

  ngOnInit() {
    this.disableCompletion();
    this.handleActivitiesSubscription = this.handleValidActivities();
  }

  ngOnDestroy() {
    this.handleActivitiesSubscription.unsubscribe();
  }

  handleValidActivities() {
    return this.lastValidActivity$.subscribe(weightActivity => {
      this.lastValidActivity = weightActivity;

      if (this.lastValidActivity || this.lesson.isCompleted) {
        this.enableCompletion();
      } else {
        this.disableCompletion();
      }
    });
  }

  enableCompletion() {
    this.enableNext.next();
  }
  disableCompletion() {
    this.disableNext.next();
  }

  goToMyWeight() {
    this.store.dispatch(new CloseAllModals());

    this.navCtrl.navigateRoot('/tabs/account/weight');
  }
}
