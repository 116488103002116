import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocationStrategy } from '@angular/common';
import { Store } from '@ngrx/store';
import { IonContent, Platform } from '@ionic/angular';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, mapTo, take, takeUntil } from 'rxjs/operators';

import { StepValidationSlideComponent } from 'src/app/components/slides/step-validation-slide';
import { User } from 'src/app/store/normalized/schemas/user.schema';
import { getCurrentUser } from 'src/app/store/normalized/selectors/user.selectors';
import { SessionState } from 'src/app/store/session';
import { CheckboxGroupControl, CheckboxItemControl } from 'src/app/utils/checkbox-form-control';
import * as Questions from './account-setup-questions';
import * as accountActions from '../../../store/session/actions/account.actions';
import { ClarityConfig } from 'src/app/config/clarity.config';
import { US_PHONE_NUMBER_REGEX } from 'src/app/utils/us-phone-number-regex';
import { BrowserService } from 'src/app/services/browser.service';
import { AccountDppSetupPayload } from 'src/app/store/session/models/account.model';
import { getGoals } from 'src/app/store/normalized/selectors/list-items.selectors';
import * as syncActions from 'src/app/store/session/actions/sync.actions';
import { ExercisesProvider } from 'src/app/providers/exercises.provider';
import { Exercise } from 'src/app/store/normalized/schemas/exercise.schema';
import { DottedStepsIndicatorStatus } from 'src/app/components/dotted-steps-indicator/dotted-steps.model';

@Component({
  selector: 'page-dpp-account-setup',
  styleUrls: ['dpp-account-setup.scss'],
  templateUrl: 'dpp-account-setup.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DppAccountSetupPage extends StepValidationSlideComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly destroyed$ = new Subject<void>();
  readonly TEXTAREA_CHARACTER_LIMIT = 500;

  @ViewChild('ionContent', { read: ElementRef, static: true })
  public ionContent: ElementRef<IonContent>;
  public form: FormGroup;

  public goals$ = this.store.select(getGoals);
  goalsModel: CheckboxGroupControl;
  motivationModel: CheckboxGroupControl;
  doctorMotivationOptions = Questions.doctorMotivation;
  educationOptions = Questions.education;

  goalsElse: string;
  aboutYou: string;
  nickname: string;
  phoneNumber: string;

  userData: User;
  exercise: Exercise;

  readonly VIDEO_ELEMENT_ID = 'video-demo';
  selectedSlideId$ = this.selectedSlideElement$.pipe(
    map(element => element?.id)
  );

  playerController$ = this.selectedSlideId$.pipe(
    filter(selectedSlideId => selectedSlideId === this.VIDEO_ELEMENT_ID),
    mapTo('play')
  );

  transitionController$ = this.selectedSlideNumber$
    .pipe(takeUntil(this.destroyed$))
    .subscribe(selectedSlide => {
      this.animateImageTransitions(selectedSlide);
    });
  imageDidAnimate = [false, false, false];

  dottedStepsStatus$: Observable<DottedStepsIndicatorStatus> = this.selectedSlideNumber$.pipe(
    takeUntil(this.destroyed$),
    map(selectedSlide => {
      switch (selectedSlide) {
        case 1:
          return { dots: 3, activeDot: 0 };

        case 2:
          return { dots: 3, activeDot: 1 };

        case 3:
          return { dots: 3, activeDot: 2 };

        case 4:
          return { dots: 4, activeDot: 0 };

        case 5:
          return { dots: 4, activeDot: 1 };

        case 6:
          return { dots: 4, activeDot: 2 };

        case 7:
          return { dots: 4, activeDot: 3 };

        case 8:
          return { dots: 4, activeDot: 0 };

        case 9:
          return { dots: 4, activeDot: 1 };

        case 10:
          return { dots: 4, activeDot: 2 };

        case 11:
          return { dots: 4, activeDot: 3 };

        default:
          return null;
      }
    })
  );

  isSlideScrolledToBottom$ = new BehaviorSubject(false);
  @ViewChild('scrolledToBottomObserver', { read: ElementRef, static: false })
  scrolledToBottomObserver: ElementRef<HTMLElement>;
  observer: IntersectionObserver;

  private insideDppSetup = false;

  constructor(
    public config: ClarityConfig,
    public platform: Platform,
    private store: Store<SessionState>,
    private browser: BrowserService,
    private locationStrategy: LocationStrategy,
    private exercisesProvider: ExercisesProvider
  ) {
    super();

    history.pushState(null, null, location.href);

    this.locationStrategy.onPopState(() => {
      // need this check because there's no way to unsubscribe to locationStrategy listeners
      if (this.insideDppSetup) {
        history.pushState(null, null, location.href);
      }
    });

    this.swiperInit$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.swiper.on('slideChangeTransitionStart', () => {
        (this.swiper.slides[this.swiper.activeIndex] as HTMLElement).style.zIndex = `${this.swiper.activeIndex}`;

        setTimeout(() => {
          this.observer.unobserve(this.scrolledToBottomObserver.nativeElement);
          this.ionContent.nativeElement.scrollToTop();
        }, 0);
      });

      this.swiper.on('slideChangeTransitionEnd', () => {
        setTimeout(() => {
          this.observer.observe(this.scrolledToBottomObserver.nativeElement);
        }, 0);
      });
    });
  }

  ionViewWillEnter() {
    this.insideDppSetup = true;

    this.exercisesProvider.loadExercisesByTag('dpp_onboarding').pipe(take(1))
      .subscribe(exercises => {
        this.exercise = exercises[0];
      });

    this.addScrollInputIntoViewListener();
  }

  animateImageTransitions(targetSlide: number) {
    switch (targetSlide) {
      case 1:
        this.imageDidAnimate[0] = true;
        break;
      case 2:
        this.imageDidAnimate[1] = true;
        break;
      case 5:
        this.imageDidAnimate[2] = true;
        break;
    }
  }

  ionViewWillLeave() {
    this.insideDppSetup = false;
    this.removeScrollInputIntoViewListener();
  }

  private addScrollInputIntoViewListener() {
    window.addEventListener('ionKeyboardDidShow', this.scrollDownWhenKeyboardAppears);
  }

  private removeScrollInputIntoViewListener() {
    window.removeEventListener('ionKeyboardDidShow', this.scrollDownWhenKeyboardAppears);
  }

  private scrollDownWhenKeyboardAppears() {
    const isDppOnBoardingGoals = (activeElement) =>
      activeElement?.tagName?.toLowerCase() === 'textarea' && activeElement?.parentElement?.parentElement?.classList?.contains('onboarding-goals');

    if (!isDppOnBoardingGoals(document?.activeElement)) {
      return;
    }

    if (this.config.isDevice) {
      setTimeout(() => {
        document.activeElement.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
      }, 100);
    } else {
      // Mobile browsers automatically attempt to scroll a focused input into view,
      // however in this onboarding we have to do it ourselves
      // because of an ionic issue: https://github.com/ionic-team/ionic-framework/issues/24647
      this.ionContent.nativeElement.scrollToBottom();
      // Scroll down just enough so the textarea is shown.
      window.scrollBy(0, 150);
    }
  };

  ngOnInit() {
    this.store.dispatch(new syncActions.SyncGoals());
    this.form = this.createFormInputs();
    this.patchFormWithAsyncValues();
  }

  private createFormInputs() {
    let motivationControlArray: CheckboxItemControl[];
    if (this.config.programDPP()) {
      motivationControlArray = Questions.dppMotivation.map(item => new CheckboxItemControl(item));
    } else if (this.config.programWL()) {
      motivationControlArray = Questions.wlMotivation.map(item => new CheckboxItemControl(item));
    }
    this.motivationModel = new CheckboxGroupControl('motivation', motivationControlArray);

    let phoneNumberStep: FormGroup;
    if (this.config.programDPPorWL()) {
      phoneNumberStep = new FormGroup({
        phoneNumber: new FormControl('', {
          validators: Validators.compose([
            Validators.required,
            Validators.pattern(US_PHONE_NUMBER_REGEX)
          ])
        })
      });
    }

    return new FormGroup({
      motivation: this.motivationModel.control,

      doctorMotivation: new FormControl('', Validators.required),
      education: new FormControl('', Validators.required),
      goalsElse: new FormControl(''),
      aboutYou: new FormControl(''),
      nickname: new FormControl(''),

      phoneNumberStep
    });
  }

  private async patchFormWithAsyncValues() {
    this.userData = await this.getUserData();
    this.form.get('nickname').setValue(this.userData.first_name);

    const goals = await this.goals$
      .pipe(
        filter(goalsFromStore => Boolean(goalsFromStore.length)),
        take(1)
      ).toPromise();
    this.goalsModel = new CheckboxGroupControl(
      'goals',
      goals.map(item => new CheckboxItemControl({ value: String(item.id), label: item.name }))
    );
    this.form.addControl('goals', this.goalsModel.control);
  }

  private async getUserData(): Promise<User> {
    return this.store.select(getCurrentUser)
      .pipe(take(1))
      .toPromise();
  }

  ngAfterViewInit() {
    this.observer = new IntersectionObserver(entries => {
      this.isSlideScrolledToBottom$.next(!entries[0].isIntersecting);
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0
    });

    setTimeout(() => this.observer.observe(this.scrolledToBottomObserver.nativeElement), 300);
  }

  get currentEmail() {
    return this.userData?.email || '';
  }

  openTerms() {
    this.browser.goTo(this.config.privacyTermsUrl);
  }

  isStepInvalid(step: string) {
    if (!this.form || !step) return false;

    const stepControl = this.form.get(step);

    if (!stepControl) {
      console.log('warning. not found step control. step: ', step);

      return false;
    }

    return stepControl.invalid;
  }

  isStepValid() {
    return true; // true because button will be disabled in html with isStepInvalid
  }

  async slideFinished() {
    let goals: number[] = [];
    if (this.goalsModel.value.length) {
      goals = this.goalsModel.value.map(id => Number(id));
    }

    const formResult: AccountDppSetupPayload = {
      goals,
      goalsElse: this.form.get('goalsElse').value,
      motivations: this.motivationModel.value,
      doctorsMotivations: this.form.get('doctorMotivation').value,
      aboutYou: this.form.get('aboutYou').value,
      nickname: this.form.get('nickname').value,
      phoneNumber: this.form.get('phoneNumberStep').get('phoneNumber').value,
      education: this.form.get('education').value
    };

    this.store.dispatch(new accountActions.AccountDppSetupProcess(formResult));
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
