// sync selectors
import { MediaFile } from '../schemas/mediaFile.schema';
import {
  BonusExercise,
  Exercise,
  bonusExerciseSchema,
  exerciseSchema
} from '../schemas/exercise.schema';
import { createSchemaSelectors } from 'ngrx-normalizr';
import { createSelector } from '@ngrx/store';
import {
  LiveBonusExercise,
  getCurrentModule, getPlayExerciseId
} from '../../session/selectors/program.selectors';

import { getCurrentUserProgram } from './user.selectors';
import { getCompletedModules, getAcceleratedTo } from './user-bootstrap.selectors';
import { exerciseTags, exerciseId } from '../../session/reducers/exercises.reducer';

import { getExercisesState } from '../../state.reducer';
import equal from 'fast-deep-equal';

let bonusExercisesCopy: BonusExercise[] = [];

export const bonusExercisesSchemaSelector = createSchemaSelectors<BonusExercise>(bonusExerciseSchema);
export const getBonusExercises = createSelector(
  bonusExercisesSchemaSelector.getEntities,
  (bonusExercises) => {
    if (!equal(bonusExercises, bonusExercisesCopy)) {
      bonusExercisesCopy = bonusExercises;
    }

    return bonusExercisesCopy;
  }
);

export const getBonusExercise = (id: number) => createSelector(
  getLiveBonusExercises,
  (bonusExercises) => bonusExercises.find(exercise => exercise.id === id)
);

let exercisesCopy: Exercise[] = [];

export const exercisesSchemaSelector = createSchemaSelectors<Exercise>(exerciseSchema);
export const getExercises = createSelector(
  exercisesSchemaSelector.getEntities,
  (exercises) => {
    if (!equal(exercises, exercisesCopy)) {
      exercisesCopy = exercises;
    }

    return exercisesCopy;
  }
);

const comparePosition = (firstExercise, secondExercise) => firstExercise.position - secondExercise.position;

const checkTag = (exercise: Exercise, searchTag: string): boolean => exercise.tags.some((tag) => tag && tag.name === searchTag);

export const getExerciseId = createSelector(
  getExercisesState,
  exerciseId
);

export const getExerciseTags = createSelector(
  getExercisesState,
  exerciseTags
);

export const getLiveBonusExercises = createSelector(
  getBonusExercises,
  getCompletedModules,
  getCurrentModule,
  getAcceleratedTo,
  (bonusExercises, completedModules, currentModule, accelereatedTo): LiveBonusExercise[] => {
    console.log('$$$ SELECTOR getLiveBonusExercises');

    const liveBonusExercises = [];

    if (completedModules && bonusExercises) {
      bonusExercises.forEach((item) => {
        const liveBonusExercise = Object.assign({}, item, {
          // API should return moduleNumber, but it doesn't so we fix it here
          moduleNumber: item.module,
          isLocked: false,
          isNew: false
        });

        if (item.module) {
          liveBonusExercise.isLocked =
            completedModules.indexOf(item.module) > -1
            || accelereatedTo >= item.module
              ? false
              : true;

          liveBonusExercise.isNew = !liveBonusExercise.isLocked && currentModule && currentModule.number === item.module;
        }

        liveBonusExercises.push(liveBonusExercise);
      });
    }

    [].sort.call(liveBonusExercises, comparePosition);

    console.log('$$$ SELECTOR getLiveBonusExercises');

    return liveBonusExercises;
  }
);

export const getAvailableBonusExercisesIds = createSelector(
  getLiveBonusExercises,
  (bonusExercises) => {
    const exercisesIds = [];

    bonusExercises.forEach((bonusExercise) => {
      if (bonusExercise && bonusExercise.exercise && !bonusExercise.isLocked) {
        exercisesIds.push(bonusExercise.exercise.id);
      }
    });

    return exercisesIds;
  }
);

export const getMediaFilesByBonusExercise = createSelector(
  getLiveBonusExercises,
  (bonusExercises) => {
    const normalizedExercises: {[key: string]: string[]} = {};

    if (!bonusExercises || !bonusExercises.length) {
      return normalizedExercises;
    }

    for (let i = 0; i < bonusExercises.length; i++) {
      if (!normalizedExercises[bonusExercises[i].exercise.id]) {
        normalizedExercises[bonusExercises[i].exercise.id] = [];
      }
      if (bonusExercises[i].exercise && bonusExercises[i].exercise.media_file) {
        normalizedExercises[bonusExercises[i].exercise.id].push(
          bonusExercises[i].exercise.media_file.data_fingerprint ||
          bonusExercises[i].exercise.media_file.jw_key
        );
      }

      if (bonusExercises[i].exercise && bonusExercises[i].exercise.alternative_media_files) {
        for (const mediaFile of bonusExercises[i].exercise.alternative_media_files) {
          if (mediaFile) {
            normalizedExercises[bonusExercises[i].exercise.id].push(mediaFile.data_fingerprint || mediaFile.jw_key);
          }
        }
      }
    }

    return normalizedExercises;
  }
);

export const checkBonusExerciseAlternatives = createSelector(
  getBonusExercises,
  (bonusExercises) => {
    if (!bonusExercises || bonusExercises.length === 0) {
      return true;
    }

    const result = bonusExercises.map((bonusExercise) => {
      const { alternative_media_files } = bonusExercise.exercise;
      if (alternative_media_files && alternative_media_files.length > 0) {

        // checks if all alternative_media_files are different
        // by comparing if all file hashes are the same length
        // as unique hashes
        const alternativeList = [];
        alternative_media_files.forEach((mediaFile) => {
          if (mediaFile) {
            alternativeList.push(mediaFile.data_fingerprint || mediaFile.jw_key);
          }
        });
        const alternativeSet = new Set(alternativeList);

        if (alternativeSet.size !== alternativeList.length) {
          const { exercise } = bonusExercise;
          // eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
          const { alternative_media_files, ...newExercise } = exercise;

          return newExercise;
        }

        return undefined;
      }

      return undefined;
    });

    return result.filter((exercise) => !!exercise);
  }
);

export const getMindfulnessExercises = createSelector(
  getLiveBonusExercises,
  (bonusExercises): LiveBonusExercise[] => bonusExercises.filter((item) => item.exercise && item.exercise.category_name.toLowerCase()
    .includes('mindful'))
);

export const getMindfulnessActiveExercises = createSelector(
  getMindfulnessExercises,
  (bonusExercises): LiveBonusExercise[] => bonusExercises.filter((item) => !item.isLocked)
);

export const getOtherExercises = createSelector(
  getLiveBonusExercises,
  (bonusExercises): LiveBonusExercise[] => bonusExercises.filter((item) => item.exercise && !item.exercise.category_name.toLowerCase()
    .includes('mindful'))
);

export const getOtherActiveExercises = createSelector(
  getOtherExercises,
  (bonusExercises): LiveBonusExercise[] => bonusExercises.filter((item) => !item.isLocked)
);

export const getBonusExercisesModuleIds = createSelector(
  getLiveBonusExercises,
  (liveBonusExercises): any => {
    const modules = {};
    liveBonusExercises.forEach((exercise) => {
      if (exercise.moduleNumber) {
        modules[exercise.moduleNumber] = [...modules[exercise.moduleNumber] || [], exercise.id];
      }
    });

    return modules;
  }
);

export const getPlayExercise = createSelector(
  getPlayExerciseId,
  getLiveBonusExercises,
  (exerciseIde, bonusExercises): any => {
    if (!exerciseIde || !bonusExercises) {
      return null;
    }

    const bonusExercise = bonusExercises.find((exercise) => exercise.id === exerciseIde);
    bonusExercise['record'] = {
      exercise: bonusExercise.exercise
    };

    return bonusExercise;
  }
);

export const getExerciseById = createSelector(
  getExerciseId,
  getExercises,
  (exerciseIde, exercises): any => {
    if (!exerciseId || !exercises) {
      return null;
    }
    const exercise = exercises.find((exer) => exer.id === exerciseIde);

    return exercise;
  }
);

export const getBreathingExercises = createSelector(
  getExercises,
  (exercises): Exercise[] => {
    if (!exercises) {
      return null;
    }

    const tagName = 'breathe_into_anxiety';
    const breathingExercises = exercises.filter((exercise) => exercise.kind === 'perform' &&
        exercise.tags &&
        exercise.tags.findIndex((tag) => tag.name === tagName) > -1);

    return breathingExercises;
  }
);

export const getExerciseByTagsStressTest = createSelector(
  getExerciseTags,
  getExercises,
  (tags, exercises): any => {
    const result = {
      recommended: [],
      other: []
    };
    if (!tags || !exercises) {
      return result;
    }
    exercises.forEach((exercise) => {
      if (!exercise.tags.length) {
        return;
      }

      const tagName = exercise.tags[0].name;
      if (tagName === tags[0]) {
        result.recommended.push(exercise);
      }
      else if (['rain', 'mindful', 'lovingkindness', 'stress', 'body'].indexOf(tagName) > -1) {
        result.other.push(exercise);
      }
    });

    return result;
  }
);

export const getExercisesByTags = createSelector(
  getExerciseTags,
  getExercises,
  (tags, exercises): any => {
    const result = [];
    if (!tags || !exercises) {
      return result;
    }
    exercises.forEach((exercise) => {
      if (!exercise.tags.length) {
        return;
      }
      const check = tags.some(tag => checkTag(exercise, tag));
      if (check) {
        result.push(exercise);
      }
    });

    return result;
  }
);

export const getExercisesNightReflection = createSelector(
  getLiveBonusExercises,
  (exercises): any => {
    const result = [];
    if (!exercises) {
      return result;
    }
    exercises.forEach((exercise) => {
      if (!exercise.exercise.tags.length) {
        return;
      }
      const check = ['night_reflection'].some(tag => checkTag(exercise.exercise, tag));
      if (check) {
        result.push(exercise);
      }
    });

    return result;
  }
);

export const getRainExercise = createSelector(
  getExercises,
  getCurrentUserProgram,
  (exercises, program): any => {
    const exercisesList = exercises.filter((exercise: Exercise) =>
      exercise.tags.length && (checkTag(exercise, 'rain')) && exercise.program_id === program.program_id);

    return exercisesList;
  }
);

export const getMindfulExercise = createSelector(
  getExercises,
  getCurrentUserProgram,
  (exercises, program): any => {
    const exercisesList = exercises.filter((exercise: Exercise) =>
      exercise.tags.length && (checkTag(exercise, 'mindful')) && exercise.program_id === program.program_id);

    return exercisesList;
  }
);

export const getExercisesByExerciseTag = createSelector(
  getExerciseById,
  getExercises,
  (searchExercise, exercises): any => {
    if (!searchExercise || !exercises) {
      return null;
    }
    const primaryTag = searchExercise.tags[0].name === 'mindful'
      ? 'mindful'
      : 'rain';
    const secondaryTag = searchExercise.tags[0].name === 'mindful'
      ? 'rain'
      : 'mindful';

    const exercisesList = exercises.filter((exercise: Exercise) => exercise.tags.length ?
      (checkTag(exercise, primaryTag) || checkTag(exercise, secondaryTag))
      && exercise.id !== searchExercise.id : false);

    return exercisesList;
  }
);

export const getIntroduceGearsVideo = createSelector(
  getExercises,
  (exercises): MediaFile => {
    if (!exercises) {
      return null;
    }

    const exercise = exercises.find((exer: Exercise) => checkTag(exer, 'gears_animation'));

    if (!exercise) {
      return null;
    }

    return exercise.media_file;
  }
);

export const getWorryToolExercises = createSelector(
  getLiveBonusExercises,
  (exercises): LiveBonusExercise[] => exercises.filter(exercise => checkTag(exercise.exercise, 'show_in_worry_tool'))
);
