import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { AccountCompletedGuard } from './guards/account-completed.guard';

import { config } from './config/clarity.constants';

const defaultPath = config.program.programCode === 'ctq' ? 'tabs/home' : 'tabs/dashboard';

const routes: Routes = [
  {
    path: '',
    redirectTo: defaultPath,
    pathMatch: 'full'
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module')
      .then(m => m.TabsPageModule),
    canActivate: [AuthGuard, AccountCompletedGuard]
  },
  {
    path: 'account-setup',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/account-setup/account-setup.module')
      .then(m => m.AccountSetupPageModule)
  },
  {
    path: 'upgrade',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/upgrade/upgrade.module')
          .then(m => m.UpgradePageModule)
      }
    ]
  },
  {
    path: 'subscribe',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/subscribe/subscribe.module')
          .then(m => m.SubscribePageModule)
      }
    ]
  },
  {
    path: 'license',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/license/license.module')
          .then(m => m.LicensePageModule)
      }
    ]
  },
  {
    path: 'welcome-tour',
    loadChildren: () => import('./pages/welcome-tour/welcome-tour.module')
      .then(m => m.WelcomeTourPageModule),
    data: {page: 'welcome-tour'}
  },
  // start login with a provider
  {
    path: 'provider/:provider',
    loadChildren: () => import('./pages/login/login.module')
      .then(m => m.LoginPageModule),
    canActivate: [LoginGuard],
    data: {page: 'login'}
  },
  {
    path: 'provider/:provider/:rand',
    loadChildren: () => import('./pages/login/login.module')
      .then(m => m.LoginPageModule),
    canActivate: [LoginGuard],
    data: {page: 'login'}
  },
  // start login with sharecare with params
  {
    path: 'provider_params/:provider/:offeringCode/:sponsor',
    loadChildren: () => import('./pages/login/login.module')
      .then(m => m.LoginPageModule),
    canActivate: [LoginGuard],
    data: {page: 'login'}
  },
  {
    path: 'provider_params/:provider/:offeringCode/:sponsor/:rand',
    loadChildren: () => import('./pages/login/login.module')
      .then(m => m.LoginPageModule),
    canActivate: [LoginGuard],
    data: {page: 'login'}
  },
  // exchange authorization code
  {
    path: 'authorize/:code',
    loadChildren: () => import('./pages/login/login.module')
      .then(m => m.LoginPageModule),
    canActivate: [LoginGuard],
    data: {page: 'login'}
  },
  {
    path: 'authorize/:code/:rand',
    loadChildren: () => import('./pages/login/login.module')
      .then(m => m.LoginPageModule),
    canActivate: [LoginGuard],
    data: {page: 'login'}
  },
  // exchange authorization code with params
  {
    path: 'authorize_params/:code/:offeringCode/:sponsor',
    loadChildren: () => import('./pages/login/login.module')
      .then(m => m.LoginPageModule),
    canActivate: [LoginGuard],
    data: {page: 'login'}
  },
  {
    path: 'authorize_params/:code/:offeringCode/:sponsor/:rand',
    loadChildren: () => import('./pages/login/login.module')
      .then(m => m.LoginPageModule),
    canActivate: [LoginGuard],
    data: {page: 'login'}
  },
  // exchange preauth token
  {
    path: 'preauth/:preauth_token',
    loadChildren: () => import('./pages/login/login.module')
      .then(m => m.LoginPageModule),
    canActivate: [LoginGuard],
    data: {page: 'login'}
  },
  {
    path: 'preauth/:preauth_token/:rand',
    loadChildren: () => import('./pages/login/login.module')
      .then(m => m.LoginPageModule),
    canActivate: [LoginGuard],
    data: {page: 'login'}
  },
  // default login page
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module')
      .then(m => m.LoginPageModule),
    data: {page: 'login'}
  },
  {
    path: 'login-email',
    loadChildren: () => import('./pages/login-email/login-email.module')
      .then(m => m.LoginEmailPageModule),
    data: {page: 'login'}
  },
  {
    path: 'login-email/dpp',
    loadChildren: () => import('./pages/login-email/login-email.module')
      .then(m => m.LoginEmailPageModule),
    data: {page: 'login', forceDpp: true}
  },
  {
    path: 'login-email/wl',
    loadChildren: () => import('./pages/login-email/login-email.module')
      .then(m => m.LoginEmailPageModule),
    data: {page: 'login', forceWl: true}
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module')
      .then(m => m.SignupPageModule),
    data: {page: 'signup'}
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module')
      .then(m => m.ResetPasswordPageModule),
    data: {page: 'reset-password'}
  },
  {
    path: 'signup/dpp',
    loadChildren: () => import('./pages/signup/signup.module')
      .then(m => m.SignupPageModule),
    data: {page: 'signup', forceDpp: true}
  },
  {
    path: 'signup/wl',
    loadChildren: () => import('./pages/signup/signup.module')
      .then(m => m.SignupPageModule),
    data: {page: 'signup', forceWl: true}
  },
  {
    path: 'fitbit_callback_devices',
    loadChildren: () => import('./pages/fitbit-callback/fitbit-callback.module')
      .then(m => m.FitbitCallbackModule)
  },
  {
    path: 'fitbit_callback_weight',
    loadChildren: () => import('./pages/fitbit-callback/fitbit-callback.module')
      .then(m => m.FitbitCallbackModule)
  },
  {
    path: 'go/:activity', // checkin, wantometer-ern, wantometer-ctq, stressmeter, worrytool etc.
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/go-resolver/go-resolver.module')
      .then(m => m.GoResolverPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules, enableTracing: false, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
