import { Injectable } from '@angular/core';
import { ClarityConfig } from 'src/app/config/clarity.config';
import { Store } from '@ngrx/store';
import { SessionState } from 'src/app/store/session';
import * as iridiumActions from 'src/app/store/session/actions/iridium.actions';
import * as myCoachActions from 'src/app/store/session/actions/my-coach.actions';
import io from 'socket.io-client';
import { MyCoachService } from 'src/app/services/my-coach/my-coach.service';
import { Subscription, timer } from 'rxjs';
import * as fromAuth from '../../../store/sensitive/selectors/auth.selectors';
import { getInappCoaching } from 'src/app/store/session/selectors/my-coach.selectors';
import { State } from 'src/app/store';
import { filter, take } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class IridiumWebsocketService {

  private socket: SocketIOClient.Socket;
  private token: string;
  private statusTimer$: Subscription;
  constructor(
    private sessionStore: Store<SessionState>,
    private store: Store<State>,
    private config: ClarityConfig,
    private myCoachService: MyCoachService
  ) {
  }

  connect(token: string) {
    this.store.select(fromAuth.getIridiumHost)
      .pipe(
        filter((host) => !!host),
        take(1)
      )
      .subscribe((host) => {
        this.disconnect();

        this.socket = io(`${host}/v3/api_gateway`, { reconnectionAttempts: 5, transports: ['websocket'] });

        this.registerSocketEvents(token);
      });
  }

  registerSocketEvents(token: string) {
    this.token = token;

    this.socket.on('connection', () => {
      this.socket.emit('auth', {
        token,
        ...this.connectionExtra()
      });
    });

    this.socket.on('authenticated', (data) => {
      console.log('Iridium - WS authenticated!');
      if (!this.statusTimer$) {
        this.statusTimer$ = timer(0, 10000)
          .subscribe(() =>
            this.store.select(getInappCoaching)
              .subscribe(inappCoaching =>
                this.socket.emit('status', {
                  token,
                  coaching: inappCoaching,
                  ...this.connectionExtra()
                })
              )
          );
      }
    });

    this.socket.on('inappMessage', (message) => {
      console.log('Iridium - Inapp Message received', message);
      // delay 1 second to allow other actions to clear
      setTimeout(() => {
        this.sessionStore.dispatch(new iridiumActions.LoadInAppMessagesSuccess([message]));
      }, 1000);
    });

    this.socket.on('myAgentMessage', (message) => {
      console.log('Iridium - Inapp Reply received', message);
      // delay 1 second to allow other actions to clear
      setTimeout(() => {
        this.sessionStore.dispatch(new myCoachActions.LoadAllMessages(message.conversationId));
        this.sessionStore.dispatch(new myCoachActions.LoadMyCoachNotifications());
      }, 1000);
    });

    this.socket.on('myCoachMessage', (message) => {
      console.log('Iridium - MyCoach Message received', message);
      // delay 1 second to allow other actions to clear
      setTimeout(() => {
        this.sessionStore.dispatch(new myCoachActions.NotifyNewMessage(message));
        this.sessionStore.dispatch(new myCoachActions.LoadMyCoachNotifications());
      }, 1000);
    });

    this.socket.on('agentTypingSignal', (message) => {
      console.log('Iridium - Typing Signal received', message);
      this.myCoachService.typingCoach.next(message);
    });

    this.socket.on('deletedCoachingMessage', (deletedMessage) => {
      console.log('Iridium - MyCoach Message deleted', deletedMessage);
      this.store.dispatch(new myCoachActions.DeleteCoachMessage(deletedMessage.id));
    });

    this.socket.on('coachStatus', (payload) => {
      const { agentStatus } = payload;

      console.log('Iridium - Coach Status received', agentStatus);
      if (agentStatus === 'online') {
        this.myCoachService.onCoachOnlineWsMessage(agentStatus);
      }
    });
  }

  notifyUserTyping(conversationId) {
    this.socket.emit('userTypingSignal', {
      token: this.token, data: {
        conversationId,
        ...this.connectionExtra()
      }
    });
  }

  disconnect() {
    if (!this.socket) {
      return false;
    }

    this.socket.emit('close', {...this.connectionExtra()});

    if (this.statusTimer$) {
      this.statusTimer$.unsubscribe();
      this.statusTimer$ = null;
    }

    this.socket.close();
  }

  private connectionExtra() {
    return {
      platform: this.config.buildPlatform,
      program: this.config.currentProgramCode
    };
  }
}
