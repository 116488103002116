import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  HostBinding,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  OnChanges
} from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { FileService } from '../../services/files/file.service';
import { ClarityConfig } from '../../config/clarity.config';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';

export enum mediaErrorCode {
  MEDIA_ERR_ABORTED = 1,
  MEDIA_ERR_DECODE = 3,
  MEDIA_ERR_ENCRYPTED = 5,
  MEDIA_ERR_NETWORK = 2,
  MEDIA_ERR_SRC_NOT_SUPPORTED = 4
}

@Component({
  selector: 'cl-audio-player',
  styleUrls: ['audio-player.component.scss'],
  template: `
    <div [class.card-bg]="cardBg">
      <div class="audio-overlay" *ngIf="loadingOverlay"></div>
      <div class="audio-wrapper">
        <audio #audio type="audio/mpeg" controls controlsList="nodownload" playsinline></audio>
      </div>
      <ion-row>
        <ion-col *ngIf="!isDesktop"></ion-col>
        <ion-col>
          <ion-button mode="ios" *ngIf="player" type="button" (click)="backwardFifteenSec()" fill="outline" size="small" color="white">
            <ion-icon name="play-back"></ion-icon>
          </ion-button>
          <span>{{'common.15_sec' | translate}}</span>
        </ion-col>
        <ion-col>
          <ion-button mode="ios" *ngIf="player" type="button" (click)="stopped ? resumePlay() : pausePlay()" fill="outline" size="small"
                      color="white">
            <ion-icon name="{{playIcon}}"></ion-icon>
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button mode="ios" *ngIf="player" type="button" (click)="forwardFifteenSec()" fill="outline" size="small" color="white">
            <ion-icon name="play-forward"></ion-icon>
          </ion-button>
          <span>{{'common.15_sec' | translate}}</span>
        </ion-col>
        <ion-col *ngIf="!isDesktop"></ion-col>
      </ion-row>
    </div>
  `
})
export class AudioPlayerComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() title: string;
  @Input() controls: Observable<string>;
  @Input() src: string;
  @Input() cardBg: boolean;
  @Input() autoplay = true;
  @Output() canPlay = new EventEmitter();
  @Output() canPlayThrough = new EventEmitter();
  @Output() playerError = new EventEmitter();
  @Output() playedMinimum = new EventEmitter();
  @Output() completed = new EventEmitter();
  @ViewChild('audio', {static: true}) playerElement: ElementRef;
  player: HTMLVideoElement;
  playingStarted = false;
  controlsSubscription: Subscription;
  audioPausedTimeout: any;
  loadingOverlay = true;

  constructor(
    private config: ClarityConfig,
    private insomnia: Insomnia,
    private file: FileService,
    protected changeDetector: ChangeDetectorRef,
    private backgroundMode: BackgroundMode
  ) {
  }

  @HostBinding('class.desktop') isDesktop = this.config.isWebApp;

  get playIcon() {

    return this.stopped ? 'play' : 'pause';
  }

  get stopped() {
    return this.player.paused ? true : false;
  }

  doNothing = () => undefined;

  ngOnInit() {
    this.player = this.playerElement.nativeElement;

    this.player.addEventListener('playing', this.handlePlaying.bind(this));
    this.player.addEventListener('pause', this.handlePause.bind(this));
    this.player.addEventListener('ended', this.handleEnded.bind(this));
    this.player.addEventListener('volumechange', this.doNothing);
    this.player.addEventListener('canplaythrough', this.handleCanPlayThrough.bind(this));
    this.player.addEventListener('canplay', this.handleCanPlay.bind(this));
    this.player.addEventListener('error', this.handleError.bind(this));
    this.player.addEventListener('timeupdate', this.doNothing);

    if (this.controls) {
      this.controlsSubscription = this.controls.subscribe((action) => this.parentPlayControl(action));
    }
  }

  ngAfterViewInit() {
    console.log('ngAfterViewInit audio-player');
    this.resetPlayer();

    if (this.src) {
      this.loadAudio(this.src);
    }
  }

  ngOnChanges(changes) {
    // first time ngonchanges is triggered before the view is initialized and the audio element doesn't exist
    if (changes.src && changes.src.currentValue !== changes.src.previousValue && !changes.src.firstChange) {
      console.log('audio - src', changes.src.currentValue);
      this.loadAudio(changes.src.currentValue);
    }
  }

  ngOnDestroy() {
    this.disableBackgroundMode();
    this.cancelAudioPausedTimeout();
    this.resetPlayer();
    this.player && this.player.remove();
    this.controlsSubscription && this.controlsSubscription.unsubscribe();
  }

  parentPlayControl(action) {
    switch (action) {
      case 'play':
        return this.resumePlay();

      case 'pause':
        return this.pausePlay();

      case 'reset':
        return this.resetPlayer();

      default:
        return false;
    }
  }

  pausePlay() {
    this.disableBackgroundMode();
    this.player.pause();
  }

  resumePlay() {
    const ret = this.player.play();

    // on some older Android devices, this will not return a promise
    if (ret && ret.catch) {
      ret.catch((error) => {
        // sometimes the error will not be available so this will throw an exception
        // console.log('Player error occurred', error, this.player.error.code ? this.player.error.code : 'Unknown error code');

        this.handleError(error);
      });

      return ret;
    }
  }

  handleError(event) {
    this.disableBackgroundMode();

    // discard errors generated by empty src attribute
    if (!this.player.error ||
      ((!this.player.src || this.player.src === '') && this.player.error && this.player.error.code === 4)) {
      return false;
    }

    this.playerError.emit(this._handleMediaError());
  }

  getCurrentTime() {
    return this.player.currentTime;
  }

  setCurrentTime(newTime) {
    this.player.currentTime = newTime;
  }

  forwardFifteenSec() {
    this.pausePlay();
    this.setCurrentTime(this.getCurrentTime() + 15);
    this.resumePlay();
  }

  backwardFifteenSec() {
    this.pausePlay();
    this.setCurrentTime(Math.max(this.getCurrentTime() - 15, 0));
    this.resumePlay();
  }

  handlePlaying() {
    console.log('audio - playing');
    this.enableBackgroundMode();
    this.changeDetector.detectChanges();

    this.cancelAudioPausedTimeout();

    if (!this.playingStarted && this.playedMinimum.observers.length > 0) {
      setTimeout(() => {
        this.playedMinimum.emit(10);
      }, 1000);
    }

    this.playingStarted = true;
  }

  handlePause() {
    console.log('audio - pause');
    this.changeDetector.detectChanges();

    if (this.config.isDevice && this.backgroundMode.isEnabled() && this.backgroundMode.isActive()) {
      // user paused and the background-mode enabled and active
      // we will give alive for the current audio's length
      const time = this.player.duration ? this.player.duration * 1000 : 60000;
      if (this.audioPausedTimeout) {
        this.cancelAudioPausedTimeout();
      }

      this.audioPausedTimeout = setTimeout(() => {
        console.log('audio - paused - timeout');
        this.disableBackgroundMode();
      }, time);
    }
  }

  handleEnded() {
    console.log('audio - ended');
    this.changeDetector.detectChanges();
    this.completed.emit();

    setTimeout(() => this.disableBackgroundMode(), 30000);
  }

  handleCanPlayThrough() {
    console.log('audio - canplaythrough');
    this.loadingOverlay = false;

    if (this.autoplay) {
      this.canPlayThrough.emit(true);
    }

    this.changeDetector.detectChanges();
  }

  handleCanPlay() {
    console.log('audio - canplay');
    if (this.autoplay) {
      this.canPlay.emit(true);
      this.resumePlay();
    }
  }

  loadAudio(src) {
    this.loadingOverlay = true;
    this.changeDetector.detectChanges();

    // reset buffer before loading a audio
    this.resetPlayer();

    let prefix = '';

    // sanitize urls missing protocol
    if (src.indexOf('//') === 0) {
      prefix = 'https:';
    }

    this.file.getFileLocalUrl(src)
      .then((localSrc) => {
        console.log('found local file', localSrc);
        this.setSrc(localSrc);
      })
      .catch((error) => {
        console.log('playing remote file', src);
        this.setSrc(prefix + src);
      });
  }

  setSrc(src) {
    this.playingStarted = false;
    this.player.src = src;
    this.player.load();
  }

  resetPlayer() {
    this.player.pause();
    // this.player.src = '';
    this.player.removeAttribute('src');
    this.player.load();
  }

  private enableBackgroundMode() {
    if (this.config.isDevice) {
      this.insomnia.keepAwake();

      this.backgroundMode.enable();
    }
  }

  private disableBackgroundMode() {
    if (this.config.isDevice) {
      this.insomnia.allowSleepAgain();

      if (this.backgroundMode.isEnabled()) {
        this.backgroundMode.disable();
      }
    }
  }

  private cancelAudioPausedTimeout() {
    clearTimeout(this.audioPausedTimeout);
    this.audioPausedTimeout = null;
  }

  private _handleMediaError() {
    let errorResult: any = this.player.error;

    try {
      const msg = errorResult instanceof MediaError ?
        `MediaError - Could not play audio file - ErrorCode ${errorResult.code} - Msg: ${mediaErrorCode[errorResult.code]}`
        : `Could not play audio file - ErrorCode: ${errorResult.code}`;
      errorResult = new Error(msg);
    } catch (error) {
      errorResult = new Error('Cannot process player error!');
    }

    return errorResult;
  }
}
