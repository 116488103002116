import { MetaReducer } from '@ngrx/store';

import { logoutMetaReducer } from './meta-reducers/logout.meta-reducer';
import { offlineReducerFactory } from './meta-reducers/offline.meta-reducer';
import { NgZone } from '@angular/core';

// export const metaReducers: MetaReducer<any, any>[] = [logoutMetaReducer, offlineMetaReducer];

export function getMetaReducers(zone: NgZone): MetaReducer<any>[] {
  return [logoutMetaReducer, offlineReducerFactory(zone)];
}
