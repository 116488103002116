import { Injectable, OnDestroy } from '@angular/core';
import { ClarityFileError, FileService } from './file.service';
import { Filesystem } from '@capacitor/filesystem';
import { ClarityConfig } from '../../config/clarity.config';
import { ConnectivityService } from '../connectivity.service';
import { Store } from '@ngrx/store';
import { DownloadStart } from '../../store/session/actions/download.actions';
import { SessionState } from '../../store/session/session.reducers';
import { combineLatest, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';

import { getCurrentModule } from '../../store/session/selectors/program.selectors';
import { getAvailableBonusExercisesIds } from '../../store/normalized/selectors/exercises.selectors';
import { MediaFile } from '../../store/normalized/schemas/mediaFile.schema';
import { getModulesNumbers } from '../../store/normalized/selectors/program-bootstrap.selectors';
import {
  getDownloadedModules,
  isDownloading,
  getDownloadQueue,
  getOfflineStorageStatus
} from '../../store/session/selectors/download.selectors';
import { EventsService } from '../events.service';

@Injectable({providedIn: 'root'})
export class DownloadService implements OnDestroy {

  MAX_MODULES_TO_DOWNLOAD = 30;
  
  static DEBUGGING = true;

  // can be used while running in development for debugging purposes
  static FORCE_DOWNLOAD_START = false;

  maxTries = 3;

  downloadQueue$ = this.store.select(getDownloadQueue);
  isDownloading$ = this.store.select(isDownloading);

  private offlineStorage$ = this.store.select(getOfflineStorageStatus);
  private offlineStorage: boolean;

  downloadSubscription: Subscription;

  debugMessage = (...data) => {
    if (!DownloadService.DEBUGGING) {
      return false;
    }

    console.log(...data);
  };

  constructor(
    private store: Store<SessionState>,
    public config: ClarityConfig,
    private fileService: FileService,
    private connection: ConnectivityService,
    private events: EventsService
  ) {}

  public initialize() {
    this.offlineStorage$.subscribe(offlineStorage => {
      this.offlineStorage = offlineStorage;
      offlineStorage ? this.startWatchingQueue() : this.stopWatchingQueue();
    });
    this.events.subscribe(this.config.events.connection + 'wifi-on', () => this.startWatchingQueue());
    this.events.subscribe(this.config.events.connection + 'wifi-off', () => this.stopWatchingQueue());
    this.events.subscribe(this.config.events.connection + 'offline', () => this.stopWatchingQueue());
    this.events.subscribe(this.config.events.logout, () => this.stopWatchingQueue());

    this.startWatchingQueue();

    if (this.config.onProduction()) {
      DownloadService.DEBUGGING = false;
    }
  }

  public generateQueue(): Promise<any[]> {
    // TODO We disable the media download until CLARITY-820 is merged
    if(this.config.isBrightcoveEnabled()) {
      return Promise.resolve([]);
    }

    return combineLatest([
      this.store.select(getCurrentModule),
      this.store.select(getDownloadedModules),
      this.store.select(getModulesNumbers),
      this.store.select(getAvailableBonusExercisesIds)
        .pipe(filter((modules) => modules.length > 0))
    ])
      .pipe(take(1))
      .toPromise()
      .then(([currentModule, downloadedModules, modulesNumbers, exercisesIds]) => {
        if (!currentModule) {
          return [];
        }

        // exit if all modules were downloaded
        if (modulesNumbers === downloadedModules) {
          return [];
        }

        let modulesToQueue = [];

        // download module 0 if one exists
        if (modulesNumbers.indexOf(0) > -1 && downloadedModules.indexOf(0) === -1) {
          modulesToQueue.push(0);
        }

        // always download module 1
        if (downloadedModules.indexOf(1) === -1) {
          modulesToQueue.push(1);
        }

        // add bonus exercises
        exercisesIds.forEach((exerciseId) => {
          if (downloadedModules.indexOf('e' + exerciseId) === -1) {
            modulesToQueue.push('e' + exerciseId);
          }
        });

        // download trial modules if first module is completed
        if ((currentModule.number === 1 && currentModule.isCompleted) || currentModule.number > 1) {
          for (let i = 2; i <= Number(this.config.program.trialModules); i++) {
            if (downloadedModules.indexOf(i) === -1) {
              modulesToQueue.push(i);
            }
          }

          // TODO: Enable subscription check when subscriptions are implemented
          for (let j = Number(this.config.program.trialModules) + 1; j <= Number(modulesNumbers[modulesNumbers.length - 1]); j++) {
            if (downloadedModules.indexOf(j) === -1) {
              modulesToQueue.push(j);
            }
          }
        }

        modulesToQueue = this.limitModulesForDownload(modulesToQueue, downloadedModules);

        this.debugMessage('Generated Download Queue');

        return modulesToQueue;
      });
  }

  limitModulesForDownload(modulesToQueue: any[], downloadedModules: any[]) {
    const downloadLimit = Math.max(this.MAX_MODULES_TO_DOWNLOAD - downloadedModules.length, 0);

    return modulesToQueue.slice(0, downloadLimit);
  }

  ngOnDestroy() {
    this.stopWatchingQueue();
  }

  async isDownloading() {
    this.isDownloading$
      .pipe(
        take(1)
      )
      .toPromise();
  }

  async downloadMediaFile(downloadInfo: MediaFile) {
    // check wifi
    try {
      const isOnWifi = await this.isOnWifi();

      if (!isOnWifi) {
        this.debugMessage('Not on wifi, cannot download');

        throw new ClarityFileError({
          message: 'no wifi connection',
          errorType: ClarityFileError.errorTypes.NO_WIFI
        });
      }
    }
    catch (error) {
      this.debugMessage('cannot check wifi, canceling...');

      throw new ClarityFileError({
        message: 'unknown error',
        errorType: ClarityFileError.errorTypes.UNKNOWN_ERROR
      });
    }

    // check if permissions are granted
    try {
      const hasPermission = this.hasPermissions();

      if (!hasPermission) {
        this.debugMessage('No permissions for storage, canceling...');

        throw new ClarityFileError({
          message: 'no permissions',
          errorType: ClarityFileError.errorTypes.NO_PERMISSIONS
        });
      }
    }
    catch (error) {
      this.debugMessage('cannot check permissions, continuing anyway...');
    }

    // check if the file exists already
    try {
      await this.fileExists(downloadInfo);
      this.debugMessage('already have file, skipping download');

      // file already downloaded
      return downloadInfo.data_fingerprint;

    } catch (error) {
      this.debugMessage(error, 'file not found, will download now');
    }

    // check if there's enough space
    try {
      const hasEnoughSpace = await this.hasEnoughSpace(downloadInfo);

      if (!hasEnoughSpace) {
        this.debugMessage('not enough space');
        // TODO throw exception
        throw new ClarityFileError({
          message: 'not enough space',
          errorType: ClarityFileError.errorTypes.NOT_ENOUGH_SPACE
        });
      }
    }
    catch (error) {
      this.debugMessage('cannot check free space, continuing anyway', error);
    }

    // start downloading
    let tries = 0;
    let lastError = null;

    while (tries < this.maxTries) {
      tries++;

      try {
        this.debugMessage(`downloading (attempt #${tries})`);

        const result = await this.fileService.downloadByUrl(
          downloadInfo.data,
          downloadInfo.data_file_name,
          downloadInfo.data_fingerprint
        );

        if (result) {
          this.debugMessage('download successful');

          // download completed successfully
          return downloadInfo.data_fingerprint;
        }
      } catch (error) {
        this.debugMessage('download error', error);
        lastError = error;
      }
    }

    // max retries reached
    throw lastError;
  }

  async hasEnoughSpace(mediaInfo: MediaFile) {
    if (!this.config.isDevice) {
      this.debugMessage('Not running on device, hasEnoughSpace will always be true');

      return Promise.resolve(true);
    }

    const space = await this.fileService.getFreeDiskSpace();
    this.debugMessage('free space', space);

    if (space > +mediaInfo.data_file_size) {
      return true;
    }

    return false;
  }

  async hasPermissions() {
    if (!this.config.isDevice) {
      this.debugMessage('Not running on device, pemissions are always granted');

      return true;
    }

    if (this.config.isIos) {
      this.debugMessage('Running on iOS, permissions are always granted');

      return true;
    }

    const permissions = await Filesystem.checkPermissions();

    return permissions.publicStorage === 'granted';
  }

  async isOnWifi() {
    return this.connection.isOnWifi();
  }

  async fileExists(downloadInfo: MediaFile) {
    return this.fileService.checkFileSize(
      downloadInfo.data,
      downloadInfo.data_file_size
    );
  }

  stopDownloads() {
    this.fileService.abortAllDownloads();
  }

  public startWatchingQueue() {
    // Prevent downloads if user disable option in settings
    if(!this.offlineStorage) {
      return;
    }

    // only start if not already watching
    if (this.downloadSubscription && !this.downloadSubscription.closed) {
      return false;
    }

    this.downloadSubscription = this.downloadQueue$
      .pipe(
        switchMap((downloadQueue) => this.isDownloading$
          .pipe(
            filter((downloading) => !downloading),
            distinctUntilChanged(),
            map(() => downloadQueue)
          ))
      )
      .subscribe((queue) => {
        if (queue.length <= 0) {
          return false;
        }

        this.debugMessage('Download queue found, starting download...');

        if ((!this.config.isDevice || !this.config.onProduction()) && !DownloadService.FORCE_DOWNLOAD_START) {
          return console.log('Not running on device or env not production, downloading will not be started');
        }

        this.downloadStart();
      });
  }

  public downloadStart() {
    this.store.dispatch(new DownloadStart());
  }

  public stopWatchingQueue() {
    this.downloadSubscription && this.downloadSubscription.unsubscribe();
    this.stopDownloads();
  }
}
