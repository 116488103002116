import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { TapticService } from '../../services/taptic.service';

@Component({
  selector: 'cl-play-button',
  styleUrls: ['./play-button.component.scss'],
  template: `
    <ion-button
      class="play-pause-button"
      fill="clear"
      color="white"
      aria-hidden="true"
      [ngClass]="{'is-animating': playing}"
      (click)="onClick()">
        <div #circlesWrapper class="circles-wrapper" aria-hidden="true"></div>
        <ion-spinner *ngIf="loading" name="crescent" aria-hidden="true"></ion-spinner>
        <ion-icon class="play-pause-icon" [ngClass]="{'hidden': loading}" name="{{playIcon}}"></ion-icon>
    </ion-button>
  `
})
export class PlayButtonComponent implements AfterViewInit, OnChanges {
  @ViewChild('circlesWrapper', { static: true }) circlesWrapper: ElementRef;

  @Input() loading = false;
  @Input() playing = false;
  @Output() play = new EventEmitter();
  @Output() pause = new EventEmitter();

  circleAnimations: Animation[] = [];

  get playIcon() {
    return this.playing ? 'pause' : 'play';
  }

  constructor(
    private renderer: Renderer2,
    private tapticService: TapticService
  ) {}

  ngAfterViewInit() {
    this.initCirclesAnimation();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('playing' in changes && changes.playing.currentValue !== changes.playing.previousValue) {
      setTimeout(() => {
        changes.playing.currentValue ? this.playAnimation() : this.pauseAnimation();
      });
    }
  }

  private initCirclesAnimation() {
    const duration = 5000;
    let circlesCount = 0;

    for (let i = 0; i <= 1; i++) {
      const circleContainer = this.renderer.createElement('div');
      this.renderer.addClass(circleContainer, 'circle-container');

      for (let j = 0; j <= 3; j++, circlesCount++) {
        const circle = this.renderer.createElement('div');
        this.renderer.addClass(circle, 'circle');
        this.renderer.appendChild(circleContainer, circle);
        const animation = circle.animate(
          { transform: ['rotate(0deg)', 'rotate(360deg)'] },
          {
            duration,
            delay: circlesCount * (duration / 20),
            iterations: Infinity
          }
        );
        animation.pause();
        this.circleAnimations.push(animation);
      }
      this.renderer.appendChild(this.circlesWrapper.nativeElement, circleContainer);
    }
  }

  onClick() {
    this.tapticService.impact();
    this.playing ? this.pauseAnimation() : this.playAnimation();
  }

  playAnimation() {
    this.circleAnimations.forEach((animation) => animation.play());
  }

  pauseAnimation() {
    this.circleAnimations.forEach((animation) => animation.pause());
  }
}
