import { schema } from 'normalizr';
export class CountByDay {
  date: string;
  count: number;
}

function createCountByDaySchema(typeName: string) {
  return new schema.Entity(
    typeName.trim() + 'ByDay',
    undefined,
    { idAttribute: (countByDay) => countByDay.date }
  );
}

export const checkinsByDaySchema = createCountByDaySchema('checkIns');
export const cigarettesByDaySchema = createCountByDaySchema('cigarettes');
export const stressTestsByDaySchema = createCountByDaySchema('stressTests');
export const wantOMeterByDaySchema = createCountByDaySchema('wantOMeter');
export const cravingToolsByDaySchema = createCountByDaySchema('cravingTool');
export const programDaysByDaySchema = createCountByDaySchema('programDays');
export const userWeekDaysByDaySchema = createCountByDaySchema('userWeekDays');
export const worryToolByDaySchema = createCountByDaySchema('worryTool');
