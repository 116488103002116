import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import moment, { Moment } from 'moment';
import { combineLatest, merge, Observable, Subscription } from 'rxjs';
import { filter, take, throttleTime } from 'rxjs/operators';
import { DatetimeChangeEventDetail } from '@ionic/angular';

import { State } from 'src/app/store';
import { ClarityConfig } from '../../../../config/clarity.config';
import { AlertsService } from '../../../../services/alerts.service';
import { ConnectivityService } from '../../../../services/connectivity.service';
import { CountByDay } from '../../../../store/normalized/schemas/count-by-day.schema';
import { SmokingType, User } from '../../../../store/normalized/schemas/user.schema';
import { getCigarettesForToday } from '../../../../store/normalized/selectors/count-by-day.selectors';
import { getCurrentUser, getCurrentUserProgram } from '../../../../store/normalized/selectors/user.selectors';
import { SessionState } from '../../../../store/session';
import { UpdateUserAccount } from '../../../../store/session/actions/account.actions';
import { UpdateUserProgramSmokingType } from '../../../../store/session/actions/user-program.actions';
import { isAuthenticating } from '../../../../store/sensitive/selectors/auth.selectors';
import { dateFormatter } from 'src/app/utils/date-formatter';

@Component({
  selector: 'cl-quitting-plan',
  styleUrls: ['quitting-plan-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <form [formGroup]="quitingPlanSettingsForm" autocomplete="off" *ngIf="currentUser$ | async"
          class="quitting-plan-settings-container">

      <ion-row class="quit-plan-control-wrapper" *ngIf="!hideVaping">
        <div class="quit-plan-label"><span translate="auth.program_type"></span></div>
        <ion-item button no-lines (click)="editSmokingType()"
                  class="quit-plan-control">
          <ion-label><span [translate]="isCigarette() ? 'auth.smoking' : 'auth.vaping'"></span></ion-label>
        </ion-item>
      </ion-row>

      <ion-row class="quit-plan-control-wrapper date-time">
        <div class="quit-plan-label"><span translate="goals_menu.started_on"></span></div>
        <ion-item id="quitting-plan-start-date" button no-lines class="quit-plan-control">
          <ion-label>{{ startDateControl.value }}</ion-label>
        </ion-item>

        <ion-modal class="date-time-picker" trigger="quitting-plan-start-date">
          <ng-template>
            <ion-content>
              <ion-datetime
                (ionChange)="setStartDate($event)"
                [showDefaultButtons]="true"
                formControlName="start_date"
                presentation="date"
                [min]="minStartDate"
                [max]="maxStartDate">
              </ion-datetime>
            </ion-content>
          </ng-template>
        </ion-modal>
      </ion-row>

      <ion-row class="quit-plan-control-wrapper date-time">
        <div class="quit-plan-label"><span translate="goals_menu.will_end_at"></span></div>
        <ion-item id="quitting-plan-end-date" button no-lines class="quit-plan-control">
          <ion-label>{{ endDateControl.value }}</ion-label>
        </ion-item>

        <ion-modal class="date-time-picker" trigger="quitting-plan-end-date">
          <ng-template>
            <ion-content>
              <ion-datetime
                (ionChange)="setEndDate($event)"
                [showDefaultButtons]="true"
                formControlName="end_date"
                presentation="date"
                [min]="minEndDate"
                [max]="maxEndDate">
              </ion-datetime>
            </ion-content>
          </ng-template>
        </ion-modal>
      </ion-row>

      <ion-row class="quit-plan-control-wrapper">
        <div class="quit-plan-label">
          {{ (isCigarette() ? 'auth.cigarettes_per_day' : 'auth.puffs_per_day') | translate }}
        </div>
        <cl-input
          [controlForm]="quitingPlanSettingsForm.get(CONTROL_NAME.CIGS_PER_DAY)"
          [errorMessages]="getErrors(isCigarette() ? CONTROL_NAME.CIGS_PER_DAY : CONTROL_NAME.PUFFS_PER_DAY)"
          type="number"
          [readonly]="true"
          class="quit-plan-control"
          (click)="showEditControlDialog(CONTROL_NAME.CIGS_PER_DAY, isCigarette() ? CONTROL_NAME.CIGS_PER_DAY : CONTROL_NAME.PUFFS_PER_DAY)"
          name="cigs_per_day"
          stacked>
        </cl-input>
      </ion-row>

      <ion-row class="quit-plan-control-wrapper">
        <div class="quit-plan-label"><span translate="auth.current"></span></div>
        <ion-item class="quit-plan-control" no-lines>
          <ion-label>{{(currentCigaretteCount$ | async).count}}</ion-label>
        </ion-item>
      </ion-row>

      <ion-row class="quit-plan-control-wrapper" *ngIf="isCigarette()">
        <div class="quit-plan-label"><span translate="auth.cig_pack_cost_profile"></span></div>
        <cl-input
          [controlForm]="quitingPlanSettingsForm.get(CONTROL_NAME.CIG_PACK_COST)"
          [errorMessages]="getErrors(CONTROL_NAME.CIG_PACK_COST)"
          type="number"
          [readonly]="true"
          class="quit-plan-control"
          stacked
          (click)="showEditControlDialog(CONTROL_NAME.CIG_PACK_COST)"
          name="cig_pack_cost">
        </cl-input>
      </ion-row>
    </form>
  `
})
export class QuittingPlanSettingsComponent implements OnInit, OnDestroy {
  public readonly DATE_FORMAT = 'YYYY-MM-DD';
  public readonly CONTROL_NAME = {
    START_DATE: 'start_date',
    END_DATE: 'end_date',
    SMOKING_TYPE: 'smoking_type',
    CIGS_PER_DAY: 'cigs_per_day',
    PUFFS_PER_DAY: 'puffs_per_day',
    CIG_PACK_COST: 'cig_pack_cost'
  };

  public hideVaping = false;

  private readonly UPDATING_FORM_INTERVAL_IN_MS = 500;
  private readonly ERRORS = {
    [this.CONTROL_NAME.CIGS_PER_DAY]: ['required'],
    [this.CONTROL_NAME.CIG_PACK_COST]: ['required'],
    [this.CONTROL_NAME.PUFFS_PER_DAY]: ['required']
  };
  private readonly formControlTemplates = [
    { key: this.CONTROL_NAME.START_DATE, validators: [Validators.required] },
    { key: this.CONTROL_NAME.END_DATE, validators: [Validators.required] },
    { key: this.CONTROL_NAME.CIGS_PER_DAY, validators: [Validators.required], defaultValue: 0 },
    { key: this.CONTROL_NAME.CIG_PACK_COST, validators: [Validators.required], defaultValue: 0 }
  ];
  private readonly DIALOG_BUTTONS = {
    CANCEL: 'cancel',
    SAVE: 'Save',
    CONTINUE: 'continue'
  };

  @Output() endAnimation = new EventEmitter();

  public currentUser$: Observable<User>;
  public currentCigaretteCount$: Observable<CountByDay>;
  public authenticating$: Observable<boolean>;
  public quitingPlanSettingsForm: FormGroup;
  public minStartDate = this.getFormattedDate(moment()
    .subtract(90, 'days'));
  public maxStartDate = this.getFormattedDate();
  public minEndDate = this.getFormattedDate();
  public maxEndDate = this.getFormattedDate(moment()
    .add(90, 'days'));

  private user: User;
  private initialFormSettings: any;
  private formChangingSubscription: Subscription;
  private finishEditValue = new EventEmitter();
  private smokingType: SmokingType;
  private dialogTranslationsConfig = [
    { control: this.DIALOG_BUTTONS.CANCEL, path: `common.${ this.DIALOG_BUTTONS.CANCEL }`, text: '' },
    { control: this.DIALOG_BUTTONS.SAVE, path: `common.${ this.DIALOG_BUTTONS.SAVE }`, text: '' },
    { control: this.DIALOG_BUTTONS.CONTINUE, path: `common.${ this.DIALOG_BUTTONS.CONTINUE }`, text: '' },
    { control: this.CONTROL_NAME.CIGS_PER_DAY, path: 'auth.cigarettes_per_day', text: '' },
    { control: this.CONTROL_NAME.PUFFS_PER_DAY, path: 'auth.puffs_per_day_dialog', text: '' },
    { control: this.CONTROL_NAME.CIG_PACK_COST, path: 'auth.cig_pack_cost_profile', text: '' },
    { control: this.CONTROL_NAME.SMOKING_TYPE, path: 'auth.program_type', text: '' },
    { control: SmokingType.CIGARETTE, path: 'auth.smoking', text: '' },
    { control: SmokingType.VAPING, path: 'auth.vaping', text: '' }
  ];

  constructor(
    private formBuilder: FormBuilder,
    public config: ClarityConfig,
    private store: Store<State>,
    private sessionStore: Store<SessionState>,
    private connectivity: ConnectivityService,
    private alerts: AlertsService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.authenticating$ = this.store.select(isAuthenticating);
    this.currentUser$ = this.sessionStore.select(getCurrentUser);
    this.currentCigaretteCount$ = this.sessionStore.select(getCigarettesForToday);
    this.initDialogTranslations();
    combineLatest([
      this.currentUser$,
      this.sessionStore.select(getCurrentUserProgram)
    ])
      .pipe(take(1))
      .subscribe(([user, { smoking_type }]) => {
        this.user = user;
        this.smokingType = smoking_type;
        this.checkIfVapingAvailable();
        this.quitingPlanSettingsForm = this.buildForm(user);
        this.updatePackCostValidators();
        this.saveFormState();
        this.formChangingSubscription =
          merge(
            this.quitingPlanSettingsForm.valueChanges,
            this.finishEditValue
          )
            .pipe(
              throttleTime(this.UPDATING_FORM_INTERVAL_IN_MS),
              filter(() => this.hasNewChanges && this.quitingPlanSettingsForm.valid)
            )
            .subscribe(() => this.handleUpdatingSettings());
      });
  }

  ngOnDestroy(): void {
    this.formChangingSubscription && this.formChangingSubscription.unsubscribe();
  }

  handleUpdatingSettings() {
    if (this.connectivity.preventAccessWhenOffline()) {
      return false;
    }
    this.saveFormState();
    const { start_date, end_date, cigs_per_day, cig_pack_cost } = this.quitingPlanSettingsForm.value;
    const toSubmit = {
      ...this.user,
      start_date,
      end_date,
      cigs_per_day,
      ...(this.isCigarette() ? { cig_pack_cost } : {})
    };
    this.sessionStore.dispatch(new UpdateUserAccount(toSubmit));
  }

  get startDateControl() {
    return this.quitingPlanSettingsForm.get(this.CONTROL_NAME.START_DATE) as FormControl;
  }

  setStartDate(changeEvent: CustomEvent<DatetimeChangeEventDetail>) {
    this.startDateControl.setValue(dateFormatter(changeEvent.detail.value));
  }

  get endDateControl() {
    return this.quitingPlanSettingsForm.get(this.CONTROL_NAME.END_DATE) as FormControl;
  }

  setEndDate(changeEvent: CustomEvent<DatetimeChangeEventDetail>) {
    this.endDateControl.setValue(dateFormatter(changeEvent.detail.value));
  }

  get cigPackCostControl(): FormControl {
    return this.quitingPlanSettingsForm.get(this.CONTROL_NAME.CIG_PACK_COST) as FormControl;
  }

  isCigarette() {
    return this.smokingType === SmokingType.CIGARETTE;
  }

  updatePackCostValidators() {
    this.cigPackCostControl.clearValidators();
    if (this.isCigarette()) {
      this.cigPackCostControl.setValidators([Validators.required]);
    }
    this.cigPackCostControl.updateValueAndValidity();
  }

  getErrors(controlName) {
    const errors = {};
    const errorTypes = this.ERRORS[controlName];
    errorTypes && errorTypes.forEach(type => errors[type] = `errors.user.${ controlName }_${ type }`);

    return errors;
  }

  get hasNewChanges() {
    const hasChanges = this.initialFormSettings && Object.keys(this.initialFormSettings)
      .some(key => {
        if (key === 'start_date' || key === 'end_date') {
          this.quitingPlanSettingsForm.value[key] = moment(this.quitingPlanSettingsForm.value[key])
            .format(this.DATE_FORMAT);
        }

        return this.initialFormSettings[key] !== this.quitingPlanSettingsForm.value[key];
      });

    return hasChanges;
  }

  async showEditControlDialog(controlName, translationsName?) {
    if (!controlName) {
      return;
    }
    const dialog = await this.alerts.alertController.create({
      header: this.getDialogTranslatedTextByName(translationsName || controlName),
      buttons: [
        { text: this.getDialogTranslatedTextByName(this.DIALOG_BUTTONS.CANCEL), role: 'cancel' },
        {
          text: this.getDialogTranslatedTextByName(this.DIALOG_BUTTONS.SAVE),
          handler: values => {
            this.quitingPlanSettingsForm.get(controlName)
              .setValue(values[controlName]);
            this.finishEditValue.emit();
          }
        }
      ],
      inputs: [{ name: controlName, type: 'number', min: 0, value: this.quitingPlanSettingsForm.get(controlName).value }]
    });

    await dialog.present();
  }

  async editSmokingType() {
    const dialog = await this.alerts.alertController.create({
      header: this.getDialogTranslatedTextByName(this.CONTROL_NAME.SMOKING_TYPE),
      buttons: [
        { text: this.getDialogTranslatedTextByName(this.DIALOG_BUTTONS.CANCEL), role: 'cancel' },
        {
          text: this.getDialogTranslatedTextByName(this.DIALOG_BUTTONS.SAVE),
          handler: smokingType => {
            if (smokingType !== this.smokingType) {
              this.showSmokingTypeWarning(smokingType);
            }
          }
        }
      ],
      inputs: [
        {
          name: this.CONTROL_NAME.SMOKING_TYPE,
          type: 'radio',
          label: this.getDialogTranslatedTextByName(SmokingType.CIGARETTE),
          value: SmokingType.CIGARETTE,
          checked: this.isCigarette()
        },
        {
          name: this.CONTROL_NAME.SMOKING_TYPE,
          type: 'radio',
          label: this.getDialogTranslatedTextByName(SmokingType.VAPING),
          value: SmokingType.VAPING,
          checked: !this.isCigarette()
        }
      ]
    });

    await dialog.present();
  }

  // VAP-45 - TODO: remove this once Vaping is released to avoid not needed stuff
  private checkIfVapingAvailable() {
    const vapingReleaseDate: moment.Moment = moment(new Date(this.config.env.vapingReleaseDate))
      .startOf('day');
    const today: moment.Moment = moment();
    if (today.isSameOrBefore(vapingReleaseDate)) {
      this.hideVaping = true;
      this.smokingType = SmokingType.CIGARETTE;
    }
  }

  private buildForm(userData) {
    const form = {};
    this.formControlTemplates.forEach(({ key, validators, defaultValue }) => {
      const value = userData[key] || defaultValue;
      form[key] = this.formBuilder.control(value, validators);
    });

    return this.formBuilder.group(form);
  }

  private getFormattedDate(date: Moment = moment()): string {
    return moment(date)
      .format(this.DATE_FORMAT);
  }

  private saveFormState() {
    this.initialFormSettings = { ...this.quitingPlanSettingsForm.value };
  }

  private initDialogTranslations() {
    this.translate.get(this.dialogTranslationsConfig.map(({ path }) => path))
      .pipe(take(1))
      .subscribe((translations: any) => this.dialogTranslationsConfig.forEach(config => config.text = translations[config.path]));
  }

  private getDialogTranslatedTextByName(controlName) {
    if (!controlName) {
      return '';
    }
    const config = this.dialogTranslationsConfig.find(({ control }) => control === controlName);

    return config ? config.text : '';
  }

  private async showSmokingTypeWarning(selectedProgramType) {
    const dialog = await this.alerts.alertController.create({
      header: this.translate.instant('account_menu.quit.warnings.program_type.header'),
      message: this.translate.instant('account_menu.quit.warnings.program_type.message'),
      buttons: [
        { text: this.getDialogTranslatedTextByName(this.DIALOG_BUTTONS.CANCEL), role: 'cancel' },
        {
          text: this.getDialogTranslatedTextByName(this.DIALOG_BUTTONS.CONTINUE),
          handler: () => this.sessionStore.dispatch(new UpdateUserProgramSmokingType(selectedProgramType))
        }
      ]
    });

    await dialog.present();
  }
}
