import { OnInit, Component, OnDestroy } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { SettingsComponent } from './settings.component';
import { getOnboardingState, State } from '../../../store/state.reducer';
import { ActivatedRoute, Router } from '@angular/router';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { BrowserService } from 'src/app/services/browser.service';
import { ConnectivityService } from 'src/app/services/connectivity.service';
import { ClarityConfig } from 'src/app/config/clarity.config';
import { IntegrationsService } from 'src/app/services/integrations.service';
import * as navigationActions from 'src/app/store/session/actions/navigation.actions';
import * as integrationsActions from 'src/app/store/session/actions/integrations.actions';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { isFitbitConnected as isFitbitConnectedSelector } from 'src/app/store/session/selectors/integrations.selectors';
import { FitbitCallback } from 'src/app/store/session/models/integration.model';
import { getNotManualWeightActivitiesByDatesDescending } from 'src/app/store/normalized/selectors/weight-activity.selectors';
import { getCurrentUser, getCurrentUserProgram } from 'src/app/store/normalized/selectors/user.selectors';
import { isAfter, isSameDay, parseJSON } from 'date-fns';
import { SetOnboardingCompleted } from 'src/app/store/persistent/onboarding/onboarding.actions';
import { WeightTrackingInfo } from './weight-track-block.component';
import { convertWeight } from 'src/app/utils/weight-conversions';

@Component({
  selector: 'cl-account-weight-dpp',
  styleUrls: ['weight-track-dpp.component.scss'],
  template: `
    <div *ngIf="didntReceiveScale$ | async" class="onboarding-incomplete">
      <h2 class="title">{{ 'weight_tracking.havent_received_your_scale' | translate }}</h2>
      <h3 class="sub-title bold">{{ 'weight_tracking.check_your_email_to_redeem' | translate }}</h3>
      <p class="sub-title">{{ 'weight_tracking.once_you_received_your_scale' | translate }}</p>

      <h3 class="sub-title bold">{{ 'weight_tracking.in_the_meantime_lets_get_going' | translate }}</h3>
      <div class="button-container">
        <cl-action-button
          color="white"
          (action)="goToTodaysLesson()"
          [canClick]="true"
          label="{{ 'weight_tracking.todays_lesson' | translate }}">
        </cl-action-button>
      </div>
    </div>
    <div *ngIf="receivedScale$ | async" class="onboarding-complete">
      <h2 class="title">{{ 'weight_tracking.awesome_you_have_your_scale' | translate }}</h2>
      <h3 class="sub-title">{{ 'weight_tracking.connect_your_fitbit_account_now' | translate }}</h3>
      <div class="button-container">
        <cl-action-button
          (action)="connectFitbit()"
          [canClick]="true"
          label="{{ 'weight_tracking.connect_to_fitbit' | translate }}">
        </cl-action-button>
      </div>
      <ng-container *ngTemplateOutlet="help"></ng-container>
    </div>
    <div *ngIf="connectedFitbit$ | async">
      <h3 class="sub-title"><img src="assets/imgs/danger.png" /> {{ 'weight_tracking.you_have_no_recordings_yet' | translate }}</h3>
      <h2 class="title">{{ 'weight_tracking.step_on_your_scale' | translate }}</h2>
      <ng-container *ngTemplateOutlet="help"></ng-container>
    </div>
    <div *ngIf="usedScale$ | async" class="account-weight-container">
      <ion-row class="weight-stats">
        <ion-col class="weight-tracker big">
          <cl-weight-track-block
            [weightTrackingInfo]="startWeight$ | async"
            [weightTitle]="'weight_tracking.start' | translate">
          </cl-weight-track-block>
        </ion-col>

        <ion-col class="weight-tracker big">
          <cl-weight-track-block
            [weightTrackingInfo]="weightTarget$ | async"
            [weightTitle]="'weight_tracking.goal' | translate"
            [showInfoIcon]="true">
          </cl-weight-track-block>
        </ion-col>
      </ion-row>

      <div class="weight-track-list">
        <ion-row *ngFor="let weightActivity of (weightActivities$ | async) | slice:0:shownWeightActivities" class="weight-point-container">
          <ion-col class="track-col ion-text-left">
            {{weightActivity.value | number:'1.1-1' }} {{ weightActivity | weightUnit }},
            {{ weightActivity.activity_at | weightInfoDate : 'mediumDate' : 'UTC' | titlecase }}
          </ion-col>
        </ion-row>
      </div>

      <ion-row *ngIf="(weightActivities$ | async)?.length > shownWeightActivities" class="action-button">
        <ion-col style="padding: 0;" class="ion-text-center" auto>
          <ion-button class="dots" color="action" size="small" (click)="showMoreWeights()">
            ...
          </ion-button>
        </ion-col>
      </ion-row>

      <div *ngIf="isProgrammDPP" [innerHTML]="'weight_tracking.manual_entries_informations' | translate" class="manual-entries-informations"></div>
    </div>

    <ng-template #help>
      <p class="small-print">{{ 'weight_tracking.trouble_setting_up_your_fitbit' | translate }}</p>
      <div class="button-container">
        <cl-action-button
          color="white"
          (action)="openHelpCenter()"
          [canClick]="true"
          label="{{ 'weight_tracking.contact_us' | translate }}">
        </cl-action-button>
      </div>
    </ng-template>`
})
export class WeightTrackDPPComponent implements SettingsComponent, OnInit, OnDestroy {
  isProgrammDPP = this.config.programDPP();

  queryParamsSubscription: Subscription;
  private currentUser$ = this.store.select(getCurrentUser);
  private userProgramCreatedAt$ = this.store.select(getCurrentUserProgram)
    .pipe(
      map(userProgram => userProgram.created_at)
    );
  public weightActivities$ = this.store.select(getNotManualWeightActivitiesByDatesDescending)
    .pipe(
      withLatestFrom(this.userProgramCreatedAt$, this.currentUser$),
      map(([weightActivities, createdAt, user]) =>
        weightActivities.map(weightActivity => ({
          ...weightActivity,
          value: convertWeight(weightActivity.value, weightActivity.unit, user.weight_unit),
          unit: user.weight_unit
        }))
          .filter(weightActivity => {
            const activityDate = parseJSON(weightActivity.activity_at);
            const programDate = parseJSON(createdAt);

            return isAfter(activityDate, programDate) || isSameDay(activityDate, programDate);
          }))
    );

  private isOnboardingComplete$ = this.store.select(getOnboardingState).pipe(
    map(onboardingState => onboardingState.dppwlScale)
  );
  private isFitbitConnected$ = this.store.select(isFitbitConnectedSelector);
  private didUseScale$ = this.weightActivities$
    .pipe(map(weightActivities => Boolean(weightActivities?.length)));
  private controlProperties$ = combineLatest([
    this.isOnboardingComplete$,
    this.isFitbitConnected$,
    this.didUseScale$
  ]);

  public didntReceiveScale$ = this.controlProperties$
    .pipe(map(([isOnboardingComplete, isFitbitConnected]) => !isOnboardingComplete && !isFitbitConnected));
  public receivedScale$ = this.controlProperties$
    .pipe(map(([isOnboardingComplete, isFitbitConnected]) => isOnboardingComplete && !isFitbitConnected));
  public connectedFitbit$ = this.controlProperties$
    .pipe(map(([_, isFitbitConnected, didUseScale]) => isFitbitConnected && !didUseScale));
  public usedScale$ = this.controlProperties$
    .pipe(map(([_, isFitbitConnected, didUseScale]) => isFitbitConnected && didUseScale));

  public shownWeightActivities = 10;
  public startWeight$: Observable<WeightTrackingInfo> = this.currentUser$
    .pipe(map(user => ({
      value: user.weight,
      unit: user.weight_unit
    })));
  public weightTarget$: Observable<WeightTrackingInfo> = this.startWeight$
    .pipe(
      map(weightTrackingInfo => ({
        ...weightTrackingInfo,
        value: weightTrackingInfo.value * 0.95
      }))
    );

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<State>,
    private browser: BrowserService,
    private connectivity: ConnectivityService,
    private config: ClarityConfig,
    private integrationsService: IntegrationsService,
    private onboardingService: OnboardingService
  ) {
  }

  ngOnInit() {
    this.queryParamsSubscription = this.handleIntegrationCallbackParams();
    this.isFitbitConnected$.pipe(take(1)).subscribe(isFitbitConnected => {
      if (!isFitbitConnected) {
        this.onboardingService.checkShowingOnboarding({type: 'dppwlScale'});
      } else {
        this.store.dispatch(new SetOnboardingCompleted('dppwlScale'));
      }
    });
  }

  openHelpCenter() {
    if (this.connectivity.preventAccessWhenOffline()) {
      return false;
    }

    this.browser.goTo(this.config.env.helpCenterUrl);
  }

  connectFitbit() {
    this.integrationsService.goToFitbitConnectionPage({
      returnPath: this.config.env.fitbitCallbackWeightPath.replace('/', '')
    });
  }

  goToTodaysLesson() {
    this.store.dispatch(new navigationActions.GotoLessons());
  }

  showMoreWeights() {
    this.shownWeightActivities += 10;
  }

  ngOnDestroy() {
    this.queryParamsSubscription.unsubscribe();
  }

  handleIntegrationCallbackParams() {
    return this.activatedRoute.queryParams.subscribe((queryParams: FitbitCallback) => {
      console.log('[WeightTrackDPPComponent] queryParams', queryParams);
      const { callback, status, error } = queryParams;
      if (!callback) {
        return;
      }

      this.store.dispatch(new integrationsActions.ConnectFitBit(status, error));

      this.removeCurrentQueryParams();
    });
  }

  private removeCurrentQueryParams() {
    if (this.config.isWebApp) {
      history.pushState(null, '', window.location.pathname);
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {}
      }
    );
  }
}
