import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { US_PHONE_NUMBER_REGEX } from 'src/app/utils/us-phone-number-regex';
import { NrtService } from '../../../services/nrt.service';

@Component({
  selector: 'cl-nrt-provider-information',
  styleUrls: [
    'nrt-shipping-address.component.scss',
    'nrt.component.scss'
  ],
  template: `
    <ion-grid class="component-content-grid">
      <ion-row>
        <ion-col>
          <ion-row class="subtitle-holder lateral-padding">
            <ion-col>
              <h4>{{'nrt.provider.subtitle' | translate}}</h4>
            </ion-col>
          </ion-row>
          <ion-row class="lateral-padding">
            <ion-col class="content-wrapper">
              <form [formGroup]="providerForm" autocomplete="off">
                <cl-input
                  [controlForm]="providerForm.get('provider_full_name')"
                  type="text"
                  name="provider_full_name"
                  autocapitalize="words"
                  [errorMessages]="validations.provider_full_name.errors"
                  placeHolder="{{'nrt.provider.provider_full_name' | translate}}">
                </cl-input>

                <cl-input
                  [controlForm]="providerForm.get('provider_phone')"
                  type="text"
                  name="provider_phone"
                  [errorMessages]="validations.provider_phone.errors"
                  placeHolder="{{'nrt.provider.provider_phone' | translate}}">
                </cl-input>

                <cl-input
                  [controlForm]="providerForm.get('provider_fax')"
                  type="text"
                  name="provider_fax"
                  [errorMessages]="validations.provider_fax.errors"
                  placeHolder="{{'nrt.provider.provider_fax' | translate}}">
                </cl-input>
              </form>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>
  `
})
export class NrtProviderComponent implements OnDestroy {
// <cl-nrt-navigation
//   (previous)="previousStep()"
//   (next)="nextStep()"
//   [disableNext]="!providerForm.valid"
//     ></cl-nrt-navigation>

  formChangeSubscription: Subscription;
  providerForm: FormGroup;
  serviceData: any;
  @Output() previous = new EventEmitter();
  @Output() next = new EventEmitter();
  @Output() validityChange = new EventEmitter();

  public readonly validations = {
    provider_full_name: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.nrt.full_name_required'
      }
    },
    provider_phone: {
      validators: Validators.compose([
        Validators.required,
        Validators.pattern(US_PHONE_NUMBER_REGEX)
      ]),
      errors: {
        required: 'errors.nrt.phone_required',
        pattern: 'errors.nrt.phone_invalid'
      }
    },
    provider_fax: {
      validators: Validators.compose([
        Validators.pattern(US_PHONE_NUMBER_REGEX)
      ]),
      errors: {
        pattern: 'errors.nrt.fax_invalid'
      }
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    private nrtService: NrtService,
    private cdr: ChangeDetectorRef
  ) {

    this.serviceData = nrtService.providerData;

    this.providerForm = this.formBuilder.group({
      provider_full_name: new FormControl(this.serviceData.provider_full_name, this.validations.provider_full_name.validators),
      provider_phone: this.generateAndGetValidatedFormControl(this.serviceData.provider_phone, this.validations.provider_phone.validators),
      provider_fax: this.generateAndGetValidatedFormControl(this.serviceData.provider_fax, this.validations.provider_fax.validators)
    });
    this.unsubSub();
  }

  private generateAndGetValidatedFormControl(val, validators) {
    const formControl = new FormControl(val, validators);
    if (formControl.errors && formControl.errors.pattern) {
      formControl.markAsTouched();
    }

    return formControl;
  }

  previousStep() {
    this.nrtService.providerData = this.providerForm.value;
    this.previous.emit();
  }

  unsubSub() {
    if (this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
    }
    this.formChangeSubscription = this.providerForm.valueChanges.subscribe(() => {
      this.nrtService.providerData = this.providerForm.value;

      const wasValid = this.nrtService.providerValid;
      this.nrtService.providerValid = this.providerForm.valid;
      if (wasValid !== this.nrtService.providerValid) {
        this.validityChange.emit();
      }

      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    if (this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
    }
  }
}
