import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as toolsActions from '../actions/tools.actions';
import * as interstitialsActions from '../../persistent/interstitials/interstitials.actions';
import { concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as navigationActions from '../actions/navigation.actions';
import {
  isFirstCheckInCompleted,
  isFirstStressTestCompleted,
  isFirstStressMeterCompleted
} from '../../persistent/interstitials/interstitials.selectors';
import { of } from 'rxjs';

import { getCurrentModule } from '../selectors/program.selectors';
import { ClarityConfig } from '../../../config/clarity.config';
// import { App } from '@ionic/angular';
import { NotificationsService } from '../../../services/notification.service';
import { cravingToolSchema } from '../../normalized/schemas/craving-tool.schema';
import { AddData } from 'ngrx-normalizr';
import { CravingToolService } from '../../../services/wizards/craving-tool.service';
import { SessionState } from '../session.reducers';
import { StressMetersService } from '../../../services/wizards/stress-meters.service';
import { SmokingType } from '../../normalized/schemas/user.schema';
import { getCurrentUserProgram } from '../../normalized/selectors/user.selectors';
import { WorryToolProvider } from 'src/app/providers/wizards/worry-tool.provider';

@Injectable({providedIn: 'root'})
export class ToolsEffects {


  saveCheckin$ = createEffect(() => this.actions$.pipe(ofType<toolsActions.SaveCheckin>(toolsActions.SAVE_CHECKIN),
    map((action) => action.payload),
    concatMap(checkin => of(checkin)
      .pipe(withLatestFrom(this.store.select(isFirstCheckInCompleted)))
    ),
    switchMap(([checkin, firstCheckInCompleted]) => {
      const actions = [];
      // update normalized store

      // dispatch track action
      actions.push(new toolsActions.TrackCheckin(checkin, 'checkin'));

      let checkinTitle = 'interstitials.well_done';
      let checkinSubTitle = 'interstitials.checkins_are_brief_moments';

      // compute checkin messages based on feel now value
      if (checkin.feel_now >= 0.5) {
        checkinTitle = 'interstitials.great_job';
        checkinSubTitle = 'interstitials.take_a_moment_to_notice_reward';
      }

      // show interstitial
      if (firstCheckInCompleted) {
        actions.push(new navigationActions.ShowInterstitial(
          {
            page: 'InterstitialPage',
            params: {
              type: 'checkedIn',
              badge: this.config.program.avatar,
              title: checkinTitle,
              subTitle: checkinSubTitle,
              reminderSettingsLink: true
            }
          })
        );
      } else {
        actions.push(new interstitialsActions.SetFirstActionCompleted('firstCheckInCompleted'));
        actions.push(new navigationActions.ShowInterstitial(
          {
            page: 'InterstitialPage',
            params: {
              type: 'firstWizard',
              badge: this.config.program.avatar,
              title: checkinTitle,
              subTitle: checkinSubTitle,
              reminderSettingsLink: true
            }
          })
        );
      }

      return actions;
    })
  ));


  saveNightReflection$ = createEffect(() => this.actions$.pipe(ofType<toolsActions.SaveNightReflection>(toolsActions.SAVE_NIGHT_REFLECTION),
    map((action) => action.payload),
    switchMap((nightReflection) => {
      const actions = [];
      // update normalized store

      // dispatch track action
      actions.push(new toolsActions.TrackCheckin(nightReflection, 'night-reflection'));

      // show interstitial
      if (isFirstCheckInCompleted) {
        actions.push(new navigationActions.ShowInterstitial(
          {
            page: 'InterstitialPage',
            params: {
              type: 'checkedIn',
              badge: this.config.program.avatar,
              title: 'interstitials.good_night',
              notes: 'interstitials.this_would_be_a_great_time',
              communityLink: true
            }
          })
        );
      }

      return actions;
    })
  ));


  saveMorningMotivation$ = createEffect(() => this.actions$.pipe(ofType<toolsActions.SaveMorningMotivation>(toolsActions.SAVE_MORNING_MOTIVATION),
    map((action) => action.payload),
    switchMap((morningMotivation) => {
      const actions = [];
      // update normalized store

      // dispatch track action
      actions.push(new toolsActions.TrackCheckin(morningMotivation, 'morning-motivation'));

      let checkinTitle = 'interstitials.well_done';
      let checkinSubTitle = 'interstitials.checkins_are_brief_moments';

      // compute checkin messages based on feel now value
      if (morningMotivation.feel_now >= 0.5) {
        checkinTitle = 'interstitials.great_job';
        checkinSubTitle = 'interstitials.take_a_moment_to_notice_reward';
      }

      // show interstitial
      if (isFirstCheckInCompleted && !morningMotivation.playedModule) {
        actions.push(new navigationActions.ShowInterstitial(
          {
            page: 'InterstitialPage',
            params: {
              type: 'checkedIn',
              badge: this.config.program.avatar,
              title: checkinTitle,
              subTitle: checkinSubTitle,
              reminderSettingsLink: true
            }
          })
        );
      }

      return actions;
    })
  ));


  completeStressTest$ = createEffect(() => this.actions$.pipe(ofType<toolsActions.CompleteStressTest>(toolsActions.COMPLETE_STRESS_TEST),
    map((action) => action.payload),
    withLatestFrom(
      this.store.select(isFirstStressTestCompleted),
      this.store.select(getCurrentModule)
    ),
    switchMap(([stressTest, firstStressTestCompleted, module]) => {
      const actions = [];

      // dispatch track action
      actions.push(new toolsActions.SaveStressTest(stressTest));

      // dispatch interstitial actions
      if (firstStressTestCompleted) {
        const goal = module && module.record ? module.record.goal : '';
        actions.push(new navigationActions.ShowInterstitial({
          page: 'InterstitialPage',
          params: {
            type: 'congratulations',
            badge: this.config.program.avatar,
            subTitle: 'interstitials.for_checkin_yourself',
            notes: goal ? 'interstitials.today_goal' : null,
            goal
          }
        }));
      } else {
        actions.push(new navigationActions.ShowInterstitial({
          page: 'InterstitialPage',
          params: {
            type: 'firstWizard',
            badge: this.config.program.avatar,
            notes: 'interstitials.developing_new_habits',
            title: 'interstitials.stress_test_completed'
          }
        }));
      }

      return actions;
    })
  ));

  saveStressTest$ = createEffect(() => this.actions$.pipe(ofType<toolsActions.SaveStressTest>(toolsActions.SAVE_STRESS_TEST),
    map((action) => action.payload),
    withLatestFrom(
      this.store.select(isFirstStressTestCompleted)
    ),
    switchMap(([stressTest, firstStressTestCompleted]) => {
      const actions = [];
      actions.push(new toolsActions.TrackStressTest(stressTest));

      if (!firstStressTestCompleted) {
        actions.push(new interstitialsActions.SetFirstActionCompleted('firstStressTestCompleted'));
      }

      return actions;
    })
  ));

  saveCravingTool$ = createEffect(() => this.actions$.pipe(ofType<toolsActions.SaveCravingTool>(toolsActions.SAVE_CRAVING_TOOL),
    switchMap((cravingTool) => {
      const actions = [];
      const {payload, options} = cravingTool;
      // update normalized store

      // dispatch track action
      const {silent} = options;

      if (!silent) {
        actions.push(new navigationActions.ShowInterstitial({
          page: 'InterstitialPage',
          params: {
            type: 'goodJob',
            badge: this.config.program.avatar,
            notes: 'interstitials.thank_you_for_completing_this_exercise'
          }
        }));
      }
      const completedPayload = {
        ...payload,
        completed: true
      };

      actions.push(new toolsActions.TrackCravingTool(completedPayload, true));
      this.notificationService.clearCravingToolNotifications();

      return actions;
    })
  ));

  saveSmokingCravingTool$ = createEffect(() => this.actions$.pipe(ofType<toolsActions.SaveSmokingCravingTool>(toolsActions.SAVE_SMOKING_CRAVING_TOOL),
    switchMap((cravingTool) => {
      const actions = [];
      const {payload} = cravingTool;
      // update normalized store

      actions.push(new navigationActions.ShowInterstitial({
        page: 'InterstitialPage',
        params: {
          type: 'goodJob',
          badge: this.config.program.avatar,
          notes: 'interstitials.thank_you_for_completing_this_exercise'
        }
      }));

      const completedPayload = {
        ...payload,
        completed: true
      };

      // dispatch track action
      actions.push(new toolsActions.TrackSmokingCravingTool(completedPayload));

      return actions;
    })
  ));


  clearCravingToolNotifications$ = createEffect(() => this.actions$.pipe(
    ofType<toolsActions.ClearCravingToolNotifications>(toolsActions.CLEAR_CRAVING_TOOL_NOTIFICATIONS),
    tap(() => {
      this.notificationService.clearCravingToolNotifications();
    })
  ), {dispatch: false});


  scheduleCravingTool$ = createEffect(() => this.actions$.pipe(ofType<toolsActions.ScheduleCravingTool>(toolsActions.SCHEDULE_CRAVING_TOOL),
    switchMap((cravingTool) => {
      const actions = [];
      const {payload, options} = cravingTool;
      // update normalized store

      let notes = '';

      const {inMinutes} = options;

      if (options.inMinutes === 5) {
        notes = 'interstitials.we_will_checkin_in_5_minutes';
      }

      if (options.inMinutes === 15) {
        notes = 'interstitials.we_will_checkin_in_15_minutes';
      }

      this.notificationService.scheduleCravingToolNotification({inMinutes});
      this.cravingToolService.scheduleCravingToolPopup({inMinutes});

      actions.push(new navigationActions.ShowInterstitial({
        page: 'InterstitialPage',
        params: {
          type: 'greatJob',
          notes
        }
      }));

      actions.push(new AddData({
        schema: cravingToolSchema,
        data: [payload]
      }));

      // dispatch track action
      actions.push(new toolsActions.TrackCravingTool(payload, false));

      return actions;
    })
  ));


  saveStressMeter$ = createEffect(() => this.actions$.pipe(ofType<toolsActions.SaveStressMeter>(toolsActions.SAVE_STRESS_METER),
    map((action) => action.payload),
    concatMap(stressMeter => of(stressMeter)
      .pipe(withLatestFrom(
        this.store.select(isFirstStressMeterCompleted),
        this.store.select(getCurrentModule),
        this.store.select(getCurrentUserProgram)
      ))
    ),
    switchMap(([stressMeter, firstStressMeterCompleted, module, userProgram]) => {
      const actions = [];
      // update normalized store

      // dispatch track action
      actions.push(new toolsActions.TrackStressMeter(stressMeter));

      // show interstitial
      if (firstStressMeterCompleted) {
        const goal = module && module.record ? module.record.goal : '';
        actions.push(new navigationActions.ShowInterstitial({
          page: 'InterstitialPage',
          params: {
            type: 'congratulations',
            badge: this.config.program.avatar,
            subTitle: 'interstitials.for_checkin_yourself',
            notes: goal ? 'interstitials.today_goal' : null,
            goal
          }
        }));
      }
      else {
        actions.push(new interstitialsActions.SetFirstActionCompleted('firstStressMeterCompleted'));
        let title = '';
        let notes = '';

        if (this.config.isERN()) {
          title = 'interstitials.want_o_meter_completed';
          notes = 'interstitials.use_the_want_o_meter';
        }

        if (this.config.isCTQ()) {
          // Check if Smoke or Vape
          title = 'interstitials.want_o_meter_completed';
          notes = userProgram.smoking_type === SmokingType.CIGARETTE
            ? 'interstitials.use_the_want_o_meter_ctq_cig'
            : 'interstitials.use_the_want_o_meter_ctq_puff';
        }

        if (this.config.isUA()) {
          notes = 'interstitials.use_the_stress_meter';
          title = 'interstitials.stress_meter_completed';
        }

        actions.push(new navigationActions.ShowInterstitial({
          page: 'InterstitialPage',
          params: {
            type: 'firstWizard',
            badge: this.config.program.avatar,
            notes,
            title
          }
        }));
      }

      return actions;
    })
  ));


  returnStressMeter$ = createEffect(() => this.actions$.pipe(ofType<toolsActions.ReturnStressMeter>(toolsActions.RETURN_STRESS_METER),
    tap(() => {
      // this.getLastView().dismiss();
      // stress meter has no more steps so we need to wrap it up
      this.store.dispatch(new toolsActions.StopStressMeter());

      this.stressMeterService.save();
    })
  ), {dispatch: false});

  worryToolCompleted$ = createEffect(() => this.actions$.pipe(
    ofType<toolsActions.WorryToolCompleted>(toolsActions.WORRY_TOOL_COMPLETED),
    map(action => action.payload),
    switchMap((worryTool) => {
      this.store.dispatch(new navigationActions.ShowInterstitial({
        params: {
          type: 'congratulations',
          hideBadge: true,
          title: 'wizards.worry_tool_thanks_for_completing',
          subTitle: '',
          notes: 'wizards.worry_tool_thanks_take_a_moment',
          buttonText: 'common.Done'
        }
      }));

      return this.worryToolProvider.saveWorryTool(worryTool);
    })
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private store: Store<SessionState>,
    private config: ClarityConfig,
    // private app: App,
    private stressMeterService: StressMetersService,
    private notificationService: NotificationsService,
    private cravingToolService: CravingToolService,

    private worryToolProvider: WorryToolProvider
  ) {
  }

  getActiveNav() {
    // TODO: migrate - app alternative
    // return this.app.getActiveNavs()[0];
  }

  getAllViews() {
    // return this.getActiveNav()
    //   .getViews();
  }

  getLastView(): any {
    // return this.getActiveNav()
    //   .last();
  }
}
