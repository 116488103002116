import { createSelector } from '@ngrx/store';
import { createSchemaSelectors } from 'ngrx-normalizr';
import { dateSorter } from 'src/app/utils/date-sorter';
import { WeightActivity, weightActivitySchema } from '../schemas/weight-activity.schema';
import { IntegrationSource } from '../../session/models/integration.model';

const weightActivitySchemaSelector = createSchemaSelectors<WeightActivity>(weightActivitySchema);

export const getWeightActivity = createSelector(
  weightActivitySchemaSelector.getEntities,
  (weightActivities) => weightActivities || []
);

export const getNotManualWeightActivity = createSelector(
  getWeightActivity,
  (weightActivities) => weightActivities.filter(({ source }) => source !== IntegrationSource.manual)
);

export const getWeightActivityActionsPending = createSelector(
  // we only allow weights measured by devices (fitbit, etc), so we exclude manual entries here
  getNotManualWeightActivity,
  (weightActivities) => {
    if (weightActivities && Boolean(weightActivities.length)) {
      return 0;
    }

    return 1;
  }
);

export const getNotManualWeightActivitiesByDatesAscending = createSelector(
  getNotManualWeightActivity,
  weightActivities => weightActivities.sort((a, b) => dateSorter(a.activity_at, b.activity_at, 'ASC'))
);

export const getNotManualWeightActivitiesByDatesDescending = createSelector(
  getNotManualWeightActivity,
  weightActivities => weightActivities.sort((a, b) => dateSorter(a.activity_at, b.activity_at, 'DESC'))
);

export const getLastWeightActivity = createSelector(
  getNotManualWeightActivitiesByDatesDescending,
  weightActivities => {
    if(!weightActivities) {
      return null;
    } else {
      return weightActivities[0];
    }
  }
);
