import { Injectable } from '@angular/core';
import {
  HealthFit,
  HealthFitAuthorizationDataType,
  HealthFitAuthorizationType,
  HealthFitResultType
} from 'capacitor-health-fit';
import { Store } from '@ngrx/store';
import { combineLatest, from, Observable } from 'rxjs';
import { Platform } from '@ionic/angular';
import { ClarityConfig } from '../config/clarity.config';
import { SessionState } from '../store/session';
import {
  isGoogleFitConnected,
  isHealthKitConnected
} from '../store/session/selectors/integrations.selectors';
import * as integrationsActions from '../store/session/actions/integrations.actions';
import { HealthFitMultipleHistory } from 'capacitor-health-fit/dist/esm/definitions';
import { take } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class HealthFitService {
  isHealthKitConnected$ =  this.store.select(isHealthKitConnected);
  isGoogleFitConnected$ = this.store.select(isGoogleFitConnected);

  constructor(
    public store: Store<SessionState>,
    private platform: Platform,
    private clarityConfig: ClarityConfig
  ) {}

  public initialize(): Promise<boolean> {
    // Healthkit & Google fit available for DPP or WL only for now
    if(!this.clarityConfig.programDPPorWL()) {
      return Promise.resolve(true);
    }

    this.startSynchronization();

    this.platform.resume.subscribe(async () => {
      this.startSynchronization();
    });

    return Promise.resolve(true);
  }

  startSynchronization() {
    combineLatest([
      this.isHealthKitConnected$,
      this.isGoogleFitConnected$
    ]).pipe(take(1))
      .toPromise()
      .then(([hkConnected, fitConnected]) => {
        if (hkConnected || fitConnected) {
          this.store.dispatch(new integrationsActions.GetHealthDataSinceLastSync());
        }
      });
  }
  isAvailable(): Observable<boolean> {
    return from(HealthFit.isAvailable().then(result => result.available)) as Observable<boolean>;
  }

  openHealthApp(): Observable<void> {
    return from(HealthFit.openHealth()) as Observable<void>;
  }

  requestAuthorizations(read: Array<HealthFitAuthorizationDataType> = [], write: Array<HealthFitAuthorizationDataType> = []): Observable<void> {
    return from(HealthFit.requestAuthorization({
      read,
      write
    })) as Observable<void>;
  }

  isMultipleTypesAuthorized(types: Array<HealthFitAuthorizationDataType>, mode: HealthFitAuthorizationType): Observable<boolean> {
    const promises = [];
    types.forEach(type => {
      promises.push(HealthFit.isAuthorized({type, mode}));
    });

    return from(Promise.all(promises).then((results: Array<{ authorized: boolean }>) =>  results.every(result => result.authorized === true)));
  }

  readData(types: Array<HealthFitAuthorizationDataType>, start: Date, end: Date, result: HealthFitResultType): Observable<HealthFitMultipleHistory> {
    return from(HealthFit.readMultiple({types, start, end, result})) as Observable<HealthFitMultipleHistory>;
  }
}
