import { Injectable } from '@angular/core';

import { Observable , defer , from as fromPromise , of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { createEffect } from '@ngrx/effects';

import { offlineStore, OfflineSyncAction, OFFLINE_SYNC_ACTION } from './offline.store';

@Injectable({providedIn: 'root'})
export class OfflineSyncEffects {
  hydrate$: Observable<any> = createEffect(() => defer(() =>
    fromPromise(offlineStore.fetchStore())
      .pipe(
        map(state => ({
          type: OFFLINE_SYNC_ACTION,
          payload: state
        } as OfflineSyncAction)),
        catchError((error) => {
          console.warn(`Error fetching data from offline store for hydration: ${error}`);

          return of({
            type: OFFLINE_SYNC_ACTION,
            payload: {}
          } as OfflineSyncAction);
        })
      )
  ));
}
