// integrations actions

import { Action } from '@ngrx/store';
import { HealthFitData } from 'capacitor-health-fit/dist/esm/definitions';
import {
  IntegrationResultBase,
  StatusType
} from '../../../components/integrations-status-modal.component';
import { FitbitCallbackError } from '../models/integration.model';
import { WeightActivity } from '../../normalized/schemas/weight-activity.schema';
import { MinutesActivity } from '../../normalized/schemas/minutes-activity.schema';

export const ADD_MANUAL_MINUTES_ACTIVITY = '[integrations] Add Manual Minutes Activity';
export const ADD_MANUAL_MINUTES_ACTIVITY_SUCCESS = '[integrations] Add Manual Minutes Activity Success';
export const ADD_MANUAL_MINUTES_ACTIVITY_FAIL = '[integrations] Add Manual Minutes Activity Fail';

export const REMOVE_MANUAL_MINUTES_ACTIVITY = '[integrations] Remove Manual Minutes Activity';
export const REMOVE_MANUAL_MINUTES_ACTIVITY_SUCCESS = '[integrations] Remove Manual Minutes Activity Success';
export const REMOVE_MANUAL_MINUTES_ACTIVITY_FAIL = '[integrations] Remove Manual Minutes Activity Fail';

export const SYNC_OFFLINE_MANUAL_ACTIVITIES = '[integrations] Sync Offline Manual Activities';

// Open health app (iOS Only)
export const OPEN_HEALTH_APP = '[integrations] Open Health Application';

// healthkit connection status
export const CONNECT_HEALTHKIT = '[integrations] Connect HealthKit';
export const CONNECT_HEALTHKIT_SUCCESS = '[integrations] Connect HealthKit Success';
export const CONNECT_HEALTHKIT_FAIL = '[integrations] Connect Healthkit Fail';
export const DISCONNECT_HEALTHKIT = '[integrations] Disconnect Healthkit';
export const REQUEST_HEALTH_FIT_AUTHORIZATIONS = '[integrations] Request Health Fit Authorizations';

// Google fit connection status
export const CONNECT_GOOGLE_FIT = '[integrations] Connect Google fit';
export const CONNECT_GOOGLE_FIT_SUCCESS = '[integrations] Connect Google fit Success';
export const CONNECT_GOOGLE_FIT_FAIL = '[integrations] Connect Google fit Fail';
export const DISCONNECT_GOOGLE_FIT = '[integrations] Disconnect Google fit';
export const REQUEST_GOOGLE_FIT_AUTHORIZATIONS = '[integrations] Request Google Fit Authorizations';
export const CHECK_GOOGLE_FIT_AUTHORIZATIONS = '[integrations] Check Google Fit Authorizations';
export const GOOGLE_FIT_AUTHORIZATIONS_NOT_DETERMINED = '[integrations] Google Fit Authorizations Not Determined';

// Fitbit connection status
export const GET_FITBIT_CONNECTION_STATUS_AND_NAVIGATE = '[integrations] Get Fitbit Connection Status and Navigate';
export const GET_FITBIT_CONNECTION_STATUS_AND_NAVIGATE_SUCCESS = '[integrations] Get Fitbit Connection Status and Navigate Success';
export const GET_FITBIT_CONNECTION_STATUS_AND_NAVIGATE_FAIL = '[integrations] Get Fitbit Connection Status and Navigate Fail';
export const CONNECT_FITBIT = '[integrations] Connect FitBit';
export const CONNECT_FITBIT_SUCCESS = '[integrations] Connect FitBit Success';
export const CONNECT_FITBIT_FAIL = '[integrations] Connect FitBit Fail';
export const DISCONNECT_FITBIT = '[integrations] Disconnect FitBit';
export const DISCONNECT_FITBIT_SUCCESS = '[integrations] Disconnect FitBit Success';
export const DISCONNECT_FITBIT_FAIL = '[integrations] Disconnect FitBit Fail';
export const REQUEST_FITBIT_WEIGHT = '[integrations] Request fitbit Weight';
export const REQUEST_FITBIT_WEIGHT_SUCCESS = '[integrations] Request fitbit Weight Success';
export const REQUEST_FITBIT_WEIGHT_FAIL = '[integrations] Request fitbit Weight Fail';

// Get Healthkit & GoogleFit new data
export const GET_HEALTH_DATA_SINCE_LAST_SYNC = '[integrations] Get Health Data Since Last Sync';
export const GET_HEALTH_DATA_SINCE_LAST_SYNC_SUCCESS = '[integrations] Get Health Data Since Last Sync Success';
export const GET_HEALTH_DATA_SINCE_LAST_SYNC_FAIL = '[integrations] Get Health Data Since Last Sync Fail';

// Get ALL Healthkit & GoogleFit new data since first userProgram creation date
export const GET_ALL_HEALTH_DATA = '[integrations] Get All Health Data';
export const GET_ALL_HEALTH_DATA_SUCCESS = '[integrations] Get All Health Data Success';
export const GET_ALL_HEALTH_DATA_FAIL = '[integrations] Get All Health Data Fail';

// Post Healthkit & GoogleFit data
export const POST_HEALTH_DATA = '[integrations] Post Health Data';
export const POST_HEALTH_DATA_SUCCESS = '[integrations] Post Health Data Success';
export const POST_HEALTH_DATA_FAIL = '[integrations] Post Health Data Fail';

export const SHOW_INTEGRATION_STATUS_MODAL = '[integrations] Show integration status modal';

export class OpenHealthApp implements Action {
  readonly type = OPEN_HEALTH_APP;
}

export class ConnectHealthKit implements Action {
  readonly type = CONNECT_HEALTHKIT;
}

export class ConnectHealthKitSuccess implements Action {
  readonly type = CONNECT_HEALTHKIT_SUCCESS;
}

export class ConnectHealthKitFail implements Action {
  readonly type = CONNECT_HEALTHKIT_FAIL;
}

export class DisconnectHealthKit implements Action {
  readonly type = DISCONNECT_HEALTHKIT;
}

export class RequestHealthFitAuthorizations implements Action {
  readonly type = REQUEST_HEALTH_FIT_AUTHORIZATIONS;

}

export class ConnectGoogleFit implements Action {
  readonly type = CONNECT_GOOGLE_FIT;
}

export class ConnectGoogleFitSuccess implements Action {
  readonly type = CONNECT_GOOGLE_FIT_SUCCESS;
}

export class ConnectGoogleFitFail implements Action {
  readonly type = CONNECT_GOOGLE_FIT_FAIL;
}

export class GoogleFitAuthorizationsNotDetermined implements Action {
  readonly type = GOOGLE_FIT_AUTHORIZATIONS_NOT_DETERMINED;
}

export class DisconnectGoogleFit implements Action {
  readonly type = DISCONNECT_GOOGLE_FIT;
}

export class RequestGoogleFitAuthorizations implements Action {
  readonly type = REQUEST_GOOGLE_FIT_AUTHORIZATIONS;
}

export class CheckGoogleFitAuthorizations implements Action {
  readonly type = CHECK_GOOGLE_FIT_AUTHORIZATIONS;
}

export class GetAllHealthData implements Action {
  readonly type = GET_ALL_HEALTH_DATA;
}

export class GetAllHealthDataSuccess implements Action {
  readonly type = GET_ALL_HEALTH_DATA_SUCCESS;
}

export class GetAllHealthDataFail implements Action {
  readonly type = GET_ALL_HEALTH_DATA_FAIL;

  constructor(public payload: string) {
  }
}

export class ConnectFitBit implements Action {
  readonly type = CONNECT_FITBIT;

  constructor(public payload: StatusType, public error?: FitbitCallbackError) {
  }
}

export class ConnectFitBitSuccess implements Action {
  readonly type = CONNECT_FITBIT_SUCCESS;
}

export class ConnectFitBitFail implements Action {
  readonly type = CONNECT_FITBIT_FAIL;

  constructor(public error?: FitbitCallbackError) {
  }
}

export class RequestFitBitWeight implements Action {
  readonly type = REQUEST_FITBIT_WEIGHT;
}

export class RequestFitBitWeightSuccess implements Action {
  readonly type = REQUEST_FITBIT_WEIGHT_SUCCESS;

  constructor(public payload: WeightActivity) {

  }
}

export class RequestFitBitWeightFail implements Action {
  readonly type = REQUEST_FITBIT_WEIGHT_FAIL;
}

export class DisconnectFitBit implements Action {
  readonly type = DISCONNECT_FITBIT;
}

export class DisconnectFitBitSuccess implements Action {
  readonly type = DISCONNECT_FITBIT_SUCCESS;
}

export class DisconnectFitBitFail implements Action {
  readonly type = DISCONNECT_FITBIT_FAIL;

  constructor(public payload: string) {
  }
}

export class GetFitbitConnectionStatusAndNavigate implements Action {
  readonly type = GET_FITBIT_CONNECTION_STATUS_AND_NAVIGATE;

  constructor(public returnPath: string) {
  }
}

export class GetFitbitConnectionStatusAndNavigateSuccess implements Action {
  readonly type = GET_FITBIT_CONNECTION_STATUS_AND_NAVIGATE_SUCCESS;

  constructor(public returnPath: string, public isConnected: boolean) {
  }
}

export class GetFitbitConnectionStatusAndNavigateFail implements Action {
  readonly type = GET_FITBIT_CONNECTION_STATUS_AND_NAVIGATE_FAIL;

  constructor(public returnPath: string, public error?: FitbitCallbackError) {}
}

export class GetHealthDataSinceLastSync implements Action {
  readonly type = GET_HEALTH_DATA_SINCE_LAST_SYNC;
}

export class GetHealthDataSinceLastSyncSuccess implements Action {
  readonly type = GET_HEALTH_DATA_SINCE_LAST_SYNC_SUCCESS;
}

export class GetHealthDataSinceLastSyncFail implements Action {
  readonly type = GET_HEALTH_DATA_SINCE_LAST_SYNC_FAIL;

  constructor(public payload: string) {
  }
}

export class PostHealthData implements Action {
  readonly type = POST_HEALTH_DATA;
  constructor(public payload: { globalSynchronization: boolean; newWeights: HealthFitData[]; newMinutes: HealthFitData[]; newSynchronizationDate: string }) {}
}

export class PostHealthDataSuccess implements Action {
  readonly type = POST_HEALTH_DATA_SUCCESS;

  constructor(public payload: { globalSynchronization: boolean; newSynchronizationDate: string }) {}
}

export class PostHealthDataFail implements Action {
  readonly type = POST_HEALTH_DATA_FAIL;

  constructor(public payload: { error: string; globalSynchronization: boolean }) {}
}

export class AddManualMinutesActivity implements Action {
  readonly type = ADD_MANUAL_MINUTES_ACTIVITY;

  constructor(public minutes: number, public date: Date, public id?: number) {}
}

export class AddManualMinutesActivitySuccess implements Action {
  readonly type = ADD_MANUAL_MINUTES_ACTIVITY_SUCCESS;

  constructor(public minutesActivity: MinutesActivity) {}
}

export class AddManualMinutesActivityFail implements Action {
  readonly type = ADD_MANUAL_MINUTES_ACTIVITY_FAIL;

  constructor(public payload: { error: string; minutesActivity: MinutesActivity }) {}
}

export class RemoveManualMinutesActivity implements Action {
  readonly type = REMOVE_MANUAL_MINUTES_ACTIVITY;

  constructor(public payload: MinutesActivity) {}
}

export class RemoveManualMinutesActivitySuccess implements Action {
  readonly type = REMOVE_MANUAL_MINUTES_ACTIVITY_SUCCESS;

  constructor(public payload: number) {}
}

export class RemoveManualMinutesActivityFail implements Action {
  readonly type = REMOVE_MANUAL_MINUTES_ACTIVITY_FAIL;

  constructor(public payload: { error: string; minutesActivity: MinutesActivity }) {}
}

export class SyncOfflineManualActivities implements Action {
  readonly type = SYNC_OFFLINE_MANUAL_ACTIVITIES;
}

export class ShowIntegrationStatusModal implements Action {
  readonly type = SHOW_INTEGRATION_STATUS_MODAL;

  constructor(public integrationResult: IntegrationResultBase) {}
}

export type IntegrationsActions =
  | ConnectHealthKit
  | ConnectHealthKitSuccess
  | ConnectHealthKitFail
  | DisconnectHealthKit
  | RequestHealthFitAuthorizations
  | ConnectGoogleFit
  | ConnectGoogleFitSuccess
  | ConnectGoogleFitFail
  | DisconnectGoogleFit
  | RequestGoogleFitAuthorizations
  | CheckGoogleFitAuthorizations
  | GoogleFitAuthorizationsNotDetermined
  | OpenHealthApp
  | GetFitbitConnectionStatusAndNavigate
  | GetFitbitConnectionStatusAndNavigateSuccess
  | GetFitbitConnectionStatusAndNavigateFail
  | ConnectFitBit
  | ConnectFitBitSuccess
  | ConnectFitBitFail
  | DisconnectFitBit
  | DisconnectFitBitSuccess
  | DisconnectFitBitFail
  | RequestFitBitWeight
  | RequestFitBitWeightSuccess
  | RequestFitBitWeightFail
  | GetHealthDataSinceLastSync
  | GetHealthDataSinceLastSyncSuccess
  | GetHealthDataSinceLastSyncFail
  | PostHealthData
  | PostHealthDataSuccess
  | PostHealthDataFail
  | GetAllHealthData
  | GetAllHealthDataSuccess
  | GetAllHealthDataFail
  | AddManualMinutesActivity
  | AddManualMinutesActivitySuccess
  | AddManualMinutesActivityFail
  | RemoveManualMinutesActivity
  | RemoveManualMinutesActivitySuccess
  | RemoveManualMinutesActivityFail
  | SyncOfflineManualActivities
  | ShowIntegrationStatusModal;
