import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../../services/alerts.service';

@Component({
  selector: 'cl-message-card',
  styleUrls: ['./message-card.component.scss'],
  template: `
    <div [ngClass]="{'coach-container': _message.type === 'coach','user-container': _message.type === 'user'}">
      <div *ngIf="_message.type !== 'user'" class="author-avatar-container">
        <ion-avatar class="author-avatar">
          <img src="{{_message.avatar}}" alt="">
        </ion-avatar>
      </div>

      <div
        [ngClass]="{
        'user-message' : _message.type === 'user',
        'coach-message' : _message.type === 'coach',
        'message': true,
        'first-message': index === 0,
        'new-message': index > 0 && previousMessage.type !== _message.type
      }">
        <div class="more-button" (click)="deleteMessage()" *ngIf="_message.type === 'user'">...</div>
        <div [innerHTML]="_message.content"></div>
      </div>

      <div class="message-date" *ngIf="_message">
        {{ _message.created_at | relativeTime : true }}
      </div>
    </div>
  `
})

export class MessageCardComponent {
  @Input() previousMessage = null;
  @Input() nextMessage = null;
  @Input() last: boolean;
  // @Input() type: string; // coach || user
  @Input() index: number;
  @Input() userId: string;
  @Output() delete = new EventEmitter();

  constructor(
    private actionSheetController: ActionSheetController,
    private alerts: AlertsService,
    private translate: TranslateService
  ) {
  }

  _message: any;

  @Input()
  set message(value: any) {
    this._message = value;

    if (this._message.content) {
      this._message.content = this._message.content.replace(new RegExp('\\n', 'g'), '<br />');
    }
  }

  async deleteMessage(): Promise<void> {
    const deleteConfirm = await this.alerts.alertController.create({
      header: this.translate.instant('my-coach.conversation.delete_message'),
      message: this.translate.instant('my-coach.conversation.delete_message_confirmation_message'),
      buttons: [
        {
          text: this.translate.instant('common.no'),
          role: 'cancel'
        },
        {
          text: this.translate.instant('common.yes'),
          handler: () => {
            this.delete.emit(this._message);
          }
        }
      ]
    });

    const actionSheet = await this.actionSheetController.create({
      buttons: [
        {
          text: this.translate.instant('my-coach.conversation.delete_message'),
          role: 'destructive',
          icon: 'trash',
          handler: () => {
            deleteConfirm.present();
          }
        },
        {
          text: this.translate.instant('common.cancel'),
          role: 'cancel',
          icon: 'close-circle'
        }
      ],
      mode: 'md'
    });

    await actionSheet.present();
  }
}
