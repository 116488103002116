import { Injectable } from '@angular/core';

import { catchError, mergeMap, switchMap, tap, map } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as userProfileActions from '../actions/user-profile.actions';
import * as userActions from '../actions/account.actions';
import { UserProfileProvider } from '../../../providers/user-profile.provider';
import { NrtProvider } from '../../../providers/nrt.provider';
import { UserProfile, userProfileSchema } from '../../normalized/schemas/user.schema';
import { ToastService } from '../../../services/toast.service';
import { SetData } from 'ngrx-normalizr';
import { LoadingService } from '../../../services/loading.service';
import { SessionState } from '../session.reducers';
import { Store } from '@ngrx/store';
import { getProfileUpdating } from '../selectors/user-profile.selectors';
import { ReloadPosts } from '../actions/social.actions';
import { ClarityConfig } from '../../../config/clarity.config';

@Injectable({providedIn: 'root'})
export class UserProfileEffects {


  updateUserProfile$ = createEffect(() => this.actions$.pipe(ofType(userProfileActions.UPDATE_USER_PROFILE),
    tap(action => {
      this.loadingService.useLoadingObservable(
        this.store.select(getProfileUpdating)
      );
    }),
    switchMap((action: any) => this.userProfileProvider.updateUserProfile(action.payload as UserProfile)
      .pipe(
        mergeMap((userProfile: UserProfile) => [
          new SetData<UserProfile>({data: [userProfile], schema: userProfileSchema}),
          new userProfileActions.UpdateUserProfileSuccess(!!action.payload.terms_agreed)
        ]),
        catchError(error => of(new userProfileActions.UpdateUserProfileFail(error)))
      ))
  ));


  updateUserProfileSuccess$ = createEffect(() => this.actions$.pipe(ofType(userProfileActions.UPDATE_USER_PROFILE_SUCCESS),
    map((action: any) => action.payload),
    tap((silent) => {
      if(this.config.program.programCode === 'ctq') {
        this.store.dispatch(new ReloadPosts());
      }

      if (!silent) {
        this.toastService.translateConfirm('auth.profile_updated_successfully');
      }
    })
  ), {dispatch: false});


  updateUserProfileFail$ = createEffect(() => this.actions$.pipe(ofType(userProfileActions.UPDATE_USER_PROFILE_FAIL),
    tap(() => {
      this.toastService.translateError('errors.common.generic_error_please_retry');
    })
  ), {dispatch: false});


  loadUserProfile$ = createEffect(() => this.actions$.pipe(ofType(userProfileActions.LOAD_USER_PROFILE, userActions.LOAD_USER),
    switchMap(() => forkJoin({
      userProfile: this.userProfileProvider.getUserProfile()
    })
      .pipe(
        mergeMap((result) => [
          new SetData<UserProfile>({data: [{ ...result.userProfile}], schema: userProfileSchema}),
          new userProfileActions.LoadUserProfileSuccess()
        ]),
        catchError(error => of(new userProfileActions.LoadUserProfileFail(error)))
      ))
  ));

  constructor(
    private actions$: Actions,
    private userProfileProvider: UserProfileProvider,
    private nrtProvider: NrtProvider,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private store: Store<SessionState>,
    private config: ClarityConfig
  ) {

  }

}
