import { Injectable, OnDestroy } from '@angular/core';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { PluginListenerHandle } from '@capacitor/core';
import { AudioNotificationOptions, AudioPlayerState, BrightcovePlayer } from 'capacitor-brightcove-player';
import { ClarityConfig } from '../../config/clarity.config';
import { LoggerService } from '../logger.service';
import { ToastService } from '../toast.service';
import { BrightcoveAudioPlayerService } from './brightcove-audio-player-service.interface';
import { MediaFile } from '../../store/normalized/schemas/mediaFile.schema';
import { File } from '@ionic-native/file/ngx';

export enum AudioPlayerStatus {
  ERROR,
  NONE,
  MEDIA_LOADING,
  MEDIA_LOADED,
  POSITION,
  STARTING,
  RUNNING,
  PAUSED,
  STOPPED,
  COMPLETED,
  COMPLETED_WATCHED
}

@Injectable({providedIn: 'root'})
export class BrightcoveNativeAudioPlayerService implements OnDestroy, BrightcoveAudioPlayerService {

  private playing = false;
  private isAndroid;
  private readonly status$: BehaviorSubject<AudioPlayerStatus> = new BehaviorSubject<AudioPlayerStatus>(AudioPlayerStatus.NONE);
  private readonly positionSubject: Subject<number> = new Subject<number>();

  private _currentPosition = 0;
  private _duration = 0;
  private remainingLoopTime = 0;
  private lastLoopTime: number | null = null;

  private playerListeners: PluginListenerHandle[] = [];
  readonly looping$: Subject<boolean> = new Subject();
  position$ = this.positionSubject.asObservable();
  readonly closePlayerModal$: Subject<boolean> = new Subject<boolean>();
  closePlayerModal = this.closePlayerModal$.asObservable();

  get currentPosition() {
    return this._currentPosition;
  }

  set currentPosition(position) {
    this._currentPosition = position;
  }

  constructor(
    private readonly platform: Platform,
    private readonly insomnia: Insomnia,
    private readonly toastService: ToastService,
    private readonly config: ClarityConfig,
    private loggerService: LoggerService
  ) {
    this.isAndroid = this.platform.is('android');
  }

  ngOnDestroy() {
    this.unload();
    this.playerListeners.forEach(listener => {
      listener.remove();
    });
  }

  getCurrentTime() {
    return this._currentPosition;
  }

  downloadFile(mediaFile: MediaFile): Promise<void> {
    if (!this.config.isDevice) {
      return;
    }

    return BrightcovePlayer.downloadMedia({fileId: mediaFile?.brightcove_key});
  }

  load(config: { brightcove_key: string; notificationOptions: AudioNotificationOptions }): Promise<void> {
    if (!this.config.isDevice) {
      return;
    }

    if (!this.playerListeners.length) {
      this.initPlayerListeners();
    }

    this.status$.next(AudioPlayerStatus.MEDIA_LOADING);

    // set logo of the app as the poster for iOS
    const file =  new File();
    const defaultPosterUrl = this.config.isIos ?
      file.applicationDirectory + `public/assets/icons/${this.config.currentProgram()}/brightcove-audio-lock-screen.png` : '';

    return BrightcovePlayer.destroyAudioPlayer()
      .then(() => {
        if (config.notificationOptions) {
          BrightcovePlayer.setAudioNotificationOptions(config.notificationOptions).catch(error => {
            this.loggerService.error('Error setting audio notification options', error);
            throw error;
          });
        }

        return BrightcovePlayer.loadAudio({fileId: config.brightcove_key, local: true, defaultPosterUrl})
          .then(() => Promise.resolve())
          .catch(error => {
            this.loggerService.error('Error loading audio', error);
            throw error;
          });
      })
      .catch(error => {
        this.loggerService.error('Error destroying audio', error);
        throw error;
      });
  }

  unload(forceDestroyAudioPlayer: boolean = true): Promise<void> {
    if (!this.config.isDevice) {
      return Promise.resolve();
    }

    return this.disableInsomnia()
      .then(() => this.removePlayerListeners())
      .then(() => {
        forceDestroyAudioPlayer && BrightcovePlayer.destroyAudioPlayer()
          .catch(error => {
            this.loggerService.error('Error destroying audio', error);
            throw error;
          });

        this.playing = false;
      });
  }

  play(): Promise<void> {
    if (!this.config.isDevice) {
      return Promise.resolve();
    }

    return BrightcovePlayer.playAudio().catch(error => {
      this.loggerService.error('Error play audio', error);
      throw error;
    });
  }

  pause(): Promise<void> {
    if (!this.config.isDevice) {
      return Promise.resolve();
    }

    return BrightcovePlayer.pauseAudio().catch(error => {
      this.loggerService.error('Error pause audio', error);
      throw error;
    });
  }

  togglePlayPause() {
    if (this.playing) {
      return this.pause();
    }

    return this.play();

  }

  stop(): Promise<void> {
    if (!this.config.isDevice) {
      Promise.resolve();
    }

    return BrightcovePlayer.getAudioPlayerState()
      .then((playerState: AudioPlayerState) => this.positionSubject.next(playerState.currentMillis))
      .then(() => BrightcovePlayer.stopAudio())
      .catch(error => {
        this.loggerService.error('Error stop audio', error);
        throw error;
      });
  }

  seekTo(seconds: number) {
    if (!this.config.isDevice || isNaN(seconds)) {
      return Promise.resolve();
    }
    let newPositionSeconds = seconds;
    if (newPositionSeconds < 0) {
      newPositionSeconds = 0;
    } else if (this.getDuration() && newPositionSeconds > this.getDuration()) {
      newPositionSeconds = this.getDuration();
    }
    this.currentPosition = newPositionSeconds;

    return BrightcovePlayer.seekToAudio({position: newPositionSeconds * 1000})
      .catch(reason => {
        this.loggerService.info('Error seeking audio', reason);
      });
  }

  async jumpBy(seconds: number) {
    if (!this.config.isDevice) {
      return;
    }

    // Current position is in milliseconds
    this.currentPosition += seconds * 1000;

    this.handleStatusFromPosition(this.currentPosition, this.getDuration());

    return BrightcovePlayer[seconds > 0 ? 'forwardAudio' : 'backwardAudio']({amount: Math.abs(seconds * 1000)});
  }

  getDuration() {
    return this._duration;
  }

  get playerStatus() {
    return this.status$;
  }

  getRemainingLoopTime() {
    if (!this.remainingLoopTime) {
      return null;
    }

    return Math.round(this.remainingLoopTime / 1000);
  }

  isLooping(): Promise<boolean> {
    if (!this.config.isDevice) {
      return Promise.resolve(false);
    }

    return BrightcovePlayer.isAudioLooping()
      .then(result => result && result.value)
      .catch(error => {
        this.loggerService.error('Error is audio looping', error);
        throw error;
      });
  }

  setLooping(loopingEnabled: boolean, duration?: number) {
    if (!this.config.isDevice) {
      return;
    }

    this.lastLoopTime = null;

    BrightcovePlayer[loopingEnabled ? 'enableAudioLooping' : 'disableAudioLooping']({time: duration})
      .then(() => {
        this.looping$.next(loopingEnabled);
      });
  }

  private async initPlayerListeners() {
    this.playerListeners = [
      await BrightcovePlayer.addListener('audioNotificationClose', () => {
        // In this case, the audio player is destroyed by Android when the user click on the notification close button
        this.unload(false)
          .then(() => {
            this.closePlayerModal$.next(true);
          })
          .catch(reason => {
            this.loggerService.error('Error closing audio player', reason);
          });
      }),
      await BrightcovePlayer.addListener('audioStateChange', (playerState: AudioPlayerState) => {
        this.handleStatusUpdate(playerState);
      }),
      await BrightcovePlayer.addListener('audioPositionChange', (position: AudioPlayerState) => {
        let currentPosition = position.currentMillis;
        if (position.timestamp && currentPosition > 0) {
          currentPosition += new Date().getTime() - position.timestamp;
        }
        this.currentPosition = currentPosition;
        this.positionSubject.next(currentPosition);
        this._duration = position.totalMillis;
        this.remainingLoopTime = position.remainingTime;
        this.handleStatusFromPosition(position.currentMillis, position.totalMillis);
      }),
      await BrightcovePlayer.addListener('audioError', () => {
        this.toastService.error('Error while playing audio');
        this.loggerService.error('Error while playing audio');
      })
    ];
  }

  private async removePlayerListeners() {
    await Promise.all(this.playerListeners.map(listener => listener.remove()));
    this.playerListeners = [];
  }

  private async enableInsomnia() {
    if (!this.isAndroid) {
      return;
    }

    return this.insomnia.keepAwake();
  }

  private async disableInsomnia() {
    if (!this.isAndroid) {
      return;
    }

    return this.insomnia.allowSleepAgain();
  }

  // eslint-disable-next-line complexity
  private handleStatusUpdate(playerState: AudioPlayerState) {
    const status = playerState.state;
    switch (status) {
      case 'NONE':
        this.status$.next(AudioPlayerStatus.NONE);
        break;
      case 'RUNNING':
        this.enableInsomnia();
        this.status$.next(AudioPlayerStatus.RUNNING);
        break;
      case 'READY_TO_PLAY':
        const currentStatus = this.status$.getValue();
        if (currentStatus === AudioPlayerStatus.MEDIA_LOADING) {
          this.status$.next(AudioPlayerStatus.MEDIA_LOADED);
        } else {
          this.status$.next(AudioPlayerStatus.PAUSED);
          this.isLooping()
            .then(looping => {
              this.looping$.next(looping);
            });
          this.disableInsomnia();
        }
        break;
      case 'LOADING':
        this.status$.next(AudioPlayerStatus.MEDIA_LOADING);
        break;
      case 'STOPPED':
        this.isLooping()
          .then(looping => {
            this.looping$.next(looping);
            if (!looping) {
              this.status$.next(AudioPlayerStatus.COMPLETED);
              this.disableInsomnia();
            }
            this.status$.next(AudioPlayerStatus.STOPPED);
          });

        break;
    }
  }

  private handleStatusFromPosition(currentMillis: number, totalMillis: number) {

    if (this.isStatusCompletedFromPosition(currentMillis, totalMillis)) {
      this.status$.next(AudioPlayerStatus.COMPLETED_WATCHED);
    }
  }

  private isStatusCompletedFromPosition(currentMillis: number, totalMillis: number) {
    if (!currentMillis && !totalMillis) {
      return;
    }

    const currentPositionInSeconds = Math.ceil(currentMillis / 1000);
    const totalInSeconds = Math.ceil(totalMillis / 1000);

    // Emit one time status COMPLETED_WATCHED is enough
    return (this.status$.getValue() !== AudioPlayerStatus.COMPLETED_WATCHED)
      && currentPositionInSeconds >= totalInSeconds - 1;
  }
}
