import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-lesson-card',
  styleUrls: ['lesson-card.component.scss'],
  template: `
    <div class="locked-overlay" *ngIf="!lesson.isAvailable">
      <div class="lock-triangle">
        <img src="assets/imgs/lock-icon-triangle.png" alt="">
      </div>
    </div>
    <div class="shadow-wrapper"
         [ngClass]="{'completed': lesson.isCompleted, 'current': lesson.isCurrent, 'locked': !lesson.isAvailable}"
         (click)="onClick()">
      <ion-row>
        <ion-col size="3" class="ion-text-center icon-holder">
          <div class="icon-wrapper">
            <ion-icon name="logo-youtube"
              *ngIf="lesson.record?.exercise?.kind === 'video'">
            </ion-icon>
            <ion-icon name="headset"
              *ngIf="lesson.record?.exercise?.kind === 'audio'">
            </ion-icon>
            <ion-icon src="assets/imgs/icon-head-exercise.svg"
              *ngIf="lesson.record?.exercise?.kind === 'capture'">
            </ion-icon>
            <ion-icon src="assets/imgs/icon-head-exercise.svg"
              *ngIf="lesson?.record?.exercise?.kind === 'perform'">
            </ion-icon>
          </div>
        </ion-col>
        <ion-col size="9" class="actions-holder">
          <ion-row>
            <ion-col size="8" class="lesson-number ion-text-left">
              {{'common.lesson' | translate}} {{lesson.record.position}}
            </ion-col>
            <ion-col size="4" class="lesson-time ion-text-right" *ngIf="lesson?.record?.exercise?.length">
              {{formatMinutes(lesson.record.exercise.length)}}
            </ion-col>
          </ion-row>

          <ion-row class="lesson-title-holder">
            <ion-col class="lesson-title ion-text-left" size="11">{{lesson?.record?.exercise?.title}}</ion-col>
            <ion-col class="checkmark-holder ion-text-right" size="1">
              <ion-icon name="checkmark-circle-outline" color="white"></ion-icon>
            </ion-col>
          </ion-row>

        </ion-col>
      </ion-row>
    </div>
  `
})
export class LessonCardComponent {
  @Input() lesson: any;

  @Output() loadLesson = new EventEmitter();

  constructor(
    public element: ElementRef
  ) {

  }

  formatMinutes(length) {
    const minutes = Math.floor(length / 60);
    const seconds = length - (minutes * 60);

    return this.pad(minutes, 2) + ':' + this.pad(seconds, 2);
  }

  onClick() {
    if (!this.lesson.isAvailable) { return; }
    this.loadLesson.emit(this.lesson);
  }

  pad(n, width, z = 0) {
    n = n + '';

    return n.length >= width ? n : new Array(width - n.length + 1).join(z.toString()) + n;
  }
}
