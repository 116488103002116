import { schema } from 'normalizr';

export class WeightTrack {
  weight: number;
  activity_at: string;
  weight_unit: string;
}

export class QuitDate {
  quit_date: string;
}

export class UserActivity {
  activity_at: string;
  activityable_id?: number;
  activityable_type?: string;
  created_at: string;
  data: WeightTrack | any;
  id: number;
  kind: string;
  name: string;
  user_program_session_id: number;
}

export const userActivitySchema = new schema.Entity('userActivities');
