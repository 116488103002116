import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { IonContent, NavParams } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { State } from '../../store/state.reducer';
import { ClarityConfig } from '../../config/clarity.config';
import { CloseAllModals, CloseModal, ShowUpgradePopup } from '../../store/session/actions/navigation.actions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getCurrentUserProfile, isCurrentProfileCoach } from '../../store/normalized/selectors/user-profile.selectors';
import {
  CreateConversation,
  DeleteMessage,
  LoadAllMessages,
  LoadMessages,
  MarkConversationAsRead,
  PostMessage,
  ResetNewConversationId
} from '../../store/session/actions/my-coach.actions';
import {
  getLastCoachMessageFromFeed,
  getMessageLoading,
  getMessageSended,
  getMessagesFromFeed,
  getMessagesTotalCountFromFeed,
  getNewConversationId,
  getOpenConversationId,
  getOpenedConversation
} from '../../store/session/selectors/my-coach.selectors';
import { Observable } from 'rxjs';
import { Conversation, Message } from '../../store/normalized/schemas/my-coach.schema';
import { UserAgent } from '../../store/normalized/schemas/user.schema';
import * as userActivityActions from '../../store/session/actions/user-activity.actions';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import { getResumedAt } from '../../store/session/selectors/sync.selectors';
import { getLiveSubscription } from 'src/app/store/normalized/selectors/subscription.selectors';
import { filter, withLatestFrom } from 'rxjs/operators';
import { MyCoachService } from '../../services/my-coach/my-coach.service';
import { IridiumWebsocketService } from '../../services/analytics/iridium/iridium-websocket.service';
import { EventsService } from 'src/app/services/events.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'page-my-coach-conversation-ctq',
  styleUrls: ['my-coach-conversation.scss'],
  templateUrl: 'my-coach-conversation.html'
})
export class MyCoachConversationPage implements OnDestroy, OnInit {
  @ViewChild('content') private content: IonContent;
  @ViewChild('messageFormWrapper') formWrapper: ElementRef;

  messageForm: FormGroup;
  textAreaHasFocus = false;
  posting = false;
  localMessages: Message[] = [];
  public showBack = this.navParams.get('showBack') || true;

  public messages$: Observable<Message[]> = this.store.select(getMessagesFromFeed);
  public lastCoachMessage$: Observable<Message> = this.store.select(getLastCoachMessageFromFeed);
  public messageLoading$: Observable<boolean> = this.store.select(getMessageLoading);
  public currentUserProfile$ = this.store.select(getCurrentUserProfile);
  public isCoach$ = this.store.select(isCurrentProfileCoach);
  public currentConversation$: Observable<Conversation> = this.store.select(getOpenedConversation);

  private readonly typingSignalHideDelay: number = 10000;
  private hideTypingMessageTimeout;
  typingMessageCoachName = null;

  private readonly typingSignalSendDelay: number = 9500;
  private lastTypingSignalSendTime;

  userId: string;
  silentLoading = false;
  private newConversationId$ = this.store.select(getNewConversationId);
  private messageSended$ = this.store.select(getMessageSended);

  private messagesTotalCount$: Observable<number> = this.store.select(getMessagesTotalCountFromFeed);
  private messagesTotalcount: number;
  private loadAllMessagesAction = false;
  private subscriptions = [];
  private modal;
  private conversationId: string;
  private hasActiveSubscription: boolean;

  constructor(
    public config: ClarityConfig,
    private formBuilder: FormBuilder,
    private store: Store<State>,
    private navParams: NavParams,
    private changeDetector: ChangeDetectorRef,
    private events: EventsService,
    public myCoachService: MyCoachService,
    private iridiumWebsocketService: IridiumWebsocketService
  ) {
    this.conversationId = this.navParams.get('conversationId');
    this.messageForm = this.formBuilder.group({
      content: [{ value: '', disabled: this.posting }, Validators.required]
    });

    this.events.subscribe(this.config.events.logout, () => {
      this.onClose();
    });
  }

  get canLoadAllMessages() {
    return this.localMessages.length < this.messagesTotalcount;
  }

  get remainingMessages() {
    return this.messagesTotalcount - this.localMessages.length;
  }

  private getFirstMessageField(field, messages) {
    const firstMessage = this.getFirstMessage(messages);

    if (!firstMessage || !field) {
      return null;
    }

    return firstMessage[field];
  }

  public getMessageOwner(messages) {
    return this.getFirstMessageField('username', messages);
  }

  public getAdminUrl(messages) {
    return this.getFirstMessageField('admin_panel_url', messages);
  }

  public getStandaloneClient(messages) {
    return this.getFirstMessageField('standalone_client', messages);
  }

  private getFirstMessage(messages) {
    if (!messages || messages.length <= 0) {
      return null;
    }

    return messages[0];
  }

  @HostListener('document:click', ['$event'])
  andClickEvent(event) {
    if (this.formWrapper && !this.formWrapper.nativeElement.contains(event.target)) {
      this.textAreaHasFocus = false;
    }
  }

  onMessageInput() {
    if (!this.conversationId) {
      return;
    }

    const now = new Date().getTime();
    if (!this.lastTypingSignalSendTime || (now - this.lastTypingSignalSendTime > this.typingSignalSendDelay)) {
      this.iridiumWebsocketService.notifyUserTyping(this.conversationId);
      this.lastTypingSignalSendTime = now;
    }
  }

  ngOnInit(): void {
    this.listenToCoachingEvents();

    if (!this.conversationId) {
      this.subscriptions.push(this.newConversationId$
        .subscribe((id) => {
          if (id) {
            this.conversationId = id;
            this.store.dispatch(new ResetNewConversationId());
            this.store.dispatch(new LoadMessages(this.conversationId));
            this.messageForm.reset();
            this.textAreaHasFocus = false;
            this.posting = false;
          }
        })
      );
    } else {
      this.store.dispatch(new LoadMessages(this.conversationId));
      // this.subscriptions.push(this.myCoachService.coachOnline$
      //   .subscribe((status) => {
      //     this.coachOnline = status;
      //     this.changeDetector.detectChanges();
      //   })
      // );
    }

    // update messages total count
    this.subscriptions.push(this.messagesTotalCount$.subscribe(messagesTotalcount => {
      this.messagesTotalcount = messagesTotalcount;

      this.changeDetector.detectChanges();
    }));

    this.subscriptions.push(this.messageSended$.subscribe(
      sended => {
        if (sended) {
          this.messageForm.reset();
          this.textAreaHasFocus = false;
          this.posting = false;
          this.changeDetector.detectChanges();
        }
      })
    );

    this.subscriptions.push(this.messages$.subscribe(
      messages => {
        this.localMessages = messages;
        this.silentLoading = true;

        if (!this.loadAllMessagesAction) {
          setTimeout(() => {
            this.content.scrollToBottom(200);
            this.changeDetector.detectChanges();
            this.typingMessageCoachName = null;
          }, 50);
        } else {
          this.loadAllMessagesAction = false;
        }
      }
    ));

    this.subscriptions.push(this.store.select(getResumedAt)
      .subscribe(resumedAt => {

        if (!this.conversationId) return;

        const currentTimestamp = new Date().getTime();

        if (resumedAt && (currentTimestamp - resumedAt) <= 500) {
          this.store.dispatch(new LoadMessages(this.conversationId));
        }
      }
      ));

    this.subscriptions.push(this.store.select(getLiveSubscription)
      .subscribe((liveSubscription) => {
        this.hasActiveSubscription = liveSubscription && liveSubscription.isActive;
        if (!this.hasActiveSubscription) {
          this.messageForm.get('content')
            .disable();
        }
      }
      ));
  }

  onBack(): void {
    this.store.dispatch(new CloseModal({modalId: this.modal.id}));
  }

  onClose() {
    this.store.dispatch(new CloseAllModals());
  }

  loadAllMessages(): void {
    this.silentLoading = false;
    this.loadAllMessagesAction = true;
    this.store.dispatch(new LoadAllMessages(this.conversationId));
  }

  addMessage(): void {
    this.posting = true;
    this.changeDetector.detectChanges();

    if (!this.conversationId) {
      this.store.dispatch(new CreateConversation(this.messageForm.get('content').value));
      this.store.dispatch(new userActivityActions.TrackActivity({
        kind: 'coaching',
        name: 'New Conversation'
      }));
    } else {
      this.store.dispatch(new PostMessage(
        {
          content: this.messageForm.get('content').value,
          conversation_id: this.conversationId
        })
      );

      this.store.select(getMessageSended)
        .pipe(filter(sent => sent))
        .subscribe(sentMessage => {
          this.lastTypingSignalSendTime = null;
        });

      this.store.dispatch(new userActivityActions.TrackActivity({
        kind: 'coaching',
        name: 'Message',
        data: {
          conversation_id: this.conversationId
        }
      }));
    }
  }

  deleteMessage(id: string): void {
    this.store.dispatch(new DeleteMessage(id));
  }

  onMessageClick(): void {
    if (!this.hasActiveSubscription) {
      this.store.dispatch(new ShowUpgradePopup());
    }
  }

  onMessageFocus(): void {
    if (this.hasActiveSubscription) {
      this.textAreaHasFocus = true;
    }
  }

  ngOnDestroy(): void {
    if(this.conversationId) {
      this.store.dispatch(new MarkConversationAsRead(this.conversationId));
    }

    this.reset();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private listenToCoachingEvents() {
    this.subscriptions.push(this.myCoachService.typingCoach$
      .pipe(withLatestFrom(this.store.select(getOpenConversationId)))
      .subscribe(([data, openConversationId]) => {
        if (!openConversationId) {
          return;
        }

        const conversationId = parseInt(openConversationId, 10);
        if (!openConversationId || data.conversationId !== conversationId) {
          return;
        }

        const coachName = data.name ? data.name.replace(/ .*/, '') : null;

        this.typingMessageCoachName = coachName;

        this.changeDetector.detectChanges();
        if (!data.name) {
          return;
        }
        if (this.hideTypingMessageTimeout) {
          clearTimeout(this.hideTypingMessageTimeout);
        }
        this.hideTypingMessageTimeout = setTimeout(() => {
          this.myCoachService.typingCoach.next({
            conversationId: data.conversationId
          });
          this.hideTypingMessageTimeout = null;
        }, this.typingSignalHideDelay);
      })
    );
  }

  private reset() {
    this.conversationId = null;
    this.localMessages = [];
  }

  showCoachingBio(conversation: Conversation, lastCoachMessage: Message) {
    const agents = conversation?.agents || [];

    let agent: UserAgent = agents.find(({ name }) => name === lastCoachMessage.username);
    agent = agent || agents[agents.length - 1];

    this.store.dispatch(
      new navigationActions.OpenModal('MyCoachBioPage', {
        avatar: agent ? agent.avatarUrl : lastCoachMessage.avatar,
        name: agent ? agent.name : lastCoachMessage.username,
        bio: agent ? agent.bio : ''
      })
    );
  }
}
