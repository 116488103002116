import { schema } from 'normalizr';

export class AnswerType {
  [key: string]: number;
}

export class AwarenessQuiz {
  date: string;
  scores: number[];
  answers?: AnswerType;
}

export const awarenessQuizSchema = new schema.Entity(
  'awarenessQuizzes',
  undefined,
  { idAttribute: (awarenessQuizz: AwarenessQuiz) => awarenessQuizz.date }
);
