
import { throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';

import { catchError } from 'rxjs/operators';

import { HttpProvider } from '../http/http.provider';
import { OfflineHttpProvider } from '../http/offline-http.provider';

@Injectable({providedIn: 'root'})
export class CheckinsProvider {

  private checkinsEndpoint = '/check_ins';
  private checkinExercisesEndpoint = '/check_in_exercises';
  private checkinQuestionsEndpoint = '/check_in_questions';

  constructor(
    protected http: HttpProvider,
    private offlineHttp: OfflineHttpProvider
  ) {

  }

  public trackCheckin(checkin) {
    return this.offlineHttp.post(this.checkinsEndpoint, checkin);
  }

  public loadCheckinExercises() {
    return this.http.get(this.checkinExercisesEndpoint)
      .pipe(
        catchError((error) => {
          console.log('error retrieving checkin exercises', error);

          return observableThrowError(error);
        })
      );
  }

  public loadCheckinQuestions() {
    return this.http.get(this.checkinQuestionsEndpoint)
      .pipe(
        catchError((error) => {
          console.log('error retrieving checkin questions', error);

          return observableThrowError(error);
        })
      );
  }

  public getCountByDay() {
    return this.http.get(this.checkinsEndpoint + '/count_by_day' + '?tz=' + this.getTZ());
  }

  public getAnxietyByDay() {
    return this.http.get(this.checkinsEndpoint + '/anxiety_by_day' + '?tz=' + this.getTZ());
  }

  getTZ() {
    let offset = new Date().getTimezoneOffset() / 60;
    let ret = 'GMT';
    if (offset > 0) {
      ret += '-' + offset;
    } else if (offset < 0) {
      offset = -offset;
      ret += '%2B' + offset;
    }

    return ret;
  }
}
