import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { State } from '../../../store';
import { AlertController, NavController } from '@ionic/angular';
import { AddManualMinutesActivity } from '../../../store/session/actions/integrations.actions';
import {
  getDailyActivity, getIsFirstManualEntry,
  getTodayManualMinutesActivity,
  getTodaysMinutesActivitySum
} from '../../../store/normalized/selectors/minutes-activity.selectors';
import { IntegrationSource } from '../../../store/session/models/integration.model';
import {
  getShowInformationDialog,
  isFitbitConnected,
  isGoogleFitConnected,
  isHealthKitConnected, isIntegrationConnected
} from '../../../store/session/selectors/integrations.selectors';
import { IntegrationInformation } from '../../../components/integrations-status-modal.component';
import * as integrationsActions from '../../../store/session/actions/integrations.actions';
import { combineLatest, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { format } from 'date-fns';
import { getCurrentUserProgram } from '../../../store/normalized/selectors/user.selectors';
import { slideInOut } from 'src/app/utils/animations';

@Component({
  selector: 'cl-account-activity',
  styleUrls: ['activity.component.scss'],
  animations: [slideInOut],
  template: `
    <div [@state]="visibility"
         (@state.done)="animationDone($event)">
      <div class="activity">
        <div class="activity-section">
          <ion-grid class="today-grid">
            <ion-row class="ion-align-items-center">
              <ion-col class="ion-text-left main-title" size="5">
                {{'my_activity.today' | translate}}
              </ion-col>
              <ion-col class="ion-text-right date" size="7">{{today}}</ion-col>
            </ion-row>
          </ion-grid>

          <div class="activity-value">
            <div class="unit">{{ 'my_activity.my_activity_minutes' | translate}}</div>
            <code class="value">{{ getTodaysMinutesActivitySum$ | async }}</code>
          </div>

          <div class="connected-device">{{ 'my_activity.connected_device' | translate }}
            <img *ngIf="integrationConnected$ | async" src="assets/icon/information.svg" alt="" (click)="openConnectedDeviceModal()"/>
          </div>

          <div *ngIf="!(integrationConnected$ | async)" class="connect-device-content">
            {{'my_activity.connect_device_1' | translate}}
            <span (click)="goToMyDeviceSection()">{{'my_activity.connect_device_2' | translate}}</span>
            {{'my_activity.connect_device_3' | translate}}
          </div>

          <cl-integration-activity-card
            *ngIf="isFitBitConnected$ | async"
            [integration]="integrationSource.fitbit"
            [today]="true">
          </cl-integration-activity-card>
          <cl-integration-activity-card
            *ngIf="isGoogleFitConnected$ | async"
            [integration]="integrationSource.googlefit"
            [today]="true">
          </cl-integration-activity-card>
          <cl-integration-activity-card
            *ngIf="isHealthKitConnected$ | async"
            [integration]="integrationSource.healthkit"
            [today]="true">
          </cl-integration-activity-card>

          <ion-grid class="manual-entries">
            <ion-row>
              <ion-col class="ion-text-left">
                <div>{{ 'my_activity.manual_entries' | translate }}</div>
              </ion-col>
              <!-- Display this if manual entries for the current day -->
              <ion-col class="ion-text-right" *ngIf="(getTodayManualMinutesActivity$ | async)?.length > 0">
                <div class="add" (click)="addMinutesMannualy()">
                  {{ 'my_activity.add' | translate }}
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>

          <!-- Display this only if no manual entries for the current day -->
          <div class="daily-activity-details" *ngIf="(getTodayManualMinutesActivity$ | async)?.length === 0">
            <span (click)="addMinutesMannualy()">{{'my_activity.add' | translate}}</span>
            <span> {{((isFirstManualEntry$ | async) ? 'my_activity.first_manual_entry' : 'my_activity.new_manual_entry') | translate}}</span>
          </div>

          <div class="daily-activity-details" *ngIf="(getTodayManualMinutesActivity$ | async)?.length > 0">
            <cl-minutes-activity-card
              *ngFor="let minutesActivity of (getTodayManualMinutesActivity$ | async) | slice:0:shownMinutesActivities"
              [activity]="minutesActivity">
            </cl-minutes-activity-card>

            <ion-row
              class="action-button"
               *ngIf="(getTodayManualMinutesActivity$ | async)?.length > shownMinutesActivities"
               (click)="showMoreMinutesActivities()">
              <ion-col class="ion-text-center" >
                <ion-button
                  class="dots"
                  color="white"
                  size="small"
                  >
                  ...
                </ion-button>
              </ion-col>
            </ion-row>
          </div>
        </div>
      </div>
      <div class="daily-minutes-section" *ngIf="getDailyKeys?.length > 0">
        <div class="title">{{'my_activity.daily_minutes' | translate}}</div>
        <cl-daily-minutes-activity-card
          *ngFor="let getDailyKey of getDailyKeys | slice:0:shownDailyActivities"
          [activities]="(getDailyActivity$ | async)![getDailyKey]"
        >
        </cl-daily-minutes-activity-card>
        <ion-row
          class="action-button"
          *ngIf="getDailyKeys?.length > shownDailyActivities"
          (click)="showMoreDailyActivities()">
          <ion-col class="ion-text-center" >
            <ion-button
              class="dots"
              color="white"
              size="small"
            >
              ...
            </ion-button>
          </ion-col>
        </ion-row>
      </div>
      <div class="integration-section">
        <cl-action-button
          class="connect-my-device-btn"
          (click)="goToMyDeviceSection()"
          [label]="'my_activity.open_my_devices' | translate"
          [canClick]="true"
          color="white"
        >
        </cl-action-button>
        <div class="supported-platforms" [innerHTML]="'my_activity.supported_platforms' | translate"></div>
      </div>
    </div>
  `
})
export class ActivityComponent implements OnInit, OnDestroy {
  @Output() activityEnd = new EventEmitter();

  getTodaysMinutesActivitySum$ = this.stateStore.select(getTodaysMinutesActivitySum);
  getTodayManualMinutesActivity$ = this.stateStore.select(getTodayManualMinutesActivity);
  isFirstManualEntry$ = this.stateStore.select(getIsFirstManualEntry);
  getDailyActivity$ = this.stateStore.select(getDailyActivity);
  getDailyKeys = [];
  integrationSource = IntegrationSource;

  isHealthKitConnected$ = this.stateStore.select(isHealthKitConnected);
  isGoogleFitConnected$ = this.stateStore.select(isGoogleFitConnected);
  isFitBitConnected$ = this.stateStore.select(isFitbitConnected);
  integrationConnected$ = this.stateStore.select(isIntegrationConnected);
  showInformationDialog$ = this.stateStore.select(getShowInformationDialog);
  userProgramCreationDate: Date;

  shownMinutesActivities = 7;
  shownDailyActivities = 7;
  visibility = 'visible';

  private subscriptions: Subscription[] = [];

  showMoreDailyActivities() {
    this.shownDailyActivities += 7;
  }

  showMoreMinutesActivities() {
    this.shownMinutesActivities += 7;
  }

  get today() {
    return format(new Date(), 'MMMM do, yyyy');
  }

  constructor(
    private translate: TranslateService,
    public alertController: AlertController,
    private stateStore: Store<State>,
    private nav: NavController
  ) {}

  ngOnInit() {
    this.stateStore.select(getCurrentUserProgram).pipe(take(1))
      .subscribe(userProgram => {
        this.userProgramCreationDate = new Date(userProgram.created_at);
      });

    this.subscriptions.unshift(this.getDailyActivity$.subscribe(dailyActivity =>  {
      this.getDailyKeys = Object.keys(dailyActivity);
    }));

    this.showInformationDialog$.pipe(take(1)).subscribe(showDialog => {
      const platform = showDialog?.fitbit ? 'fitbit' : showDialog?.googleFit ? 'android' : showDialog?.healthKit ? 'ios' : null;

      if(platform) {
        const integrationResult: IntegrationInformation = {platform, type: 'information', status: null};
        this.stateStore.dispatch(new integrationsActions.ShowIntegrationStatusModal(integrationResult));
      }
    });
  }

  animationDone(event) {
    if (event.toState === 'hidden') {
      this.activityEnd.emit(true);
    }
  }

  closeComponent() {
    this.visibility = 'hidden';
  }

  async addMinutesMannualy() {
    this.translate.get(
      ['my_activity.add_daily_minutes', 'my_activity.time_in_minutes' ,'my_activity.submit', 'common.cancel']
    )
      .toPromise()
      .then(async translations => {
        const alert = await this.alertController.create({
          header: translations['my_activity.add_daily_minutes'],
          inputs: [
            {
              name: 'minutes',
              type: 'number',
              placeholder: translations['my_activity.time_in_minutes']
            },
            {
              name: 'date',
              type: 'datetime-local',
              min: format(this.userProgramCreationDate, 'yyyy-MM-dd\'T\'HH:mm'),
              max: format(new Date(), 'yyyy-MM-dd\'T\'HH:mm'),
              value: format(new Date(), 'yyyy-MM-dd\'T\'HH:mm')
            }],
          buttons: [
            {
              text: translations['common.cancel']
            },
            {
              text: translations['my_activity.submit'],
              handler: data => {
                data.minutes && this.stateStore.dispatch(new AddManualMinutesActivity(data.minutes, new Date(data.date)));
              }
            }]
        });

        await alert.present();
      });
  }

  goToMyDeviceSection() {
    this.nav.navigateRoot('tabs/account/devices');
  }

  openConnectedDeviceModal() {
    combineLatest([
      this.isHealthKitConnected$,
      this.isGoogleFitConnected$,
      this.isFitBitConnected$
    ]).pipe(take(1))
      .subscribe(([ios, android, fitbit]) => {
        const platform = ios ? 'ios' : android ? 'android' : fitbit ? 'fitbit' : null;

        const integrationResult: IntegrationInformation = {platform, type: 'information', status: null};
        this.stateStore.dispatch(new integrationsActions.ShowIntegrationStatusModal(integrationResult));
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
