import { schema } from 'normalizr';

export class Symptoms {
  id: number;
  name: string;
  description: string;
  stress: boolean;
  hunger: boolean;
  habit: boolean;
}

export const symptomsSchema = new schema.Entity('symptoms');
