import { Component, Input } from '@angular/core';
import { LiveBonusExercise } from '../../store/session/selectors/program.selectors';
import { Exercise } from '../../store/normalized/schemas/exercise.schema';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'exercise-item',
  styleUrls: ['exercise-item.component.scss'],
  template: `
    <button class="button-lists" [class.unlocked]="!exerciseItem?.isLocked" [class.wizard]="isWizard || isLive">
      <div class="locked-item" *ngIf="exerciseItem?.isLocked"></div>
      <div class="new-item" *ngIf="exerciseItem.isNew && !isWizard"></div>

      <div class="img-block">
        <img [src]="'assets/imgs/' + getImg(exerciseItem) + '.png'"/>
      </div>

      <div class="divider" *ngIf="isLiveExercise"></div>
      <span [class.no-timing]="!exerciseHasTime">{{!isLiveExercise ? exerciseItem.title : exerciseItem.exercise.title}}</span>

      <p *ngIf="exerciseHasTime">{{!isLiveExercise ? (exerciseItem.length | processTime) : (exerciseItem.exercise.length | processTime)}}</p>
    </button>
  `
})
export class ExerciseItemComponent {

  @Input() exerciseItem: LiveBonusExercise | Exercise;
  @Input() isWizard: boolean;
  @Input() isLive: boolean;

  get isLiveExercise() {
    return !this.isWizard || this.isLive;
  }

  get exerciseHasTime() {
    if (!this.isLiveExercise) {
      const exercise = this.exerciseItem as Exercise;

      return exercise.kind === 'audio' || exercise.kind === 'video'
        ? true
        : false;
    }

    return this.exerciseItem['exercise'].kind === 'audio' || this.exerciseItem['exercise'].kind === 'video'
      ? true
      : false;
  }

  getImg(exerciseItem): string {
    return !this.isLiveExercise
      ? exerciseItem.kind
      : exerciseItem.isLocked ? exerciseItem.exercise.kind + '-locked' : exerciseItem.exercise.kind;
  }

}
