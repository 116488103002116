import { schema } from 'normalizr';

export class Reminder {
  id: number;
  program_id: number;
  created_at: string;
  updated_at: string;
  name: string;
  max_daily_count: number;
  enabled: boolean;
  daily_count: number;
  day_sun: boolean;
  day_mon: boolean;
  day_tue: boolean;
  day_wed: boolean;
  day_thu: boolean;
  day_fri: boolean;
  day_sat: boolean;
  first_at: string;
  last_before: string;
  tag: string;
  days: Array<boolean>;
}

export class UserReminder {
  id: number;
  reminder_id: number;
  daily_count: number;
  max_daily_count: number;
  enabled: boolean;
  day_sun: boolean;
  day_mon: boolean;
  day_tue: boolean;
  day_wed: boolean;
  day_thu: boolean;
  day_fri: boolean;
  day_sat: boolean;
  first_at: string;
  last_before: string;
  days: Array<boolean>;
  tag?: string;
}

export const reminderSchema = new schema.Entity('reminders');
export const userReminderSchema = new schema.Entity('userReminders');
