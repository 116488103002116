import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { LiveModule } from '../../store/session/selectors/program.selectors';

@Component({
  selector: 'cl-module-item-web',
  styleUrls: ['module-item.web-component.scss'],
  template: `
    <ion-button
      class="module-item-button-wrapper"
      mode="ios"
      (click)="onClick($event)"
      [disabled]="!expanded"
      [ngClass]="{'completed': isCompleted, 'current': isCurrent, 'missing': isMissing, 'expanded': expanded}">
      <div class="inner-button-container" [class.no-icon]="!isCompleted && !isPartiallyCompleted">
        <div class="inner-button-icon-container">
          <ion-icon
            *ngIf="isCompleted || (isCustomPath && isSelected)"
            clTooltip
            [tooltipText]="'tooltips.program_module.completed' | translate"
            src="/assets/icon/checkin_icon.svg">
          </ion-icon>
          <ion-icon
            *ngIf="isPartiallyCompleted"
            clTooltip
            [tooltipText]="'tooltips.program_module.in_progress' | translate"
            src="/assets/icon/partially_completed.svg">
          </ion-icon>
        </div>
        <div class="inner-button-title-container">
          <p [ngClass]="{'missing': isMissing}" *ngIf="_module && _module.type !== 'extend'">
            {{ moduleName }}
          </p>

          <ng-container *ngIf="!isCustomPath && _module && _module.isFav">
            <ion-icon name="heart"></ion-icon>
          </ng-container>
        </div>
      </div>
    </ion-button>
  `
})
export class ModuleItemWebComponent implements OnChanges {

  @Input()
  set module(value: LiveModule) {
    this._module = value;
    if (this._module) {
      this.checkIfIsPartiallyCompleted();
    }
  }

  @Input() expanded = false;
  @Input() theme = false;
  @Input() isCustomPath = false;
  @Input() selectedModules = [];

  @Output() clicked = new EventEmitter();

  _module: LiveModule;
  isSelected = false;
  isPartiallyCompleted: boolean;

  get moduleName() {
    const moduleNumber = this._module.type === 'program'
      ? this._module.number
      : this._module.positionInWeek;

    return `${moduleNumber}. ${this._module.record.title}`;
  }

  get isMissing() {
    return this._module && this._module.type !== 'extend' ? false : true;
  }

  get isCurrent() {
    if (this.isCustomPath) {
      return false;
    }

    return this._module && this._module.isCurrent ? true : false;
  }

  get isCompleted() {
    if (this.isCustomPath) {
      return false;
    }

    return this._module && this._module.isCompleted ? true : false;
  }

  checkIfIsPartiallyCompleted() {
    if (!this._module) {
      return;
    }

    if (this.isCustomPath) {
      this.isPartiallyCompleted = false;
    }

    const lessonsCompleted = this._module.lessons.filter(l => l.isCompleted);
    const lessonsToComplete = lessonsCompleted.length < this._module.lessons.length;

    this.isPartiallyCompleted = this._module && !this._module.isCompleted && lessonsCompleted.length > 0 && lessonsToComplete ?
      true : false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedModules']) {
      this.isSelected = !!changes['selectedModules']
        .currentValue.find((selectedModule) => this._module && selectedModule.number === this._module.number);
    }
  }

  onClick($event) {
    if (!this.expanded) {
      return false;
    }

    // do not bubble when element is expanded and play only when expanded
    $event.stopPropagation();
    this.clicked.emit(this._module);
  }
}
