import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LoggerService } from '../services/logger.service';

@Injectable({providedIn: 'root'})
export class ClarityErrorHandler implements ErrorHandler {

  logger;

  constructor(
    private injector: Injector
  ) {
    setTimeout(() => {
      this.logger = this.injector.get(LoggerService);
    });
  }

  handleError(error) {
    if (this.logger) {
      this.logger.ionicError(error);
    }
    console.error(error);
  }
}

export const ERROR_CONNECTION_TIMEOUT = 'ERROR_CONNECTION_TIMEOUT';
export const ERROR_INVALID_CREDENTIALS = 'ERROR_INVALID_CREDENTIALS';
export const ERROR_INVALID_CREDENTIALS_TOKEN = 'ERROR_INVALID_CREDENTIALS_TOKEN';
export const ERROR_INVALID_INPUT = 'ERROR_INVALID_INPUT';
export const ERROR_UNAUTHORIZED_ACCESS = 'ERROR_UNAUTHORIZED_ACCESS';
export const ERROR_REQUEST_LIMIT_EXCEEDED = 'ERROR_REQUEST_LIMIT_EXCEEDED';
