export type ISimpleAssessmentItem = ISimpleAssessmentCheckbox | ISimpleAssessmentTextField;

export interface ISimpleAssessmentCheckbox {
  type: 'checkbox';
  label: string;
  value?: boolean | string;
  name?: string;
  isValid?: boolean;
}

export class SimpleAssessmentCheckbox implements ISimpleAssessmentCheckbox {
  public type: 'checkbox';
  public isValid = true;
  public label: string;
  public value = false;
  public name: string;

  constructor(item: ISimpleAssessmentCheckbox, index: number) {
    this.name = 'item__' + index;
    Object.assign(this, item);
  }
}

export interface ISimpleAssessmentTextField {
  type: 'text';
  label: string;
  value?: boolean | string;
  name?: string;
  maxLength?: number;
  placeholder?: string;
}

export class SimpleAssessmentTextField implements ISimpleAssessmentTextField {
  public type: 'text';
  public label: string;
  public value = '';
  public name: string;
  public maxLength?: number;
  public placeholder?: string;

  constructor(item: ISimpleAssessmentTextField, index: number) {
    this.name = 'item__' + index;
    Object.assign(this, item);
  }
}

export interface ISimpleAssessmentConfig {
  disableSubmitWhenFormInvalid?: boolean;
  showFeedbackOnSelection?: boolean;
  dialogOnSubmitLimit?: number;
}

export class SimpleAssessmentConfig implements ISimpleAssessmentConfig {
  public disableSubmitWhenFormInvalid = false;
  public showFeedbackOnSelection = false;
  public dialogOnSubmitLimit = undefined;

  constructor() {}
}

export interface ISimpleAssessmentValidators {
    form?: {
      anySelected?: boolean;
      onlyCorrectSelected?: boolean;
    };
}

export class SimpleAssessmentValidators implements ISimpleAssessmentValidators {
  public form: any = { anySelected: false, onlyCorrectSelected: false };

  constructor() {}
}

export interface ISimpleAssessmentUI {
  heading?: {
    text?: string;
    title?: string;
  };
  actionButton?: {
    label?: string;
  };
  feedbackMessage?: {
    correctSelectionText?: string;
    incorrectSelectionText?: string;
  };
  dialogOnSubmit?: {
    message: string;
    buttonText: string;
    backButtonText: string;
  };
}

export class SimpleAssessmentUI implements ISimpleAssessmentUI {
  public heading = { text: '', title: '' };
  public actionButton = { label: '' };
  public feedbackMessage = { correctSelectionText: '', incorrectSelectionText: '' };
  public dialogOnSubmit = { message: '', buttonText: '', backButtonText: '' };

  constructor() {}
}

export interface ISimpleAssessmentStep {
  items: ISimpleAssessmentItem[];
  config: SimpleAssessmentConfig;
  validators: SimpleAssessmentValidators;
  ui: SimpleAssessmentUI;
}

export class SimpleAssessmentStep {
  public items: ISimpleAssessmentItem[] = [];
  public config: SimpleAssessmentConfig = new SimpleAssessmentConfig();
  public validators: SimpleAssessmentValidators = new SimpleAssessmentValidators();
  public ui: SimpleAssessmentUI = new SimpleAssessmentUI();

  constructor(payload: ISimpleAssessmentStep) {
    const { items, config, validators, ui } = payload;

    Object.assign(this.items, items.map((item, index) => {
      switch (item.type) {
        case 'checkbox':
          return new SimpleAssessmentCheckbox(item, index);
        case 'text':
          return new SimpleAssessmentTextField(item, index);
        default:
          return new SimpleAssessmentTextField(item, index);
      }
    }));

    Object.assign(this.config, config);
    Object.assign(this.validators.form, validators.form);
    Object.assign(this.ui, ui);
  }
}

export interface SimpleAssessmentModel {
  steps: ISimpleAssessmentStep[];
}
