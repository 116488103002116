import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromNormalizedStore from '../../../../app/store';
import * as userActivityActions from '../actions/user-activity.actions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { UserActivitiesProvider } from '../../../../app/providers/user-activities.provider';
import { AddData } from 'ngrx-normalizr';
import { userActivitySchema } from '../../../../app/store/normalized/schemas/user-activity.schema';
import { ToastService } from '../../../../app/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../../../../app/services/loading.service';
import { isUpdatingQuitDate } from '../selectors/user-activities.selectors';
import { AlertsService } from '../../../../app/services/alerts.service';
import { AnalyticsService } from '../../../../app/services/analytics/analytics.service';
import { SessionState } from '../session.reducers';

@Injectable({providedIn: 'root'})
export class UserActivityEffects {


  quitDate$ = createEffect(() => this.actions$.pipe(ofType(userActivityActions.SET_QUIT_DATE),
    tap(() => {
      this.loadingService.useLoadingObservable(
        this.store.select(isUpdatingQuitDate),
        this.translate.get('tracker.saving_quit_date')
      );
    }),
    map((action: userActivityActions.SetQuitDate) => action.payload),
    switchMap((quitDate) => this.userActivityProvider.setQuitDate(quitDate)
      .pipe(
        map((res) => {
          this.store.dispatch(new AddData({
            data: [res],
            schema: userActivitySchema
          }));

          return new userActivityActions.SetQuitDateSuccess();
        }),
        catchError(() => of(new userActivityActions.SetQuitDateFail()))
      ))
  ));

  constructor(
    private actions$: Actions,
    private store: Store<SessionState>,
    private normalizedStore: Store<fromNormalizedStore.State>,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private translate: TranslateService,
    private userActivityProvider: UserActivitiesProvider,
    private alerts: AlertsService,
    private analyticsService: AnalyticsService
  ) {

  }
}
