import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cl-list-single-select',
  styleUrls: ['list-single-select.component.scss'],
  template: `
    <div class="select-button-item" *ngFor="let item of items">
      <cl-radio-list-item
        [checked]="selected === item.id"
        [label]="translateLabel(item.name) | async"
        (click)="onSelected(item.id)">
      </cl-radio-list-item>
    </div>
  `
})
export class ListSingleSelectComponent {

  @Input() items: any[];
  @Input() selected: any;
  @Input() isGlobalUse = false;

  @Output() selectItem = new EventEmitter();

  constructor(
    private translate: TranslateService
  ) {
  }

  onSelected(key) {
    this.selectItem.emit(key);
  }

  translateLabel(label) {
    return this.isGlobalUse ? this.translate.get(label)
      : this.translate.get(`${label}`);
  }

}
