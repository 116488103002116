import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnChanges,
  AfterViewInit,
  OnDestroy
} from '@angular/core';

import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Insomnia } from '@ionic-native/insomnia/ngx';

import { Observable , Subscription } from 'rxjs';

import { FileService } from '../../services/files/file.service';
import { ClarityConfig } from '../../config/clarity.config';
import { Subtitle } from '../../store/normalized/schemas/mediaFile.schema';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { Platform } from '@ionic/angular';
import * as mediaActions from '../../store/persistent/media/media.actions';
import { hasEnglishSubtitles } from '../../store/persistent/media/media.selectors';
import { State } from 'src/app/store';
import { Store } from '@ngrx/store';
import { getCurrentUserProgram } from '../../store/normalized/selectors/user.selectors';
import { withLatestFrom, take } from 'rxjs/operators';

export enum mediaErrorCode {
  MEDIA_ERR_ABORTED = 1,
  MEDIA_ERR_DECODE = 3,
  MEDIA_ERR_ENCRYPTED = 5,
  MEDIA_ERR_NETWORK = 2,
  MEDIA_ERR_SRC_NOT_SUPPORTED = 4
}

@Component({
  selector: 'cl-video-player',
  styleUrls: ['video-player.component.scss'],
  template: `
    <div class="video-wrapper">
      <div class="video-overlay" *ngIf="loadingOverlay"></div>
      <video #video type="video/mp4" controls controlsList="nodownload">
        <track #track *ngIf="subtitleTrack"
               kind="subtitles"
               srclang="{{subtitle.language_code}}"
               src="{{subtitleSrc}}"
               label="{{subtitle.language_code}}"
               [default]="showSubtitles">
      </video>
    </div>
    <ion-row>
      <ion-col>
        <ion-button *ngIf="player && showControls" type="button" (click)="backwardFifteenSec()"
                fill="outline" size="small" color="white">
          <ion-icon name="play-back"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-button *ngIf="player && showControls" type="button" (click)="stopped ? resumePlay() : pausePlay()"
                fill="outline" size="small"
                color="white">
          <ion-icon name="{{playIcon}}"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-button *ngIf="player && showControls" type="button" (click)="enterFullscreen()"
                fill="outline" size="small" color="white">
          <ion-icon name="desktop"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-button *ngIf="player && showControls" type="button" (click)="forwardFifteenSec()"
                fill="outline" size="small" color="white">
          <ion-icon name="play-forward"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  `
})
export class VideoPlayerComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  readonly MINIMUM_PLAYED_SECS = 1;

  @Input() title: string;
  @Input() controls: Observable<string>;
  @Input() showControls: boolean;
  @Input() src: string;
  @Input() autoplay: boolean;
  @Input() autoSkip: boolean;
  @Input() autoPauseAfter: number;
  @Input() subtitle: Subtitle;
  @Input() inlineSrc = false;
  @Input() isInlineOnIos = false;

  @Output() canPlay = new EventEmitter();
  @Output() canPlayThrough = new EventEmitter();
  @Output() playerError = new EventEmitter();
  @Output() playedMinimum = new EventEmitter();
  @Output() completed = new EventEmitter();
  @Output() autoSkipped = new EventEmitter();
  @Output() autoPaused = new EventEmitter();

  @ViewChild('video', { static: true }) playerElement: ElementRef;
  player: HTMLVideoElement;

  @ViewChild('track', { static: true }) trackElement: ElementRef;

  subtitleSrc = '';
  subtitleTrack = false;
  showSubtitles = true;
  textTracks: TextTrackList;
  track;

  playingStarted = false;
  controlsSubscription: Subscription;

  videoPausedTimeout: any;
  autoPauseAfterTimeout: any;

  userLanguage: string;
  userProgramSubscription: Subscription;
  loadingOverlay = true;

  constructor(
    public config: ClarityConfig,
    private file: FileService,
    private platform: Platform,
    private screenOrientation: ScreenOrientation,
    private insomnia: Insomnia,
    protected changeDetector: ChangeDetectorRef,
    private backgroundMode: BackgroundMode,
    private store: Store<State>
  ) {
    this.subtitlesTracksChanged = this.subtitlesTracksChanged.bind(this);
    this.userProgramSubscription = this.store.select(getCurrentUserProgram)
      .pipe(withLatestFrom(this.store.select(hasEnglishSubtitles)), take(1))
      .subscribe(([userProgram, englishSubtitleEnable]) => {
        this.showSubtitles = (userProgram.language_code !== ClarityConfig.DEFAULT_LANGUAGE) ||
          (userProgram.language_code === ClarityConfig.DEFAULT_LANGUAGE && englishSubtitleEnable);
        this.userLanguage = userProgram.language_code;
      });
  }

  get playIcon() {
    return this.stopped ? 'play' : 'pause';
  }

  get stopped() {
    return this.player.paused ? true : false;
  }

  ngOnInit() {
    this.player = this.playerElement.nativeElement;
    this.player.addEventListener('playing', this.handlePlaying.bind(this));
    this.player.addEventListener('pause', this.handlePause.bind(this));
    this.player.addEventListener('ended', this.handleEnded.bind(this));
    this.player.addEventListener('volumechange', this.doNothing);
    this.player.addEventListener('canplaythrough', this.handleCanPlayThrough.bind(this));
    this.player.addEventListener('canplay', this.handleCanPlay.bind(this));
    this.player.addEventListener('error', this.handleError.bind(this));
    this.player.addEventListener('timeupdate', this.doNothing);

    // TODO: Reenable orientation handling when landscape disabled
    this.player.addEventListener('webkitfullscreenchange', this.handleScreenOrientation.bind(this));
    if (this.controls) {
      this.controlsSubscription = this.controls.subscribe((action) => this.parentPlayControl(action));
    }
  }

  ngAfterViewInit() {
    if (this.isInlineOnIos) {
      this.player.setAttribute('playsinline', '');
    }

    this.resetPlayer();

    if (this.src) {
      this.loadVideo(this.src);
    }
    if (this.subtitle) {
      this.subtitleTrack = true;
      this.loadSubtitle(this.subtitle);
      this.changeDetector.detectChanges();
    } else {
      this.subtitleTrack = false;
    }
  }

  loadSubtitle(subtitle: Subtitle) {
    return this.file.getSubtitleLocalUrl(subtitle.file_path)
      .then((source) => {
        if (this.platform.is('ios')) {
          source = source.replace('capacitor://localhost', '');
        }
        this.subtitleSrc = source;
        this.handleSubtitlesTrack();
      })
      .catch((error) => {
        console.log('subtitle not found', subtitle.file_path, error);
      });
  }

  ngOnChanges(changes) {
    // first time ngonchanges is triggered before the view is initialized and the video element doesn't exist
    if (changes.src) {
      const {src} = changes;
      const hasChanges = src.currentValue !== src.previousValue && !src.firstChange;

      if (!hasChanges) {
        return;
      }

      console.log('video - src', src.currentValue);
      if (src.currentValue) {
        this.loadVideo(src.currentValue);
      }
    }

    if (changes.subtitle) {
      const {subtitle} = changes;
      const hasChanges = subtitle.currentValue !== subtitle.previousValue && !subtitle.firstChange;

      if (!hasChanges) {
        return;
      }

      // attempt to remove the src attribute from track first - iOS 10 will display multiple subtitles otherhwise
      console.log('video - subtitle', subtitle.currentValue);
      if (subtitle.currentValue) {
        this.subtitleTrack = true;
        this.loadSubtitle(subtitle.currentValue);
      } else {
        this.subtitleTrack = false;
      }
    }
  }

  handleSubtitlesTrack() {
    if (this.userLanguage !== ClarityConfig.DEFAULT_LANGUAGE) {
      return;
    }

    this.track = this.trackElement ? this.trackElement.nativeElement : null;
    if (this.track) {
      this.track.mode = this.showSubtitles ? 'showing' : 'disabled';
    }

    this.textTracks = this.player.textTracks;
    if (this.textTracks) {
      this.textTracks.removeEventListener('change', this.subtitlesTracksChanged);
      this.textTracks.addEventListener('change', this.subtitlesTracksChanged);
    }
  }

  subtitlesTracksChanged(event) {
    if (this.userLanguage !== ClarityConfig.DEFAULT_LANGUAGE) {
      return;
    }
    if (event.target[0] && event.target[0].mode === 'disabled') {
      this._disableSubtitles();
    } else {
      this._enableSubtitles();
    }
  }

  private _enableSubtitles() {
    this.store.dispatch(new mediaActions.EnableEnglishSubtitles());
    this.showSubtitles = true;
  }

  private _disableSubtitles() {
    this.store.dispatch(new mediaActions.DisableEnglishSubtitles());
    this.showSubtitles = false;
  }

  ngOnDestroy() {
    this.disableBackgroundMode();
    this.resetPlayer();
    this.cancelVideoPausedTimeout();
    this.player && this.player.remove();
    this.controlsSubscription && this.controlsSubscription.unsubscribe();
    this.userProgramSubscription && this.userProgramSubscription.unsubscribe();
  }

  parentPlayControl(action) {
    switch (action) {
      case 'play':
        return this.resumePlay();

      case 'pause':
        return this.pausePlay();

      case 'reset':
        return this.resetPlayer();

      default:
        return false;
    }
  }

  pausePlay() {
    this.disableBackgroundMode();
    this.player.pause();
  }

  resumePlay() {
    const ret = this.player.play();

    // on some older Android devices, this will not return a promise
    if (ret && ret.catch) {
      ret.catch((error) => {
        // sometimes the error will not be available so this will throw an exception
        // console.log('Player error occurred', error, this.player.error.code ? this.player.error.code : 'Unknown error code');

        this.handleError(error);
      });
    }

    return ret;
  }

  handleError(event) {
    this.disableBackgroundMode();

    // discard errors generated by empty src attribute
    if (!this.player.error ||
      ((!this.player.src || this.player.src === '') && this.player.error && this.player.error.code === 4)) {
      return false;
    }

    this.playerError.emit(this._handleMediaError());
  }

  getCurrentTime() {
    return this.player.currentTime;
  }

  setCurrentTime(newTime) {
    this.player.currentTime = newTime;
  }

  forwardFifteenSec() {
    this.pausePlay();
    this.setCurrentTime(this.getCurrentTime() + 15);
    this.resumePlay();
  }

  backwardFifteenSec() {
    this.pausePlay();
    this.setCurrentTime(Math.max(this.getCurrentTime() - 15, 0));
    this.resumePlay();
  }

  handlePlaying() {
    console.log('video - playing');
    this.enableBackgroundMode();
    this.changeDetector.detectChanges();

    this.cancelVideoPausedTimeout();
    this.cancelAutoPauseTimeout();

    if (!this.playingStarted) {
      if (this.playedMinimum.observers.length > 0) {
        setTimeout(() => {
          this.playedMinimum.emit(this.MINIMUM_PLAYED_SECS);
        }, this.MINIMUM_PLAYED_SECS * 1000);
      }

      if (this.autoPauseAfter && this.autoPaused.observers.length > 0) {
        this.autoPauseAfterTimeout = setTimeout(() => {
          this.pausePlay();
          this.exitFullscreen();

          this.autoPaused.emit(this.autoPauseAfter);
        }, this.autoPauseAfter * 1000);
      }
    }

    this.playingStarted = true;
  }

  handlePause() {
    console.log('video - pause');
    // cancel any autopause if the user pauses manually
    if (this.autoPauseAfterTimeout) {
      this.cancelAutoPauseTimeout();
    }

    if (this.config.isDevice && this.backgroundMode.isEnabled() && this.backgroundMode.isActive()) {
      // the user paused and the screen is locked lets keep the background-mode alive for the ¿current video's length?
      const time = this.player.duration ? this.player.duration * 1000 : 60000;
      if (this.videoPausedTimeout) {
        this.cancelVideoPausedTimeout();
      }

      this.videoPausedTimeout = setTimeout(() => {
        console.log('video - paused - timeout');
        this.disableBackgroundMode();
      }, time);
    }

    this.changeDetector.detectChanges();
  }

  handleEnded() {
    console.log('video - ended');
    this.changeDetector.detectChanges();
    this.completed.emit();

    setTimeout(() => this.disableBackgroundMode(), 30000);
  }

  handleCanPlayThrough() {
    console.log('video - canplaythrough');
    this.loadingOverlay = false;

    if (this.autoplay) {
      this.canPlayThrough.emit(true);
    }

    this.changeDetector.detectChanges();
  }

  handleCanPlay() {
    console.log('video - canplay', this.autoplay, this.autoSkip);

    if (this.autoplay && !this.autoSkip) {
      this.canPlay.emit(true);
      this.resumePlay();
    }

    if (this.autoSkip) {
      this.autoSkipped.emit();
      this.playedMinimum.emit(0);
    }
  }

  handleScreenOrientation() {
    if (!this.screenOrientation) {
      return;
    }

    const fullscreenAccessors = [
      'fullScreen', 'mozFullScreen', 'webkitIsFullScreen'
    ];

    const isFullScreen = fullscreenAccessors
      .map((accessor) => window.document[accessor])
      .reduce((accumulator, currentValue) => accumulator || currentValue, false);

    if (this.config.isDevice) {
      if (isFullScreen) {
        this.screenOrientation.unlock();
      }
      else {
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
      }
    }
  }

  enterFullscreen() {
    if (this.player['webkitRequestFullScreen']) {
      this.player['webkitRequestFullScreen']();
    }
    else if (this.player['webkitEnterFullScreen']) {
      this.player['webkitEnterFullScreen']();
    }
    else if (this.player.requestFullscreen) {
      this.player.requestFullscreen();
    }
  }

  loadVideo(src) {
    this.loadingOverlay = true;
    this.changeDetector.detectChanges();

    // reset buffer before loading a video
    this.resetPlayer();

    let prefix = '';

    // sanitize urls missing protocol
    if (src.indexOf('//') === 0) {
      prefix = 'https:';
    }

    if (this.inlineSrc) {
      this.setSrc(src);
    } else {
      this.file.getFileLocalUrl(src)
        .then((localSrc) => {
          console.log('found local file', localSrc);
          this.setSrc(localSrc);
        })
        .catch((error) => {
          console.log('playing remote file', src);
          this.setSrc(prefix + src);
        });
    }
  }

  setSrc(src) {
    this.playingStarted = false;
    this.player.src = src;
    this.player.load();
  }

  resetPlayer() {
    this.player.pause();

    // ios10 will stack subtitles unless we manually remove the previous one
    if (this.trackElement && this.trackElement.nativeElement) {
      this.trackElement.nativeElement.removeAttribute('src');
    }

    // this approach will trigger an error
    // this.player.src = '';

    // this is supposed to achieve the same thing
    this.player.removeAttribute('src');
    this.player.load();
  }

  exitFullscreen() {
    if (document['cancelFullScreen']) {
      document['cancelFullScreen']();
    }
    else if (document['exitFullscreen'] && document['fullscreenElement']) {
      document.exitFullscreen();
    }
    else if (document['webkitCancelFullScreen']) {
      document['webkitCancelFullScreen']();
    }
    else if (document['webkitExitFullscreen']) {
      document['webkitExitFullscreen']();
    }
    else if (document.querySelector('video')) {
      document.querySelector('video')['webkitExitFullscreen']();
    }
  }

  doNothing = () => undefined;

  private enableBackgroundMode() {
    if (this.config.isDevice) {
      this.insomnia.keepAwake();

      this.backgroundMode.enable();
    }
  }

  private disableBackgroundMode() {
    if (this.config.isDevice) {
      this.insomnia.allowSleepAgain();

      if (this.backgroundMode.isEnabled()) {
        this.backgroundMode.disable();
      }
    }
  }

  private cancelAutoPauseTimeout() {
    clearTimeout(this.autoPauseAfterTimeout);
    this.autoPauseAfterTimeout = null;
  }

  private cancelVideoPausedTimeout() {
    clearTimeout(this.videoPausedTimeout);
    this.videoPausedTimeout = null;
  }

  private _handleMediaError() {
    let errorResult: any = this.player.error;

    try {
      const msg = errorResult instanceof MediaError ?
        `MediaError - Could not play video file - ErrorCode ${errorResult.code} - Msg: ${mediaErrorCode[errorResult.code]}`
        : `Could not play video file - ErrorCode: ${errorResult.code}`;
      errorResult = new Error(msg);
    }
    catch (error) {
      errorResult = new Error('Cannot process player error!');
    }

    return errorResult;
  }
}
