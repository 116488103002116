import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { getCurrentUser } from '../../../store/normalized/selectors/user.selectors';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { State } from '../../../store/state.reducer';
import { NrtService, ValidateShippingDataResponse } from '../../../services/nrt.service';
import { ToastService } from 'src/app/services/toast.service';
import { US_PHONE_NUMBER_REGEX } from 'src/app/utils/us-phone-number-regex';

@Component({
  selector: 'cl-nrt-shipping-address',
  styleUrls: [
    'nrt-shipping-address.component.scss',
    'nrt.component.scss'
  ],
  template: `
    <ion-grid class="component-content-grid">
      <ion-row>
        <ion-col>
          <ion-row class="subtitle-holder lateral-padding">
            <ion-col>
              <h4>{{'nrt.shipping.subtitle' | translate}}</h4>
            </ion-col>
          </ion-row>
          <ion-row class="lateral-padding">
            <ion-col class="content-wrapper">
              <form [formGroup]="shippingForm" autocomplete="off">
                <cl-input
                  [controlForm]="shippingForm.get('first_name')"
                  type="text"
                  name="first_name"
                  autocapitalize="on"
                  [errorMessages]="validations.first_name.errors"
                  placeHolder="{{'auth.first_name' | translate}}">
                </cl-input>

                <cl-input
                  [controlForm]="shippingForm.get('last_name')"
                  type="text"
                  name="last_name"
                  autocapitalize="on"
                  [errorMessages]="validations.last_name.errors"
                  placeHolder="{{'auth.last_name' | translate}}">
                </cl-input>

                <cl-input
                  [controlForm]="shippingForm.get('address1')"
                  type="text"
                  name="address1"
                  autocapitalize="on"
                  [errorMessages]="validations.address1.errors"
                  placeHolder="{{'nrt.shipping.address1' | translate}}">
                </cl-input>

                <cl-input
                  [controlForm]="shippingForm.get('address2')"
                  type="text"
                  name="address2"
                  autocapitalize="on"
                  placeHolder="{{'nrt.shipping.address2' | translate}}">
                </cl-input>

                <cl-input
                  [controlForm]="shippingForm.get('city')"
                  type="text"
                  name="city"
                  autocapitalize="on"
                  [errorMessages]="validations.city.errors"
                  placeHolder="{{'nrt.shipping.city' | translate}}">
                </cl-input>

                <cl-range-select
                  [options]="statesOptions"
                  [controlForm]="shippingForm.get('state')"
                  labelName="{{ 'nrt.shipping.state' | translate }}"
                  name="state">
                </cl-range-select>

                <cl-input
                  [controlForm]="shippingForm.get('zipCode')"
                  type="text"
                  name="zipCode"
                  [errorMessages]="validations.zipCode.errors"
                  placeHolder="{{'nrt.shipping.zipCode' | translate}}">
                </cl-input>

                <cl-input
                  [controlForm]="shippingForm.get('email')"
                  type="text"
                  name="email"
                  [errorMessages]="validations.email.errors"
                  placeHolder="{{'auth.email' | translate}}">
                </cl-input>

                <cl-input
                  [controlForm]="shippingForm.get('phone')"
                  type="text"
                  name="phone"
                  [errorMessages]="validations.phone.errors"
                  placeHolder="{{'nrt.shipping.phone' | translate}}">
                </cl-input>
              </form>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>
  `
})
export class NrtShippingAddressComponent implements OnDestroy {

  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();
  @Output() validityChange = new EventEmitter();

  userData: any;
  serviceData: any;
  shippingForm: FormGroup;
  formChangeSubscription: Subscription;

  public readonly validations = {
    first_name: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.nrt.first_name_required'
      }
    },
    last_name: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.nrt.last_name_required'
      }
    },
    address1: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.nrt.address_required'
      }
    },
    address2: {},
    city: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.nrt.city_required'
      }
    },
    state: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.nrt.state_required'
      }
    },
    zipCode: {
      validators: Validators.compose([
        Validators.required,
        Validators.pattern(/^\d{5}(?:[-\s]\d{4})?$/)
      ]),
      errors: {
        required: 'errors.nrt.zip_required',
        pattern: 'errors.nrt.zip_invalid'
      }
    },
    email: {
      validators: Validators.compose([
        Validators.required,
        Validators.email
      ]),
      errors: {
        required: 'errors.user.email_required',
        pattern: 'errors.user.email_invalid'
      }
    },
    phone: {
      validators: Validators.compose([
        Validators.required,
        Validators.pattern(US_PHONE_NUMBER_REGEX)
      ]),
      errors: {
        required: 'errors.nrt.phone_required',
        pattern: 'errors.nrt.phone_invalid'
      }
    }
  };

  public readonly statesOptions = [
    {label: 'Alabama', value: 'AL'}, {label: 'Alaska', value: 'AK'}, {label: 'American Samoa', value: 'AS'},
    {label: 'Arizona', value: 'AZ'}, {label: 'Arkansas', value: 'AR'}, {label: 'California', value: 'CA'},
    {label: 'Colorado', value: 'CO'}, {label: 'Connecticut', value: 'CT'}, {label: 'Delaware', value: 'DE'},
    {label: 'District Of Columbia', value: 'DC'}, {label: 'Federated States Of Micronesia', value: 'FM'},
    {label: 'Florida', value: 'FL'}, {label: 'Georgia', value: 'GA'}, {label: 'Guam', value: 'GU'},
    {label: 'Hawaii', value: 'HI'}, {label: 'Idaho', value: 'ID'}, {label: 'Illinois', value: 'IL'},
    {label: 'Indiana', value: 'IN'}, {label: 'Iowa', value: 'IA'}, {label: 'Kansas', value: 'KS'}, {label: 'Kentucky', value: 'KY'},
    {label: 'Louisiana', value: 'LA'}, {label: 'Maine', value: 'ME'}, {label: 'Marshall Islands', value: 'MH'},
    {label: 'Maryland', value: 'MD'}, {label: 'Massachusetts', value: 'MA'}, {label: 'Michigan', value: 'MI'},
    {label: 'Minnesota', value: 'MN'}, {label: 'Mississippi', value: 'MS'}, {label: 'Missouri', value: 'MO'},
    {label: 'Montana', value: 'MT'}, {label: 'Nebraska', value: 'NE'}, {label: 'Nevada', value: 'NV'},
    {label: 'New Hampshire', value: 'NH'}, {label: 'New Jersey', value: 'NJ'}, {label: 'New Mexico', value: 'NM'},
    {label: 'New York', value: 'NY'}, {label: 'North Carolina', value: 'NC'}, {label: 'North Dakota', value: 'ND'},
    {label: 'Northern Mariana Islands', value: 'MP'}, {label: 'Ohio', value: 'OH'},
    {label: 'Oklahoma', value: 'OK'}, {label: 'Oregon', value: 'OR'},
    {label: 'Palau', value: 'PW'}, {label: 'Pennsylvania', value: 'PA'},
    {label: 'Puerto Rico', value: 'PR'}, {label: 'Rhode Island', value: 'RI'}, {label: 'South Carolina', value: 'SC'},
    {label: 'South Dakota', value: 'SD'}, {label: 'Tennessee', value: 'TN'}, {label: 'Texas', value: 'TX'}, {label: 'Utah', value: 'UT'},
    {label: 'Vermont', value: 'VT'}, {label: 'Virgin Islands', value: 'VI'}, {label: 'Virginia', value: 'VA'},
    {label: 'Washington', value: 'WA'}, {label: 'West Virginia', value: 'WV'},
    {label: 'Wisconsin', value: 'WI'}, {label: 'Wyoming', value: 'WY'}];

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<State>,
    private cdr: ChangeDetectorRef,
    private nrtService: NrtService,
    private toastService: ToastService
  ) {

    this.serviceData = nrtService.shippingData;

    this.store.select(getCurrentUser)
      .pipe(first())
      .subscribe(user => {
        this.userData = {
          ...user
        };
        const formGroup = this.generateFormGroup();

        this.shippingForm = this.formBuilder.group(formGroup);

        this.unsubSub();
      });
  }

  generateFormGroup() {
    const formGroupData = {
      first_name: {},
      last_name: {},
      address1: {},
      address2: {},
      city: {},
      state: {defaultValue: 'AL'},
      zipCode: {},
      phone: {},
      email: {}
    };

    const formGroup = {};

    Object.keys(formGroupData)
      .forEach((key) => {
        const data = formGroupData[key];
        const formValue = this.serviceData[key] || this.userData[key] || data.defaultValue;
        const formValidator = data.validators || this.validations[key].validators;
        const formControl = new FormControl(formValue, formValidator);

        if (formControl.errors && formControl.errors.pattern) {
          formControl.markAsTouched();
        }
        formGroup[key] = formControl;
      });

    return formGroup;
  }

  unsubSub() {
    if (this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
    }
    this.formChangeSubscription = this.shippingForm.valueChanges.subscribe(() => {
      this.nrtService.shippingData = this.shippingForm.value;

      const wasValid = this.nrtService.shippingValid;
      this.nrtService.shippingValid = this.shippingForm.valid;
      if (wasValid !== this.nrtService.shippingValid) {
        this.validityChange.emit();
      }
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    if (this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
    }
  }

  nextStep() {
    this.nrtService.validateShippingData()
      .pipe(first())
      .subscribe((validation: ValidateShippingDataResponse) => {
        if (validation.isValid) {
          this.orderIfNeeded();
        } else {
          this.toastService.translateError('errors.nrt.address_invalid');
        }
      });
  }

  private orderIfNeeded() {

  }
}
