import { Component, EventEmitter, Input, Output, HostBinding } from '@angular/core';
import { OnboardingCloseEvent } from 'src/app/constants/onboarding-types.const';
import { OnboardingCustomSettings } from 'src/app/store/session/actions/navigation.actions';
import { ClarityConfig } from '../../../config/clarity.config';
import { MediaFile } from '../../../store/normalized/schemas/mediaFile.schema';

@Component({
  selector: 'cl-full-screen',
  styleUrls: ['full-screen.component.scss'],
  template: `
    <ion-grid class="onboarding-content" [ngClass]="{'with-video': video}">

      <ion-row *ngIf="!settings.hideCloseIcon" class="first-row no-padding">
        <cl-close-button
          color="primary"
          icon="close"
          (closeClick)="onClose()"
        ></cl-close-button>
      </ion-row>

      <ion-row class="second-row margin-top-minus-20">
        <p class="page-title blue-heading text-align-center full-width">{{settings.header | translate}}</p>
      </ion-row>

      <ion-row class="third-row vertical-align-center">
        <p class="page-details blue-heading text-align-center lateral-padding"
           [innerHTML]="settings.body | translate"></p>

        <div class="video" *ngIf="video">
          <cl-video-player-brightcove
            *ngIf="useBrightcovePlayer"
            [brightcove_key]="video?.brightcove_key"
            [brightcove_poster_url]="video?.brightcove_poster_url">
          </cl-video-player-brightcove>

          <cl-video-player-brightcove-web
            *ngIf="useBrightcovePlayerWeb"
            [brightcove_key]="video?.brightcove_key">
          </cl-video-player-brightcove-web>


          <cl-video-player-jwplayer
            *ngIf="useJWPlayer"
            [showControls]="false"
            [autoplay]="false"
            [jw_key]="video?.jw_key"
            (playedMinimum)="onPlayedMinimum($event)">
          </cl-video-player-jwplayer>

          <cl-video-player
            *ngIf="useVideoPlayer"
            [showControls]="false"
            [autoplay]="false"
            [src]="video?.data"
            [subtitle]="video?.subtitle"
            (playedMinimum)="onPlayedMinimum($event)">
          </cl-video-player>
        </div>
      </ion-row>

      <ion-row class="fourth-row"></ion-row>

      <ion-row class="fifth-row">
        <p class="footer-link white-heading" *ngIf="settings.link && !settings.button"
           (click)="onLinkClick()">{{settings.link | translate}}</p>

        <p class="footer-link footer-double-link white-heading" *ngIf="settings.link && settings.button"
           (click)="onLinkClick()">{{settings.link | translate}}</p>

        <div class="button-wrapper" *ngIf="settings.button">
          <div class="center-button">
            <cl-action-button
              label="{{settings.button | translate}}"
              [canClick]="true"
              (action)="onButtonClick()">
            </cl-action-button>
          </div>
        </div>
      </ion-row>

    </ion-grid>
  `
})
export class FullScreenComponent {

  @HostBinding('class.desktop') isDesktop = this.config.isWebApp;

  @Input() settings: OnboardingCustomSettings;
  @Input() video: MediaFile;

  @Output() closeClick = new EventEmitter<OnboardingCloseEvent>();
  @Output() playedVideo = new EventEmitter();

  get useBrightcovePlayerWeb() {
    return this.config.isBrightcoveWebEnabled() && this.video && this.video.brightcove_key;
  }

  get useBrightcovePlayer() {
    return this.config.isBrightcoveEnabled() && this.video && this.video.brightcove_key;
  }

  get useJWPlayer() {
    return !this.useBrightcovePlayerWeb && !this.useBrightcovePlayer && this.config.jwplayerEnabled() && this.video && this.video.jw_key;
  }

  get useVideoPlayer() {
    return !this.useBrightcovePlayerWeb && !this.useBrightcovePlayer && !this.useJWPlayer;
  }

  constructor(public config: ClarityConfig) { }

  onPlayedMinimum(seconds) {
    if (seconds > 0) {
      this.playedVideo.emit();
    }
  }

  onLinkClick() {
    this.onClose({ isComplete: !Boolean(this.settings.dontCompleteOnLinkClick) });
  }

  onButtonClick() {
    this.onClose({ isComplete: !Boolean(this.settings.dontCompleteOnButtonClick) });

    if (this.settings.actAsConfirm && this.settings.callbackOnButton) {
      this.settings.callbackOnButton();
    }
  }

  onClose(onboardingCloseEvent?: OnboardingCloseEvent) {
    this.closeClick.emit(onboardingCloseEvent);
  }
}
