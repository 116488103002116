import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  AfterViewInit
} from '@angular/core';
import { LiveLesson } from '../../../store/session/selectors/program.selectors';
import { Subject } from 'rxjs';
import { ClarityConfig } from '../../../config/clarity.config';
import { AlertsService } from '../../../services/alerts.service';
import { Store } from '@ngrx/store';
import { OnboardingService } from '../../../services/onboarding.service';
import { Exercise } from '../../../store/normalized/schemas/exercise.schema';
import { MediaFile } from '../../../store/normalized/schemas/mediaFile.schema';
import { getDefaultMediaForExercise } from '../../../store/persistent/media/media.selectors';
import { SetDefaultMediaFileForExercise } from '../../../store/persistent/media/media.actions';
import { take } from 'rxjs/operators';
import { State } from '../../../store/state.reducer';
import { ExcercisePopUpService } from 'src/app/services/excercise-popup.service';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'cl-exercise-player',
  styleUrls: ['../play.scss', 'exercise-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div>
      <div class="title-holder">
        <ion-col>
          <h1>{{isExercise ? lesson.title : lesson.record.exercise.title}}</h1>
        </ion-col>
      </div>

      <div class="player-holder lateral-padding ion-padding">
        <div class="ion-text-center" *ngIf="canLoad; else loadingTemplate">

          <ng-container *ngIf="exercise.kind === 'video'">
            <cl-video-player-brightcove
              *ngIf="useBrightcovePlayer"
              [brightcove_key]="mediaFile?.brightcove_key"
              [brightcove_poster_url]="mediaFile?.brightcove_poster_url"
              [controls]="playerController"
              [autoPauseAfter]="autoPauseAfter"
              [autoSkip]="autoSkipVideo"
              (autoSkipped)="onAutoSkipped()"
              (autoPaused)="onAutoPaused()"
              (playedMinimum)="onWatchedMinimum()"
              [autoplay]="autoplay"
              (canPlay)="onPlayerReady($event)"
              (playerError)="onPlayerError($event)"
              (completed)="onNextLesson()"
              (closePlayer)="closePlayer()">
            </cl-video-player-brightcove>

            <cl-video-player-brightcove-web
              *ngIf="useBrightcovePlayerWeb"
              [brightcove_key]="mediaFile?.brightcove_key"
              [controls]="playerController"
              [autoPauseAfter]="autoPauseAfter"
              [autoSkip]="autoSkipVideo"
              (autoSkipped)="onAutoSkipped()"
              (autoPaused)="onAutoPaused()"
              (playedMinimum)="onWatchedMinimum()"
              [autoplay]="autoplay"
              (canPlay)="onPlayerReady($event)"
              (canPlayThrough)="onCanPlayThrough($event)"
              (playerError)="onPlayerError($event)"
              (completed)="onNextLesson()">
            </cl-video-player-brightcove-web>

            <cl-video-player-jwplayer
              *ngIf="useJWPlayer"
              [jw_key]="mediaFile?.jw_key"
              [controls]="playerController"
              [autoPauseAfter]="autoPauseAfter"
              [autoSkip]="autoSkipVideo"
              (autoSkipped)="onAutoSkipped()"
              (autoPaused)="onAutoPaused()"
              (playedMinimum)="onWatchedMinimum()"
              [autoplay]="autoplay"
              (canPlay)="onPlayerReady($event)"
              (canPlayThrough)="onCanPlayThrough($event)"
              (playerError)="onPlayerError($event)"
              (completed)="onNextLesson()">
            </cl-video-player-jwplayer>

            <cl-video-player
              *ngIf="useVideoPlayer"
              [src]="mediaFile?.data"
              [subtitle]="mediaFile?.subtitle"
              [controls]="playerController"
              [autoPauseAfter]="autoPauseAfter"
              [autoSkip]="autoSkipVideo"
              (autoSkipped)="onAutoSkipped()"
              (autoPaused)="onAutoPaused()"
              (playedMinimum)="onWatchedMinimum()"
              [autoplay]="autoplay"
              (canPlay)="onPlayerReady($event)"
              (canPlayThrough)="onCanPlayThrough($event)"
              (playerError)="onPlayerError($event)"
              (completed)="onNextLesson()">
            </cl-video-player>
          </ng-container>

          <ng-container *ngIf="exercise.kind === 'audio'">
            <cl-audio-player-brightcove
              *ngIf="useBrightcovePlayerWeb || useBrightcovePlayer"
              [brightcove_key]="mediaFile?.brightcove_key"
              [controls]="playerController"
              [autoplay]="autoplay"
              (playedMinimum)="onWatchedMinimum()"
              (canPlay)="onPlayerReady($event)"
              (canPlayThrough)="onCanPlayThrough($event)"
              (playerError)="onPlayerError($event)"
              (completed)="onNextLesson()">
            </cl-audio-player-brightcove>

            <cl-audio-player-jwplayer
              *ngIf="useJWPlayer"
              [jw_key]="mediaFile?.jw_key"
              [controls]="playerController"
              [autoplay]="autoplay"
              (playedMinimum)="onWatchedMinimum()"
              (canPlay)="onPlayerReady($event)"
              (canPlayThrough)="onCanPlayThrough($event)"
              (playerError)="onPlayerError($event)"
              (completed)="onNextLesson()">
            </cl-audio-player-jwplayer>

            <cl-audio-player
              *ngIf="useVideoPlayer"
              [src]="mediaFile?.data"
              [controls]="playerController"
              [autoplay]="autoplay"
              (playedMinimum)="onWatchedMinimum()"
              (canPlay)="onPlayerReady($event)"
              (canPlayThrough)="onCanPlayThrough($event)"
              (playerError)="onPlayerError($event)"
              (completed)="onNextLesson()">
            </cl-audio-player>
          </ng-container>
        </div>
      </div>

      <div class="alternative-holder" *ngIf="hasAlternatives">
        <div class="choose-duration">
          {{ 'exercise_player.choose_duration' | translate }}
        </div>

        <ion-row class="duration-buttons-holder">
          <ion-col *ngFor="let alternative of sortedAlternativesWithOriginal" size="2">
            <ion-button
              type="button"
              fill="outline"
              size="small"
              class="time-circle"
              [class.selected]="mediaFile.data_fingerprint
                ? mediaFile.data_fingerprint === alternative.data_fingerprint
                : mediaFile?.jw_key === alternative.jw_key"
              (click)="onClickAlternative(alternative)">
              {{alternativeMinutes(alternative)}}
            </ion-button>
          </ion-col>
        </ion-row>
      </div>

      <ion-row class="description lateral-padding" *ngIf="exercise.caption">
        <ion-col>
          <p [innerHTML]="exercise.caption"></p>
        </ion-col>
      </ion-row>

      <ion-row class="action-buttons-holder">
        <ion-col>
          <cl-next-lesson-button
            [lesson]="lesson"
            [playExercise]="playExercise"
            [isExercise]="isExercise"
            [enabled]="nextEnabled || playExercise"
            (next)="onNextLesson()">
          </cl-next-lesson-button>
        </ion-col>
      </ion-row>
    </div>

    <ng-template #loadingTemplate>
      <ion-col class="loading-overlay" [class.audio]="exercise.kind === 'audio'"></ion-col>
    </ng-template>
  `
})
export class ExercisePlayerComponent implements OnChanges, OnInit, AfterViewInit {
  @Input() lesson: LiveLesson & Exercise;
  @Input() canLoad: boolean;
  @Input() playExercise: boolean;
  @Input() isExercise: boolean;

  @Output() next = new EventEmitter();
  @Output() init = new EventEmitter();
  @Output() onClose = new EventEmitter();

  playerController = new Subject<string>();

  autoplay = true;
  nextEnabled = false;
  autoSkipVideo = false;
  autoPauseAfter: number = null;

  mediaFileAlternativeToPlay: string;

  get useBrightcovePlayerWeb() {
    return this.config.isBrightcoveWebEnabled() && this.mediaFile && this.mediaFile.brightcove_key;
  }

  get useBrightcovePlayer() {
    return this.config.isBrightcoveEnabled() && this.mediaFile && this.mediaFile.brightcove_key;
  }

  get useJWPlayer() {
    return !this.useBrightcovePlayerWeb && !this.useBrightcovePlayer && this.config.jwplayerEnabled() && this.mediaFile && this.mediaFile.jw_key;
  }

  get useVideoPlayer() {
    return !this.useBrightcovePlayerWeb && !this.useBrightcovePlayer && !this.useJWPlayer;
  }

  constructor(
    public config: ClarityConfig,
    private events: EventsService,
    private alerts: AlertsService,
    protected changeDetector: ChangeDetectorRef,
    public store: Store<State>,
    private onboardingService: OnboardingService,
    private excercisePopUpService: ExcercisePopUpService
  ) {
    // stop player on logout
    this.events.subscribe(this.config.events.logout, () => {
      this.playerController.next('pause');
    });
  }

  get hasAlternatives() {
    if (this.alternatives && this.alternatives.length > 0) {
      return true;
    }

    return false;
  }

  get alternatives() {
    return this.exercise.alternative_media_files;
  }

  get sortedAlternativesWithOriginal() {
    const baseMediaFile = this.baseMediaFile;

    return [...this.alternatives, baseMediaFile].sort((a, b) => a.length - b.length);
  }

  get baseMediaFile() {
    return this.exercise.media_file;
  }

  get exercise() {
    return this.isExercise ? this.lesson : this.lesson.record.exercise;
  }

  get mediaFile() {
    if (this.mediaFileAlternativeToPlay) {
      return this.alternatives.find(
        (mediaFile: MediaFile) => {
          // TO DO refactor this find fires a lot!!!
          let file = mediaFile.data_fingerprint === this.mediaFileAlternativeToPlay;

          if (mediaFile.jw_key) {
            file = mediaFile.jw_key === this.mediaFileAlternativeToPlay;
          }

          if (this.config.isBrightcoveWebEnabled() && mediaFile.brightcove_key) {
            file = mediaFile.brightcove_key === this.mediaFileAlternativeToPlay;
          }

          return file;
        }
      ) || this.baseMediaFile;
    }

    return this.baseMediaFile;
  }

  onWatchedMinimum() {
    console.log('lesson-player -> watched-minimum');
    this.nextEnabled = true;
    this.changeDetector.detectChanges();
  }

  onAutoPaused() {
    console.log('lesson-player -> auto-paused');

    this.onboardingService.setupSkipLessonAction(this.lesson)
      .then((action) => this.store.dispatch(action));
  }

  onAutoSkipped() {
    console.log('lesson-player -> auto-skipped');

    this.onboardingService.setupSkipLessonAction(this.lesson, true)
      .then((action) => this.store.dispatch(action));
  }

  onPlayerError(error) {
    console.log('lesson-player -> error: ', error);

    this.alerts.playerError(error);
  }

  onCanPlayThrough(event) {
    console.log('lesson-player -> canPlayThrough: ', event);
  }

  onPlayerReady(event) {
    console.log('lesson-player -> canPlay: ', event);
  }

  onClickAlternative(alternative: MediaFile) {
    if (this.config.isBrightcoveWebEnabled()) {
      return this.selectAlternative(alternative.brightcove_key);
    }

    this.selectAlternative(alternative.data_fingerprint || alternative.jw_key);
  }

  ngOnInit() {
    this.store.select(getDefaultMediaForExercise)
      .pipe(take(1))
      .subscribe((defaultMediaFilesForExercises) => {
        this.mediaFileAlternativeToPlay = defaultMediaFilesForExercises[this.exercise.id];

        this.changeDetector.detectChanges();

        this.store.dispatch(
          new SetDefaultMediaFileForExercise(this.exercise.id, this.mediaFileAlternativeToPlay)
        );
      });
  }

  ngAfterViewInit() {
    this.init.emit();

    if (this.excercisePopUpService.hasPopUpBefore(this.lesson)) {
      this.autoplay = false;

      this.excercisePopUpService
        .showPopUp(this.lesson)
        .then(() => {
          this.playerController.next('play');
          this.autoplay = true;
        });
    }
  }

  ngOnChanges(): void {
    this.autoSkipVideo = false;
    this.autoPauseAfter = null;

    this.enableAutoSkip();
    this.enableAutoPause();
  }

  onNextLesson() {
    this.playerController.next('pause');

    this.nextEnabled = false;

    this.autoSkipVideo = false;
    this.autoPauseAfter = null;

    this.changeDetector.detectChanges();

    if (this.excercisePopUpService.hasPopUpAfter(this.lesson)) {
      this.excercisePopUpService
        .showPopUp(this.lesson)
        .then(() => this.next.emit(this.lesson));
    } else {
      this.next.emit(this.lesson);
    }
  }

  enableAutoPause() {
    if (this.autoPauseAfter) {
      return false;
    }

    if (!this.lesson || !this.lesson.record.exercise.tags || this.lesson.record.exercise.tags.length === 0) {
      return false;
    }

    const autoPauseTag = this.lesson.record.exercise.tags.find((tag) => /auto_pause_/.test(tag.name));

    if (!autoPauseTag) {
      return false;
    }

    const parts = autoPauseTag.name.match(/auto_pause_(\d*)/);

    if (parts[1]) {
      this.autoPauseAfter = Number(parts[1]);
      this.changeDetector.detectChanges();
    }
  }

  enableAutoSkip() {
    if (this.autoSkipVideo) {
      return false;
    }

    if (!this.lesson || !this.lesson.record.exercise.tags || this.lesson.record.exercise.tags.length === 0) {
      return false;
    }

    const autoSkipTag = this.lesson.record.exercise.tags.find((tag) => /auto_skip/.test(tag.name));

    if (!autoSkipTag) {
      return false;
    }

    if (autoSkipTag && this.onboardingService.isFastOnboardingEnabled()) {
      this.autoSkipVideo = true;
      this.changeDetector.detectChanges();
    }
  }

  selectAlternative(alternativeId) {
    this.playerController.next('pause');
    this.playerController.next('reset');

    this.mediaFileAlternativeToPlay = alternativeId;
    this.changeDetector.detectChanges();

    this.store.dispatch(
      new SetDefaultMediaFileForExercise(this.exercise.id, this.mediaFileAlternativeToPlay)
    );
  }

  alternativeMinutes(mediaFile: MediaFile) {
    return Math.round(mediaFile.length / 60);
  }

  closePlayer() {
    this.onClose.emit();
  }
}
