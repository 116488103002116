import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild
} from '@angular/core';
import { TapticService } from '../../services/taptic.service';
import { IonRange } from '@ionic/angular';
import { getFormattedTimeInMinutesAndSeconds } from '../../utils/format-time';
import { LoggerService } from '../../services/logger.service';
import { ClarityConfig } from '../../config/clarity.config';
import { takeUntil } from 'rxjs/operators';
import { PluginListenerHandle } from '@capacitor/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { BrightcoveWebAudioPlayerService } from 'src/app/services/brightcove/brightcove-web-audio-player.service';
import { BrightcoveWebPlayerLoaderService } from 'src/app/services/brightcove/brightcove-web-player-loader.service';
import { AudioPlayerStatus } from './audio-player-status.enum';
import { BrightcoveNativeAudioPlayerService } from '../../services/brightcove/brightcove-native-audio-player.service';
import { AudioNotificationOptions, BrightcovePlayer } from 'capacitor-brightcove-player';

@Component({
  selector: 'cl-audio-player-brightcove',
  styleUrls: ['audio-player-brightcove.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="page-card">
      <div #audio style="display:none"></div>

      <div class="audio-player">
        <ion-range #rangeBar
          (ionStart)="onSeekStart()"
          (ionEnd)="onSeekEnd()"
          (ionChange)="onRangeChange($event)"
          max="{{audioTotalLength || 100}}"
          min="0"
          aria-hidden="true">
        </ion-range>
        <div class="slider-infos">
          <ion-label>{{ displayCurrentTime }}</ion-label>
          <ion-label>{{ displayTotalLength }}</ion-label>
        </div>
        <div class="controls">
          <div class="seek backward" (click)="backwardFifteenSec()">
            <ion-icon name="play-back"></ion-icon>
            <span>{{'common.15_sec' | translate}}</span>
          </div>
          <cl-play-button
            [loading]="loading"
            [playing]="playing"
            (click)="playing ? pausePlay() : resumePlay()"
            [attr.aria-label]="playing ? 'pause' : 'play'"
            role="button"
          >
          </cl-play-button>
          <div class="seek forward" (click)="forwardFifteenSec()">
            <span>{{'common.15_sec' | translate}}</span>
            <ion-icon name="play-forward"></ion-icon>
          </div>
        </div>
      </div>
    </div>
  `
})
export class AudioPlayerBrightcoveComponent implements OnInit, OnDestroy, OnChanges {
  readonly MINIMUM_PLAYED_SECS = 1;

  @ViewChild('rangeBar') rangeBar: IonRange;
  @ViewChild('audio', { static: true }) webPlayerElement: ElementRef;

  @Input() brightcove_key: string;
  @Input() controls: Observable<string>;
  @Input() autoplay = true;

  @Output() canPlay = new EventEmitter();
  @Output() canPlayThrough = new EventEmitter();
  @Output() playerError = new EventEmitter();
  @Output() playedMinimum = new EventEmitter();
  @Output() completed = new EventEmitter();

  playing = false;
  loading = true;
  isSeeking = false;
  playedMinimumEmitted = false;
  currentTimeInSeconds: number;
  audioTotalLength = 0;
  displayTotalLength = '';
  notificationCloseListener: PluginListenerHandle;
  controlsSubscription: Subscription;

  private unsubscribe$: Subject<boolean> = new Subject<boolean>();

  audioPlayerService;

  constructor(
    private tapticService: TapticService,
    private brightcoveWebPlayerLoaderService: BrightcoveWebPlayerLoaderService,
    private nativeAudioPlayerService: BrightcoveNativeAudioPlayerService,
    private webAudioPlayerService: BrightcoveWebAudioPlayerService,
    private logger: LoggerService,
    private config: ClarityConfig,
    private cdr: ChangeDetectorRef
  ) {
    if (!this.config.isDevice && !this.brightcoveWebPlayerLoaderService.isPlayerLoaded()) {
      this.brightcoveWebPlayerLoaderService.forcePlayerLoad();
    }
  }

  get displayCurrentTime() {
    if (isNaN(this.currentTimeInSeconds)) {
      return '00:00';
    } else {
      return getFormattedTimeInMinutesAndSeconds(this.currentTimeInSeconds);
    }
  }

  async ngOnInit() {
    if (!this.brightcove_key) {
      this.logger.error('BrightcovePlayerError', 'Audio player requested, but no Brightcove key provided');

      return this.playerError.emit();
    }

    this.audioPlayerService = this.config.isDevice ? this.nativeAudioPlayerService : this.webAudioPlayerService;

    this.load()
      .then(() => {
        this.addPlayerListeners();

        if (this.controls) {
          this.controlsSubscription = this.controls.pipe(takeUntil(this.unsubscribe$)).subscribe((action) => this.parentPlayControl(action));
        }
      });
  }

  load(): Promise<void> {
    this.updateProgressBar(0);
    let options: { brightcove_key: string; webPlayerElement?: ElementRef; notificationOptions?: AudioNotificationOptions };

    if(this.config.isDevice) {
      options = {
        brightcove_key: this.brightcove_key,
        notificationOptions: {
          forwardIncrementMs: 15000,
          rewindIncrementMs: 15000
        }};
    } else {
      options = {
        brightcove_key: this.brightcove_key,
        webPlayerElement: this.webPlayerElement
      };
    }

    return this.audioPlayerService.load(options);
  }

  ngOnDestroy() {
    this.notificationCloseListener && this.notificationCloseListener.remove();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    this.closePlayer();
  }

  private addPlayerListeners() {
    this.audioPlayerService.position$.pipe(takeUntil(this.unsubscribe$))
      .subscribe(position => {
        if (this.isSeeking) {
          return;
        }
        this.currentTimeInSeconds = position / 1000;
        this.updateProgressBar(this.currentTimeInSeconds);

        if (this.currentTimeInSeconds > this.MINIMUM_PLAYED_SECS && !this.playedMinimumEmitted) {
          this.playedMinimum.emit();
          this.playedMinimumEmitted = true;
        }
      });

    this.audioPlayerService.playerStatus.pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (status: AudioPlayerStatus) => {
        this.loading = status === AudioPlayerStatus.MEDIA_LOADING || status === AudioPlayerStatus.STARTING;
        this.playing = status === AudioPlayerStatus.RUNNING;

        switch (status) {
          case AudioPlayerStatus.MEDIA_LOADED:
            this.canPlayThrough.emit(true);
            this.autoplay && this.resumePlay();
            break;
          case AudioPlayerStatus.ERROR:
            this.playerError.emit();
            break;
          case AudioPlayerStatus.COMPLETED_WATCHED:
            this.completed.emit();
            break;
        }

        this.cdr.detectChanges();
      });
  }

  pausePlay() {
    this.audioPlayerService.pause();
  }

  closePlayer() {
    this.audioPlayerService.stop()
      // Unload before closing modal to avoid error when closing/opening audio lesson too fast
      .then(() => this.audioPlayerService.unload())
      .catch(reason => {
        this.logger.error('Error closing audio player', reason);
      });
  }

  onSeekStart(): void {
    this.tapticService.impact();
    this.isSeeking = true;
  }

  onSeekEnd() {
    this.tapticService.impact();

    if (this.isSeeking) {
      this.setCurrentTime(this.currentTimeInSeconds);
      this.isSeeking = false;
    }
  }

  resumePlay() {
    this.audioPlayerService.play().catch(reason => {
      this.logger.info('Error playing audio', reason);
    });
  }

  setCurrentTime(newTime): Promise<void> {
    this.currentTimeInSeconds = newTime;

    return this.audioPlayerService.seekTo(newTime);
  }

  onRangeChange(event) {
    this.currentTimeInSeconds = event.target.value;
  }

  ngOnChanges(changes) {
    if (changes.brightcove_key) {
      const { brightcove_key } = changes;
      const hasBrightcoveKeyChanges = brightcove_key && brightcove_key.currentValue !== brightcove_key.previousValue && !brightcove_key.firstChange;

      if (!hasBrightcoveKeyChanges) {
        return;
      }

      if (brightcove_key && brightcove_key.currentValue) {
        this.load();
      }
    }
  }

  async forwardFifteenSec() {
    if(this.playing) {
      await this.audioPlayerService.jumpBy(15);
    }
  }

  async backwardFifteenSec() {
    if(this.playing) {
      this.audioPlayerService.jumpBy(-15);
    }
  }

  parentPlayControl(action) {
    switch (action) {
      case 'play':
        return this.resumePlay();
      case 'pause':
        return this.pausePlay();
      default:
        return false;
    }
  }

  protected updateProgressBar(position) {
    if (position > this.audioPlayerService.getDuration()) {
      position = this.audioPlayerService.getDuration();
    }
    if (this.rangeBar) {
      this.rangeBar.value = position;
    }
    this.audioTotalLength = this.audioPlayerService.getDuration() > 0 ? (this.audioPlayerService.getDuration() / 1000) : 0;
    this.displayTotalLength = getFormattedTimeInMinutesAndSeconds((this.audioTotalLength >= 0) ? this.audioTotalLength : 0);
    this.cdr.detectChanges();
  }
}
