import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { SettingsComponent } from './settings.component';
import { IntegrationsService } from 'src/app/services/integrations.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store';
import { Subscription } from 'rxjs';
import { ClarityConfig } from '../../../config/clarity.config';
import * as integrationsActions from '../../../store/session/actions/integrations.actions';
import {AlertsService} from '../../../services/alerts.service';
import { TranslateService } from '@ngx-translate/core';
import {
  isGoogleFitConnected,
  isHealthKitConnected,
  isFitbitConnected,
  isGoogleFitAuthorizationsNotDetermined
} from '../../../store/session/selectors/integrations.selectors';
import { FitbitCallback } from '../../../store/session/models/integration.model';
import { ConnectivityService } from '../../../services/connectivity.service';
import { BrowserService } from 'src/app/services/browser.service';
import { slideInOut } from 'src/app/utils/animations';

/**
 * component to show software integrations, for example with Fitbit or Apple Healthkit
 */
@Component({
  selector: 'cl-account-integrations',
  styleUrls: ['integrations.component.scss'],
  animations: [slideInOut],
  template: `
    <div [@state]="visibility"
         (@state.done)="animationDone($event)">
      <div class="integrations">
        <!-- fitbit -->
        <ion-row class="integration" >
          <ion-col class="health-logo-col ion-align-self-center">
            <div class="health-image fitbit"></div>
          </ion-col>
          <ion-col  class="ion-align-self-center">
            <div>{{ 'my_devices.fitbit' | translate}}</div>
          </ion-col>
          <ion-col  class="ion-align-self-center ion-text-end">
            <ng-template *ngIf="isFitBitConnected$ | async; then disconnectFitbitSection else connectFitbitSection"></ng-template>

            <ng-template #connectFitbitSection>
                <div tappable class="connect" (click)="connectFitbit()"> {{ 'my_devices.connect' | translate}}</div>
            </ng-template>

            <ng-template #disconnectFitbitSection>
              <div tappable class="connect" (click)="disconnectFitbit()"> {{ 'my_devices.disconnect' | translate}}</div>
            </ng-template>
          </ion-col>
        </ion-row>

        <!-- healthkit -->
        <div class="integration" *ngIf="config.isIos">
          <ion-row>
            <ion-col class="health-logo-col ion-align-self-center">
              <div class="health-image ios"></div>
            </ion-col>
            <ion-col  class="ion-align-self-center">
              <div>{{ 'my_devices.apple_health' | translate}}</div>
            </ion-col>
            <ion-col class="ion-align-self-center ion-text-end">
              <ng-template *ngIf="isHealthKitConnected$ | async; then disconnectHealthKitSection else connectHealthKitSection"></ng-template>

              <ng-template #connectHealthKitSection >
                <div tappable class="connect" (click)="connectHealthKit()">
                  {{ 'my_devices.connect' | translate}}
                </div>
              </ng-template>

              <ng-template #disconnectHealthKitSection >
                <div tappable class="connect" (click)="disconnectHealthKit()">
                  {{ 'my_devices.disconnect' | translate}}
                </div>
              </ng-template>
            </ion-col>
          </ion-row>
          <ion-row  *ngIf="isHealthKitConnected$ | async">
            <ion-col>
              <ion-button
              class="resync-button"
              (click)="resyncData()">
                {{'my_devices.resync_data' | translate}}
              </ion-button>
            </ion-col>
          </ion-row>
        </div>

        <!-- google fit -->
        <div class="integration"  *ngIf="config.isAndroid">
          <ion-row>
            <ion-col class="health-logo-col ion-align-self-center">
              <div class="health-image android"></div>
            </ion-col>
            <ion-col class="ion-align-self-center">
              <div>{{ 'my_devices.google_fit' | translate}}</div>
            </ion-col>
            <ion-col class="ion-align-self-center ion-text-end">
              <ng-template *ngIf="isGoogleFitConnected$ | async; then disconnectGoogleFitSection else connectGoogleFitSection"></ng-template>

              <ng-template #connectGoogleFitSection >
                <div tappable class="connect" (click)="connectGoogleFit()">
                  {{ 'my_devices.connect' | translate }}
                </div>
              </ng-template>

              <ng-template #disconnectGoogleFitSection >
                <div tappable class="connect" (click)="disconnectGoogleFit()">
                  {{ 'my_devices.disconnect' | translate}}
                </div>
              </ng-template>
            </ion-col>
          </ion-row>
          <ion-row  *ngIf="(isGoogleFitConnected$ | async) && !(isGoogleFitAuthorizationsNotDetermined$ | async)">
            <ion-col>
              <ion-button
                class="resync-button"
                (click)="resyncData()">
                {{'my_devices.resync_data' | translate}}
              </ion-button>
            </ion-col>
          </ion-row>
        </div>

        <div class="authorizations" *ngIf="config.isAndroid && isGoogleFitAuthorizationsNotDetermined$ | async">
          <div class="information" [innerHTML]="'my_devices.google_fit_authorization_not_determined' | translate"></div>
          <div class="authorize-health">
            <cl-action-button
              (click)="connectGoogleFit()"
              [label]="'my_devices.authorize' | translate"
              [canClick]="true"
              color="white">
            </cl-action-button>
          </div>
        </div>

        <div class="authorizations" *ngIf="config.isIos && isHealthKitConnected$ | async">
          <div class="information" [innerHTML]="'my_devices.check_authorizations' | translate" ></div>
          <div class="open-health">
            <cl-action-button
              (click)="openHealthApp()"
              [label]="'my_devices.open_healthkit' | translate"
              [canClick]="true"
              color="white">
            </cl-action-button>
          </div>
          <a (click)="openHealthKitHelp()" class="learn-more">{{ 'my_devices.learn_more' | translate }}</a>
        </div>
      </div>
    </div>
  `
})
export class IntegrationsComponent implements SettingsComponent, OnInit, OnDestroy {
  @Output() endAnimation = new EventEmitter();

  isHealthKitConnected$ = this.store.select(isHealthKitConnected);
  isGoogleFitConnected$ = this.store.select(isGoogleFitConnected);
  isFitBitConnected$ = this.store.select(isFitbitConnected);
  isGoogleFitAuthorizationsNotDetermined$ = this.store.select(isGoogleFitAuthorizationsNotDetermined);

  queryParamsSubscription: Subscription;

  visibility: 'visible' | 'hidden' = 'visible';

  constructor(
    private integrationsService: IntegrationsService,
    private activatedRoute: ActivatedRoute,
    private store: Store<State>,
    public config: ClarityConfig,
    private alerts: AlertsService,
    private translate: TranslateService,
    private connectivityService: ConnectivityService,
    private router: Router,
    private browser: BrowserService
  ) {}

  ngOnInit() {
    this.queryParamsSubscription = this.handleIntegrationCallbackParams();
  }

  ngOnDestroy() {
    this.queryParamsSubscription.unsubscribe();
  }

  handleIntegrationCallbackParams() {
    return this.activatedRoute.queryParams.subscribe((queryParams: FitbitCallback) => {
      console.log('[IntegrationsComponent] queryParams', queryParams);
      const { callback, status, error } = queryParams;
      if (!callback) {
        return;
      }

      this.store.dispatch(new integrationsActions.ConnectFitBit(status, error));

      this.removeCurrentQueryParams();
    });
  }

  animationDone(event) {
    if (event.toState === 'hidden') {
      this.endAnimation.emit(true);
    }
  }

  closeComponent() {
    this.visibility = 'hidden';
  }

  connectFitbit() {
    this.integrationsService.goToFitbitConnectionPage({
      returnPath: this.config.env.fitbitCallbackDevicesPath.replace('/', '')
    });
  }

  disconnectFitbit() {
    this.translate.get(
      ['my_devices.disconnect_fitbit', 'my_devices.disconnect_fitbit_confirmation' ,'my_devices.disconnect', 'common.cancel']
    )
      .toPromise()
      .then(async translations => {
        const dialog = await this.alerts.alertController.create({
          header: translations['my_devices.disconnect_fitbit'],
          message: translations['my_devices.disconnect_fitbit_confirmation'],
          buttons: [
            { text: translations['common.cancel'], role: 'cancel' },
            {
              text: translations['my_devices.disconnect'],
              handler: () => this.store.dispatch(new integrationsActions.DisconnectFitBit())
            }
          ]
        });
        await dialog.present();
      });
  }

  connectHealthKit() {
    this.store.dispatch(new integrationsActions.ConnectHealthKit());
  }

  connectGoogleFit() {
    this.store.dispatch(new integrationsActions.ConnectGoogleFit());
  }

  async disconnectHealthKit() {
    this.translate.get(
      ['my_devices.disconnect_healthkit', 'my_devices.disconnect_healthkit_confirmation' ,'my_devices.disconnect', 'common.cancel']
    )
      .toPromise()
      .then(async translations => {
        const dialog = await this.alerts.alertController.create({
          header: translations['my_devices.disconnect_healthkit'],
          message: translations['my_devices.disconnect_healthkit_confirmation'],
          buttons: [
            { text: translations['common.cancel'], role: 'cancel' },
            {
              text: translations['my_devices.disconnect'],
              handler: () => this.store.dispatch(new integrationsActions.DisconnectHealthKit())
            }
          ]
        });
        await dialog.present();
      });
  }

  async disconnectGoogleFit() {
    this.translate.get(
      ['my_devices.disconnect_google_fit', 'my_devices.disconnect_google_fit_confirmation' ,'my_devices.disconnect', 'common.cancel']
    )
      .toPromise()
      .then(async translations => {
        const dialog = await this.alerts.alertController.create({
          header: translations['my_devices.disconnect_google_fit'],
          message: translations['my_devices.disconnect_google_fit_confirmation'],
          buttons: [
            { text: translations['common.cancel'], role: 'cancel' },
            {
              text: translations['my_devices.disconnect'],
              handler: () => this.store.dispatch(new integrationsActions.DisconnectGoogleFit())
            }
          ]
        });

        await dialog.present();
      });
  }

  async resyncData() {
    if(this.connectivityService.isOffline()) {
      return this.displayOfflinePopup();
    }

    this.translate.get(
      [
        'my_devices.resync_healthkit',
        'my_devices.resync_google_fit',
        'my_devices.resync_healthkit_confirmation',
        'my_devices.resync_googlefit_confirmation',
        'my_devices.resync',
        'common.cancel'
      ]
    )
      .toPromise()
      .then(async translations => {
        const dialog = await this.alerts.alertController.create({
          header: translations[this.config.isAndroid ? 'my_devices.resync_google_fit':'my_devices.resync_healthkit'],
          message: translations[this.config.isAndroid ? 'my_devices.resync_googlefit_confirmation':'my_devices.resync_healthkit_confirmation'],
          buttons: [
            { text: translations['common.cancel'], role: 'cancel' },
            {
              text: translations['my_devices.resync'],
              handler: () => this.store.dispatch(new integrationsActions.GetAllHealthData())
            }
          ]
        });

        await dialog.present();
      });
  }

  openHealthApp() {
    this.store.dispatch(new integrationsActions.OpenHealthApp());
  }

  private displayOfflinePopup() {
    this.connectivityService.preventAccessWhenOffline();
  }

  private removeCurrentQueryParams() {
    if (this.config.isWebApp) {
      history.pushState(null, '', window.location.pathname);
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {}
      }
    );
  }

  openHealthKitHelp() {
    this.browser.goTo('https://claritasmindsciences.zendesk.com/knowledge/articles/4413288557971/en-us?brand_id=353088');
  }
}
